import Page from "../../components/page/Page";
import NotFoundImage from "../../assets/images/undraw_page_not_found_su7k.svg";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NotFoundView = () => {
  return (
    <Page title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h3"
            sx={{
              lineHeight: 1.2,
              letterSpacing: "-0.5px",
            }}
          >
            Oops! Page Not Found
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="body1"
            sx={{ paddingTop: "1.5rem", marginX: "1rem" }}
          >
            You either tried some shady route or you came here by mistake.
            <br />
            Whichever it is, try using the navigation
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              src={NotFoundImage}
              style={{
                marginTop: 50,
                display: "inline-block",
                maxWidth: "100%",
                width: "40rem",
              }}
            />
          </Box>
        </Container>
        <Button
          sx={{ marginTop: "2rem" }}
          variant="contained"
          color="primary"
          component={Link}
          to="/dashboard"
        >
          Go Home
        </Button>
      </Box>
    </Page>
  );
};

export default NotFoundView;
