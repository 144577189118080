import React from "react";
import { Container, Card, TablePagination } from "@mui/material";
import Page from "components/page/Page";
import GenericTable from "common/components/datapage/GenericTable";

interface DataPageProps<T> {
  title: string;
  columns: Array<{ key: string; label: string }>;
  data: T[];
  totalCount: number;
  loading: boolean;
  page: number;
  count: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderItem: (item: T) => React.ReactNode;
  ToolbarComponent?: React.ReactNode;
  SearchbarComponent?: React.ReactNode;
  cardProps?: Record<string, unknown>; // Props for the Card component
  paginationProps?: Record<string, unknown>; // Props for the TablePagination component
  tableProps?: Record<string, unknown>; // Props for the GenericTable component
}

const DataPage = <T,>({
  title,
  columns,
  data,
  totalCount,
  loading,
  page,
  count,
  onPageChange,
  onRowsPerPageChange,
  renderItem,
  ToolbarComponent,
  SearchbarComponent,
  cardProps = {},
  paginationProps = {},
  tableProps = {},
}: DataPageProps<T>) => {
  return (
    <Page title={title}>
      <Container maxWidth="xl">
        {ToolbarComponent && ToolbarComponent}
        {SearchbarComponent && SearchbarComponent}
        <Card sx={{ mt: 3 }} {...cardProps}>
          <GenericTable
            columns={columns}
            data={data}
            totalCount={totalCount}
            loading={loading}
            renderItem={renderItem}
            {...tableProps}
          />
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={count}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25]}
            {...paginationProps}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default DataPage;
