import { useQuery } from '@apollo/client';
import { GET_RECORDINGS } from 'adapters/queries/GetRecording';
import { useEffect } from 'react'
import ReactPlayer from "react-player";
import Carousel from 'react-material-ui-carousel'
import { Box } from '@material-ui/core';

const RecordingPlayer = ({ id }: { id: string }) => {

    const { data, loading } = useQuery(GET_RECORDINGS, {
        variables: { chatRoomId: id },
    });

    useEffect(() => {
        console.log('Player Open')
    }, [])

    if (loading) {
        return (
            <div>Loading Recordings...</div>
        )
    }

    return data?.getRecordings?.urls?.length > 0 ?
        <Box width="100%"
            height="100%">
            <Carousel>
                {
                    data?.getRecordings?.urls.map((url: string, i: number) => <ReactPlayer
                        key={url + '' + i}
                        url={url}
                        width="100%"
                        height="100%"
                        controls={true} />)
                }
            </Carousel>
        </Box>
        : <div>No recording</div>
}

export default RecordingPlayer