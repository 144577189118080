import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import DataPage from "common/components/datapage/DataPage";
import CommonToolbar from "common/components/toolbar/Toolbar";
import CommonSearchbar from "common/components/searchbar/Searchbar";
import { useMemo } from "react";
import CallItem from "../../components/list_item/ListItem";
import { ANSWERD_AND_MISSED_CALLS } from "modules/agent_sessions/adapters/queries/AnsweredAndMissedCalls";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const CallList = (props: any): JSX.Element => {

  let customerId = props.match?.params?.customerId;

  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
    setSort,
  } = usePaginationQuery(ANSWERD_AND_MISSED_CALLS, {customerId: customerId}, "customers");

  const columns = [
    { key: "name", label: "Customer" },
    { key: "email", label: "Agent" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Creted At" },
    { key: "record", label: "Record" },
    { key: "chat", label: "Chat" },
    { key: "feedback", label: "Feedback" },
  ];

  const totalCount = data?.answeredAndMissed?.totalCount || 0;
  const { answeredAndMissed } = data || {};
  const list = useMemo(() => {
    return answeredAndMissed && answeredAndMissed.edges
      ? answeredAndMissed.edges
          .filter(isNotNull)
          .map((edge: any) => edge.node)
          .filter(isNotNull)
      : [];
  }, [answeredAndMissed]);

  return (
    <DataPage
      title="Agent Sessions"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      setSort={setSort}
      renderItem={(node: any) => (
        <CallItem key={node.id} session={node} />
      )}
      ToolbarComponent={
        <CommonToolbar title="Agent Sessions" />
      }
      SearchbarComponent={
        <CommonSearchbar
          defaultValues={{ search: "" }}
          fields={[
            {
              name: "search",
              // label: "Search by name or email",
              type: "text",
            },
          ]}
          onSearch={setSearchQuery}
        />
      }
      paginationProps={{ rowsPerPageOptions: [10, 25, 50] }} // Example customization
    />
  );
};

export default CallList;
