import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            padding: theme.spacing(1, 2),
            textAlign: "center",
        },
        cardTitle: {
            fontSize: "1.25rem",
        },
        feedback: {
            marginTop: theme.spacing(2),
            textTransform: "capitalize",
        },
        title: {
            fontSize: 14,
        },
        error: {
            textTransform: "capitalize",
        },
        button: {
            color: "white",
        },
        buttonWrapper: {
            position: "relative",
        },
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -9,
            marginLeft: -12,
        },
        option: {
            fontSize: 15,
            "& > span": {
                marginRight: 10,
                fontSize: 18,
            },
        },
        logo: {
            width: "60%"
        }
    })
);