import ResetPassword from "./components/reset_password/ResetPassword";
import EditUser from "./pages/UsersEditPage";
import AddUser from "./pages/UsersAddPage";
import UserList from "./pages/UsersPage";
import { ModuleRouteConfig } from "common/types/RouteTypes";
import UsersTestPage from "modules/users/pages/UsersTestPage";

export const UserRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "edit_users",
    path: "/password/change/:id",
    element: <ResetPassword />,
  },
  {
    permissionId: "edit_users",
    path: "/edit/:id/:mode",
    element: <EditUser />,
  },
  {
    permissionId: "edit_users",
    path: "/edit/:id",
    element: <EditUser />,
  },
  {
    permissionId: "create_users",
    path: "/add",
    element: <AddUser />,
  },
  {
    permissionId: "view_users",
    path: "/test",
    element: <UsersTestPage />,
  },
  {
    permissionId: "view_users",
    path: "",
    element: <UserList />,
  },
];
