import * as React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { useStyles } from "./styles";
import { CREATE_DEFAULT_ROLE } from "superadmin/modules/subscriptions/adapters/mutations/CreateDefaultRole";
import { useParams } from "react-router-dom";
import { UPDATE_DEFAULT_ROLE } from "superadmin/modules/subscriptions/adapters/mutations/UpdateDefaultRole";

const CreateUpdateRoleModal = ({
  rolesRefetch,
  roles,
  editMode = false,
  roleToEdit = null,
}: {
  rolesRefetch: any;
  roles: any[];
  editMode?: boolean;
  roleToEdit?: any;
}) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [roleName, setRoleName] = useState<string>("");
  const [parentRole, setParentRole] = useState<string>("");

  const [createRole] = useMutation(CREATE_DEFAULT_ROLE, {
    onCompleted: () => {
      setSnack({
        message: "Role Created Successfully",
        severity: "success",
        open: true,
      });
      rolesRefetch();
      setOpen(false);
    },
  });

  const [updateRole] = useMutation(UPDATE_DEFAULT_ROLE, {
    onCompleted: () => {
      setSnack({
        message: "Role Updated Successfully",
        severity: "success",
        open: true,
      });
      rolesRefetch();
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
    if (editMode && roleToEdit) {
      setRoleName(roleToEdit.name);
      setParentRole(roleToEdit.parentDefaultRoleId || "");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.target.value);
  };

  const handleParentRoleChange = (e: SelectChangeEvent<string>) => {
    setParentRole(e.target.value as string);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (editMode) {
      updateRole({
        variables: {
          defaultRole: {
            id: roleToEdit.id,
            name: roleName,
            parentDefaultRoleId: parentRole || null,
          },
        },
      });
    } else {
      createRole({
        variables: {
          defaultRole: {
            name: roleName,
            parentDefaultRoleId: parentRole || null,
            subscriptionId: id,
          },
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center">
        <IconButton size="small" onClick={handleClickOpen}>
          {editMode ? (
            <EditIcon fontSize="small" />
          ) : (
            <AddIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ marginY: 1 }}>
            {editMode ? "Update Role" : "Create New Role"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="roleName"
              label="Role Name"
              value={roleName}
              onChange={handleRoleNameChange}
              fullWidth
              variant="outlined"
            />
            {(!editMode || (editMode && !roleToEdit?.isOwner)) && (
              <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel id="parentRole-label">Parent Role</InputLabel>
                <Select
                  labelId="parentRole-label"
                  id="parentRole"
                  value={parentRole}
                  onChange={handleParentRoleChange}
                  label="Parent Role"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {roles.map((role) =>
                    !editMode || role.id !== roleToEdit.id ? (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ) : null
                  )}
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="secondary" type="submit">
              {editMode ? "Update" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateUpdateRoleModal;
