import { createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme?.spacing(2),
            width: '50rem',
        },
        closeButton: {
            position: "absolute",
            right: theme?.spacing(1),
            top: theme?.spacing(1),
            color: theme?.palette.grey[500],
        },
        rating: {
            display: 'flex',
        },
        label: {
            flexDirection: 'column'
        }
    });