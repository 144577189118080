import { gql } from "@apollo/client";

export const CREATE_SESSION = gql`
mutation createSession($input: CreateSessionInput!){
    createSession(input: $input){
      sessionEdge{
        node {
          id,
          createdAt,  
        }
        cursor
      }
    }
  }
`