import { gql } from "@apollo/client";

export const GENERATE_TOKEN = gql`
mutation generateToken($input: GenerateTokenInput!) {
    generateToken(input: $input){
      token
    }
  }
`

export const GENERATE_JAAS_TOKEN = gql`
mutation generateJaasToken($input: GenerateJaasTokenInput!){
  generateJaasToken(input: $input){
    token
  }
}
`