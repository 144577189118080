import React, { useEffect } from "react";
import { Card, Box, Grid } from "@mui/material";
import GenericTable from "common/components/datapage/GenericTable";
import CustomerItem from "../customer_item/CustomerItem";
import useStyles from "./style";
import { Customer } from "../live_conversations/LiveConversation";
import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import { useSocket } from "context/SocketProvider";
import { GET_CUSTOMERS } from "adapters/queries/GetCustomersData";

interface CustomersListProps {
  isSmallScreen: boolean;
  onSelectCustomer: (customer: Customer) => void;
}

const CustomersList: React.FC<CustomersListProps> = ({
  isSmallScreen,
  onSelectCustomer,
}) => {
  const classes = useStyles({ isSmallScreen });
  const { socket } = useSocket();

  const columns = [{ key: "customerDetails", label: "Active Customers" }];

  const { data, loading, setPage, refetch } = usePaginationQuery(
    GET_CUSTOMERS,
    {},
    "getCustomers"
  );

  useEffect(() => {
    if (socket?.connected) {
      socket?.on("userUpdated", () => {
        refetch();
      });
    }

    return () => {
      if (socket?.connected) {
        socket?.off("userUpdated");
      }
    };
  }, [socket]);

  const customers = data?.getAssignedCustomers || [];

  useEffect(() => {
    setPage(0);
    refetch();
  }, [refetch]);

  return (
    <Grid item md={4} sm={12}>
      <Card className={classes.customerCard}>
        <Box className={classes.scrollBox}>
          <GenericTable
            columns={columns}
            data={customers}
            totalCount={customers.length}
            loading={loading}
            renderItem={(node: any) => (
              <CustomerItem
                key={node.id}
                customer={node}
                onClick={() => onSelectCustomer(node)}
              />
            )}
          />
        </Box>
      </Card>
    </Grid>
  );
};

export default CustomersList;
