import { gql } from "@apollo/client";
import { BUSINESS_HOURS_BUSINESS_ADMIN_CALL_FOR_HELP } from "adapters/fragments/BusinessHoursBusinessAdminCallForHelp";

export const CALL_FOR_HELP_BUSINESS_ADMIN = gql`
    ${BUSINESS_HOURS_BUSINESS_ADMIN_CALL_FOR_HELP}
    query botprofile($id: String!) {
        botprofile(id: $id) {
            profilePicture
            ...BusinessHoursAccountBotProfile
            id
        }
    }
`;
