//@ts-nocheck
import React from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Rating from "@material-ui/lab/Rating";
import RateReview from "@material-ui/icons/RateReview";

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children?: React.ReactNode | null;
  onClose: () => void;
}

interface Props {
  feedbackQuality: number;
  feedbackComment: string;
}

const DialogTitle = withStyles(useStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function FeedbackContent({ feedbackQuality, feedbackComment, classes }: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  console.log("FEEDBACK QUALITY", feedbackQuality);
  console.log("FEEDBACK COMMENT", feedbackComment);

  return (
    <div>
      <IconButton
        aria-label="Actions"
        aria-controls="actions-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClickOpen}
        classes={classes}
      >
        <RateReview />
        <Typography>Feedback</Typography>
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Feedback
        </DialogTitle>
        <DialogContent>
          {feedbackQuality !== 0 && (
            <>
              <Typography style={{ fontWeight: 600 }}> Rating: </Typography>
              <Rating name="read-only" value={feedbackQuality} readOnly />
              <br />
              <br />
            </>
          )}
          {feedbackComment && (
            <>
              <Typography style={{ fontWeight: 600 }}>Comments: </Typography>
              <Typography>{feedbackComment}</Typography>
            </>
          )}
          {!feedbackQuality && !feedbackComment && (
            <Typography>No feedback given</Typography>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
const Feedback = withStyles(() => ({
  label: {
    flexDirection: 'column'
  },
}))(FeedbackContent);

export default Feedback