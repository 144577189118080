import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRouteConfig } from "common/types/RouteTypes";
import { RootState } from "redux/reducers/rootReducer";
import { TicketRoutes } from "modules/tickets/Routes";
import { UserRoutes } from "modules/users/Routes";
import { DashboardRoutes } from "modules/dashboard/Routes";
import { SubscriptionRoutes } from "modules/subscriptions/Routes";
import { CustomerRoutes } from "modules/customers/Routes";
import { CallbackRoutes } from "modules/callbacks/Routes";
import { AgentSessionRoutes } from "modules/agent_sessions/Routes";
import { AppointmentRoutes } from "modules/appointments/Routes";
import { BotSessionRoutes } from "modules/bot_sessions/Routes";
import { BotProfileRoutes } from "modules/bot_profiles/Routes";
import { PaymentRoutes } from "modules/payments/Routes";
import { EventLogRoutes } from "modules/event_log/Routes";
import { AccountRoutes } from "modules/account/Routes";
import { LiveConversationRoutes } from "modules/live_conversations/Routes";
import Layout from "common/layouts/User/Layout";
import NotFoundPage from "modules/auth/NotFoundPage";
import { AddOnRoutes } from "modules/addons/Routes";

const routeConfig: PrivateRouteConfig[] = [
  {
    pageId: "dashboard",
    path: "/dashboard",
    routes: DashboardRoutes,
  },
  {
    pageId: "subscriptions",
    path: "/subscriptions",
    routes: SubscriptionRoutes,
  },
  {
    pageId: "addons",
    path: "/addons",
    routes: AddOnRoutes,
  },
  {
    pageId: "users",
    path: "/users",
    routes: UserRoutes,
  },
  {
    pageId: "tickets",
    path: "/tickets",
    routes: TicketRoutes,
  },
  {
    pageId: "customers",
    path: "/customers",
    routes: CustomerRoutes,
  },
  {
    pageId: "appointments",
    path: "/appointments",
    routes: AppointmentRoutes,
  },
  {
    pageId: "callbacks",
    path: "/callbacks",
    routes: CallbackRoutes,
  },
  {
    pageId: "agentSessions",
    path: "/agent-sessions",
    routes: AgentSessionRoutes,
  },
  {
    pageId: "liveConversations",
    path: "/live-conversations",
    routes: LiveConversationRoutes,
  },
  {
    pageId: "botSessions",
    path: "/bot-sessions",
    routes: BotSessionRoutes,
  },
  {
    pageId: "botProfiles",
    path: "/bot-profile",
    routes: BotProfileRoutes,
  },
  {
    pageId: "payments",
    path: "/payments",
    routes: PaymentRoutes,
  },
  {
    pageId: "eventLog",
    path: "/eventlogs",
    routes: EventLogRoutes,
  },
  {
    pageId: "settings",
    path: "/account",
    routes: AccountRoutes,
  },
];

const PrivateRoutes = () => {
  const { isAuthenticated, permissions } = useSelector(
    (state: RootState) => state.auth
  );

  const hasAnyPermission = (pageId: string): boolean => {
    return permissions[pageId] && permissions[pageId].length > 0;
  };

  const hasItemPermission = (pageId: string, permissionId: string): boolean => {
    return permissions[pageId] && permissions[pageId].includes(permissionId);
  };

  const routes = routeConfig.reduce((acc: JSX.Element[], route) => {
    const { pageId, path: basePath, routes: moduleRoutes } = route;

    if (hasAnyPermission(pageId) && moduleRoutes?.length > 0) {
      moduleRoutes.forEach((item) => {
        const { permissionId, path: subPath, element } = item;
        const key = `${pageId}__${permissionId}`;

        if (hasItemPermission(pageId, permissionId)) {
          acc.push(
            <Route
              key={key}
              path={basePath + subPath}
              element={!isAuthenticated ? <Navigate to="/login" /> : element}
            />
          );
        }
      });
    }

    return acc;
  }, []);

  return (
    <Layout>
      <Routes>
        {routes}
        <Route path="" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  );
};

export default PrivateRoutes;
