import { ReactNode } from "react";
import { Controller } from "react-hook-form";
// import { Typography } from "@material-ui/core";
// import FormField from "@material-ui/core/TextField";
import { useStyles } from "./style";
import { TextField, Typography } from "@mui/material";

interface FormInputProps {
  id?: string;
  control: any;
  error?: any;
  name: string;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  select?: any;
  children?: ReactNode;
  inputProps?: any;
  InputProps?: any;
  defaultValue?: any;
  InputLabelProps?: any;
  helperText?: string;
  inputRef?: any;
  size?: any;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  SelectProps?: any;
  value?: any;
  className?: string;
  style?: any;
  onBlur?: any;
  onChange?: any;
}
export default function FormInput({
  control,
  error,
  name,
  type,
  placeholder,
  autoComplete,
  label,
  select,
  children,
  defaultValue,
  InputLabelProps,
  inputProps,
  helperText,
  inputRef,
  size,
  required,
  SelectProps,
  // onBlur,
  // onChange,
  InputProps,
  // value,
  disabled,
  className,
  style,
}: FormInputProps) {
  const classes = useStyles();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            label={label}
            placeholder={placeholder}
            type={type}
            autoComplete={autoComplete}
            variant="outlined"
            select={select}
            children={children}
            defaultValue={defaultValue}
            InputLabelProps={InputLabelProps}
            inputProps={inputProps}
            helperText={helperText}
            inputRef={inputRef ? inputRef : ref}
            size={size}
            required={required}
            fullWidth={true}
            SelectProps={SelectProps}
            // value={value}
            disabled={disabled}
            className={className}
            style={style}
            InputProps={InputProps}
          // onBlur={onBlur}
          // onChange={onChange}
          />
        )}
      />
      {error && (
        <Typography className={classes.errorMessage}>
          {error.message}
        </Typography>
      )}
    </>
  );
}
