import { gql } from "@apollo/client";

export const ANSWERD_AND_MISSED_CALLS = gql`
query answeredAndMissed($status: String, $search: String , $count: Int , $endcursor: String , $startcursor: String , $reversecount: Int,$customerId: String) {
    answeredAndMissed(status: $status, search: $search, first: $count, after: $endcursor , before : $startcursor , last : $reversecount,  customerId: $customerId){
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          status
          createdAt
          updatedAt
          chatRoomId
          chatMessage
          feedbackQuality
          feedbackComment
          customerId
          duration
          user {
            firstName
            lastName
            email
          }
          customer {
            firstName
            lastName
            email
            phone
          }
        }
        cursor
      }
      totalCount
    }
  }
`;