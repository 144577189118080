import { gql } from "@apollo/client";

export const EDIT_BUSINESS_ADMIN_COMPANY = gql`
    fragment EditAccountCompany_data on Company {
        id
        name
        vat
        subdomain
        type
        phone
        address
        pgwSubscriptionPlanId
        planId
        contactEmailList
    }
`;
