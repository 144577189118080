import { useState } from "react";
import * as React from "react";
import clsx from "clsx";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useStyles } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/actions/AuthActions";
import { RootState } from "redux/reducers/rootReducer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface IProps {
  data?: any | null;
  error?: any | null;
  className?: string;
  isOpen: boolean;
  onMobileNavOpen: () => void;
  setOpen: any;
}

const TopBar = ({ onMobileNavOpen, isOpen, setOpen, ...rest }: IProps) => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onLogIn = (event: any) => {
    if (user) {
      event.preventDefault();
      dispatch(logout());
    }
    navigate("/login");
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    navigate(`/admin/users/edit/${user?.id}`);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpen,
      })}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Hidden mdDown>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <Link to="/admin" style={{ textDecoration: "none" }}>
          <Typography
            component="h1"
            variant="h6"
            display="inline"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
        </Link>

        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={onLogIn}>Logout</MenuItem>
            </Menu>
          </>
        </Hidden>

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
