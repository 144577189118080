import { JitsiActionTypes, AgentDetails, RESET_JITSI_STORE, UPDATE_AGENT_DETAILS, UPDATE_MESSAGES, UPDATE_CHAT_ROOM_ID } from '../actions/JitsiActions';


interface JitsiState {
    messages: Array<any>;
    agentDetails: AgentDetails | null;
    chatRoomId: null | string;

}

const initialState: JitsiState = {
    messages: [],
    agentDetails: null,
    chatRoomId: null
}


export const JitsiReducer = (state = initialState, action: JitsiActionTypes): JitsiState => {
    switch (action.type) {
        case RESET_JITSI_STORE: {
            return initialState
        }
        case UPDATE_AGENT_DETAILS: {
            return {
                ...state,
                agentDetails: action.payload
            }
        }
        case UPDATE_MESSAGES: {
            const tempMessages = state.messages
            tempMessages.push(action.payload)
            return {
                ...state,
                messages: tempMessages
            }
        }
        case UPDATE_CHAT_ROOM_ID: {
            return {
                ...state,
                chatRoomId: action.payload
            }
        }
        default:
            return state;
    }
}

export default JitsiReducer;