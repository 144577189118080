import { gql } from "@apollo/client";

export const GET_CUSTOMERS = gql`
query GetAssignedCustomers {
  getAssignedCustomers {
    id
    email
    companyId
    phone
    status
    firstName
    lastName
    botId
    isChattingToAgent
    socketId
  }
}
`