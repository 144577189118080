//import { useState } from "react";
import { format } from "ts-date/esm/locale/en";
import { IconButton } from "@mui/material";
import Comment from "components/modal/Comment";
import { AppointmentsPlaceHolders } from "../searchbar/Searchbar";
import {
  TableRow,
  TableCell,
  Hidden,
  Grid,
  Typography,
  ListItemSecondaryAction,
} from "@mui/material";

export default function RejectedItem(props: any) {
  const str1 = new Date(props?.appointment?.startDate!);
  let dt: any;
  dt = "";
  if (str1) {
    dt = format(new Date(str1), "DD MMM, YYYY - hh:mm A");
  }

  const isExpired = () => {
    var d1 = new Date();
    var d2 = new Date(props?.request?.startDate);
    return d1.getTime() > d2.getTime() + 600000;
  };

  return (
    <TableRow hover>
      <Hidden smDown>
        <TableCell>
          {props?.request?.customer?.firstName!}
          {props?.request?.customer?.lastName!
            ? props?.request?.customer?.lastName! ===
              props?.request?.customer?.firstName!
              ? " "
              : " " + props?.request?.customer?.lastName!
            : ""}
          <br />
          {props?.request?.customer?.email!}
          <br />
          {props?.request?.customer?.phone!}
        </TableCell>
        <TableCell>
          {isExpired()
            ? "Expired"
            : AppointmentsPlaceHolders(props?.request?.status)}
        </TableCell>
        <TableCell>{props?.appointment?.reason}</TableCell>
        <TableCell>{dt}</TableCell>
        <TableCell></TableCell>
        <TableCell>
          {props?.request?.assignee?.firstName! +
            " " +
            props?.request?.assignee?.lastName!}
          <br />
          {props?.request?.assignee?.email!}
        </TableCell>
        {/* <TableCell>
          <Comment comment={props?.request?.comment} />
          <IconButton />
        </TableCell> */}
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          spacing={0}
          style={{ position: "relative", padding: "16px" }}
        >
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.request?.customer?.firstName!}
              {props?.request?.customer?.lastName!
                ? props?.request?.customer?.lastName! ===
                  props?.request?.customer?.firstName!
                  ? " "
                  : " " + props?.request?.customer?.lastName!
                : ""}
              <br />
              {props?.request?.customer?.email!}
              <br />
              {props?.request?.customer?.phone!}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {isExpired()
                ? "Expired"
                : AppointmentsPlaceHolders(props?.request?.status)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.appointment?.reason}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{dt}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <ListItemSecondaryAction>
              <Comment comment={props?.request?.comment} />
              <IconButton />
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
