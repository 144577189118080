import { Company } from "utils/interfaces"

export const LOGIN_START = "LOGIN_START"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const UPDATE_USER_COMPANY = "UPDATE_USER_COMPANY"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const USER_STATUS_CHANGE = "USER_STATUS_CHANGE"
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"
export const REFRESH_ACTIVE_TOKEN = "REFRESH_ACTIVE_TOKEN"

export type User = {
    company: Company,
    companyId: string
    email: string
    firstName: string
    id: string
    roleId: string
    isSuperAdmin: boolean
    lastName: string
    profilePicture: string
    managerId: string
    role: string
    token: string | null
    status: number
    refreshToken: string
    permissions: Record<string, string[]>
}

export interface LoginStartAction {
    type: typeof LOGIN_START
}

export interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS
    payload: User
}

export interface RefreshActiveTokenAction {
    type: typeof REFRESH_ACTIVE_TOKEN
    payload: { user: User, token: string, permissions: Record<string, string[]> }
}

export interface UpdateUserCompany {
    type: typeof UPDATE_USER_COMPANY
    payload: { name: string, subdomain: string }
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER
    payload: {
        firstName: string,
        lastName: string,
        role: string,
    }
}

export interface UpdateUserStatusAction {
    type: typeof UPDATE_USER_STATUS
    payload: Partial<User>
}

export interface UpdateProfilePictureAction {
    type: typeof UPDATE_PROFILE_PICTURE
    payload: Partial<User>
}

export interface LoginFailAction {
    type: typeof LOGIN_FAIL
    payload: string
}

export interface LogoutAction {

    type: typeof LOGOUT
}

export interface UserStatusChnageAction {

    type: typeof USER_STATUS_CHANGE
}
export type AuthActionTypes = LoginStartAction | LoginSuccessAction | LoginFailAction | LogoutAction | UserStatusChnageAction | UpdateUserAction | UpdateUserCompany | UpdateUserStatusAction | UpdateProfilePictureAction | RefreshActiveTokenAction


export const loginStart = (): LoginStartAction => ({
    type: LOGIN_START
})

export const loginSuccess = (user: User): LoginSuccessAction => ({
    type: LOGIN_SUCCESS,
    payload: user
})

export const refreshActiveToken = (tokenInfo: {user: User, token: string, permissions: Record<string, string[]>}): RefreshActiveTokenAction => ({
    type: REFRESH_ACTIVE_TOKEN,
    payload: tokenInfo
})

export const updateUserCompany = (comapnyData: { name: string, subdomain: string }): UpdateUserCompany => ({
    type: UPDATE_USER_COMPANY,
    payload: comapnyData
}
)
export const updateUser = (userData: {
    firstName: string,
    lastName: string
    role: string
}): UpdateUserAction => ({
    type: UPDATE_USER,
    payload: userData
})

export const updateProfilePicture = (userData: {
    profilePicture: string,
}): UpdateProfilePictureAction => ({
    type: UPDATE_PROFILE_PICTURE,
    payload: userData
})

export const updateUserStatus = (userData: Partial<User>): UpdateUserStatusAction => ({
    type: UPDATE_USER_STATUS,
    payload: userData
})

export const loginFail = (error: string): LoginFailAction => ({
    type: LOGIN_FAIL,
    payload: error
})

export const logout = (): LogoutAction => ({
    type: LOGOUT
})

export const userStatusChange = (): UserStatusChnageAction => ({
    type: USER_STATUS_CHANGE
})


