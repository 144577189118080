import React, { useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useQuery } from "@apollo/client";
import { GET_AVAILABLE_CUSTOMERS } from "adapters/queries/GetAvailableCustomers";
import { useSocket } from "context/SocketProvider";

interface CustomerListProps {
    handleDragStart: (event: React.DragEvent<HTMLElement>, item: string) => void;
    handleDropCustomer: (event: React.DragEvent<HTMLElement>) => void;
    handleDragOver: (event: React.DragEvent<HTMLElement>, customerId?: string) => void;
    isSmallScreen: boolean;
}

const getInitials = (firstName?: string, lastName?: string, id?: any) => {
    return firstName && lastName
        ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
        : `${id[0]}${id[1]}`.toUpperCase();
};

const CustomerList: React.FC<CustomerListProps> = ({
    handleDragStart,
    handleDropCustomer,
    handleDragOver,
    isSmallScreen,
}) => {
    const classes = useStyles({ isSmallScreen });
    const { user } = useSelector((state: RootState) => state.auth);
    const companyId = user?.companyId;
    const { data: dataCustomers, refetch: refetchCustomers } = useQuery(GET_AVAILABLE_CUSTOMERS, {
        variables: { companyId },
    });
    const customers = dataCustomers?.getAvailableCustomers || [];
    const { socket } = useSocket();

    useEffect(() => {
        if (socket?.connected) {

            socket.on("status_changed", () => {
                refetchCustomers();
            });
        }
        return () => {
            if (socket?.connected) {
                socket.off("status_changed");
            }
        };
    }, [socket]);

    return (
        <>
            <Typography
                variant="h5"
                style={{ marginBottom: "30px", textAlign: isSmallScreen ? "center" : "left" }}
            >
                Customers
            </Typography>
            <Box
                onDrop={handleDropCustomer}
                onDragOver={(e) => handleDragOver(e)}
                onDragEnd={(e) => {
                    handleDragOver(e)
                }}
                className={classes.box}
            >
                {customers.map((customer: any) => (
                    <Box
                        key={customer.id}
                        draggable
                        onDragStart={(event: React.DragEvent<HTMLElement>) => handleDragStart(event, customer.firstName || customer.id)}
                        className={classes.customerItem}
                    >
                        <Avatar className={classes.agentAvatar}>
                            {getInitials(customer.firstName, customer.lastName, customer.id)}
                        </Avatar>
                        <Typography style={{
                            lineHeight: 1.1
                        }}>{customer.firstName ? `${customer.firstName} ${customer.lastName}` : customer.id}</Typography>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default CustomerList;
