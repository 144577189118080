import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Subscription } from "superadmin/modules/subscriptions/models/subscription";

interface SubscriptionItemProps {
  subscription: Subscription;
}

const SubscriptionsListItem = ({ subscription }: SubscriptionItemProps) => {
  const { id, name, agents, botProfiles, minutes, trialDays, price, isPublic } =
    subscription;

  const navigate = useNavigate();

  return (
    <TableRow
      style={{ cursor: "pointer" }}
      hover
      key={id}
      onClick={() => navigate(`/admin/subscriptions/edit/${id}`)}
    >
      <TableCell>{name}</TableCell>
      <TableCell>{agents}</TableCell>
      <TableCell>{botProfiles}</TableCell>
      <TableCell>{minutes}</TableCell>
      <TableCell>{trialDays}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{isPublic}</TableCell>
    </TableRow>
  );
};

export default SubscriptionsListItem;
