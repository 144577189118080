import {
    Switch,
    Theme,
    createStyles,
    makeStyles,
    withStyles
} from "@material-ui/core";

export const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: "flex"
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            "&$checked": {
                transform: "translateX(12px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main
                }
            }
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: "none"
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white
        },
        checked: {}
    })
)(Switch);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column"
        },
        avatar: {
            width: 64,
            height: 64
        },
        statsItem: {
            alignItems: "center",
            display: "flex"
        },
        itemAvatar: {
            borderRadius: 0
        },
        listItem: {
            borderBottom: "1px solid rgb(224, 224, 224)",
            "&:last-child": {
                borderBottom: "none"
            }
        },
        paper: {
            position: "absolute",
            width: 400,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        }
    })
);
