import { gql } from '@apollo/client';

export const CREATE_DEFAULT_ROLE = gql`mutation CreateDefaultRole($defaultRole: DefaultRoleCreateInput!) {
  createDefaultRole(defaultRole: $defaultRole) {
    id
    createdAt
    updatedAt
    isDeleted
    name
    subscriptionId
    permissions
    parentDefaultRoleId
  }
}`