import { gql } from "@apollo/client";

export const UPDATE_NOTE = gql`
mutation UpdateNote($input: UpdateNoteInput!) {
  updateNote(input: $input) {
    NoteEdge {
      node {
        id
      }
    }
  }
}
`;

// "input": {
//     "botProfileId": null,
//     "callId": null,
//     "customerId": null,
//     "id": null,
//     "note": null,
//     "userId": null
//   }
// }