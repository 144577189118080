import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    root: {
        marginLeft: "10%",
        marginRight: "10%",
        marginTop: "5%",
    },
    planName: {
        color: "#000000",
        fontSize: "16px",
        '@media (min-width: 720px)': {
            fontSize: "20px",
        },
    },
    cardHeader: {
        textAlign: "center",
        fontSize: "1rem",
        '@media (min-width: 720px)': {
            fontSize: "2rem",
        },
    },
    cardContent: {
        padding: "0 1rem",
        '@media (min-width: 720px)': {
            padding : '0 2rem',
        }
    },
    changePlanBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    spaceBetween: {
        display: "flex",
        justifyContent: "space-between",
    },
    outerDiv :{
        '@media (min-width: 720px)': {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
        },
    },
    innerDivLeft :{
        width: "100%",
        '@media (min-width: 720px)' : {
            width: "40%",
        },
    },
    innerDivRight :{
        width: "100%",
        '@media (min-width: 720px)' : {
            width: "40%",
        },
    },
    pageTitle : {
        display: "flex",
        justifyContent: "center",
        fontSize: "2rem",
        '@media (min-width: 720px)' : {
            display: "flex",
            justifyContent: "center",
            fontSize: "3rem",
            marginTop: "4rem",
        },
    },
    disclaimerNote :{
        fontSize: "14px",
        '@media (min-width: 720px)' : {
            paddingTop: "2rem",
            fontSize: "16px",
        }
    },
    modalDisclaimerNote :{
        fontSize: "14px",
        color: "#000000",
        '@media (min-width: 720px)' : {
            fontSize: "16px",
        }
    }


});