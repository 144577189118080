import { gql } from '@apollo/client';

export const UPDATE_DEFAULT_ROLE = gql`
mutation UpdateDefaultRole($defaultRole: DefaultRoleUpdateInput!) {
  updateDefaultRole(defaultRole: $defaultRole) {
    id
    createdAt
    updatedAt
    isDeleted
    name
    subscriptionId
    permissions
    parentDefaultRoleId
  }
}`