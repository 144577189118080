import { useState } from "react";
import * as React from "react";
import { format } from "ts-date/esm/locale/en";
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import AssignModal from "../assign_modal/AssignModal";
import ChangeModal from "../change_modal/ChangeModal";
import Comment from "components/modal/Comment";
import { AppointmentsPlaceHolders } from "../searchbar/Searchbar";
import {
  TableRow,
  TableCell,
  Hidden,
  Grid,
  Typography,
  IconButton,
  ListItemSecondaryAction,
} from "@mui/material";
import Modal from "../modal/Modal";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export default function AppointmentItem(props: any) {
  const [changeModalOpen, setChangeModal] = useState<HTMLButtonElement | null>(
    null
  );
  const [assignModalOpen, setAssignModal] = useState<HTMLButtonElement | null>(
    null
  );
  const [modalOpen, setModal] = useState<HTMLButtonElement | null>(null);

  const str1 = new Date(props?.request?.startDate!);

  let dt: any;
  dt = "";
  if (str1) {
    dt = format(new Date(str1), "DD MMM, YYYY - hh:mm A");
  }

  const handleAssign = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props?.request?.id!) {
      setAssignModal(event.currentTarget);
    }
  };

  const handleAssignAccept = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props?.request?.id!) {
      setModal(event.currentTarget);
    }
  };

  const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props?.request?.id!) {
      setChangeModal(event.currentTarget);
    }
  };

  const isExpired = () => {
    var d1 = new Date();
    var d2 = new Date(props?.request?.startDate);
    return d1.getTime() > d2.getTime() + 600000;
  };

  return (
    <TableRow hover>
      <Hidden smDown>
        <TableCell>
          {props?.request?.customer?.firstName!}
          {props?.request?.customer?.lastName!
            ? props?.request?.customer?.lastName! ===
              props?.request?.customer?.firstName!
              ? " "
              : " " + props?.request?.customer?.lastName!
            : ""}
          <br />
          {props?.request?.customer?.email!}
          <br />
          {props?.request?.customer?.phone!}
        </TableCell>
        <TableCell>
          {isExpired() ? "Expired" : AppointmentsPlaceHolders(props?.request?.status)}
        </TableCell>
        <TableCell>{props?.request?.reason}</TableCell>
        <TableCell>{dt}</TableCell>
        <TableCell></TableCell>
        <TableCell>
          <IconButton
            disabled={isExpired()}
            onClick={handleAssignAccept}
            aria-label="Assign"
            aria-haspopup="true"
          >
            <AssignmentTurnedInIcon />
          </IconButton>
          <IconButton
            disabled={isExpired()}
            onClick={handleAssign}
            aria-label="assign"
            aria-haspopup="true"
          >
            <PersonIcon />
          </IconButton>

          <IconButton
            disabled={isExpired()}
            onClick={handleChange}
            aria-label="Change"
            aria-haspopup="true"
          >
            <EditIcon />
          </IconButton>

          <Modal
            id={props?.request?.id!}
            el={modalOpen}
            onClose={setModal}
            appointment={props?.request!}
            getAssignedList={props?.getAssignedList}
            returnUrl={props?.getAssignedList}
          />
          
          <AssignModal
            id={props?.request?.id!}
            el={assignModalOpen}
            onClose={setAssignModal}
            appointment={props?.request!}
            getAssignedList={props?.getAssignedList}
          />
          <ChangeModal
            id={props?.request?.id!}
            el={changeModalOpen}
            onClose={setChangeModal}
            appointment={props?.request!}
            getAssignedList={props?.getAssignedList}
            getPendingList={props?.getPendingList}
          />

        </TableCell>
        <TableCell><IconButton /></TableCell>

      </Hidden>

      <Hidden mdUp>
        <Grid container spacing={0} style={{ position: "relative", padding: "16px" }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.request?.customer?.firstName!}
              {props?.request?.customer?.lastName!
                ? props?.request?.customer?.lastName! ===
                  props?.request?.customer?.firstName!
                  ? " "
                  : " " + props?.request?.customer?.lastName!
                : ""}
              <br />
              {props?.request?.customer?.email!}
              <br />
              {props?.request?.customer?.phone!}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {isExpired() ? "Expired" : AppointmentsPlaceHolders(props?.request?.status)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props?.request?.reason}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{dt}</Typography>
          </Grid>

          <Grid item xs={12}>
            <ListItemSecondaryAction>

              <IconButton
                disabled={isExpired()}
                onClick={handleAssignAccept}
                aria-label="Assign"
                aria-haspopup="true"
              >
                <AssignmentTurnedInIcon />
              </IconButton>

              <IconButton
                disabled={isExpired()}
                onClick={handleAssign}
                aria-label="assign"
                aria-haspopup="true"
              >
                <PersonIcon />
              </IconButton>

              <IconButton
                disabled={isExpired()}
                onClick={handleChange}
                aria-label="Change"
                aria-haspopup="true"
              >
                <EditIcon />
              </IconButton>

              <Modal
            id={props?.request?.id!}
            el={modalOpen}
            onClose={setModal}
            appointment={props?.request!}
            getAssignedList={props?.getAssignedList}
            returnUrl={props?.getAssignedList}
          />

              <AssignModal
                id={props?.request?.id!}
                el={assignModalOpen}
                onClose={setAssignModal}
                appointment={props?.request!}
                getAssignedList={props?.getAssignedList}
              />
              <ChangeModal
                id={props?.request?.id!}
                el={changeModalOpen}
                onClose={setChangeModal}
                appointment={props?.request!}
                getAssignedList={props?.getAssignedList}
                getPendingList={props?.getPendingList}
              />

              <Comment comment={props?.request?.comment} />

              <IconButton />
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
