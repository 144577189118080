import { Route, Navigate } from "react-router-dom";
import { PrivateRouteConfig } from "common/types/RouteTypes";

export interface RouteConfigProps {
    routeConfig: PrivateRouteConfig[];
    isAuthenticated: boolean;
    hasAnyPermission: (pageId: string) => boolean;
    hasItemPermission: (pageId: string, permissionId: string) => boolean;
}

// Convert RouteConfig to a function that returns an array of routes
export function getRouteConfig(
    routeConfig: PrivateRouteConfig[],
    isAuthenticated: boolean,
    hasAnyPermission: (pageId: string) => boolean,
    hasItemPermission: (pageId: string, permissionId: string) => boolean
): JSX.Element[] {
    return routeConfig.reduce((acc: JSX.Element[], route) => {
        const { pageId, path: basePath, routes: moduleRoutes } = route;

        if (hasAnyPermission(pageId) && moduleRoutes?.length > 0) {
            moduleRoutes.forEach((item) => {
                const { permissionId, path: subPath, element } = item;
                const key = `${pageId}__${permissionId}`;

                if (hasItemPermission(pageId, permissionId)) {
                    acc.push(
                        <Route
                            key={key}
                            path={basePath + subPath}
                            element={!isAuthenticated ? <Navigate to="/login" /> : element}
                        />
                    );
                }
            });
        }

        return acc;
    }, []);
}
