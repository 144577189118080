import { gql } from "@apollo/client";

export const ASSIGNED_MODAL_BUSINESS_ADMIN_APPOINTMENT = gql`
    mutation AssignModalAccountAppointmentMutation(
        $input: AssignInput!
    ) {
        assign(input: $input) {
            appointmentEdge {
                node {
                    id
                }
            }
        }
    }
`;
