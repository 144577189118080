import { useState } from "react";
import * as React from "react";
import PersonIcon from "@mui/icons-material/Person";
import AssignModal from "../assign_modal/AssignModal";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { CallbacksPlaceHolders } from "../searchbar/Searchbar";
import { format } from "ts-date/esm/locale/en";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  TableRow,
  TableCell,
  Hidden,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import Modal from "../modal/Modal";


export default function AppointmentItem(props: any) {
  const [assignModalOpen, setAssignModal] = useState<HTMLButtonElement | null>(
    null
  );
  const [modalOpen, setModal] = useState<HTMLButtonElement | null>(null);

  const handleAssign = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props?.request?.id!) {
      setAssignModal(event.currentTarget);
    }
  };

  const handleAssignAccept = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props?.request?.id!) {
      setModal(event.currentTarget);
    }
  };

  // const isExpired = () => {
  //   var d1 = new Date();
  //   var d2 = new Date(props?.request?.callbackDate);
  //   return d1.getTime() > d2.getTime() + 600000;
  // };

  const dateStringRequest = new Date(props?.request?.createdAt!).toISOString();
  let requestedDate: any;
  requestedDate = "";
  if (dateStringRequest) {
    requestedDate = format(
      new Date(dateStringRequest.substr(0, 19)),
      "DD MMM, YYYY - hh:mm A"
    );
  }

  return (
    <TableRow hover>
      <Hidden smDown>
        <TableCell>
          {props?.request?.customer?.firstName!}
          {props?.request?.customer?.lastName!
            ? props?.request?.customer?.lastName! ===
              props?.request?.customer?.firstName!
              ? " "
              : " " + props?.request?.customer?.lastName!
            : ""}
          <br />
          {props?.request?.customer?.email!}
          <br />
          {props?.request?.customer?.phone!}
        </TableCell>
        <TableCell>{CallbacksPlaceHolders(props?.request?.status)}</TableCell>
        <TableCell>
          {props?.request?.reason ? props?.request?.reason : ""}
        </TableCell>
        <TableCell>{requestedDate}</TableCell>
        <TableCell></TableCell>
        <TableCell>
        <IconButton
            // disabled={isExpired()}
            onClick={handleAssignAccept}
            aria-label="Assign"
            aria-haspopup="true"
          >
            <AssignmentTurnedInIcon />
          </IconButton>
        <Modal
            id={props?.request?.id!}
            el={modalOpen}
            onClose={setModal}
            appointment={props?.request!}
            getAssignedList={props?.getAssignedList}
            returnUrl={
             props?.getAssignedList 
            }
          />
          
          <IconButton
            disabled={props?.request?.status == "assigned"}
            onClick={handleAssign}
            aria-label="assign"
            aria-haspopup="true"
          >
            <PersonIcon />
          </IconButton>

          <AssignModal
            id={props?.request?.id!}
            el={assignModalOpen}
            onClose={setAssignModal}
            callback={props?.request!}
            getAssignedList={props?.getAssignedList}
          />
        </TableCell>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          spacing={0}
          style={{ position: "relative", padding: "16px" }}
        >
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.request?.customer?.firstName!}
              {props?.request?.customer?.lastName!
                ? props?.request?.customer?.lastName! ===
                  props?.request?.customer?.firstName!
                  ? " "
                  : " " + props?.request?.customer?.lastName!
                : ""}
              <br />
              {props?.request?.customer?.email!}
              <br />
              {props?.request?.customer?.phone!}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {CallbacksPlaceHolders(props?.request?.status)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.request?.reason ? props?.request?.reason : ""}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{requestedDate}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <ListItemSecondaryAction>
              <IconButton
                disabled={props?.request?.status == "assigned"}
                onClick={handleAssign}
                aria-label="assign"
                aria-haspopup="true"
              >
                <PersonIcon />
              </IconButton>

              <AssignModal
                id={props?.request?.id!}
                el={assignModalOpen}
                onClose={setAssignModal}
                callback={props?.request!}
                getAssignedList={props?.getAssignedList}
              />
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
