import { PrivateRouteConfig } from "common/types/RouteTypes";
import { TicketRoutes } from "modules/tickets/Routes";
import { UserRoutes } from "modules/users/Routes";
import { DashboardRoutes } from "modules/dashboard/Routes";
import { SubscriptionRoutes } from "modules/subscriptions/Routes";
import { CustomerRoutes } from "modules/customers/Routes";
import { CallbackRoutes } from "modules/callbacks/Routes";
import { AgentSessionRoutes } from "modules/agent_sessions/Routes";
import { AppointmentRoutes } from "modules/appointments/Routes";
import { BotSessionRoutes } from "modules/bot_sessions/Routes";
import { BotProfileRoutes } from "modules/bot_profiles/Routes";
import { PaymentRoutes } from "modules/payments/Routes";
import { EventLogRoutes } from "modules/event_log/Routes";
import { AccountRoutes } from "modules/account/Routes";
import { LiveConversationRoutes } from "modules/live_conversations/Routes";
import { AddOnRoutes } from "modules/addons/Routes";

export const routeConfig: PrivateRouteConfig[] = [
    {
        pageId: "dashboard",
        path: "/dashboard",
        routes: DashboardRoutes,
    },
    {
        pageId: "subscriptions",
        path: "/subscriptions",
        routes: SubscriptionRoutes,
    },
    {
        pageId: "addons",
        path: "/addons",
        routes: AddOnRoutes,
    },
    {
        pageId: "users",
        path: "/users",
        routes: UserRoutes,
    },
    {
        pageId: "tickets",
        path: "/tickets",
        routes: TicketRoutes,
    },
    {
        pageId: "customers",
        path: "/customers",
        routes: CustomerRoutes,
    },
    {
        pageId: "appointments",
        path: "/appointments",
        routes: AppointmentRoutes,
    },
    {
        pageId: "callbacks",
        path: "/callbacks",
        routes: CallbackRoutes,
    },
    {
        pageId: "agentSessions",
        path: "/agent-sessions",
        routes: AgentSessionRoutes,
    },
    {
        pageId: "liveConversations",
        path: "/live-conversations",
        routes: LiveConversationRoutes,
    },
    {
        pageId: "botSessions",
        path: "/bot-sessions",
        routes: BotSessionRoutes,
    },
    {
        pageId: "botProfiles",
        path: "/bot-profile",
        routes: BotProfileRoutes,
    },
    {
        pageId: "payments",
        path: "/payments",
        routes: PaymentRoutes,
    },
    {
        pageId: "eventLog",
        path: "/eventlogs",
        routes: EventLogRoutes,
    },
    {
        pageId: "settings",
        path: "/account",
        routes: AccountRoutes,
    },
];

