import * as React from "react";
import TopBar from "./topbar/TopBar";
import { useStyles } from "./style";
import CommonAppBarAlert from "components/common_appbar_alert/CommonAppBarAlert";
import CheckSubscription from "components/common_appbar_alert/CheckSubscription";
import FacebookProgress from "components/facebook_progress/FacebookProgress";
import Sidebar from "./sidebar/Sidebar";

interface IProps {
  data?: any | null;
  error?: any | null;
  children?: React.ReactNode | null;
}

export default function Layout(props: IProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);

  React.useEffect(() => {
  }, [isMobileNavOpen]);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        setOpen={setOpen}
        isOpen={open}
      />
      <Sidebar />
      <main className={classes.content}>
        <CommonAppBarAlert>
          <CheckSubscription>
            <React.Suspense fallback={<FacebookProgress />}>
              {props.children}
            </React.Suspense>
          </CheckSubscription>
        </CommonAppBarAlert>
      </main>
    </div>
  );
}
