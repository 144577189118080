
import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider, Button as FormButton,
  Grid,
  MenuItem
} from "@mui/material";

import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { EDIT_BUSINESS_ADMIN_COMPANY } from "adapters/mutations/EditBusinessAdminCompany";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import PhoneInput from "components/form/phone_input/PhoneInput"
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { updateUserCompany } from "redux/actions/AuthActions";
import { USER_BUSINESS_ADMIN_REFETCH } from "modules/users/adapters/queries/UserBusinessRefetch";

const schema = yup.object().shape({
  name: yup.string().required(),
});

const types = [
  {
    value: "1",
    label: "General",
  },
  {
    value: "2",
    label: "Real Estate",
  },
  {
    value: "3",
    label: "Accounting",
  },
  {
    value: "4",
    label: "Law Firm",
  },
  {
    value: "5",
    label: "Health Care",
  },
  {
    value: "6",
    label: "Others",
  },
];

export default function EditCompanyForm({ company }: any) {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch()
  const { setSnack } = useContext(SnackbarContext);
  const defaultValues = {
    ...company,
  };

  const [mutate, { loading, error }] = useMutation(
    EDIT_BUSINESS_ADMIN_COMPANY,
    {
      onCompleted: (res) => {
        dispatch(updateUserCompany(res.updateCompany.companyEdge.node))
        setSnack({ message: "Record Updated Successfully", severity: 'success', open: true });
      },
      onError: () => {
        setSnack({ message: "Editing company failed", severity: 'error', open: true });
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = ({ name, type, vat, phone, subdomain }: any) => {
    mutate({
      refetchQueries: [{ query: USER_BUSINESS_ADMIN_REFETCH }],
      variables: {
        input: {
          id: company?.id!,
          name: name!,
          type: type!,
          vat: vat!,
          phone: phone!,
          subdomain: subdomain,
          planId: company?.planId
        },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                control={control}
                type="text"
                name="name"
                label="Business Name*"
                defaultValue={company?.name}
                error={errors && errors.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                control={control}
                name="type"
                defaultValue={company?.type}
                label="Business Type"
                select={true}
                fullWidth
              >
                {types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormInput>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                control={control}
                type="text"
                name="vat"
                defaultValue={company?.vat}
                label="VAT Number"
                fullWidth
              ></FormInput>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                control={control}
                type="email"
                defaultValue={user?.email}
                name="email"
                label="Email"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <PhoneInput
               control={control} 
               name="phone" 
               />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                control={control}
                type="text"
                name="subdomain"
                defaultValue={company?.subdomain}
                fullWidth
                label="Subdomain"
                disabled
              />
            </Grid>
          </Grid>
          {
          error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null
        }
        </CardContent>
        <Divider />
        <CardActions className={classes.buttonContainer}>
        <Grid item sx={{ padding: "16px 16px 16px 16px" }}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
            >
              Update Record
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </Grid>
        </CardActions>
      </form>
    </div>
  );
}
