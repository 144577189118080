import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
    createStyles({
        errorMessage: {
            color: "#FF0000",
            fontFamily: "Poppins",
            fontSize: "13px",
            fontStyle: "italic",
        }
    })
);
