import { gql } from "@apollo/client";
import { EDIT_BUSINESS_ADMIN_USER_DATA } from "adapters/fragments/EditBusinessAdminUserData";

export const USER_BUSINESS_ADMIN_EDIT = gql`
    ${EDIT_BUSINESS_ADMIN_USER_DATA}
    query UserAccountEditQuery($id: String!) {
        user(id: $id) {
            ...EditAccountUser_data
            id
        }
    }
`;
