import { gql } from "@apollo/client";

export const CREATE_TRANSLATED_FAQ_NODE = gql`mutation CreateTranslatedFAQNode($input: CreateTranslatedFAQNodeInput!) {
  createTranslatedFAQNode(input: $input) {
    faqNodeEdge {
      node {
        id
      }
    }
  }
}`