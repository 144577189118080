import { gql } from "@apollo/client";

export const LIST_BUSINESS_ADMIN_CUSTOMERS = gql`
    fragment ListAccount_customer_1G22uz on Query {
        customers(first: $count, last : $reversecount , after: $endcursor, before: $startcursor, search: $search, role: $role, sortBy: $sortBy,sortOrder: $sortOrder) {
            edges {
                node {
                    id
                    firstName
                    lastName
                    email
                    createdAt
                    updatedAt
                    companyId
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
