import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootButton: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%"
        },
        chip: {
            margin: theme.spacing(1)
        },
        gridHeading: {
            color: "#263238",
            fontWeight: 500,
            borderBottom: "1px solid rgb(224, 224, 224)"
        },
        gridList: {
            backgroundColor: theme.palette.background.paper,
            border: "1px solid rgb(224, 224, 224)",
            borderRadius: "4px"
        },
        gridEmptyRecords: {
            padding: theme.spacing(4),
            textAlign: "center",
            color: "#263238"
        }
    })
);
