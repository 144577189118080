import { gql } from "@apollo/client";

export const UPDATE_TICKET_MUTATION = gql`
  mutation UpdateTicket($ticket: TicketUpdateInput!) {
    updateTicket(ticket: $ticket) {
      id
      company
      code
      ticketStatus
    }
  }
`;
