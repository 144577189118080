import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
    createStyles({
        button: {
                color: "white",
                textTransform: "none",        
        },
    })
);
