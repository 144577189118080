import { gql } from '@apollo/client';

export const CREATE_ROLES = gql`mutation Mutation($role: RoleCreateInput!) {
  createRole(role: $role) {
    id
    createdAt
    updatedAt
    isDeleted
    name
    companyId
    permissions
  }
}`