import { ModuleRouteConfig } from "common/types/RouteTypes";
import LiveConversation from "components/chats/live_conversations/LiveConversation";


export const LiveConversationRoutes: ModuleRouteConfig[] = [
    {
        permissionId: "view_live_conversations",
        path: "",
        element: <LiveConversation />,
    },
]
