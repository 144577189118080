import { TableRow, TableCell, Hidden, Grid, Typography } from "@mui/material";

export default function PaymentItem(props: any) {
  const str1 = new Date(props?.payment?.createdAt!).toISOString();
  let dt: any;
  dt = "";
  if (str1) {
    dt = new Date(str1.substr(0, 19)).toLocaleString();
  }

  return (
    <TableRow hover>
      {/* Desktop view-set */}
      <Hidden smDown>
        <TableCell>{props.payment.planName}</TableCell>

        <TableCell>
          {props?.payment?.amount! ? "£ " + props?.payment?.amount! : "£ 0"}
        </TableCell>

        <TableCell>{props.payment.status}</TableCell>

        <TableCell>{dt}</TableCell>
      </Hidden>

      {/* Mobile view-set */}
      <Hidden mdUp>
        <Grid
          container
          spacing={1}
          padding={1}
          style={{ position: "relative" }}
        >
          <Grid item xs={12}>
            <Typography variant="body2">{props.payment.planName}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.payment?.amount! ? "£ " + props?.payment?.amount! : "£ 0"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props.payment.status}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{dt}</Typography>
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
