import { Container, Card, TablePagination } from "@mui/material";
import AuthLogToolbar from "../../components/toolbar/Toolbar";
import Searchbar from "../../components/searchbar/Searchbar";
import AuthLogItem from "../../components/list_item/ListItem";
import Page from "components/page/Page";
import GenericTable from "common/components/datapage/GenericTable";
import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import { AUDIT_ADMIN_REFETCH } from "modules/event_log/adapters/queries/AuditAdminRefetch";

const AuditLogs = () => {
  const columns = [
    { key: "entity", label: "Entity" },
    { key: "action", label: "Action" },
    { key: "user", label: "User" },
    { key: "ipAddress", label: "IP Address" },
    { key: "date", label: "Date" },
  ];

  // Custom hook for handling query, pagination, and search
  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
  } = usePaginationQuery(AUDIT_ADMIN_REFETCH, {}, "audits");

  const audits = data?.audits || {};
  const totalCount = audits.totalCount || 0;
  const list =
    audits.edges
      ?.map((edge: any) => edge.node)
      .filter((node: any) => node != null) || [];

  return (
    <Page title="Audit Logs">
      <Container maxWidth="xl">
        <AuthLogToolbar />
        <Searchbar onSearch={setSearchQuery} />
        <Card sx={{ mt: 3 }}>
          <GenericTable
            columns={columns}
            data={list}
            totalCount={totalCount}
            loading={loading}
            renderItem={(node: any) => (
              <AuthLogItem key={node.id} authLog={node} />
            )}
          />
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={count}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default AuditLogs;
