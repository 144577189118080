import { gql } from "@apollo/client";

export const ASSIGNED_MODAL_BUSINESS_ADMIN_CALLBACK = gql`
    mutation AssignModalAccountCallbackMutation(
        $input: AssignCallbackInput!
    ) {
        assignCallback(input: $input) {
            callbackEdge {
                node {
                    id
                }
            }
        }
    }
`;
