import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            flex: 1,
            marginRight: theme.spacing(1)
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        editorArea: {
            minHeight: 200
        }

    })
);
