
import { AgentSessionActionTypes, UPDATE_SESSION } from '../actions/AgentSessionActions';



interface AgentSessionState {
    isCallOn: boolean;
    agentStatus: "available" | "unavailable" | "engaged";
}

const initialState: AgentSessionState = {
    isCallOn: false,
    agentStatus: "available"
}


export const AgentSessionReducer = (state = initialState, action: AgentSessionActionTypes): AgentSessionState => {
    switch (action.type) {
        case UPDATE_SESSION: {
            console.log(action, 'action');

            return {
                isCallOn: action.payload.isCallOn,
                agentStatus: action.payload.agentStatus
            }
        }
        default:
            return state;
    }
}

export default AgentSessionReducer;