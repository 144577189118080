import { gql } from "@apollo/client";
import { LIST_BUSINESS_ADMIN_CUSTOMERS } from "adapters/fragments/ListBusinessAdminCustomers";

export const CUSTOMER_BUSINESS_ADMIN_REFETCH = gql`
    ${LIST_BUSINESS_ADMIN_CUSTOMERS}
    query CustomerAccountFragmentRefetchQuery(
        $count: Int 
        $reversecount: Int
        $startcursor: String
        $endcursor: String
        $search: String
        $role: String
    ) {
        ...ListAccount_customer_1G22uz
    }
`;
