//@ts-nocheck
import React from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Visibility from "@material-ui/icons/Visibility";

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children?: React.ReactNode | null;
  onClose: () => void;
}

interface Props {
  comment: string
}

const DialogTitle = withStyles(useStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function Comment({ comment }: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        aria-label="Change"
        aria-haspopup="true"
      >
        <Visibility />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Comment
        </DialogTitle>
        <DialogContent>
          <Typography>{comment}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}


export default Comment