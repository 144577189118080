import { gql } from "@apollo/client";

export const VIEW_BUSINESS_ADMIN_PAYMENT_ACCOUNT_DATA = gql`
    fragment ViewAccountPayment_data on Payment {
        id
        user {
            firstName
            lastName
        }
        company {
            name
        }
        planId
        status
        cost
        tax
        amount
        createdAt
        pgwSubscriptionId
    }
`;
