import { gql } from "@apollo/client";

export const LIST_SUBSCRIPTIONS = gql `query ListSubscriptions($before: String, $after: String, $first: Int, $last: Int, $search: String) {
  listSubscriptions(before: $before, after: $after, first: $first, last: $last, search: $search) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        createdAt
        name
        agents
        botProfiles
        minutes
        trialDays
        price
        isPublic
      }
      cursor
    }
    totalCount
  }
}`