
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/rootReducer';

export const useHasAnyPermission = (pageId: string): boolean => {
  const { user, permissions } = useSelector((state: RootState) => state.auth);

  if (user?.isSuperAdmin) return true;

  return permissions[pageId] && permissions[pageId].length > 0;
};

export const useHasItemPermission = (pageId: string, permissionId: string): boolean => {
  const { user, permissions } = useSelector((state: RootState) => state.auth);

  if (user?.isSuperAdmin) return true;
  
  return permissions[pageId] && permissions[pageId].includes(permissionId);
};
