import { ModuleRouteConfig } from "common/types/RouteTypes";
import TicketDetailsPage from "./pages/TicketDetailsPage";
import TicketsCreatePage from "./pages/TicketsCreatePage";
import TicketsPage from "./pages/TicketsPage";
import AssetsDownloadPage from "components/s3_assets/AssetsDownloadPage";

export const TicketRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "edit_tickets",
    path: "/edit/:id",
    element: <TicketDetailsPage />,
  },
  {
    permissionId: "create_tickets",
    path: "/add",
    element: <TicketsCreatePage />,
  },
  {
    permissionId: "view_tickets",
    path: "/ticketsassets/:key(.+)",
    element: <AssetsDownloadPage />,
  },
  {
    permissionId: "view_tickets",
    path: "",
    element: <TicketsPage />,
  },
];
