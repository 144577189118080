import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { ADD_PROFILE_PICTURE } from "modules/users/adapters/mutations/AddProfilePticure";
import { USER_BUSINESS_ADMIN_EDIT } from "modules/users/adapters/queries/UserBusinessAdminEdit";
import { GET_PROFILE_ASSET } from "modules/users/adapters/mutations/GetProfileAsset";
import { updateProfilePicture } from "redux/actions/AuthActions";
import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import {
  Container,
  Grid,
  Typography,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Button,
  IconButton,
  Modal,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";
import { getInitials } from "utils/get-initials";
import EditForm from "../../components/view_update/EditForm";
import ResetPassword from "../../components/reset_password/ResetPassword";
import { useStyles } from "./style";

export default function EditUser() {
  const classes = useStyles();
  const { id, mode } = useParams();
  const viewOnly = mode === "view";

  // Redux and Context
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const userRole = useSelector((state: RootState) => state.auth.user?.role);

  const isMyProfile = userId === id;
  const isOwner = userRole === "owner";

  // Queries and Mutations
  const { data, loading } = useQuery(USER_BUSINESS_ADMIN_EDIT, {
    variables: { id },
  });
  const [addProfilePicture] = useMutation(ADD_PROFILE_PICTURE, {
    onCompleted: ({ imageFileUpload }: any) => {
      if (imageFileUpload.key) {
        setSnack({
          message: "Image uploaded successfully",
          severity: "success",
          open: true,
        });
        dispatch(updateProfilePicture({ profilePicture: imageFileUpload.key }));
        getProfileAsset({ variables: { input: { key: imageFileUpload.key } } });
      } else {
        setSnack({
          message: imageFileUpload.error,
          severity: "error",
          open: true,
        });
      }
    },
    onError: () => {
      setSnack({
        message: "Image upload failed",
        severity: "error",
        open: true,
      });
    },
  });
  const [getProfileAsset] = useLazyQuery(GET_PROFILE_ASSET, {
    onCompleted: (data) => {
      if (data?.getProfileAsset?.url) setUrl(data.getProfileAsset.url);
    },
    onError: (error) => console.error("Profile Asset Error", error),
  });

  // State for Avatar Management
  const [avatar, setAvatar] = useState<string | null>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    document.title = isMyProfile
      ? "My Profile"
      : viewOnly
      ? "View User"
      : "Edit User";
  }, [isMyProfile, viewOnly]);

  useEffect(() => {
    if (data?.user?.profilePicture) {
      getProfileAsset({
        variables: { input: { key: data.user.profilePicture } },
      });
    }
  }, [data?.user?.profilePicture, getProfileAsset]);

  const handleAvatarSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        alert("File size exceeds 2 MB limit. Please upload a smaller file.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
        setAvatar(reader.result as string);
        setAvatarFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarClick = () => {
    const imageUrl = data?.user?.profilePicture && url ? url : avatar;
    if (imageUrl) {
      setPreviewImageUrl(imageUrl);
      setIsPreviewOpen(true);
    }
  };

  const handleClosePreview = () => setIsPreviewOpen(false);
  const handleUploadButtonClick = () => {
    if (avatarFile) {
      addProfilePicture({
        variables: { input: { profilePicture: avatarFile } },
      });
    }
  };

  if (loading) return <CircularProgress size={15} />;

  return (
    <Page className={classes.root} title="Edit User">
      <Container className={classes.container}>
        <Typography
          variant="h3"
          sx={{ paddingY: "1rem" }}
          className={classes.title}
        >
          User Details
        </Typography>
        <Grid container spacing={5} justifyContent="center">
          <Grid item md={4} xs={12}>
            <UserAvatar
              classes={classes}
              user={data?.user}
              avatar={avatar}
              url={url}
              isMyProfile={isMyProfile}
              handleAvatarClick={handleAvatarClick}
              handleAvatarSelect={handleAvatarSelect}
              handleUploadButtonClick={handleUploadButtonClick}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <UserDetails
              classes={classes}
              user={data?.user}
              isMyProfile={isMyProfile}
              viewOnly={viewOnly}
              isOwner={isOwner}
            />
          </Grid>
        </Grid>
        {previewImage && (
          <ImagePreview
            classes={classes}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
          />
        )}
        <ImageModal
          isPreviewOpen={isPreviewOpen}
          handleClosePreview={handleClosePreview}
          previewImageUrl={previewImageUrl}
          classes={classes}
        />
      </Container>
    </Page>
  );
}

function UserAvatar({
  classes,
  user,
  avatar,
  url,
  isMyProfile,
  handleAvatarClick,
  handleAvatarSelect,
  handleUploadButtonClick,
}: any) {
  return (
    <Card className={classes.userDetailsCard}>
      <div onClick={handleAvatarClick}>
        <Avatar
          className={classes.avatarSize}
          sx={{ height: 64, width: 64, margin: 2 }}
        >
          {avatar ? (
            <img
              src={avatar}
              alt="Avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
              }}
            />
          ) : user?.profilePicture && url ? (
            <img
              src={url}
              alt="Avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
              }}
            />
          ) : (
            <Typography variant="h4">
              {getInitials(user?.firstName + " " + user?.lastName)}
            </Typography>
          )}
        </Avatar>
      </div>
      {isMyProfile && (
        <>
          <label htmlFor="upload-avatar">
            <input
              style={{ display: "none" }}
              accept="image/*"
              id="upload-avatar"
              type="file"
              onChange={(e) => handleAvatarSelect(e.target.files)}
            />
            <IconButton component="span" className={classes.cameraIcon}>
              <PhotoCameraIcon />
            </IconButton>
          </label>
          <div style={{ marginTop: "1rem" }}>
            <Button variant="contained" onClick={handleUploadButtonClick}>
              Upload
            </Button>
          </div>
        </>
      )}
      <CardContent>
        <Typography variant="h5">
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography>{user?.email}</Typography>
      </CardContent>
    </Card>
  );
}

function UserDetails({ classes, user, isMyProfile, viewOnly, isOwner }: any) {
  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          isMyProfile
            ? "My Profile"
            : viewOnly
            ? "User Details"
            : "Modify User Details"
        }
        subheader={`${user?.firstName}`}
        className={classes.cardHeader}
      />
      <EditForm
        user={user}
        isMyProfile={isMyProfile}
        viewOnly={viewOnly}
        isOwner={isOwner}
      />
      {!viewOnly && <ResetPassword user={user} />}
    </Card>
  );
}

function ImagePreview({ classes, previewImage, setPreviewImage }: any) {
  return (
    <div className={classes.imagePreviewContainer}>
      <div className={classes.imagePreview}>
        <IconButton
          className={classes.closePreviewButton}
          onClick={() => setPreviewImage(null)}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={previewImage}
          alt="Preview"
          className={classes.previewImage}
        />
      </div>
    </div>
  );
}

function ImageModal({
  isPreviewOpen,
  handleClosePreview,
  previewImageUrl,
  classes,
}: any) {
  return (
    <Modal
      open={isPreviewOpen}
      onClose={handleClosePreview}
      aria-labelledby="image-preview-title"
      aria-describedby="image-preview-description"
    >
      <div className={classes.imagePreviewContainer}>
        <div className={classes.imagePreview}>
          <IconButton
            className={classes.closePreviewButton}
            onClick={handleClosePreview}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={previewImageUrl || ""}
            alt="Preview"
            className={classes.previewImage}
          />
        </div>
      </div>
    </Modal>
  );
}
