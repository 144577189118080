import { useState, useMemo } from "react";
import {
  Card,
  Container,
  LinearProgress,
  Table,
  TableBody,
  TablePagination,
} from "@mui/material";
import Page from "../../../../components/page/Page";
import { useStyles } from "./style";
import AddonsToolbar from "../toolbar/Toolbar";
import { useQuery } from "@apollo/client";
import AddonItem from "../list_item/AddonItem";
import { ADD_ON_ADMIN_REFETCH } from "../../../../adapters/queries/AddOnAdminRefetch";
import { Hidden, TableCell, TableHead, TableRow } from "@mui/material";

export default function AddonsList() {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const { data, loading } = useQuery(ADD_ON_ADMIN_REFETCH);
  const addons = useMemo(() => data?.listAddOn || [], [data]);

  const onPageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Page className={classes.root} title="Addons">
      <Container maxWidth={"xl"}>
        <AddonsToolbar />
        <Card sx={{ mt: 3 }}>
          {loading && <LinearProgress />}
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow sx={{ borderRadius: "10px" }}>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Allowance Type</TableCell>
                  <TableCell>Allowance Units</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {addons.slice(page * 5, page * 5 + 5).map((addon: any) => (
                <AddonItem key={addon.id} addon={addon} />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={addons.length}
            onPageChange={onPageChange}
            page={page}
            rowsPerPage={5}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Container>
    </Page>
  );
}
