import { gql } from "@apollo/client";

export const GET_CHAT_BOT_CUSTOMERID = gql`
  query GetBotChatFromCustomerID($getBotChatFromCustomerIdId: String!) {
  getBotChatFromCustomerID(id: $getBotChatFromCustomerIdId) {
    agent_messages {
      content
      timestamp
      name
      email
    }
    sender_id
    bot_messages {
      event
      timestamp
      text
      data
    }
    latest_event_time
    session_id
    room_address
    meeting_fqn
    slots {
      bot_id
      company_id
      first_name
      last_name
      email
      mobile_number
    }
  }
}
`;
