import { ModuleRouteConfig } from "common/types/RouteTypes";
import EditAccount from "domain/business_admin/company/edit/Edit";

export const AccountRoutes: ModuleRouteConfig[] = [
    {
        permissionId: "edit_settings",
        path: "/edit",
        element: <EditAccount />,
    },
]
