import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createUploadLink } from "apollo-upload-client";
// import "./index.css";
import App from "./App";
// import WidgetApp from "./domain/business_customer/widget/App";
// import App from "./domain/business_customer/widget/App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  fromPromise,
  InMemoryCache,
  // useMutation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { SnackbarProvider } from "notistack";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { relayStylePagination } from "@apollo/client/utilities";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { logout } from "redux/actions/AuthActions";

// Function to refresh token
const refreshToken = async () => {
  // Implement the API call to refresh the token
  // This should return a new token if successful
  const response = await fetch(
    `${process.env.REACT_APP_API_DOMAIN}/userService.refreshAccessToken`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          refreshToken: store.getState().auth.user?.refreshToken,
        },
      }),
    }
  );

  if (!response.ok) {
    store.dispatch(logout());
    throw new Error("Failed to refresh token");
  }

  const data = await response.json();

  // Update the token in your Redux store
  store.dispatch({ type: "REFRESH_ACTIVE_TOKEN", payload: data });

  return data?.token;
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  const state = store.getState();

  const token = state.auth.token;
  // return the headers to the context so httpLink can read them
  //xconst token = GetToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err?.extensions?.code) {
        case "UNAUTHENTICATED":
          return fromPromise(
            refreshToken().catch((error) => {
              console.log(error);
              // Handle token refresh failure (e.g., logout user)
              store.dispatch({ type: "LOGOUT" });
              return;
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });

              // retry the request, returning the new observable
              return forward(operation);
            });
      }
    }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          audits: relayStylePagination(),
          users: relayStylePagination(),
          getAllSessions: relayStylePagination(),
          authLogs: relayStylePagination(),
          appointments: relayStylePagination(),
          rejected: relayStylePagination(),
          requests: relayStylePagination(),
          assigned: relayStylePagination(),
          payments: relayStylePagination(),
          companys: relayStylePagination(),
          fetchMore: relayStylePagination(),
        },
      },
    },
  }),
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

// After

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <SnackbarProvider maxSnack={1}>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </SnackbarProvider>
        </ApolloProvider>
      </Provider>
    </PersistGate>
  </StrictMode>
);

// ReactDOM.render(
//   ,
//   document.getElementById("root")
// );

// const widgets = document.querySelectorAll(".guidizy-widget");

// // Inject our React App into each class
// if (widgets && widgets.length > 0) {
//   const widget = widgets.item(widgets.length - 1) || 0;
//   ReactDOM.render(
//     <StrictMode>
//       <ApolloProvider client={client}>
//         <WidgetApp widgets={widgets} />
//       </ApolloProvider>
//     </StrictMode>,
//     widget
//   );
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
