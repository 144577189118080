// notification email list 

import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider, Button as FormButton, IconButton, Stack,
  Grid
} from "@mui/material";

import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useStyles } from "./style";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { updateUserCompany } from "redux/actions/AuthActions";
import CloseIcon from '@mui/icons-material/Close';
import * as yup from "yup";
import { PlusOneOutlined } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { EDIT_BUSINESS_EMAIL_LIST } from "adapters/mutations/EditBusinessEmailList";
import { USER_BUSINESS_ADMIN_REFETCH } from "modules/users/adapters/queries/UserBusinessRefetch";

const schema = yup.object().shape({
  emails: yup.array().of(yup.string().email().required()),
});

export default function EditNotificationEmailsForm({ company }: any) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { setSnack } = useContext(SnackbarContext);
  const defaultValues = {
    emails: company?.contactEmailList ? JSON.parse(company?.contactEmailList) : [''],
  };

  const [mutate, { loading, error }] = useMutation(
    EDIT_BUSINESS_EMAIL_LIST,
    {
      onCompleted: (res) => {
        dispatch(updateUserCompany(res.updateCustomerEmailList.companyEdge.node))
        setSnack({ message: "Record Updated Successfully", severity: 'success', open: true });
      },
      onError: () => {
        setSnack({ message: "Editing company failed", severity: 'error', open: true });
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ emails }: { emails: [string] }) => {
    mutate({
      refetchQueries: [{ query: USER_BUSINESS_ADMIN_REFETCH }],
      variables: {
        input: {
          id: company?.id,
          subdomain: company?.subdomain,
          contactEmailList: JSON.stringify(emails)
        },
      },
    });
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  });

  const addEmail = () => {
    append("")
  }

  const removeEmail = (index: number) => {
    if (fields.length != 1) {
      remove(index)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            {fields.map((item: any, index: number) => (
              <Grid item xs={12} key={item.id}>
                <Stack flexDirection={'row'} alignItems="center">
                  <FormInput
                    defaultValue={defaultValues.emails[index]}
                    control={control}
                    type="text"
                    name={`emails[${index}]`}
                    label={`Email ${index + 1}`}
                    vertical={true}
                    error={errors && errors.emails && errors.emails[index]}
                  />
                  {fields.length > 1 && (
                    <IconButton color='primary' onClick={() => removeEmail(index)}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            ))}
          </Grid>
          {error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null}
        </CardContent>
        <Divider />
        <CardActions className={classes.buttonContainerEmailList}>
        <Grid container justifyContent="flex-end" spacing={2}>
  <Grid item>
    <FormButton variant="contained" startIcon={<PlusOneOutlined />} onClick={addEmail}>
      Add
    </FormButton>
  </Grid>
  <Grid item sx={{ padding: "16px 16px 16px 16px" }} >
    <FormButton
      className={classes.button}
      type="submit"
      color="secondary"
      variant="contained"
    >
      Update Record
    </FormButton>
    {loading && <CircularProgress className={classes.buttonProgress} />}
  </Grid>
</Grid>
         
        </CardActions>
      </form>
    </div>
  );
}