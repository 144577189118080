import { Dispatch, SetStateAction } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  botProfile?: Record<string, any>;
}

export default function AlertDialog(props: IProps) {
  const handleClose = () => {
    props.setOpen(false);
  };

  const rawHTML = `
    <div class="guidizy-widget" data-guidizy-type="1" data-guidizy-profile="${props
      ?.botProfile?.id!}"></div> 
  `;

  const rawScriptHTML = `
  <script crossorigin src="https://dev-app.guidizy.com/static/js/widget/index.js"></script>
  <script src='https://8x8.vc/external_api.js'></script>
  <script src="https://unpkg.com/regenerator-runtime@0.13.1/runtime.js"></script>
  `;

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"How to Embed Gudizy Code?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div" id="alert-dialog-description">
          <Typography variant="h6" gutterBottom>
            {" "}
            Step1 : Copy Guidizy Button
          </Typography>
          <Typography variant="body2" gutterBottom>
            Copy the Button code snippet and paste it anywhere between "body"
            tags of your HTML webpage.
          </Typography>
          <code>
            {" "}
            <pre>{rawHTML}</pre>{" "}
          </code>

          <Typography variant="h6" gutterBottom>
            Step 2: Copy & Paste Supporting Scripts
          </Typography>
          <Typography variant="body2" gutterBottom>
            Copy the below scripts and paste them just before "body" tag of your
            HTML webpage.
          </Typography>
          <code>
            {" "}
            <pre>{rawScriptHTML}</pre>{" "}
          </code>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
