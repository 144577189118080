import { gql } from "@apollo/client";

export const GET_CHAT_BOT_SESSIONS = gql`
  query GetBotChatSessions(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: String
    $sortBy: String
    $sortOrder: String
  ) {
    getBotChatSessions(
      before: $before
      after: $after
      first: $first
      last: $last
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          sender_id
          latest_event_time
          session_id
          room_address
          meeting_fqn
          bot_messages {
            event
            timestamp
            text
            data
          }
          slots {
            bot_id
            company_id
            first_name
            last_name
            email
            mobile_number
          }
          agent_messages {
            jid
            content
            timestamp
            name
            email
          }
        }
        cursor
      }
      totalCount
    }
  }
`;
