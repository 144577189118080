import { useContext, useEffect } from "react";
import FormButton from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./style";
import FormInput from "../../../../../components/form/form_input/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@apollo/client";
import { ASSIGNED_MODAL_BUSINESS_ADMIN_APPOINTMENT } from "../../../adapters/mutations/AssignedModalBusinessAdminAppointmnet";
import { Edge } from "utils/interfaces";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { USER_BUSINESS_ADMIN_REFETCH } from "../../../adapters/queries/UserBusinessRefetch";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Popover,
} from "@mui/material";

const schema = yup.object().shape({
  assigneeId: yup.string().required(),
});

export default function AssignModal(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const { user } = useSelector((state: RootState) => state.auth);
  // console.log("user", user);
  const managerId = user?.id.toString();
  // console.log("managerId", managerId);

  const { data } = useQuery(USER_BUSINESS_ADMIN_REFETCH, {
    variables: {
      managerId: managerId,
    },
  });

  const users = data?.users?.edges;

  const [mutate, { loading }] = useMutation(
    ASSIGNED_MODAL_BUSINESS_ADMIN_APPOINTMENT,
    {
      onCompleted: () => {
        navigate("/appointments");
        props.getAssignedList();
      },
      onError: (error: any) => {
        console.log(error);
        setSnack({ message: "Assign failed", severity: "error", open: true });
      },
    }
  );

  useEffect(() => {
    document.title = "Assign";
  }, []);

  const handleClose = () => {
    props?.onClose(null);
  };

  const open = Boolean(props?.el!);
  const id = open ? "simple-popover" : undefined;
  const appointment = props?.appointment!;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    mutate({
      variables: {
        input: {
          id: appointment?.id!,
          assigneeId: data?.assigneeId,
        },
      },
    });
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={props?.el!}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      style={{ maxWidth: "500px" }}
    >
      <Card className={classes.card}>
        <CardHeader title="Assign To  " />
        <Divider />

        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <FormInput
              control={control}
              type="text"
              name="assigneeId"
              label="Assignee"
              error={errors && errors.name}
              select="select"
            >
              {users?.map((user: Edge) => (
                <MenuItem key={user?.node?.id} value={user?.node?.id}>
                  {user?.node?.firstName} {user?.node?.lastName} (
                  {user?.node?.role === "owner"
                    ? "Me"
                    : user?.node?.status == 2
                    ? "Active"
                    : "Inactive"}
                  )
                </MenuItem>
              ))}
            </FormInput>
          </CardContent>
          <Divider />
          <CardActions>
            <FormButton
              className={classes.button}
              disabled={loading}
              type="submit"
              size="large"
              color="secondary"
              variant="contained"
              fullWidth
            >
              Assign
            </FormButton>
          </CardActions>
        </form>
      </Card>
    </Popover>
  );
}
