import clsx from "clsx";
import CardHeader from "@mui/material/CardHeader";
import { useStyles } from "./style";

interface IProps {
  className?: string;
}

const PaymentToolbar = ({ className, ...rest }: IProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Payments"
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
      />
    </div>
  );
};

export default PaymentToolbar;
