import { gql } from "@apollo/client";

export const LIST_PURCHASED_ADD_ONS = gql`
  query ListAddOnUsageDetails {
    listAddOnUsageDetails {
      id
      createdAt
      updatedAt
      isDeleted
      companyId
      cartId
      allowanceType
      totalUnitsAvailable
      unitsConsumed
      active
    }
  }
`;
