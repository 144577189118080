import React, { useState, useEffect } from "react";
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useSocket } from "context/SocketProvider";

interface LiveConversationsButtonProps {
  item: {
    id: string;
    path: string;
    title: string;
    icon: React.ElementType;
  };
  isLargeScreen: boolean;
  isMediumScreen: boolean;
  isOpen: boolean;
  location: {
    pathname: string;
  };
}

const LiveConversationsButton: React.FC<LiveConversationsButtonProps> = ({
  item,
  isLargeScreen,
  isMediumScreen,
  isOpen,
  location,
}) => {
  const { socket } = useSocket();
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    const handleUserUpdate = (messages: any) => {
      console.log('Socket event received: ', messages);
      const message = messages?.message;
      if (message === "Customer got connected") {
        setUnreadCount((prevCount) => {
          const newCount = prevCount + 1;
          console.log('Updated unreadCount: ', newCount);
          return newCount;
        });
      }
    };

    if (socket) {
      if (socket.connected) {
        socket.on('userUpdated', handleUserUpdate);
      } else {
        socket.on('connect', () => {
          socket.on('userUpdated', handleUserUpdate);
        });
      }
    }

    return () => {
      if (socket) {
        socket.off('userUpdated', handleUserUpdate);
        socket.off('connect');
      }
    };
  }, [socket]);

  const handleClick = () => {
    setUnreadCount(0);
    // You can add any other onClick logic here
  };

  return (
    <ListItemButton
      key={item.id}
      component={Link}
      to={item.path}
      selected={location.pathname === item.path}
      onClick={handleClick} // Call handleClick on click
      sx={{
        justifyContent: (isLargeScreen || isMediumScreen) && isOpen ? "flex-start" : "center",
        color: "grey.300",
        borderRadius: 0.5,
        py: (isLargeScreen || isMediumScreen) && !isOpen ? 1.5 : 1,
        "&.Mui-selected": {
          color: "white",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
      }}
    >
      <Tooltip title={!isOpen ? item.title : ""} placement="right">
        <ListItemIcon
          sx={{
            color: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <item.icon color={location.pathname === item.path ? "white" : "inherit"} />
        </ListItemIcon>
      </Tooltip>
      {(isLargeScreen || isMediumScreen) && isOpen && (
        <ListItemText sx={{ ml: 2 }} primary={item.title} />
      )}
      {unreadCount > 0 && (
        <div
          style={{
            height: 20,
            width: 20,
            borderRadius: 10,
            backgroundColor: "red",
            position: "absolute",
            right: 15,
            top: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white", // Text color for better visibility
            fontSize: "12px", // Adjust font size as needed
          }}
        >
          {unreadCount}
        </div>
      )}
    </ListItemButton>
  );
};

export default LiveConversationsButton;
