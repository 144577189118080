import { Button, Stack } from "@mui/material";
import { useStyles } from "./style";
import DefaultNodes from "../default_nodes";
import FAQNodes from "../faq_nodes";
// import Fallback from '../fallback';
import KnowledgeBase from "../knowledge_base";
import WelcomeMessage from "../welcome_message";


interface SideBarProps {
  companyId: string;
  botProfileId: string;
  onSave: () => void;
  onSubmit: () => void;
  fallback: {
    fallbackMessage?: string;
    fallbackOptions?: string;
  };
  frmeworkId?: string;
  welcomeMessage: string;
}

const Sidebar = (props: SideBarProps) => {
  const classes = useStyles();
  return (
    <Stack
      display={"flex"}
      flexDirection={"column"}
      height={"80vh"}
      style={{ backgroundColor: "#f2f2f2" }}
    >
      <Stack className={classes.accordianContainer}>
        <KnowledgeBase
          botProfileId={props.botProfileId}
          companyId={props.companyId}
          frmeworkId={props.frmeworkId}
        />
        <FAQNodes
          botProfileId={props.botProfileId}
          companyId={props.companyId}
        />
        <DefaultNodes
          botProfileId={props.botProfileId}
          companyId={props.companyId}
          fallbackMessage={props.fallback.fallbackMessage}
          frmeworkId={props.frmeworkId}
        />

        <WelcomeMessage
          botProfileId={props.botProfileId}
          companyId={props.companyId}
          welcomeMessage={props.welcomeMessage}
          frmeworkId={props.frmeworkId}
        />

      </Stack>
      <Stack className={classes.submitButtonContainer} spacing={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSave()}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSubmit()}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => props.onSubmit()}
        >
          Launch
        </Button>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
