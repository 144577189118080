import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Popover,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Day from "./DatePicker";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LIST_ACCOUNT_USERS } from "adapters/queries/ListAgents";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { AGENT_ACTIVITY } from "adapters/queries/AgentActivity";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

interface AvailabilityPeriod {
  startHour: number;
  endHour: number;
  status: "Available" | "Unavailable" | "OnCall";
  auditData: string; 
}

interface DayAvailability {
  day: string;
  date: string;
  periods: AvailabilityPeriod[];
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const getColorForStatus = (status: string) => {
  switch (status) {
    case "Available":
      return "green";
    case "OnCall":
      return "red";
    case "Unavailable":
      return "lightgrey";
    default:
      return "lightgrey";
  }
};

const WeekSelector: React.FC<{
  selectedDate: Dayjs | null;
  setSelectedDate: (date: Dayjs | null) => void;
  onDateChange: () => void;
}> = ({ selectedDate, setSelectedDate, onDateChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue && !newValue.isSame(selectedDate)) {
      setSelectedDate(newValue);
      onDateChange();
      handleClose();
    }
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 150 }}>
        <Button
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          size="large"
          variant="outlined"
          aria-controls={open ? "date-picker" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {selectedDate ? selectedDate.format("MM/DD/YYYY") : "Select a week"}
        </Button>
      </FormControl>
      <Popover
        anchorEl={anchorEl}
        id="date-picker"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            maxDate={dayjs()}
            value={selectedDate}
            onChange={handleDateChange}
            shouldDisableDate={(date) => date.isSame(selectedDate, "day")}
            showDaysOutsideCurrentMonth
            slots={{ day: Day }}
            slotProps={{
              day: (ownerState) => ({
                selectedDay: selectedDate,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null),
              }),
            }}
          />
        </LocalizationProvider>
      </Popover>
    </>
  );
};

const AgentActivityChart: React.FC<{ className?: string }> = ({
  className,
  ...rest
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          type: "category",
          labels: daysOfWeek,
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            reverse: true,
          },
        },
      ],
      xAxes: [
        {
          type: "linear",
          position: "bottom",
          ticks: {
            stepSize: 1,
            min: 0,
            max: 23,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Hour of the Day (UTC)",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.3,
        borderColor: (context: any) =>
          getColorForStatus(context.dataset.status),
        borderWidth: 4,
      },
      point: {
        radius: 5,
        backgroundColor: (context: any) =>
          getColorForStatus(context.dataset.status),
        borderColor: "#fff",
        borderWidth: 2,
        hoverRadius: 6,
      },
    },
    backgroundColor: "transparent",
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const period = dataset.data[tooltipItem.index];
          const auditData = period.auditData;
          console.log("Audit Data: ", auditData); // Add this line
          return `${dataset.label}: ${auditData}`;
        },
      },
    },  
  };

  const { user: cUser } = useSelector((state: RootState) => state.auth);
  const [chartData, setChartData] = useState<any>({});
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const { data: agentsList, loading: loadingAgents } = useQuery(LIST_ACCOUNT_USERS, {
    variables: { 
      companyId: cUser?.companyId,
      status: "active"
    },
  });

  const [getAgentActivity, { data: activityData }] = useLazyQuery(
    AGENT_ACTIVITY
  );

  useEffect(() => {
  if (activityData && activityData.agentActivity) {
    const formattedData = activityData.agentActivity
      .map((dayActivity: DayAvailability) => {
        const dayIndex = daysOfWeek.indexOf(dayActivity.day);
        return dayActivity.periods.map((period) => ({
          label: `${dayActivity.day} (${period.status})`,
          borderColor: getColorForStatus(period.status),
          backgroundColor: getColorForStatus(period.status),
          fill: false,
          data: [
            { x: period.startHour, y: dayIndex, auditData: period.auditData }, // Include audit data here
            { x: period.endHour, y: dayIndex, auditData: period.auditData },
          ],
          lineTension: 0,
          pointRadius: 0,
        }));
      })
      .flat();

    console.log("Formatted Data: ", formattedData); // Add this line

    setChartData({
      datasets: formattedData,
    });
  }
}, [activityData]);

  

  const handleAgentChange = (event: SelectChangeEvent) => {
    setSelectedAgent(event.target.value);
    setChartData({}); // Clear the chart data
  };

  const { setSnack } = useContext(SnackbarContext);

  const showCallsSummary = () => {
    if (selectedAgent && selectedDate) {
      getAgentActivity({
        variables: {
          agentId: selectedAgent,
          selectedDate: selectedDate?.format("YYYY-MM-DD") || "",
        },
      });
    } else {
      setSnack({
        message: "Please select an agent and a date",
        severity: "error",
        open: true,
      });
    }
  };

  const handleDateChange = () => {
    setChartData({}); // Clear the chart data before fetching new data
    showCallsSummary(); // Fetch new data
  };

  useEffect(() => {
    if (selectedAgent && selectedDate) {
      showCallsSummary();
    }
  }, [selectedAgent, selectedDate]);

  return (
    <Card className={className} style={{ marginTop: "10px" }} {...rest}>
      <CardHeader
        title={
          <Box>
            <Typography variant="h6" component="div">
              Agent Activity Overview
            </Typography>
            <Box mt={1}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ display: "inline-flex", alignItems: "center", marginRight: "16px" }}
              >
                <Box
                  component="span"
                  style={{ width: "12px", height: "12px", backgroundColor: "green", display: "inline-block", marginRight: "8px" }}
                ></Box>
                Available
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ display: "inline-flex", alignItems: "center", marginRight: "16px" }}
              >
                <Box
                  component="span"
                  style={{ width: "12px", height: "12px", backgroundColor: "red", display: "inline-block", marginRight: "8px" }}
                ></Box>
                OnCall
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                <Box
                  component="span"
                  style={{ width: "12px", height: "12px", backgroundColor: "lightgrey", display: "inline-block", marginRight: "8px" }}
                ></Box>
                Unavailable
              </Typography>
            </Box>
          </Box>
        }
        action={
          <Box>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel id="agent-select-label">Select an Agent</InputLabel>
                  <Select
                    labelId="agent-select-label"
                    id="agent-select"
                    value={selectedAgent}
                    label="Select an Agent"
                    onChange={handleAgentChange}
                    disabled={loadingAgents}
                  >
                     {agentsList?.users.edges.map(({ node }: any) => (
                      node.role !== "owner" ? (
                        <MenuItem key={node.id} value={node.id}>
                          {node.firstName} {node.lastName}
                        </MenuItem>): null
                     ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <WeekSelector
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  onDateChange={handleDateChange}
                />
              </Grid>
            </Grid>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Line data={chartData} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AgentActivityChart;