import { Dispatch, SetStateAction, useContext } from "react";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useMutation } from "@apollo/client";
import { MODAL_STATUS_ADMIN_COMPANY } from "../../../../adapters/mutations/ModalStatusAdminCompany";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

interface IProps {
  id: string;
  el: HTMLElement | null;
  onClose: Dispatch<SetStateAction<HTMLElement | null>>;
  error?: any | null;
  company?: any | null | undefined;
}

export default function SimplePopover(props: IProps) {
  const handleClose = () => {
    props?.onClose(null);
  };
  const { setSnack } = useContext(SnackbarContext);
  const open = Boolean(props?.el!);
  const id = open ? "simple-popover" : undefined;

  const [statusMutate] = useMutation(MODAL_STATUS_ADMIN_COMPANY, {
    onCompleted: () => {
      window.location.reload();
    },
    onError: (error) => {
      setSnack({
        message: "Change Status failed",
        severity: "error",
        open: true,
      });
      console.log(error);
    },
  });

  const handleChangeStatus = () => {
    if (props?.id! && props?.company!) {
      let changedStatus = "active";
      if (props?.company?.status! === "active") {
        changedStatus = "inactive";
      }

      statusMutate({
        variables: {
          input: {
            id: props?.id!,
            status: changedStatus,
          },
        },
      });
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure, you want to{" "}
          <strong>
            {props?.company.status! === "active" ? "Deactivate" : "Activate"}
          </strong>{" "}
          the {props?.company?.name!}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          aria-label="No"
          color="secondary"
          aria-haspopup="true"
          startIcon={<CancelIcon />}
        >
          No
        </Button>
        <Button
          onClick={handleChangeStatus}
          aria-label="Yes"
          color="primary"
          aria-haspopup="true"
          startIcon={<CheckCircleIcon />}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Popover>
  );
}
