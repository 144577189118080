import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        bubbleContainerMe: {
            padding: theme.spacing(1.25),
            backgroundColor: '#3f51b5',
            borderRadius: '0.75rem 0.75rem 0rem 0.75rem',
            margin: '0rem 0rem 1rem 0rem',
            maxWidth: 275,
            alignSelf: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
        },
        bubbleTextMe: {
            color: '#ffffff',
            fontSize: '14px',
            wordWrap: "break-word",
            width: '100%',
        },
        bubbleNameMe: {
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: '12px',
            wordWrap: "break-word",
            width: '100%',
        },
        bubbleTimeMe: {
            color: '#ffffff',
            fontSize: '9px',
            alignSelf: 'flex-end',
            wordWrap: "break-word",
        },
        bubbleContainerYou: {
            padding: theme.spacing(1.25),
            backgroundColor: 'rgba(152,152,152,0.2)',
            borderRadius: '0rem 0.75rem 0.75rem 0.75rem',
            margin: '0rem 0rem 1rem 0rem',
            maxWidth: 275,
            alignSelf: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap'

        },
        bubbleTextYou: {
            fontSize: '14px',
            wordWrap: "break-word",
            width: '100%',
        },
        bubbleNameYou: {
            fontWeight: 'bold',
            fontSize: '12px',
            wordWrap: "break-word",
            width: '100%',
        },
        bubbleTimeYou: {
            fontSize: '9px',
            alignSelf: 'flex-end',
            wordWrap: "break-word",
        },
        chatBox: {
            ':before': {
                transform: 'translateY(-100%)',
            }
        },
        chatBoxHeaderText: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#ffffff',
            alignSelf: 'center',
        }
    })
);
