import { useEffect, useRef, useState } from "react";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormButton from "@mui/material/Button";
import { useMutation, useQuery } from "@apollo/client";
import Switch from "@mui/material/Switch";
import { useStyles } from "./style";
import FormFeedback from "components/form_feedback/FormFeedback";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
} from "@mui/material";
import { OpenTimeOutput } from "utils/OpeningHours";
import { BUSINESS_HOURS_BUSINESS_ADMIN } from "modules/bot_profiles/adapters/mutations/BusinessHoursBusinessAdmin";
import FormField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../../../../components/common_snackbar/CommonSnackBar";
import { CALL_FOR_HELP_BUSINESS_ADMIN } from "modules/bot_profiles/adapters/queries/CallForHelpBusinessAdmin";

const timings = [
  { value: "0", label: "Select" },
  { value: "0000", label: "12:00 AM" },
  { value: "0030", label: "12:30 AM" },
  { value: "0100", label: " 1:00 AM" },
  { value: "0130", label: " 1:30 AM" },
  { value: "0200", label: " 2:00 AM" },
  { value: "0230", label: " 2:30 AM" },
  { value: "0300", label: " 3:00 AM" },
  { value: "0330", label: " 3:30 AM" },
  { value: "0400", label: " 4:00 AM" },
  { value: "0430", label: " 4:30 AM" },
  { value: "0500", label: " 5:00 AM" },
  { value: "0530", label: " 5:30 AM" },
  { value: "0600", label: " 6:00 AM" },
  { value: "0630", label: " 6:30 AM" },
  { value: "0700", label: " 7:00 AM" },
  { value: "0730", label: " 7:30 AM" },
  { value: "0800", label: " 8:00 AM" },
  { value: "0830", label: " 8:30 AM" },
  { value: "0900", label: " 9:00 AM" },
  { value: "0930", label: " 9:30 AM" },
  { value: "1000", label: "10:00 AM" },
  { value: "1030", label: "10:30 AM" },
  { value: "1100", label: "11:00 AM" },
  { value: "1130", label: "11:30 AM" },
  { value: "1200", label: "12:00 PM" },
  { value: "1230", label: "12:30 PM" },
  { value: "1300", label: " 1:00 PM" },
  { value: "1330", label: " 1:30 PM" },
  { value: "1400", label: " 2:00 PM" },
  { value: "1430", label: " 2:30 PM" },
  { value: "1500", label: " 3:00 PM" },
  { value: "1530", label: " 3:30 PM" },
  { value: "1600", label: " 4:00 PM" },
  { value: "1630", label: " 4:30 PM" },
  { value: "1700", label: " 5:00 PM" },
  { value: "1730", label: " 5:30 PM" },
  { value: "1800", label: " 6:00 PM" },
  { value: "1830", label: " 6:30 PM" },
  { value: "1900", label: " 7:00 PM" },
  { value: "1930", label: " 7:30 PM" },
  { value: "2000", label: " 8:00 PM" },
  { value: "2030", label: " 8:30 PM" },
  { value: "2100", label: " 9:00 PM" },
  { value: "2130", label: " 9:30 PM" },
  { value: "2200", label: "10:00 PM" },
  { value: "2230", label: "10:30 PM" },
  { value: "2300", label: "11:00 PM" },
  { value: "2330", label: "11:30 PM" },
];

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

let defaultOpen: Record<number, boolean> = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

let defaultFrom = ["0900", "0900", "0900", "0900", "0900", "0900", "0900"];
let defaultUntil = ["0", "0", "0", "0", "0", "0", "0"];

export default function AssignModal(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDays, setOpenDays] =
    useState<Record<number, boolean>>(defaultOpen);
  const [fromDays, setDaysFrom] = useState<Record<number, string>>(defaultFrom);
  const [untilDays, setDaysUntil] =
    useState<Record<number, string>>(defaultUntil);
  const [businessHours, setBusinessHours] = useState<Array<OpenTimeOutput>>([]);

  const fromRef = useRef<HTMLInputElement>(null);
  const untilRef = useRef<HTMLInputElement>(null);
  const { id } = useParams();

  const { data, refetch } = useQuery(CALL_FOR_HELP_BUSINESS_ADMIN, {
    variables: { id: id },
  });

  const { setSnack } = React.useContext(SnackbarContext);

  const callForHelp = data?.botprofile;

  useEffect(() => {
    refetch();

    const items = (callForHelp?.businessHours! as Array<OpenTimeOutput>) || [];

    if (items.length > 0) {
      for (let l = 0; l < items.length; l++) {
        if (items[l]) {
          defaultOpen[items[l].day] = true;
          defaultFrom[items[l].day] = items[l].from;
          defaultUntil[items[l].day] = items[l].until;
        }
      }

      setDaysFrom(defaultFrom);
      setDaysUntil(defaultUntil);
      setOpenDays(defaultOpen);
      setBusinessHours(items);
    }
  }, [callForHelp]);

  const [mutate, { loading, error }] = useMutation(
    BUSINESS_HOURS_BUSINESS_ADMIN,
    {
      onCompleted: () => {
        setSnack({
          message: "Business Hours modified successful",
          open: true,
          severity: "success",
        });
        setTimeout(() => {
          navigate("/bot-profile");
        }, 1000);
      },
      onError: () => {
        console.log(error?.message);
        setSnack({
          message: "Modify business hours failed",
          open: true,
          severity: "error",
        });
      },
    }
  );

  const addDay = (day: OpenTimeOutput) => {
    setBusinessHours((prevState) => {
      let newHours: Array<OpenTimeOutput> = [];
      let newArray = newHours.concat(prevState);
      newArray = newArray.filter((item) => item.day !== day.day);
      newArray.push(day);

      console.log("Before filter ", newArray);
      return newArray;
    });
  };

  const removeDay = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    if (e.target.checked) return;

    setBusinessHours((prevState) => {
      let newHours: Array<OpenTimeOutput> = [];
      let newArray = newHours.concat(prevState);

      newArray.forEach((item, n) => {
        if (item === null) {
          delete newArray[n];
          return;
        }

        if (!e.target.checked && item?.day! === i) {
          delete newArray[n];
        }
      });

      return newArray.filter((item, pos) => newArray.indexOf(item) === pos);
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const day: OpenTimeOutput = {
      day: i,
      from: fromRef.current?.value!,
      until: e.target.value,
    };

    const from = new Date(day.from);
    const until = new Date(day.until);

    if (from && until && until.getTime() > from.getTime()) {
      console.log("Valid business hours");
      addDay(day);

      setDaysFrom({ ...fromDays, [i]: day?.from! });
      setDaysUntil({ ...untilDays, [i]: day?.until! });
    } else {
      setSnack({
        message: "Invalid Business Hours for " + weekdays[i],
        open: true,
        severity: "warning",
      });
    }
  };

  const handleFromChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    const day: OpenTimeOutput = {
      day: i,
      from: e.target.value,
      until: untilRef.current?.value!,
    };

    const from = new Date(day.from);
    const until = new Date(day.until);

    if (from && until && until.getTime() > from.getTime()) {
      console.log("Valid business hours");
      addDay(day);

      setDaysFrom({ ...fromDays, [i]: day?.from! });
      setDaysUntil({ ...untilDays, [i]: day?.until! });
    } else {
      setSnack({
        message: "Invalid Business Hours for " + weekdays[i],
        open: true,
        severity: "warning",
      });
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    mutate({
      variables: {
        input: {
          id: callForHelp?.id!,
          businessHours: businessHours,
        },
      },
    });
  };

  const list = weekdays.map((v, i) => (
    <Grid container spacing={3} key={i}>
      <Grid item xs={2}>
        <Box pt={2}> {v} </Box>
      </Grid>
      <Grid item xs={2}>
        <Switch
          checked={openDays[i]}
          onClick={(event: any) => removeDay(event, i)}
          onChange={(event: any) =>
            setOpenDays({
              ...openDays,
              [i]: event.target.checked,
            })
          }
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormField
          label="From"
          value={fromDays[i]}
          onChange={(event: any) => handleFromChange(event, i)}
          inputRef={fromRef}
          variant="outlined"
          margin="none"
          fullWidth
          select
          disabled={!openDays[i]}
        >
          {timings.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormField>
      </Grid>

      <Grid item xs={4}>
        <FormField
          label="Until"
          value={untilDays[i]}
          onChange={(event: any) => handleChange(event, i)}
          inputRef={untilRef}
          variant="outlined"
          fullWidth
          select
          disabled={!openDays[i]}
        >
          {timings.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormField>
      </Grid>
    </Grid>
  ));

  return (
    <Container className={classes.container} maxWidth={false}>
      <Card>
        <CardHeader
          subheader={props?.callForHelpitor?.name!}
          title="Business Hours"
        />
        <Divider />

        <form onSubmit={handleSubmit}>
          <CardContent>
            {list}
            {error ? (
              <FormFeedback className={classes.feedback} error>
                {error.message}
              </FormFeedback>
            ) : null}
          </CardContent>

          <Divider />
          <CardActions className={classes.formAction}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
            >
              Submit
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
