import { gql } from "@apollo/client";

export const DASHBOARD_BUSINESS_ADMIN = gql`
query sessionDailyStats($companyId: String){
  sessionDailyStats(days: 7, companyId: $companyId) {
       label
       data {
         date
         count
         duration
       }
     }
}

`;


export const SESSION_WEEKLY_STATS = gql`
query sessionWeeklyStats($companyId: String, $selectedDate: Timestamp, $agentId: String) {
  sessionWeeklyStats(companyId: $companyId, selectedDate: $selectedDate, agentId: $agentId) {
    data {
      count
      date
      duration
      total
      missedCount
    }
    label
  }
}`;
