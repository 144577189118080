

export const UPDATE_MESSAGES = "UPDATE_MESSAGES"
export const UPDATE_AGENT_DETAILS = "UPDATE_AGENT_DETAILS"
export const UPDATE_CHAT_ROOM_ID = "UPDATE_CHAT_ROOM_ID"
export const RESET_JITSI_STORE = "RESET_JITSI_STORE"

export interface UpdateMessagesAction {
    type: typeof UPDATE_MESSAGES,
    payload: any
}

export interface AgentDetails {
    displayName: string;
    email: string;
    id: string;
}

export interface UpdateAgentDetails {
    type: typeof UPDATE_AGENT_DETAILS
    payload: AgentDetails
}

export interface UpdateChatRoomId {
    type: typeof UPDATE_CHAT_ROOM_ID
    payload: string
}

export interface ResetJitsiStore {
    type: typeof RESET_JITSI_STORE
}

export type JitsiActionTypes = UpdateMessagesAction | UpdateAgentDetails | ResetJitsiStore | UpdateChatRoomId

export const updateMessages = (message: any): UpdateMessagesAction => ({
    type: UPDATE_MESSAGES,
    payload: message
})

export const updateAgentDetails = (agentDetails: AgentDetails): UpdateAgentDetails => ({
    type: UPDATE_AGENT_DETAILS,
    payload: agentDetails
})

export const updateChatRoomId = (id: string): UpdateChatRoomId => ({
    type: UPDATE_CHAT_ROOM_ID,
    payload: id
})

export const resetJitsiStore = (): ResetJitsiStore => ({
    type: RESET_JITSI_STORE
})
