import { CallStatus } from "./interfaces"

export const plans: any = {
    freePriceId: process.env.REACT_APP_STRIPE_FREE_PRICE_ID,
    plusPriceId: process.env.REACT_APP_STRIPE_PLUS_PRICE_ID,
    premiumPriceId: process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID
}

export const callStatus: CallStatus = {
    ANSWERED: "answered",
    MISSED: "missed"
}