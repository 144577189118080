import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DragEvent, useEffect, useState } from "react";
import { useStyles } from "./style";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_BOT_FRAMEWORK } from "modules/bot_profiles/adapters/mutations/EditBotFramework";
import { CALL_FOR_HELP_BUSINESS_ADMIN_EDIT } from "modules/bot_profiles/adapters/queries/CallForHelpBusinessAdminEdit";

interface DefaultNodesProps {
  companyId: string;
  botProfileId: string;
  fallbackMessage?: string;
  frmeworkId?: string;
}

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const DefaultNodes = (props: DefaultNodesProps) => {
  const classes = useStyles();
  const [fallbackMessage, setFallbackMessage] = useState<string>(
    props.fallbackMessage ? props.fallbackMessage : ""
  );
  const [error, setError] = useState<string | undefined>("");

  useEffect(() => {
    setFallbackMessage(props.fallbackMessage ? props.fallbackMessage : "");
  }, [props.fallbackMessage]);

  const [
    editFaq,
    { loading: editFrameworkLoading, error: editFrameworkError },
  ] = useMutation(UPDATE_BOT_FRAMEWORK, {
    onCompleted: (res) => {
      console.log(res);
    },
    onError: () => {
      console.log(editFrameworkError);
      setError(editFrameworkError?.message);
    },
  });

  const { data, loading } = useQuery(CALL_FOR_HELP_BUSINESS_ADMIN_EDIT, {
    variables: { id: props.botProfileId },
  });

  const { botprofile } = data || {};

  const onDragStart = (event: DragEvent, nodeType: string, faqId: string) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/reactflow/faqId", faqId);
    event.dataTransfer.setData("application/reactflow/displayName", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          className={classes.accordianHeader}
          expandIcon={<ExpandMoreOutlined />}
          id="default-nodes"
        >
          <Typography variant="h6">Human Support</Typography>
        </AccordionSummary>
        {loading && editFrameworkLoading && <LinearProgress />}
        {loading ? (
          <></>
        ) : (
          <AccordionDetails>
            <Box>
              <TextField
                fullWidth
                label={"Message"}
                onChange={(e) => {
                  setFallbackMessage(e.target.value);
                }}
                value={fallbackMessage}
              />
              <br />
              <br />
            </Box>
            {error ? (
              <>
                <br />
                <Typography variant="body1">{error}</Typography>
                <br />
              </>
            ) : null}

            <Box className={classes.buttonContainer}>
              <Button
                variant="contained"
                onClick={() => {
                  if (fallbackMessage.trim() == "") {
                    setError("Please add fallback message");
                  } else if (props.frmeworkId) {
                    setError("");
                    editFaq({
                      variables: {
                        input: {
                          fallbackMessage,
                          id: props.frmeworkId,
                          botProfileId: props.botProfileId,
                          companyId: props.companyId,
                        },
                      },
                    });
                  }
                }}
              >
                Save
              </Button>
            </Box>
            <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
              {botprofile?.isAppointment && (
                <Chip
                  label="Appointment"
                  variant="filled"
                  color="secondary"
                  onDragStart={(event: DragEvent) =>
                    onDragStart(event, "Appointment", "default_appointment")
                  }
                  draggable
                  className={classes.chip}
                />
              )}
              {botprofile?.isCallbackOn && (
                <Chip
                  label="Callback"
                  variant="filled"
                  color="secondary"
                  onDragStart={(event: DragEvent) =>
                    onDragStart(event, "Callback", "default_callback")
                  }
                  draggable
                  className={classes.chip}
                />
              )}
              {botprofile?.isAgentRequired && (
                <Chip
                  label="Agent Handover"
                  variant="filled"
                  color="secondary"
                  onDragStart={(event: DragEvent) =>
                    onDragStart(
                      event,
                      "Agent Handover",
                      "default_agentHandover"
                    )
                  }
                  draggable
                  className={classes.chip}
                />
              )}
              {botprofile?.isRaiseTicketRequired && (
                <Chip
                  label="Raise a Ticket"
                  variant="filled"
                  color="secondary"
                  onDragStart={(event: DragEvent) =>
                    onDragStart(event, "Raise a Ticket", "default_raise_ticket")
                  }
                  draggable
                  className={classes.chip}
                />
              )}
            </Stack>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

export default DefaultNodes;
