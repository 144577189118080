//import PropTypes from "prop-types";
import classNames from "clsx";
import { Typography } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./style";

function FormFeedback(props) {
    return (
        <div
            className={classNames(
                props.classes.root,
                {
                    [props.classes.error]: props.error,
                    [props.classes.success]: props.success
                },
                props.className
            )}
        >
            <Typography color="inherit">{props.children}</Typography>
        </div>
    );
}

// FormFeedback.propTypes = {
//     children: PropTypes.node,
//     classes: PropTypes.object.isRequired,
//     className: PropTypes.string,
//     error: PropTypes.bool,
//     success: PropTypes.bool
// };

export default withStyles(styles)(FormFeedback);
