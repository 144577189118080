import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useStyles } from "./style";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_BOT_FRAMEWORK } from "modules/bot_profiles/adapters/mutations/EditBotFramework";
import { BOT_PROFILE_LANG } from "modules/bot_profiles/adapters/queries/BotProfileFetch";
import { BOT_FRAME_WORK_TRANS } from "modules/bot_profiles/adapters/queries/BotFrameworkTrans";
import TranslateIcon from "@mui/icons-material/Translate";

interface WelcomeMessageProps {
  companyId: string;
  botProfileId: string;
  welcomeMessage?: string;
  frmeworkId?: string;
}

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const WelcomeMessage = (props: WelcomeMessageProps) => {
  const classes = useStyles();
  console.log("welcomeMessage", props.welcomeMessage);

  const [welcomeMessage, setWelcomeMessage] = useState<string>(
    props.welcomeMessage ? props.welcomeMessage : ""
  );
  const [translatedMessages, setTranslatedMessages] = useState<{
    [key: string]: string;
  }>({});
  const [loadingLanguages, setLoadingLanguages] = useState<{
    [key: string]: boolean;
  }>({});
  const [error, setError] = useState<string | undefined>("");
  const [isEditable, setIsEditable] = useState<boolean>(true);

  const { data: LangData } = useQuery(BOT_PROFILE_LANG, {
    variables: {
      botprofileId: props.botProfileId,
    },
  });

  useQuery(BOT_FRAME_WORK_TRANS, {
    variables: {
      botFrameworkByBotProfileIdId: props.botProfileId,
    },
    onCompleted: (data) => {
      const translations = JSON.parse(
        data.botFrameworkByBotProfileId.welcomeMessageTranslation || "{}"
      );
      setTranslatedMessages(translations);
    },
  });

  useEffect(() => {
    setWelcomeMessage(props.welcomeMessage ? props.welcomeMessage : "");
  }, [props.welcomeMessage]);

  const [
    editFaq,
    { loading: editFrameworkLoading, error: editFrameworkError },
  ] = useMutation(UPDATE_BOT_FRAMEWORK, {
    onCompleted: (res) => {
      console.log(res);
    },
    onError: () => {
      setError(editFrameworkError?.message);
    },
  });

  const primaryLanguage = LangData?.botprofile.primaryLanguage;
  const languages = JSON.parse(LangData?.botprofile.languages || "[]");

  const translateMessage = async (language: string) => {
    if (welcomeMessage.trim() === "") {
      setError("Please add a welcome message.");
      return;
    }

    setLoadingLanguages((prev) => ({ ...prev, [language]: true }));

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = {
        message: welcomeMessage || "",
        source_language: primaryLanguage,
        target_language: language,
      };

      const requestOptions: RequestInit = {
        method: "POST",
        mode: "cors",
        headers: myHeaders,
        body: JSON.stringify(raw),
      };

      const response = await fetch(
        `${process.env.REACT_APP_LLM_TRANSLATION_URL}/translate/message`,
        requestOptions
      );
      if (response.status === 200) {
        const JsonText = await response.text();
        const result = JSON.parse(JsonText);
        setTranslatedMessages((prevMessages) => ({
          ...prevMessages,
          [language]: result.message,
        }));
      } else {
        setError("Failed to Translate");
      }
    } catch (error) {
      console.log(error);
      setError("An error occurred during translation");
    } finally {
      setLoadingLanguages((prev) => ({ ...prev, [language]: false }));
    }
  };

  const handlePrimaryLanguageChange = (text: string) => {
    setWelcomeMessage(text);
    if (isEditable) {
      setTranslatedMessages((prevMessages) => {
        const updatedMessages = { ...prevMessages };
        languages.forEach((lang: { title: string; value: string }) => {
          if (lang.value !== primaryLanguage) {
            updatedMessages[lang.value] = text;
          }
        });
        return updatedMessages;
      });
    }
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          className={classes.accordianHeader}
          expandIcon={<ExpandMoreOutlined />}
          id="default-nodes"
        >
          <Typography variant="h6">Welcome Message</Typography>
        </AccordionSummary>
        {editFrameworkLoading && <LinearProgress />}
        <AccordionDetails>
          <Box>
            {languages.map((lang: { title: string; value: string }) => (
              <Box key={lang.value} display="flex" alignItems="center" mb={2}>
                <TextField
                  fullWidth
                  label={`Message(${lang.title})`}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (lang.value === primaryLanguage) {
                      handlePrimaryLanguageChange(newValue);
                    } else {
                      setTranslatedMessages((prevMessages) => ({
                        ...prevMessages,
                        [lang.value]: newValue,
                      }));
                    }
                  }}
                  value={
                    lang.value === primaryLanguage
                      ? welcomeMessage
                      : translatedMessages[lang.value] || ""
                  }
                  sx={{ "& .MuiInputBase-root": { height: "60px" } }}
                  InputProps={{
                    readOnly: lang.value !== primaryLanguage,
                  }}
                />
                {primaryLanguage !== lang.value && isEditable && (
                  <Button
                    variant="contained"
                    onClick={() => translateMessage(lang.value)}
                    sx={{
                      marginLeft: "10px",
                      height: "40px",
                      minWidth: "50px",
                    }}
                  >
                    {loadingLanguages[lang.value] ? (
                      <CircularProgress size={30} sx={{ color: "#fff" }} />
                    ) : (
                      <TranslateIcon />
                    )}
                  </Button>
                )}
              </Box>
            ))}
          </Box>
          {error ? (
            <>
              <br />
              <Typography variant="body1">{error}</Typography>
              <br />
            </>
          ) : null}

          <Box className={classes.buttonContainer}>
            <Button
              variant="contained"
              onClick={() => {
                if (welcomeMessage.trim() === "") {
                  setError("Please add a welcome message");
                } else if (props.frmeworkId) {
                  setError("");
                  setIsEditable(false);
                  editFaq({
                    variables: {
                      input: {
                        welcomeMessage,
                        welcomeMessageTranslation:
                          JSON.stringify(translatedMessages),
                        id: props.frmeworkId,
                        botProfileId: props.botProfileId,
                        companyId: props.companyId,
                      },
                    },
                  });
                }
              }}
              sx={{ height: "40px" }}
            >
              Save
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default WelcomeMessage;
