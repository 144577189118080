import { gql } from "@apollo/client";

export const LIST_BUSINESS_ADMIN_APPOINTMNENTS = gql`
    fragment ListAccount_appointments_2PpJm0 on Query {
        appointments(first: $count, after: $endcursor , before : $startcursor , last : $reversecount, userId: $userId, status: $status, search: $search, customerId: $customerId, assigneeId: $assigneeId) {
            edges {
                node {
                    id
                    customer {
                        firstName
                        lastName
                        email
                        phone
                        id
                    }
                    assignee {
                        id
                        firstName
                        lastName
                        email
                    }
                    startDate
                    status
                    reason
                    comment
                    companyType
                    companyId
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
