import { Route, Navigate } from "react-router-dom";
import Login from "pages/login/Login";
import ForgotPassword from "pages/forgot_password/ForgotPassword";

export function AuthRoutes(isAuthenticated: boolean) {
    const routes = [
        !isAuthenticated && <Route key="forgotPassword" path="/forgot_password" element={<ForgotPassword />} />,
        !isAuthenticated && <Route key="login" path="/login" element={<Login />} />,
        !isAuthenticated && <Route key="redirect" path="*" element={<Navigate to="/login" />} />,
    ];
    return routes.filter(Boolean);
}
