import { gql } from "@apollo/client";

export const GET_NOTE = gql`
query GetNotebyCallID($getNotebyCallIdId: String!) {
  getNotebyCallID(id: $getNotebyCallIdId) {
    id
    note
    updatedAt
    createdAt
  }
}
`

// {
//   "getNotebyCallIdId": null
// }