import { ModuleRouteConfig } from "common/types/RouteTypes";
import PaymentList from "modules/payments/pages/PaymentsPage";
import ViewPayment from "modules/payments/pages/PaymentViewPage";

export const PaymentRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "view_payments",
    path: "/:id",
    element: <ViewPayment />,
  },
  {
    permissionId: "view_payments",
    path: "",
    element: <PaymentList />,
  },
];
