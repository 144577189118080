import { gql } from "@apollo/client";

export const CHANGE_SUBSCRIPTION_COMPANY = gql`
mutation UpdateSubscription($planId: String!) {
  updateSubscription(planId: $planId) {
    status
    id
    default_payment_method
    customer
    collection_method
  }
}
`