import { gql } from "@apollo/client";

export const GET_SESSION_BY_CHATROOM_ID = gql`
query getSessionByChatRoomId($chatRoomId: String!){
    getSessionByChatRoomId(chatRoomId: $chatRoomId){
      id
      createdAt
      updatedAt
    }
  }
`