import { format } from "ts-date/esm/locale/en";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Comment from "components/modal/Comment";
import { CallbacksPlaceHolders } from "../searchbar/Searchbar";
import { TableRow, TableCell, Hidden, Grid, Typography } from "@mui/material";

export default function RejectedItem(props: any) {
  const str1 = new Date(props?.request?.createdAt!).toISOString();
  let dt: any;
  dt = "";
  if (str1) {
    try {
      dt = format(new Date(str1.substr(0, 19)), "DD MMM, YYYY - hh:mm A");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <TableRow hover>
      <Hidden smDown>
        <TableCell>
          {props?.request?.customer?.firstName!}
          {props?.request?.customer?.lastName!
            ? props?.request?.customer?.lastName! ===
              props?.request?.customer?.firstName!
              ? " "
              : " " + props?.request?.customer?.lastName!
            : ""}
          <br />
          {props?.request?.customer?.email!}
          <br />
          {props?.request?.customer?.phone!}
        </TableCell>
        <TableCell>{CallbacksPlaceHolders(props?.request?.status)}</TableCell>
        <TableCell>{props?.request?.reason}</TableCell>
        <TableCell></TableCell>
        <TableCell>
          {props?.request?.assignee?.firstName! +
            " " +
            props?.request?.assignee?.lastName!}
          <br />
          {props?.request?.assignee?.email!}
        </TableCell>

        {/* <TableCell>
          <Comment comment={props?.request?.comment} />
          <IconButton />
        </TableCell> */}
      </Hidden>

      <Hidden mdUp>
        <Grid container spacing={0} style={{ position: "relative", padding: "16px" }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.request?.customer?.firstName!}
              {props?.request?.customer?.lastName!
                ? props?.request?.customer?.lastName! ===
                  props?.request?.customer?.firstName!
                  ? " "
                  : " " + props?.request?.customer?.lastName!
                : ""}
              <br />
              {props?.request?.customer?.email!}
              <br />
              {props?.request?.customer?.phone!}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {CallbacksPlaceHolders(props?.request?.status)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props?.request?.reason}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{dt}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.request?.assignee?.firstName! +
                " " +
                props?.request?.assignee?.lastName!}
              <br />
              {props?.request?.assignee?.email!}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <ListItemSecondaryAction>
              <Comment comment={props?.request?.comment} />
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
