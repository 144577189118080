import {
    Theme,
    createStyles,
    makeStyles
} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints?.up("md")]: {
                width: "80%"
            }
        },
        gridList: {
            width: "100%"
        },
        cardSubheader: {
            marginLeft: theme.spacing(1),
            fontSize: "0.9rem",
            backgroundColor: "#ff9800",
            padding: "3px",
            borderRadius: "5px",
            color: "#fff"
        },
        greenCardSubheader: {
            marginLeft: theme.spacing(1),
            fontSize: "0.9rem",
            backgroundColor: "#4caf50",
            padding: "3px",
            borderRadius: "5px",
            color: "#fff"
        },
        redCardSubheader: {
            marginLeft: theme.spacing(1),
            fontSize: "0.9rem",
            backgroundColor: "#ff4c4c",
            padding: "3px",
            borderRadius: "5px",
            color: "#fff"
        },
        feedback: {
            marginTop: theme.spacing(2),
            textTransform: "capitalize"
        },
        title: {
            fontSize: 14
        },
        error: {
            textTransform: "capitalize"
        },
        usageContainer: {
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
            flexWrap: 'nowrap',
            padding: "16px",
            [theme.breakpoints?.up("md")]: {
                maxWidth: '80vw',
            },
            [theme.breakpoints?.down("md")]: {
                maxWidth: '70vw',
            },
            [theme.breakpoints?.down("sm")]: {
                maxWidth: '80vw',
            },
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
    })
);

export const useStylesCustom = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative"
        },
        bottom: {
            color: theme.palette.grey[
                theme.palette.type === "light" ? 200 : 700
            ]
        },
        top: {
            color: "#1a90ff",
            animationDuration: "550ms",
            position: "absolute",
            left: 0
        },
        topGreen: {
            color: "#4caf50"
        },
        topGRed: {
            color: "#f44336"
        },
        circle: {
            strokeLinecap: "round"
        }
    })
);
