import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import DialerSipIcon from '@mui/icons-material/DialerSip';
import CellTowerIcon from '@mui/icons-material/CellTower';
import IOSSwitch from 'components/layout/business_agent/topbar/IOSSwitch';
import { Customer } from '../live_conversations/LiveConversation';
import useStyles from './style';
import { useSocket } from 'context/SocketProvider';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/rootReducer';

interface ChatHeaderProps {
    selectedCustomer: Customer | null;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
    selectedCustomer
}) => {
    const classes = useStyles({});
    const { user } = useSelector((state: RootState) => state.auth);
    const { socket } = useSocket()
    const [isChattingToAgent, setIsChattingToAgent] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);

    const onHandleSubmenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setSubmenuOpen(true);
    };

    const onHandleSubmenuClose = () => {
        setAnchorEl(null);
        setSubmenuOpen(false);
    };

    const onHandleClick = (callType: string) => {
        if (socket?.connected) {
            socket?.emit("call", "chatRoomService.agentInitiatedCall", {
                data: {
                    companyId: selectedCustomer?.companyId,
                    customerId: selectedCustomer?.id,
                    agentId: user?.id,
                    type: callType
                }
            })
        }
        onHandleSubmenuClose()
    }

    useEffect(() => {
        setIsChattingToAgent(selectedCustomer?.isChattingToAgent ? true : false)
    }, [selectedCustomer])

    const closeConversation = () => {
        if (socket?.connected) {
            socket?.emit("call", "chatRoomService.removeCustomerFromAgent", {
                data: {
                    companyId: selectedCustomer?.companyId,
                    customerId: selectedCustomer?.id,
                    agentId: user?.id
                }
            })
        }
    }

    useEffect(() => {
        if (socket?.connected && selectedCustomer) {
            socket?.emit("call", "chatRoomService.switchCustomerChannel", {
                data: {
                    companyId: selectedCustomer?.companyId,
                    customerId: selectedCustomer?.id,
                    channel: isChattingToAgent ? "agent" : "bot"
                }
            })


            // socket?.on('channel_switched', (customer: Customer) => {
            //     setIsChattingToAgent(customer?.isChattingToAgent ? true : false)
            // })
        }
        return () => {
            if (socket?.connected) {
                socket?.off('channel_switched')
            }
        }
    }, [isChattingToAgent])

    const onSwitchChannel = () => {
        setIsChattingToAgent(!isChattingToAgent)
    }

    const getInitials = (firstName: string, lastName: string, id: string) => {
        return firstName ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase() : `${id.substring(0, 1).toUpperCase()}`;
    };

    return (
        <Box className={classes.stickyHeader}>
            <Box display="flex" alignItems="center">
                <Avatar className={classes.messageAvatar} sx={{ bgcolor: 'primary.main' }}>
                    {getInitials(selectedCustomer?.firstName || '', selectedCustomer?.lastName || '', selectedCustomer?.id || '')}
                </Avatar>
                <Typography variant="h6" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                    Chat with {selectedCustomer?.firstName ? `${selectedCustomer.firstName} ${selectedCustomer.lastName}` : selectedCustomer?.id}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center">
                <IOSSwitch onChange={onSwitchChannel} checked={isChattingToAgent} />
                <div>
                    <IconButton onClick={onHandleSubmenuOpen}>
                        <PhoneIcon sx={{ color: 'primary.main' }} />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={submenuOpen} onClose={onHandleSubmenuClose}>
                        <MenuItem onClick={() => onHandleClick && onHandleClick('GSM')}>
                            <DialerSipIcon sx={{ color: 'primary.main', marginRight: 1 }} />
                            Call via Phone
                        </MenuItem>
                        <MenuItem onClick={() => onHandleClick && onHandleClick('SIP')}>
                            <CellTowerIcon sx={{ color: 'primary.main', marginRight: 1 }} />
                            Call via SIP
                        </MenuItem>
                    </Menu>
                </div>
                <IconButton onClick={closeConversation}>
                    <CloseIcon sx={{ color: 'error.main' }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ChatHeader;
