import { useEffect } from "react";
import Page from "../../../../components/page/Page";
import { useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  CircularProgressProps,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Grid,
  Container,
  Stack,
  ImageList,
  ImageListItem,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { Link } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { COMPANY_BUSINESS_ADMIN_VIEW } from "modules/users/adapters/queries/CompanyBusinessAdminView";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useHasItemPermission } from "common/layouts/utils/RouteUtils";
import { LIST_PURCHASED_ADD_ONS } from "modules/subscriptions/adapters/queries/ListPurchasedAddOns";
import { Edit } from "@mui/icons-material";
import {
  useStylesCustom,
  useStyles,
} from "modules/subscriptions/pages/SubscriptionsPage/style";

function CustomCircularProgress(props: CircularProgressProps) {
  const classes = useStylesCustom();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        size={250}
        thickness={2}
        {...props}
        value={100}
        // color={"warning"}
        style={{ color: "#f2f2f2" }}
        className={classes.bottom}
      />
      <CircularProgress
        variant="determinate"
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={250}
        thickness={2}
        {...props}
      />
    </div>
  );
}

const ViewSubscriptions = (): JSX.Element => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const id = user?.id;

  const { data } = useQuery(COMPANY_BUSINESS_ADMIN_VIEW, {
    variables: { id },
  });

  const purchasedAddOns = useQuery(LIST_PURCHASED_ADD_ONS);

  useEffect(() => {
    document.title = "Subscriptions";
  }, []);

  const company = data?.company;

  const maxAgents = company?.maxAllowedAgents! || 0;
  const usedAgents = company?.consumedActiveAgents! || 0;
  const subscriptionStatus = company?.subscriptionStatus;
  // const maxMins = company?.maxAllowedMinutes || 0;
  // const usedMins = company?.consumedMinutes || 0
  const maxProfiles = company?.maxAllowedProfiles! || 0;
  const usedProfiles = company?.consumedProfiles! || 0;
  const nextBillAtString = company?.nextBillAt;
  const amount = company?.nextBillAmount || 0;

  const normalise = (value: number, min: number, max: number) => {
    if (value > max) {
      return ((value - max) * 100) / max;
    }

    return ((value - min) * 100) / (max - min);
  };

  const daysInMonth = () => {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    return new Date(year, month, 0).getDate();
  };

  // Function to calculate the difference in days
  function daysUntilNextBill(nextBillAtString: string | undefined) {
    if (!nextBillAtString) {
      // Handle the case where nextBillAtString is not available
      return null;
    }

    const nextBillAt = new Date(nextBillAtString);
    const today = new Date();
    const timeDiff = nextBillAt.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  }

  // Calculate days until the next bill, or handle the case where it's not available
  const daysUntilBill = daysUntilNextBill(nextBillAtString);

  // Check if daysUntilBill is not null before passing it to normalise
  const circularProgressDaysUntilValue =
    daysUntilBill !== null ? normalise(daysUntilBill, 0, daysInMonth()) : 0; // or any other default value you deem appropriate

  const toCapitalizedSpacedString = (input: string): string => {
    return input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const canEditSubscriptions = useHasItemPermission(
    "subscriptions",
    "edit_subscriptions"
  );
  const canViewAddOns = useHasItemPermission("subscriptions", "view_addons");
  const canEditAddOns = useHasItemPermission("subscriptions", "edit_addons");
  return (
    <Page title="">
      <Container className={classes.container} maxWidth={false}>
        <Grid className={classes.gridList} container spacing={3}>
          <Grid item key={1} lg={8} md={12} xs={12}>
            <Card>
              <CardHeader
                title="Current Plan"
                subheader={company?.pgwSubscriptionPlanId}
                titleTypographyProps={{
                  display: "inline",
                }}
                subheaderTypographyProps={
                  company?.plan! === 0
                    ? {}
                    : {
                        display: "inline",
                        className: classes.cardSubheader,
                        style: { marginLeft: 10 },
                      }
                }
                action={
                  company?.pgwSubscriptionPlanId == "enterprise"
                    ? null
                    : canEditSubscriptions && (
                        <Tooltip title="Manage Plan">
                          <IconButton
                            component={Link}
                            to={"/subscriptions/change-subscription"}
                            aria-label="Manage Plan"
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )
                }
              />
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item lg={6} md={6} xs={12}>
                    <Box
                      position="relative"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mb={1}
                    >
                      <CustomCircularProgress
                        value={normalise(usedAgents, 0, maxAgents)}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        flexDirection={"column"}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"flex-end"}
                        >
                          <Typography
                            variant="h1"
                            component="div"
                            color="textSecondary"
                          >
                            {usedAgents}
                          </Typography>
                          <Typography
                            variant="h5"
                            component="div"
                            color="textSecondary"
                            style={{ marginBottom: 8, marginLeft: 5 }}
                          >
                            /{maxAgents}
                          </Typography>
                          <Tooltip title="Number of active executives and managers.">
                            <GroupIcon
                              style={{ marginBottom: 10, marginLeft: 5 }}
                            />
                          </Tooltip>
                        </Stack>

                        <Typography
                          variant="body1"
                          component="div"
                          color="textSecondary"
                          align="center"
                        >
                          Licensed Users
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Box
                      position="relative"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CustomCircularProgress
                        value={normalise(usedProfiles, 0, maxProfiles)}
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        flexDirection={"column"}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"flex-end"}
                        >
                          <Typography
                            variant="h1"
                            component="div"
                            color="textSecondary"
                          >
                            {usedProfiles}
                          </Typography>
                          <Typography
                            variant="h5"
                            component="div"
                            color="textSecondary"
                            style={{ marginBottom: 8, marginLeft: 5 }}
                          >
                            /{maxProfiles}
                          </Typography>
                          <Tooltip title="No of bot profiles.">
                            <RecordVoiceOverIcon
                              style={{ marginBottom: 10, marginLeft: 5 }}
                            />
                          </Tooltip>
                        </Stack>

                        <Typography
                          variant="body1"
                          component="div"
                          color="textSecondary"
                          align="center"
                        >
                          Human BOTs
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          </Grid>
          <Grid item key={3} lg={4} md={12} xs={12}>
            <Card>
              <CardHeader
                title="Payment"
                subheader={subscriptionStatus}
                titleTypographyProps={{
                  display: "inline",
                }}
                subheaderTypographyProps={{
                  display: "inline",
                  className:
                    subscriptionStatus == "active"
                      ? classes.greenCardSubheader
                      : classes.redCardSubheader,
                  style: { marginLeft: 10, color: "white" },
                }}
                action={
                  <Tooltip title="Subscription Amount">
                    <Typography variant="h4" component="div" style={{}}>
                      &pound; {amount}
                    </Typography>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                <Box
                  position="relative"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={1}
                >
                  <CustomCircularProgress
                    value={circularProgressDaysUntilValue}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"flex-end"}
                    >
                      <Typography
                        variant="h1"
                        component="div"
                        color="textSecondary"
                      >
                        {daysUntilBill}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="div"
                        color="textSecondary"
                        style={{ marginBottom: 8, marginLeft: 5 }}
                      >
                        days
                      </Typography>
                    </Stack>

                    <Typography
                      variant="body1"
                      component="div"
                      color="textSecondary"
                      align="center"
                    >
                      left
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <Divider />
            </Card>
          </Grid>

          <Grid item key={2} lg={12} md={12} xs={12}>
            {canViewAddOns && (
              <Card>
                <CardHeader
                  title="Add-Ons"
                  action={
                    canEditAddOns && (
                      <Tooltip title="Add-Ons Store">
                        <IconButton
                          component={Link}
                          to={"/addons"}
                          aria-label="Add-Ons Store"
                        >
                          <AddBusinessIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                />
                <Divider />
                <CardContent>
                  <div className={classes.usageContainer}>
                    {false && (
                      <ImageList
                        sx={{
                          gridAutoFlow: "column",
                          gridTemplateColumns: `repeat(auto-fit, minmax(1fr,1fr)) !important`,
                          gridAutoColumns: "minmax(200px, 1fr)",
                        }}
                      >
                        {purchasedAddOns?.data?.listAddOnUsageDetails.map(
                          (addOn: any) => (
                            <ImageListItem key={addOn.id}>
                              <Box
                                key={addOn.id}
                                position="relative"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <CustomCircularProgress
                                  value={normalise(
                                    addOn.unitsConsumed,
                                    0,
                                    addOn.totalUnitsAvailable
                                  )}
                                />
                                <Box
                                  top={0}
                                  left={0}
                                  bottom={0}
                                  right={0}
                                  position="absolute"
                                  display="flex"
                                  flexDirection={"column"}
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"flex-end"}
                                  >
                                    <Typography
                                      variant="h1"
                                      component="div"
                                      color="textSecondary"
                                    >
                                      {addOn.unitsConsumed}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="div"
                                      color="textSecondary"
                                      style={{ marginBottom: 8, marginLeft: 5 }}
                                    >
                                      /{addOn.totalUnitsAvailable}
                                    </Typography>
                                  </Stack>
                                  <Typography
                                    variant="body1"
                                    component="div"
                                    color="textSecondary"
                                    align="center"
                                  >
                                    {addOn.allowanceType}
                                  </Typography>
                                </Box>
                              </Box>
                            </ImageListItem>
                          )
                        )}
                      </ImageList>
                    )}
                    {true &&
                      purchasedAddOns?.data?.listAddOnUsageDetails.map(
                        (addOn: any) => (
                          <div
                            key={addOn.id}
                            style={{
                              display: "inline-block",
                              marginRight: "16px",
                            }}
                          >
                            <Box
                              key={addOn.id}
                              position="relative"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <CustomCircularProgress
                                value={normalise(
                                  addOn.unitsConsumed,
                                  0,
                                  addOn.totalUnitsAvailable
                                )}
                              />
                              <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                flexDirection={"column"}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Stack
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"flex-end"}
                                >
                                  <Typography
                                    variant="h1"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    {addOn.unitsConsumed}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    component="div"
                                    color="textSecondary"
                                    style={{ marginBottom: 8, marginLeft: 5 }}
                                  >
                                    /{addOn.totalUnitsAvailable}
                                  </Typography>
                                </Stack>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  color="textSecondary"
                                  align="center"
                                >
                                  {toCapitalizedSpacedString(
                                    addOn.allowanceType
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        )
                      )}
                  </div>
                </CardContent>
                <Divider />
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ViewSubscriptions;
