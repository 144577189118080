import React from "react";
import {
    Card,
    Box,
    Avatar,
    Typography,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import { useStyles } from "./styles";

interface AgentCardProps {
    agent: {
        id: string;
        firstName: string;
        lastName: string;
        status: string;
        connectedWith: Array<{
            id: string;
            firstName?: string;
            lastName?: string;
        }>;
    };
    handleDragStart: (
        event: React.DragEvent<HTMLElement>,
        item: string,
        agentId: string | null
    ) => void;
    handleDrop: (event: React.DragEvent<HTMLElement>, agentId: string) => void;
    handleDragOver: (event: React.DragEvent<HTMLElement>) => void;
    isActive: boolean;
}

const getInitials = (
    firstName?: string,
    lastName?: string,
    id?: string
): string => {
    return firstName && lastName
        ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
        : id?.slice(0, 2).toUpperCase() || "";
};

const getTitle = (firstName?: string, lastName?: string, id?: string): string => {
    return firstName && lastName ? `${firstName} ${lastName}` : id || "";
};

const AgentCard: React.FC<AgentCardProps> = ({
    agent,
    handleDragStart,
    handleDrop,
    handleDragOver,
    isActive,
}) => {
    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const classes = useStyles({ isSmallScreen });

    return (
        <Card
            elevation={5}
            className={classes.agentBox}
            onDragOver={handleDragOver}
            onDrop={(event) => {
                if (agent.status === "available") {
                    handleDrop(event, agent.id)
                }
            }}>
            <Box display="flex" alignItems="center">
                <Avatar className={classes.agentAvatar}>
                    {getInitials(agent.firstName, agent.lastName)}
                </Avatar>
                <Box ml={1} display="flex" flexDirection="column">
                    <Typography variant="h6">{`${agent.firstName} ${agent.lastName}`}</Typography>
                    <Typography
                        variant="body2"
                        className={classes.status}
                        style={{
                            backgroundColor:
                                agent.status === "available"
                                    ? "#66B266"
                                    : agent.status === "unavailable"
                                        ? "#B2B200"
                                        : "#FF4C4C",
                        }}
                    >
                        {agent.status}
                    </Typography>
                </Box>
            </Box>
            <Box mt={2} sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}>
                {(isActive && agent.status === "available") && (
                    <Box className={classes.dropZone}>
                        <Typography variant="body2" align="center">
                            Drop customer here to assign!
                        </Typography>
                    </Box>
                )}
                {agent.connectedWith?.length > 0 ? (
                    agent.connectedWith.map((customer) => (
                        <Box
                            key={customer.id}
                            draggable
                            onDragStart={(event) =>
                                handleDragStart(event, customer.id, agent.id)
                            }
                            display="flex"
                            alignItems="center"
                            sx={{ borderRadius: 16, cursor: "move", backgroundColor: 'rgba(255,255,255,0)', overflow: 'hidden' }}
                            m={0.2}
                        >
                            <Tooltip
                                title={getTitle(customer.firstName, customer.lastName, customer.id)}
                            >
                                <Avatar className={classes.connectedCustomerAvatar}>
                                    {getInitials(
                                        customer.firstName,
                                        customer.lastName,
                                        customer.id
                                    )}
                                </Avatar>
                            </Tooltip>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body2" align="center">
                        No Customers Assigned, Drag and drop customer to assign
                    </Typography>
                )}
            </Box>
        </Card>
    );
};

export default AgentCard;
