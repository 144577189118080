import { gql } from "@apollo/client";

export const UPDATE_BOT_FRAMEWORK = gql`mutation UpdateBotFramework($input: UpdateBotFrameworkInput!) {
  updateBotFramework(input: $input) {
    botFrameworkEdge {
      node {
        id
        createdAt
        updatedAt
        isDeleted
        botProfileId
        companyId
        fallbackMessage
        fallbackOptions
        nodes
        edges
      }
    }
  }
}`