import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import DataPage from "common/components/datapage/DataPage";
import CommonToolbar from "common/components/toolbar/Toolbar";
import CommonSearchbar from "common/components/searchbar/Searchbar";
import { GET_CHAT_BOT_SESSIONS } from "../adapters/GetChatBotSessions";
import SessionItem from "../components/ListItem";

const CallList = () => {
  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
    setSort,
  } = usePaginationQuery(GET_CHAT_BOT_SESSIONS, {}, "getBotChatSessions");

  const columns = [
    { key: "customer.firstName", label: "Customer" },
    { key: "date", label: "Date" },
    { key: "chat", label: "Chat" },
  ];

  const list = data?.getBotChatSessions?.edges?.map((edge: any) => edge.node) || [];
  const totalCount = data?.getBotChatSessions?.totalCount || 0;
  console.log(totalCount);

  return (
    <DataPage
      title="Bot Sessions"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      setSort={setSort}
      renderItem={(node: any) => (
        <SessionItem key={node.id} ticket={node} />
      )}
      ToolbarComponent={
        <CommonToolbar title="Bot Sessions" />
      }
      SearchbarComponent={
        <CommonSearchbar
          defaultValues={{ search: "" }}
          fields={[
            {
              name: "search",
              label: "Search by name or email",
              type: "text",
            },
          ]}
          onSearch={setSearchQuery}
        />
      }
      paginationProps={{ rowsPerPageOptions: [10, 25, 50] }} // Example customization
    />
  );
};

export default CallList;
