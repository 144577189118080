import { gql } from "@apollo/client";

export const GET_AVAILABLE_AGENTS = gql`
query GetAvailableAgents($companyId: String!) {
  getAvailableAgents(companyId: $companyId) {
    id
    firstName
    lastName
    email
    sessionId
    companyId
    avatar
    socketId
    engagedWith
    status
    actor
    wrtc
    x_socket_token
    msgDirection
    connectedWith {
      firstName
      lastName
      email
      sessionId
      companyId
      avatar
      socketId
      status
      actor
      wrtc
      x_socket_token
      msgDirection
      id
    }
  }
}
`