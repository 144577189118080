import { ReactNode } from "react";
import { Controller } from "react-hook-form";
import { useStyles } from "./style";
import { Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

interface FormInputProps {
  id?: string;
  control: any;
  error?: any;
  name: string;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  select?: any;
  children?: ReactNode;
  inputProps?: any;
  defaultValue?: any;
  InputLabelProps?: any;
  helperText?: string;
  inputRef?: any;
  size?: any;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  SelectProps?: any;
  value?: any;
  className?: string;
  style?: any;
  vertical?: boolean;
  multiline?: boolean;
  rows?: number;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export default function FormInput({
  control,
  error,
  name,
  type,
  placeholder,
  autoComplete,
  label,
  select,
  children,
  defaultValue,
  InputLabelProps,
  inputProps,
  helperText,
  inputRef,
  size,
  required,
  SelectProps,
  value,
  disabled,
  className,
  style,
  onChange,
  vertical,
  multiline,
  rows,
}: FormInputProps) {
  const classes = useStyles();

  return (vertical ?
    <>
      <Stack direction={'column'} display={'flex'} alignSelf={'stretch'} width={'100%'}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={label}
              placeholder={placeholder}
              type={type}
              autoComplete={autoComplete}
              variant="filled"
              select={select}
              children={children}
              defaultValue={defaultValue}
              InputLabelProps={InputLabelProps}
              inputProps={inputProps}
              helperText={helperText}
              inputRef={inputRef}
              size={size}
              required={required}
              fullWidth={true}
              SelectProps={SelectProps}
              value={value}
              disabled={disabled}
              className={className}
              style={style}
              onChange={onChange}
              multiline={multiline}
              rows={rows}
            />
          )}
        />
        {error && (
          <Typography className={classes.errorMessage}>
            {error.message}
          </Typography>
        )}
      </Stack>
    </> :
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            placeholder={placeholder}
            type={type}
            autoComplete={autoComplete}
            variant="filled"
            select={select}
            children={children}
            defaultValue={defaultValue}
            InputLabelProps={InputLabelProps}
            inputProps={inputProps}
            helperText={helperText}
            inputRef={inputRef}
            size={size}
            required={required}
            fullWidth={true}
            SelectProps={SelectProps}
            value={value}
            disabled={disabled}
            className={className}
            style={style}
            multiline={multiline}
            rows={rows}
          />
        )}
      />
      {error && (
        <Typography className={classes.errorMessage}>
          {error.message}
        </Typography>
      )}
    </>
  );
}
