import { gql } from "@apollo/client";

export const CRM_DATA = gql`
mutation CreateCRMsync($input: CreateCRMsyncInput!) {
  createCRMsync(input: $input) {
    type
    secretKey
    lastSyncDate
    companyId
  }
}
`;
