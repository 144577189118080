import { gql } from "@apollo/client";
import { VIEW_BUSINESS_ADMIN_COMPANY_DATA } from "adapters/fragments/ViewBusinessAdminCompanyData";

export const COMPANY_BUSINESS_ADMIN_VIEW = gql`
    ${VIEW_BUSINESS_ADMIN_COMPANY_DATA}
    query CompanyAccountViewQuery($id: String!) {
        company(id: $id) {
            ...ViewAccountCompany_data
            id
        }
    }
`;
