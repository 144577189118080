import { gql } from "@apollo/client";

export const GET_TRANSLATED_FAQ = gql`query TranslatedFaqNodeByRefrenceId( $input: GetTranslatedFAQNodeInput!) {
  translatedFaqNodeByRefrenceId(input: $input) {
    displayName
    id
    questions
    responses
    translationCode
  }
}`