import { gql } from "@apollo/client";
import { LIST_BUSINESS_ADMIN_APPOINTMNENTS } from "adapters/fragments/ListBusinessAdminAppointments";

export const APPOINTMENT_BUSINESS_ADMIN_REFETCH = gql`
    ${LIST_BUSINESS_ADMIN_APPOINTMNENTS}
    query AppointmentAccountFragmentRefetchQuery(
        $count: Int
        $userId: String
        $status: String
        $search: String
        $customerId: String
        $startcursor: String
        $reversecount: Int
        $endcursor: String
        $assigneeId: String
    ) {
        ...ListAccount_appointments_2PpJm0
    }
`;
