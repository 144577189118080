import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Page from "../../../../components/page/Page";
import Toolbar from "../../components/toolbar/Toolbar";
import { useQuery } from "@apollo/client";
// import CallItem from "../list_item/ListItem";
import Searchbar, { SearchQuery } from "../../components/searchbar/Searchbar";
// import { Chip, } from "@mui/material";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  TablePagination,
  LinearProgress,
} from "@mui/material";
import CallItem from "../../components/list_item/ListItem";
import { ANSWERD_AND_MISSED_CALLS } from "modules/agent_sessions/adapters/queries/AnsweredAndMissedCalls";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const CallList = (props: any): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    searchText: "",
    status: "",
  });

  const [count, setCount] = useState(10);

  let customerId = props.match?.params?.customerId;

  const classes = useStyles();

  const { data, fetchMore, refetch, loading } = useQuery(
    ANSWERD_AND_MISSED_CALLS,
    {
      variables: {
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(customerId && { customerId: customerId }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
          count: count,
        }),
      },
    }
  );

  const totalCount = data?.answeredAndMissed?.totalCount || 0;

  const refetchWithCursor = (change: string) => {
    change === "forward" &&
      refetch({
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(customerId && { customerId: customerId }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
          count: count,
          endcursor: data?.answeredAndMissed?.pageInfo?.endCursor,
          reversecount: undefined,
          startcursor: "",
        }),
      });

    change === "backward" &&
      refetch({
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(customerId && { customerId: customerId }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
          count: undefined,
          endcursor: null,
          reversecount: count,
          startcursor: data?.answeredAndMissed?.pageInfo?.startCursor,
        }),
      });
  };

  const onPageChange = (event: unknown, newPage: number) => {
    if (newPage > page) {
      refetchWithCursor("forward");
    } else {
      refetchWithCursor("backward");
    }
    setPage(newPage);
    console.log("page", newPage);
  };

  useEffect(() => {
    console.log("count", totalCount);
    fetchMore({
      variables: {
        ...(searchQuery.status != " " && { status: searchQuery?.status }),
        ...(searchQuery.searchText != " " && {
          search: searchQuery?.searchText,
        }),
        count: count,
        ...(customerId && { customerId: customerId }),
      },
    });
  }, [searchQuery, count]);

  useEffect(() => {
    document.title = "Agent Sessions";
  }, []);

  const { answeredAndMissed } = data || {};
  const list = useMemo(() => {
    return answeredAndMissed && answeredAndMissed.edges
      ? answeredAndMissed.edges
          .filter(isNotNull)
          .map((edge: any) => edge.node)
          .filter(isNotNull)
      : [];
  }, [answeredAndMissed]);

  const [page, setPage] = useState(0);

  return (
    <Page className={classes.root} title="">
      <Container maxWidth={false}>
        <Toolbar title="Agent Sessions" subtitle="Answered" />
        <Searchbar onSearch={setSearchQuery} />

        <Card sx={{ mt: 3 }}>
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>Agent</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Record</TableCell>
                  <TableCell>Chat</TableCell>
                  <TableCell>Feedback</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {list.length ? (
                list!.map((node: any) => {
                  if (node == null) {
                    return null;
                  }
                  return <CallItem session={node} key={node.id} />;
                })
              ) : (
                <Grid
                  className={classes.gridEmptyRecords}
                  container
                  direction="column"
                  item
                  xs={12}
                >
                  No call records found
                </Grid>
              )}
            </TableBody>
          </Table>
          {loading && <LinearProgress />}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={(e) => setCount(parseInt(e.target.value))}
            rowsPerPage={count}
          />
        </Card>
        {/* <Grid className={classes.gridList} container direction="row">
          <Hidden smDown>
            <ListItem
              className={classes.gridHeading}
              key={1}
              style={{ width: "100%" }}
            >
              <Grid item md={3}>
                <ListSubheader disableGutters component="div">
                  Customer
                </ListSubheader>
              </Grid>
              <Grid item md={3}>
                <ListSubheader disableGutters component="div">
                  Agent
                </ListSubheader>
              </Grid>
              <Grid item md={1}>
                <ListSubheader disableGutters component="div">
                  Status
                </ListSubheader>
              </Grid>
              <Grid item md={1} lg={2}>
                <ListSubheader disableGutters component="div">
                  Date
                </ListSubheader>
              </Grid>
            </ListItem>
          </Hidden>

          {list.length ? (
            list!.map((node: any) => {
              if (node == null) {
                return null;
              }
              return <CallItem session={node} key={node.id} />;
            })
          ) : (
            <Grid
              className={classes.gridEmptyRecords}
              container
              direction="column"
              item
              xs={12}
            >
              No call records found
            </Grid>
          )}
        </Grid> */}
      </Container>
    </Page>
  );
};

export default CallList;
