import { gql } from "@apollo/client";

export const GET_ALL_SUBSCRIPTIONS = gql`
query GetSubscriptions {
  getSubscriptions {
    agents
    botProfiles
    id
    isDeleted
    isPublic
    name
    price
    priceId
    updatedAt
    createdAt
  }
}`