import * as yup from "yup";
import { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import FormInput from "../../components/form/form_input/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { FORGOT_PASSWORD } from "adapters/mutations/ForgotPassword";

const schema = yup.object().shape({
  email: yup.string().min(4).required(),
});

export default function ForgotPassword() {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [forgot_password] = useMutation(FORGOT_PASSWORD, {
    onCompleted: ({ forgotPassword }: any) => {

      if (forgotPassword.success) {
        setSnack({ message: "Password link sent to registered email address", severity: 'success', open: true });
      } else {
        setSnack({ message: forgotPassword.error, severity: 'error', open: true });
      }
    },
    onError: (error) => {
      setTimeout(() => {
        setSnack({ message: "Password reset failed", severity: 'error', open: true });
        console.log(error?.message);
        console.log(error);
      }, 500);
    },
  });

  const onSubmit = ({ email }: any) => {
    forgot_password({
      variables: {
        input: {
          email
        },
      },
    });
  };

  return (
    <Container>
      <Grid container className={classes.container}>
        <Grid item lg={6} sm={12} xs={12}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.signIn}
            color="primary"
          >
            Forgot Password
          </Typography>
          <br />
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              control={control}
              type="email"
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              fullWidth
              error={errors?.email}
            />
            <br />
            <br />
            <div className={classes.btnWrapper}>
              <Button
                type="submit"
                name="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                Submit
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
