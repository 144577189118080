import { gql } from "@apollo/client";

export const ADD_BUSINESS_ADMIN_CALL_FOR_HELP = gql`
  mutation createBotProfile($input: CreateBotProfileInput!) {
    createBotProfile(input: $input) {
      profileEdge {
        node {
          id
        }
      }
    }
  }
`;
