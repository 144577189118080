import { JaaSMeeting, JitsiMeeting } from '@jitsi/react-sdk';
import { useRef } from "react"

function Jitsi({ handleReadyToClose, uploadRecordingLink, onParticipantLeft, handleIncomingMessage, handleOutgoingMessage, handleVideoConferenceJoined, chatRoomId, jitsiToken, phoneNumber }) {
    const apiRef = useRef();


    const handleJaaSIFrameRef = iframeRef => {
        iframeRef.style.border = '10px solid #3d3d3d';
        iframeRef.style.background = '#3d3d3d';
        iframeRef.style.height = '100vh';
    };

    const handleApiReady = apiObj => {
        if (phoneNumber && phoneNumber != "undefined") {
            apiObj.invite([{
                type: 'phone',
                number: phoneNumber
            }]).then(result => {
                console.log("Invitee Result", result);
            }).catch(ex => {
                console.log("Invitee Error", ex);
            })
        }

        apiRef.current = apiObj;
        apiRef.current.on('incomingMessage', payload => handleIncomingMessage(payload));
        apiRef.current.on('outgoingMessage', payload => handleOutgoingMessage(payload));
        apiRef.current.on('videoConferenceJoined', payload => handleVideoConferenceJoined(payload));
        apiRef.current.on('videoConferenceLeft', payload => handleReadyToClose(payload));
        apiRef.current.on('recordingLinkAvailable', payload => uploadRecordingLink(payload));
        apiRef.current.on('participantLeft', payload => onParticipantLeft(payload));
    };


    return (
        <>
            {process.env.REACT_APP_JITSI_DOMAIN === "8x8.vc" ?
                <JaaSMeeting
                    roomName={localStorage.getItem("chatRoomId")}
                    appId={process.env.REACT_APP_JAAS_APP_ID}
                    domain={process.env.REACT_APP_JITSI_DOMAIN}
                    //parentNode
                    getIFrameRef={handleJaaSIFrameRef}
                    jwt={localStorage.getItem("jitsi_token")}
                    interfaceConfigOverwrite={{
                        SHOW_CHROME_EXTENSION_BANNER: false,
                        SHOW_BRAND_WATERMARK: false,
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        SHOW_POWERED_BY: false,
                        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                        MOBILE_APP_PROMO: false,
                    }}
                    configOverwrite={{
                        startWithAudioMuted: true,
                        prejoinPageEnabled: false,
                        toolbarButtons: [
                            "chat",
                            "camera",
                            "fullscreen",
                            "hangup",
                            "invite",
                            "microphone",
                            "mute-everyone",
                            "mute-video-everyone",
                            "recording",
                            "select-background",
                            "settings",
                        ],
                    }}
                // onReadyToClose={() => handleReadyToClose(messages)}
                // onApiReady={externalApi => handleApiReady(externalApi)}
                /> :
                <JitsiMeeting
                    roomName={localStorage.getItem("chatRoomId") ? localStorage.getItem("chatRoomId") : chatRoomId}
                    appId={process.env.REACT_APP_JITSI_APP_ID}
                    domain={process.env.REACT_APP_JITSI_DOMAIN}
                    // parentNode
                    getIFrameRef={handleJaaSIFrameRef}
                    jwt={localStorage.getItem("jitsi_token") ? localStorage.getItem("jitsi_token") : jitsiToken}
                    interfaceConfigOverwrite={{
                        SHOW_CHROME_EXTENSION_BANNER: false,
                        SHOW_BRAND_WATERMARK: false,
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        SHOW_POWERED_BY: false,
                        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                        MOBILE_APP_PROMO: false,
                    }}
                    configOverwrite={{
                        startWithAudioMuted: true,
                        prejoinPageEnabled: false,
                        recordingSharingUrl: "https://devdocker1-api.guidizy.com/api/sessionservice.saveRecording",
                        toolbarButtons: [
                            "chat",
                            "camera",
                            "fullscreen",
                            "hangup",
                            "invite",
                            "microphone",
                            "mute-everyone",
                            "mute-video-everyone",
                            "recording",
                            "select-background",
                            "settings",
                        ],
                    }}
                    onReadyToClose={handleReadyToClose}
                    onApiReady={externalApi => handleApiReady(externalApi)}
                />
            }

        </>
    )
}

export default Jitsi;
