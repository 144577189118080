// @ts-nocheck

import { GET_RASA_BOT_CHAT } from "adapters/queries/GetAiBotChat";
import DraggableChat from "components/draggable_chat_component/DraggableChat";
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_SESSION } from "adapters/mutations/UpdateSession";
import { GET_SESSION_BY_CHATROOM_ID } from "adapters/queries/GetSessionByChatRoomId";
import { useContext, useEffect, useState } from "react";
import { callStatus } from "utils/constants";
import Jitsi from "../business_agent/JitsiConfig";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { useNavigate, useParams } from "react-router-dom";
import { UPDATE_SESSION_END_TIME } from "adapters/mutations/UpdateSessionEndTime";
import { useDispatch, useSelector } from "react-redux";
import { resetJitsiStore, updateAgentDetails, updateMessages } from "redux/actions/JitsiActions";
import { updateSession as updateAgentSession } from "redux/actions/AgentSessionActions";
import { RootState } from "redux/reducers/rootReducer";
import { UPLOAD_CALL_RECORDING } from "adapters/mutations/UploadJitsiRecording";
import { useSocket } from "context/SocketProvider";

const AgentConference = () => {
  const params = useParams()
  console.log(params);
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate()
  const [uploadingChat, setUploadingChat] = useState(false)
  const dispatch = useDispatch()
  const { messages, agentDetails } = useSelector((state: RootState) => state.jitsi)
  const { socket } = useSocket()

  const chatRoomId = `${params?.appId}/${params?.appointmentId}`;
  const jwtToken = `${params?.token}`;
  const phoneNumber = `${params?.phoneNumber}`;

  const [getChatRoomData, { data: chatData, error }] = useLazyQuery(GET_RASA_BOT_CHAT, {
    variables: { chatRoomId },
  });

  const [getSession, { data }] = useLazyQuery(GET_SESSION_BY_CHATROOM_ID, {
    variables: {
      chatRoomId
    }
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted: () => {
      console.log("Session updated");
    },
    onError: (error) => {
      console.log("update session error", error);
    },
  });

  const [uploadCallRecording] = useMutation(UPLOAD_CALL_RECORDING, {
    onCompleted: () => {
      console.log("Session updated");
    },
    onError: (error) => {
      console.log("update session error", error);
    },
  });

  const [updateSessionEndTime] = useMutation(UPDATE_SESSION_END_TIME, {
    onCompleted: () => {
      console.log("Session updated");
      dispatch(resetJitsiStore())
      localStorage.removeItem("chatRoomId");
      navigate("/dashboard");
      // set timeout
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    onError: (error) => {
      console.log("update session error", error);
    },
  });

  useEffect(() => {
    updateSession({
      variables: {
        input: {
          id: data?.getSessionByChatRoomId?.id,
          status: callStatus.ANSWERED,
        },
      },
    });
  }, [
    data?.getSessionByChatRoomId?.id,
    updateSession,
  ]);


  useEffect(() => {
    if (chatRoomId) {
      setTimeout(() => {
        getSession();
      }, 1000);
    }
  }, [chatRoomId]);


  useEffect(() => {
    if (localStorage.getItem('chatRoomId')) {
      getChatRoomData()
    }

    return () => {
      dispatch(updateAgentSession({ isCallOn: false, agentStatus: "online" }))
    }
  }, []);

  const handleReadyToClose = () => {

    if (socket?.connected) {
      socket.emit("call", "chatRoomService.callEnded", {
        data: {
          sessionId: data?.getSessionByChatRoomId?.id,
          companyId: data?.getSessionByChatRoomId?.companyId,
          customerId: data?.getSessionByChatRoomId?.customerId,
          agentId: data?.getSessionByChatRoomId?.userId
        }
      })
    }

    console.log(messages);
    dispatch(updateAgentSession(false))
    if (!uploadingChat) {
      setUploadingChat(true)
      updateSessionEndTime({
        variables: {
          input: {
            chatRoomId,
            messages
          }
        }
      })
    }
  };

  const uploadRecordingLink = (payload) => {
    console.log(payload);
    uploadCallRecording({
      variables: {
        input: {
          fqn: chatRoomId,
          url: payload.link
        }
      }
    })
  };


  const handleIncomingMessage = (payload) => {
    console.log("INCOMING MESSAGE", payload);
    dispatch(updateMessages({
      jid: payload?.from,
      content: payload?.message,
      timestamp: new Date().getTime().toString(),
      name: payload?.nick,
      email: "",
    }))
  }
  const handleOutgoingMessage = (payload) => {
    console.log("OutGoing MESSAGE", payload);
    dispatch(updateMessages({
      jid: agentDetails?.id,
      content: payload?.message,
      timestamp: new Date().getTime().toString(),
      name: agentDetails?.displayName,
      email: agentDetails?.email
    }))
  }
  const handleVideoConferenceJoined = (payload) => {
    console.log("videoConferenceJoined", payload);
    dispatch(updateAgentSession(true))
    dispatch(updateAgentDetails({
      displayName: payload?.displayName,
      email: payload?.email,
      id: payload?.id,
    }))
  }


  return (
    <div>
      {uploadingChat ? < div > Uploading Chat.....</div> : null}
      {chatRoomId && (
        <>
          {error ? <p>{JSON.stringify(error.message)}</p> :
            <Jitsi
              chatRoomId={localStorage.getItem('chatRoomId') ? localStorage.getItem('chatRoomId') : chatRoomId}
              jitsiToken={localStorage.getItem("jitsi_token") ? localStorage.getItem("jitsi_token") : jwtToken}
              onParticipantLeft={() => {
                setSnack({ message: "Participant Left", severity: 'warning', open: true });
              }}
              handleReadyToClose={handleReadyToClose}
              uploadRecordingLink={uploadRecordingLink}
              handleVideoConferenceJoined={handleVideoConferenceJoined}
              handleOutgoingMessage={handleOutgoingMessage}
              handleIncomingMessage={handleIncomingMessage}
              phoneNumber={phoneNumber} />}
        </>
      )}
      <DraggableChat messages={chatData?.getBotChatFromChatroomID?.bot_messages} />
    </div >
  );
};
export default AgentConference;
