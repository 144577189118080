import clsx from "clsx";
import { useStyles } from "./style";
import { CardHeader, Stack } from "@mui/material";


interface IProps {
  className?: string;
  showWarning?: boolean;
  maxUsersReached?: boolean;
  showAlert?: () => void;
}

const Toolbar = ({ className, ...rest }: IProps) => {
  const classes = useStyles();

  return (
    <Stack
      sx={{ pr: 2 }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title={
          <div>
            Customers
          </div>
        }
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
      />
    </Stack>
  );
};

export default Toolbar;
