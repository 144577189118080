import Chat from "components/modal/Chat";
import moment from "moment";
import { TableRow, TableCell, Hidden, Grid, Typography, Stack } from "@mui/material";

export default function SessionItem(props: any) {
  return (
    <TableRow hover>

      {/* Desktop view-set */}
      <Hidden smDown>
        {props.session.slots.first_name ?
          <TableCell>{props.session.slots.first_name + " " + props.session.slots?.last_name}
            <br />
            {props.session.slots.email} </TableCell> : <TableCell>{props.session.sender_id}</TableCell>}
        <TableCell>
          {moment(
            parseInt(props.session.latest_event_time * 1000 + "")
          ).calendar()}
        </TableCell>
        <TableCell>
          <Chat botConversation={props?.session?.bot_messages} />
        </TableCell>
      </Hidden>

      {/* Mobile view */}
      <Hidden smUp>
        <TableCell>
          <Grid container spacing={0} style={{ position: "relative" }}>
            <Grid item xs={10}>
              <Stack display={'flex'} justifyContent={'space-around'}>
                {props.session.slots.first_name ? <Typography>{props.session.slots.first_name + " " + props.session.slots?.last_name}
                  <br />
                  {props.session.slots.email} </Typography> : <Typography variant="body2">{props.session.sender_id}</Typography>}
                <Typography variant="body2">
                  {moment(parseInt(props.session.latest_event_time * 1000 + "")).calendar()}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Chat botConversation={props?.session?.bot_messages} />
            </Grid>
          </Grid>
        </TableCell>
      </Hidden>
    </TableRow >
  );
}
