import { Button } from "@mui/material";
import { useStyles } from "../../pages/ChangeSubscriptionPage/style";

function PlanButton(props: any) {
  const classes = useStyles(props);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.planBtn}
        disabled={props.isCurrent}
        onClick={props.handleClick}
      >
        {props.name}
      </Button>
    </div>
  );
}

export default PlanButton;
