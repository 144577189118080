import { AddOn } from 'modules/addons/List/List';
import { AddOnCartActionTypes, ADD_ITEM, REMOVE_ITEM, INCREASE_QUANTITY, DECREASE_QUANTITY } from '../actions/AddOnCartActions';

export interface CartItem extends AddOn {
    quantity: number;
}

interface AddOnCartState {
    items: Array<CartItem>;
    total: number;
}

const initialState: AddOnCartState = {
    items: [],
    total: 0,
}


export const JitsiReducer = (state = initialState, action: AddOnCartActionTypes): AddOnCartState => {
    switch (action.type) {
        case ADD_ITEM: {
            if (state.items.filter(it => it.id == action.payload.id).length == 0) {
                const itemsTemp = [...state.items]
                itemsTemp.push({ ...action.payload, quantity: 1 })
                const newTotal = state.total + action.payload.price
                return {
                    items: itemsTemp,
                    total: newTotal
                }
            } else {
                return state
            }
        }
        case REMOVE_ITEM: {
            if (state.items.filter(it => it.id == action.payload).length > 0) {
                const itemToBeRemoved = state.items.find(it => it.id == action.payload)
                const itemsTemp = state.items.filter(it => it.id != action.payload)
                var newTotal = state.total
                if (itemToBeRemoved) {
                    newTotal = state.total - (itemToBeRemoved?.price * itemToBeRemoved.quantity)
                }
                return {
                    items: itemsTemp,
                    total: newTotal
                }
            } else {
                return state
            }
        }
        case INCREASE_QUANTITY: {
            if (state.items.filter(it => it.id == action.payload).length > 0) {
                const itemToBeIncreased = state.items.find(it => it.id == action.payload)
                const itemsTemp = [...state.items].map((it) => ({
                    ...it,
                    quantity: it.id == action.payload ? it.quantity + 1 : it.quantity
                }))
                var newTotal = state.total
                if (itemToBeIncreased) {
                    newTotal = state.total + itemToBeIncreased.price
                }
                return {
                    items: itemsTemp,
                    total: newTotal
                }
            } else {
                return state
            }
        }
        case DECREASE_QUANTITY: {
            const itemToBeDecreased = state.items.find(it => it.id == action.payload)
            if (itemToBeDecreased && itemToBeDecreased.quantity > 1) {
                const itemsTemp = [...state.items].map((it) => ({
                    ...it,
                    quantity: it.id == action.payload ? it.quantity - 1 : it.quantity
                }))
                var newTotal = state.total
                if (itemToBeDecreased) {
                    newTotal = state.total - itemToBeDecreased.price
                }
                return {
                    items: itemsTemp,
                    total: newTotal
                }
            } else {
                return state
            }
        }
        default:
            return state;
    }
}

export default JitsiReducer;