import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import AgentCard from "../agent_card/AgentCard";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useQuery } from "@apollo/client";
import { GET_AVAILABLE_AGENTS } from "adapters/queries/GetAvailbleAgents";
import { useSocket } from "context/SocketProvider";

interface AgentListProps {
    handleDragStart: (event: React.DragEvent<HTMLElement>, item: string, agentId: string | null) => void;
    handleDrop: (event: React.DragEvent<HTMLElement>, agentId: string) => void;
    handleDragOver: (event: React.DragEvent<HTMLElement>, agentId: string) => void;
    isSmallScreen: boolean;
    activeAgent?: string | null;
}

const AgentList: React.FC<AgentListProps> = ({ handleDragStart, handleDrop, handleDragOver, isSmallScreen, activeAgent }) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const companyId = user?.companyId;

    const { data: dataAgents, refetch: refetchAgents } = useQuery(GET_AVAILABLE_AGENTS, {
        variables: { companyId },
    });

    const { socket } = useSocket();

    useEffect(() => {
        if (socket?.connected) {

            socket.on("status_changed", () => {
                refetchAgents();
            });
        }
        return () => {
            if (socket?.connected) {
                socket.off("status_changed");
            }
        };
    }, [socket]);

    const agents = dataAgents?.getAvailableAgents || [];

    return (
        <>
            <Typography
                variant="h5"
                style={{ marginBottom: "30px", textAlign: isSmallScreen ? "center" : "left" }}
            >
                Agents
            </Typography>
            <Grid container spacing={2}>
                {agents.map((agent: any) => (
                    <Grid item xs={12} md={4} key={agent.id}>
                        <AgentCard
                            agent={agent}
                            isActive={agent.id === activeAgent}
                            handleDragStart={handleDragStart}
                            handleDrop={handleDrop}
                            handleDragOver={(e) => handleDragOver(e, agent.id)}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default AgentList;
