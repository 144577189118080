import { useEffect } from "react";

import Page from "../../components/page/Page";
import Calls from "../../components/calls_graph/Calls";
import { Container } from "@mui/material";
import LiveDashboard from "components/live_dashboard/dashboard/Dashboard";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";

function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  const { permissions } = useSelector((state: RootState) => state.auth);

  return (
    <Page title="Dashboard">
      <Container maxWidth={"xl"}>
        {permissions?.dashboard?.includes("view_live_dashboard") && <LiveDashboard />}
        <Calls />
      </Container>
    </Page>
  );
}

export default Dashboard;
