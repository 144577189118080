import * as React from "react";
import Spinner from "../../../components/facebook_progress/FacebookProgress";
import TopBar from "./topbar/TopBar";
import NavBar from "./navbar";
import { useStyles } from "./style";
import { Outlet } from "react-router-dom";

export default function SuperAdminLayout() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        setOpen={setOpen}
        isOpen={open}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setOpen={setOpen}
        isOpen={open}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <React.Suspense fallback={<Spinner />}>
          <Outlet />
        </React.Suspense>
      </main>
    </div>
  );
}
