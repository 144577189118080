import { gql } from "@apollo/client";

export const UPDATE_KNOWLEDGE_BASE = gql`
mutation UpdateBotKnowledgeBase($input: UpdateBotKnowledgeBaseInput!) {
  updateBotKnowledgeBase(input: $input) {
    botFrameworkEdge {
      node {
        updatedAt
        nodes
        isDeleted
        id
        fileUploadDate
        fileMetadata
        fallbackOptions
        fallbackMessage
        edges
        createdAt
        botProfileId
        companyId
      }
    }
  }
}`