

export const UPDATE_SESSION = "UPDATE_SESSION"

export interface UpdateSessionAction {
    type: typeof UPDATE_SESSION,
    payload: {
        isCallOn: boolean,
        agentStatus: "available" | "unavailable" | "engaged"
    }
}

export type AgentSessionActionTypes = UpdateSessionAction

export const updateSession = (message: {
    isCallOn: boolean,
    agentStatus: "available" | "unavailable" | "engaged"
}): UpdateSessionAction => ({
    type: UPDATE_SESSION,
    payload: message
})
