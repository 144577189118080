import Page from "components/page/Page"
import { useNavigate } from "react-router-dom"
import PlanButton from "../change_subscription/PlanButton";

function PaymentMethodSuccess() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/subscriptions/change-subscription")
    }
    return (
        <Page title="Success ">
            <div>Payment method successfully updated</div>
            <PlanButton handleClick={handleClick} name="Return to subscriptions" />
        </Page>
    )
}

export default PaymentMethodSuccess

