import { Theme, makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: "300px"
        },
        cardHeader: {
            padding: theme.spacing(0.7, 2)
        },
        cardTitle: {
            fontSize: "1.25rem"
        },
        cardSubheader: {
            fontSize: "0.875rem"
        },
        popper: {
            width: "fit-content"
        },
        form: {},
        button: {
            color: "white"
        },
        buttonWrapper: {
            position: "relative"
        }
    })
);
