import { Route } from "react-router-dom";
import PaymentMethodSuccess from "modules/subscriptions/components/payment_method/PaymentMethodSuccess";
import AgentAppointmentConference from "components/conference/appointment/AngentConference";
import CustomerAppointmentConference from "components/conference/appointment/CustomerConference";
import Logout from "../pages/logout/Logout";
import SignUp from "pages/signup/SignUp";
import LinkMessage from "components/link_message/LinkMessage";
import AccountActivation from "pages/signup/AccountActivation";
import PaymentMethodCancel from "modules/subscriptions/components/payment_method/PaymentMethodCancel";
import NewPassword from "pages/forgot_password/NewPassword";
import PasswordChange from "pages/signup/PasswordChangeNew";

export function GeneralRoutes(isAuthenticated: boolean) {
    const routes = [
        <Route key="logout" path="/logout" element={<Logout />} />,
        <Route key="signupWithPlan" path="/signup/:plan" element={<SignUp />} />,
        <Route key="signup" path="/signup" element={<SignUp />} />,
        <Route key="paymentSuccess" path="/success" element={<PaymentMethodSuccess />} />,
        <Route key="paymentCancel" path="/cancel" element={<PaymentMethodCancel />} />,
        <Route key="accountActivation" path="/verifySignup/:token" element={<AccountActivation />} />,
        <Route key="passwordChange" path="/passwordChange/:token" element={<PasswordChange />} />,
        <Route key="newPassword" path="/user/verify/:token" element={<NewPassword />} />,
        <Route key="forcePassword" path="/ForcePassword" element={<NewPassword />} />,
        <Route key="accountVerified" path="/account/verified" element={<LinkMessage />} />,
        <Route key="customerConference" path="/appointments/meet/:appId/:appointmentId/:token" element={<CustomerAppointmentConference />} />,
        <Route key="callbackMeeting" path="/callbacks/meeting/:appId/:callbackId/:token/:phoneNumber" element={<AgentAppointmentConference />} />,
        isAuthenticated && (
            <Route
                key="authenticatedAgentConferenceWithPhone"
                path="/appointments/meeting/:appId/:appointmentId/:token/:phoneNumber"
                element={<AgentAppointmentConference />}
            />
        ),
        isAuthenticated && (
            <Route
                key="authenticatedAgentConference"
                path="/appointments/meeting/:appId/:appointmentId/:token"
                element={<AgentAppointmentConference />}
            />
        ),
    ];

    // Filter out `false` values when isAuthenticated is `false`
    return routes.filter(Boolean);
}
