import { gql } from "@apollo/client";

export const LIST_ADMIN_USERS = gql`
    fragment ListAdmin_users_3J7gr0 on Query {
        users(first: $count, after: $cursor, companyId: $companyId) {
            edges {
                node {
                    id
                    firstName
                    email
                    createdAt
                    updatedAt
                    role
                    company {
                        id
                        name
                    }
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
