import React from "react";
import clsx from "clsx";
import { Button, CardHeader, IconButton, Stack } from "@mui/material";
import { Add, Warning } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useStyles } from "./style";

interface CommonToolbarProps {
  className?: string; // Custom className for styling
  title: string; // Title for the toolbar
  showWarning?: boolean; // Whether to show the warning icon
  onWarningClick?: () => void; // Callback function when the warning icon is clicked
  showAddButton?: boolean; // Whether to show the "Add" button
  onAddButtonClick?: () => void; // Callback function when the "Add" button is clicked
  addButtonLink?: string; // Link to navigate to when the "Add" button is clicked
  addButtonLabel?: string; // Label for the "Add" button
  addButtonDisabled?: boolean; // Disable the "Add" button if true
  addButtonProps?: Record<string, unknown>; // Additional props for the "Add" button
}

const CommonToolbar: React.FC<CommonToolbarProps> = ({
  className,
  title,
  showWarning = false,
  onWarningClick,
  showAddButton = true,
  onAddButtonClick,
  addButtonLink,
  addButtonLabel = "Add",
  addButtonDisabled = false,
  addButtonProps = {},
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Stack
      sx={{ pr: 2 }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.root, className)}
      {...rest}
    >
      {/* Card Header with Title and Optional Warning Icon */}
      <CardHeader
        title={
          <div>
            {title}
            {showWarning && (
              <IconButton onClick={onWarningClick}>
                <Warning color="error" />
              </IconButton>
            )}
          </div>
        }
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
      />

      {/* Right Section with Optional Add Button */}
      {showAddButton && (
        <Button
          className={classes.addButton}
          color="primary"
          variant="contained"
          size="large"
          startIcon={<Add />}
          onClick={onAddButtonClick}
          component={addButtonLink ? Link : "button"}
          to={addButtonLink || ""}
          disabled={addButtonDisabled}
          {...addButtonProps}
        >
          {addButtonLabel}
        </Button>
      )}
    </Stack>
  );
};

export default CommonToolbar;
