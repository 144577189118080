import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        form: {},
        button: {
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            },
            color: 'white',
        },
    })
);
