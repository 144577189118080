import { gql } from "@apollo/client";

export const LOG_IN = gql`
mutation LoginUserMutation($input: AuthUserInput!) {
    authUser(input: $input) {
        user {
            id
            email
            firstName
            lastName
            profilePicture
            role
            companyId
            status
            activeToken
            refreshToken
          managerId
          manager {
            id,
            firstName
            lastName
          }
          status
            company {
                id
                name
                type
                subdomain
                subscriptionStatus
              address 
              pgwSubscriptionId
              plan {
                id
                priceId
                name
              }
            nextBillAmount
            }
        }
        token
        error
        link
        permissions
    }
}
`;
