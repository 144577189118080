import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import DataPage from "common/components/datapage/DataPage";
import CommonToolbar from "common/components/toolbar/Toolbar";
import CommonSearchbar from "common/components/searchbar/Searchbar";
import { CUSTOMER_BUSINESS_ADMIN_REFETCH } from "../../adapters/queries/CustomerBusinessRefetch";
// import { useMemo } from "react";
import CompanyItem from "../../components/list_item/ListItemTable";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const CustomerList = () => {
  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
    setSort,
  } = usePaginationQuery(CUSTOMER_BUSINESS_ADMIN_REFETCH, {}, "customers");

  const columns = [
    { key: "firstName", label: "Name" },
    { key: "email", label: "Email" },
    { key: "createdAt", label: "Creted At" },
  ];

  const { customers } = data || {};
  const totalCount = customers?.totalCount || 0;
  const list = 
  customers?.edges
    ?.map((edge: any) => edge.node)
    .filter((node: any) => node != null) || [];

  return (
    <DataPage
      title="Customers"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      setSort={setSort}
      renderItem={(node: any) => (
        <CompanyItem key={node.id} customer={node} />
      )}
      ToolbarComponent={
        <CommonToolbar title="Customers" />
      }
      SearchbarComponent={
        <CommonSearchbar
          defaultValues={{ search: "" }}
          fields={[
            {
              name: "search",
              // label: "Search by name or email",
              type: "text",
            },
          ]}
          onSearch={setSearchQuery}
        />
      }
      paginationProps={{ rowsPerPageOptions: [10, 25, 50] }} // Example customization
    />
  );
};

export default CustomerList;
