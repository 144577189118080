import clsx from "clsx";
import { CardHeader } from "@mui/material";
import { useStyles } from "./style";

interface IProps {
  className?: string;
}

interface IProps {
  className?: string;
  title?: string;
  subTitle?: string;
  action?: number;
  role?: string;
  isAgent?: boolean;
}

const Toolbar = ({ className, title, ...rest }: IProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={title || "Appointments"}
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
      />
    </div>
  );
};

export default Toolbar;
