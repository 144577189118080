import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(logout());
    setTimeout(() => {
      navigate("/login");
    }, 10);
  }, [dispatch, navigate]);
  return <div>Logging out...</div>;
}

export default Logout;
