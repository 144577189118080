import { gql } from "@apollo/client";
import { EDIT_BUSINESS_ADMIN_COMPANY } from "./EditBusinessAdminCompany";

export const COMPANY_BUSINESS_ADMIN_EDIT = gql`
    ${EDIT_BUSINESS_ADMIN_COMPANY}
    query CompanyAccountEditQuery($id: String!) {
        company(id: $id) {
            ...EditAccountCompany_data
            id
        }
    }
`;
