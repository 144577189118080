import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Page from "../../../components/page/Page";
import Toolbar from "../components/toolbar/Toolbar";
import { useQuery } from "@apollo/client";
import SessionItem from "../components/ListItem";
import Searchbar, { SearchQuery } from "../components/searchbar/Searchbar";
import { GET_CHAT_BOT_SESSIONS } from "../adapters/GetChatBotSessions";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  TablePagination,
  LinearProgress,
} from "@mui/material";

const CallList = (): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const classes = useStyles();

  //search
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({
    searchText: "",
  });

  const { data, loading, refetch } = useQuery(GET_CHAT_BOT_SESSIONS, {
    variables: {
      search: searchQuery?.searchText,
      first: rowsPerPage,
      before: "",
      after: "",
      last: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = (newSearchQuery: any) => {
    setSearchQuery(newSearchQuery);
    setPage(0); // Reset to first page on new search
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    let cursor;
    if (newPage > page) {
      cursor = data?.getBotChatSessions?.pageInfo?.endCursor;
    } else {
      cursor = data?.getBotChatSessions?.pageInfo?.startCursor;
    }

    refetch({
      search: searchQuery?.searchText,
      after: newPage > page ? cursor : null,
      before: newPage < page ? cursor : null,
      first: newPage > page ? rowsPerPage : undefined,
      last: newPage < page ? rowsPerPage : undefined,
    }).then(() => {
      setPage(newPage);
    });
  };

  // const refetchWithCursor = (change: string) => {
  //   change === "forward" &&
  //     refetch({
  //       after: data?.getBotChatSessions?.pageInfo?.endCursor,
  //       first: rowsPerPage,
  //     });

  //   change === "backward" &&
  //     refetch({
  //       before: data?.getBotChatSessions?.pageInfo?.startCursor,
  //       last: rowsPerPage,
  //     });
  //   console.log("refetching");
  // };

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   if (newPage > page) {
  //     refetchWithCursor("forward");
  //   } else {
  //     refetchWithCursor("backward");
  //   }
  //   setPage(newPage);
  //   console.log("page", newPage);
  // };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  useEffect(() => {
    document.title = "Bot Sessions";
  }, []);

  const sessions =
    data?.getBotChatSessions?.edges?.map((edge: any) => edge.node) || [];
  const totalCount = data?.getBotChatSessions?.totalCount || 0;
  console.log(totalCount);

  return (
    <Page className={classes.root} title="Bot Sessions">
      <Container maxWidth={false}>
        <Toolbar title="Bot Sessions" />
        <Searchbar onSearch={handleSearch} />
        <Card sx={{ mt: 3 }}>
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow sx={{ borderRadius: "10px" }}>
                  <TableCell>Customer</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Chat</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {sessions.length ? (
                sessions.map((session: any) => (
                  <SessionItem session={session} key={session.sender_id} />
                ))
              ) : (
                <Grid
                  className={classes.gridEmptyRecords}
                  container
                  direction="column"
                  item
                  xs={12}
                >
                  No Chat Sessions found
                </Grid>
              )}
            </TableBody>
          </Table>
          {loading && <LinearProgress />}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default CallList;
