import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import CustomerTicketDetails from '../components/create_update/form';
import { GET_TICKET_BY_ID } from '../adapters/queries/retrieveTicket';

const TicketDetailsPage = () => {
  const { id } = useParams<{ id: string }>();

  // Fetch the ticket by its ID
  const { loading: ticketLoading, data: ticket } = useQuery(GET_TICKET_BY_ID, {
    variables: { ticket: { id } },
  });

  if (ticketLoading) {
    return <p>Loading...</p>;
  }

  if (!ticket || !ticket.retrieveTicket) {
    return <p>Ticket not found</p>;
  }

  return (
    <CustomerTicketDetails ticket={ticket.retrieveTicket} />
  );
}

export default TicketDetailsPage;