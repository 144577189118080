import { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import Page from "../../components/page/Page";
import logo from "../../assets/images/guidizy-logo.png";
import { useStyles } from "./style";

export default function LinkMessage() {
  const classes = useStyles();
  useEffect(() => {
    document.title = "Success";
  }, []);

  return (
    <Page title="Message">
      <Container maxWidth="xs">
        <Box p={1} m={1} justifyContent="center" textAlign="center">
          <img src={logo} alt="Guidizy logo" className={classes.logo} />
        </Box>

        <Card raised>
          <CardHeader title="Success!" />
          <Divider />
          <CardContent>
            <Typography component="div" variant="body2">
              {" "}
              Your account has been successfully activated. You may close this
              window
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
