import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { DropzoneState, DropEvent } from 'react-dropzone';
import { DialogContent, TextField, Dialog, Box, Button, Divider, Typography } from '@mui/material';
import { useStyles } from './style';
import { Upload } from '@mui/icons-material';

interface FileDialogProps {
    open: boolean;
    onClose: () => void;
    onFilesAdded: (files: DropzoneState['acceptedFiles']) => void;
}

const FileDialog: React.FC<FileDialogProps> = ({ open, onClose, onFilesAdded }) => {
    const [fileLink, setFileLink] = useState('');
    const [droppedFiles, setDroppedFiles] = useState<DropzoneState['acceptedFiles']>([]);

    const classes = useStyles()

    useEffect(() => {
        console.log(droppedFiles);
        if (droppedFiles.length > 0) {
            onFilesAdded(droppedFiles)
        }


    }, [droppedFiles])


    const handleFileDrop = (acceptedFiles: DropzoneState['acceptedFiles'], _rejectedFiles: DropzoneState['fileRejections'], event: DropEvent) => {
        console.log(_rejectedFiles, event);

        // event.preventDefault();
        setDroppedFiles(acceptedFiles);
    };

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileLink(event.target.value);
    };

    const handleClose = () => {
        setFileLink('');
        setDroppedFiles([]);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Typography variant='h6'>Upload file or Add Link</Typography>
                <br />
                <Dropzone onDrop={handleFileDrop} maxFiles={1} accept={{ 'application/pdf': ['.pdf'] }}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} style={{ padding: '20px', border: '2px dashed #ccc' }}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop files here, or click to select files</p>
                        </div>
                    )}
                </Dropzone>
                <TextField
                    label="File Link"
                    variant="outlined"
                    fullWidth
                    value={fileLink}
                    onChange={handleLinkChange}
                    style={{ marginTop: '10px' }}
                />
                <Divider />
                <Box className={classes.buttonContainer}>
                    <Button variant="contained" startIcon={<Upload />} onClick={() => {

                    }}>
                        Upload
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default FileDialog;
