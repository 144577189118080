import { gql } from "@apollo/client";

export const CREATE_FAQ_NODE = gql`mutation CreateFAQNode($input: CreateFAQNodeInput!) {
    createFAQNode(input: $input) {
      faqNodeEdge {
        node {
          id
        }
      }
    }
  }`