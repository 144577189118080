import { useEffect } from "react";
import clsx from "clsx";
import {
  useTheme,
  Avatar,
  Box,
  Drawer,
  Divider,
  Hidden,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/AuthActions";
import { getInitials } from "utils/get-initials";
import { useStyles } from "./style";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DomainIcon from "@mui/icons-material/Domain";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import UsersIcon from "@mui/icons-material/People";
import PaymentIcon from "@mui/icons-material/Payment";
import StorageIcon from "@mui/icons-material/Storage";
import NavItem from "./NavItem";
import LogoutItem from "components/layout/business_admin/navbar/LogoutItem";
import { RootState } from "redux/reducers/rootReducer";
import { AttachMoney } from "@mui/icons-material";

interface IProps {
  onMobileClose: () => void;
  openMobile: boolean;
  isOpen: boolean;
  setOpen: any;
}

const NavBar = ({ onMobileClose, openMobile, isOpen, setOpen }: IProps) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const userProfile = {
    avatar: "/static/images/avatars/avatar_6.png",
    jobTitle: "Super Admin",
    name: user?.firstName! + " " + user?.lastName!,
  };

  const items = [
    {
      href: "/admin/dashboard",
      icon: BarChartIcon,
      title: "Dashboard",
    },
    {
      href: "/admin/businesses",
      icon: DomainIcon,
      title: "Businesses",
    },
    {
      href: "/admin/users",
      icon: UsersIcon,
      title: "Users",
    },
    {
      href: "/admin/subscriptions",
      icon: AttachMoney,
      title: "Subscriptions",
    },
  ];

  const postItems = [
    {
      href: "/admin/payments",
      icon: PaymentIcon,
      title: "Payments",
    },
    {
      href: "/admin/add-on",
      icon: PaymentIcon,
      title: "Add On",
    },
  ];

  const docmeItems = [
    {
      href: "/admin/audit",
      icon: StorageIcon,
      title: "Event Log",
    },
  ];
  const classes = useStyles();
  const theme = useTheme();
  const { match }: any = useParams();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match!?.location]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const content = (
    <Box
      sx={{
        backgroundColor: "primary.main",
        height: "100%",
      }}
    >
      <Box
        sx={{
          paddingX: 2,
        }}
      >
        <Box
          className={clsx({
            [classes.hide]: !isOpen,
          })}
          alignItems="center"
          display="flex"
          flexDirection="column"
          color="primary"
          p={2}
        >
          <Avatar
            component={Link}
            to={`/admin/users/edit/${user?.id}`}
            sx={{
              height: 64,
              width: 64,
              fontSize: 24,
              textDecoration: "none",
              margin: 2,
            }}
          >
            {getInitials(userProfile.name)}
          </Avatar>
          <Typography color="white" variant="h5">
            {userProfile.name}
          </Typography>
          <Typography color="white" variant="body2">
            {userProfile.jobTitle}
          </Typography>
        </Box>
        <Divider
          sx={{
            borderColor: "common.white",
          }}
        />

        <Box py={0}>
          <List>
            {items.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              ></NavItem>
            ))}
            <Divider
              sx={{
                borderColor: "common.white",
              }}
            />
            {postItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
            <Divider
              sx={{
                borderColor: "common.white",
              }}
            />
            {docmeItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
          <Hidden lgUp>
            <List>
              <LogoutItem
                onClick={() => {
                  dispatch(logout());
                }}
                key={"1"}
                title={`Log Out`}
                icon={PowerSettingsNewIcon}
              />
            </List>
          </Hidden>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
            },
          }}
          open
          variant="permanent"
        >
          <Hidden lgUp>
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon sx={{ color: "common.white" }} />
                ) : (
                  <ChevronLeftIcon sx={{ color: "common.white" }} />
                )}
              </IconButton>
            </div>
          </Hidden>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
