import { gql } from "@apollo/client";

export const BUSINESS_HOURS_BUSINESS_ADMIN = gql`
    mutation updateBotProfile(
        $input: UpdateBotProfileInput!
    ) {
        updateBotProfile(input: $input) {
            profileEdge {
                node {
                    id
                }
            }
        }
    }
`;
