import { Dispatch, SetStateAction, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation } from "@apollo/client";
import { useStyles } from "./style";
import { MODAL_ACCEPT_BUSINESS_ADMIN_APPOINTMENTS } from "modules/appointments/adapters/mutations/ModalAcceptBusinessAdminAppointments";
import { MODAL_REJECT_BUSINESS_ADMIN_APPOINTMENT } from "modules/appointments/adapters/mutations/ModalRejectBusinessAdminAppointment";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { Grid, Popover, Typography } from "@mui/material";

interface IProps {
  id: string;
  el: HTMLButtonElement | null;
  onClose: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  error?: any | null;
  appointment?: any | null;
  returnUrl: () => void;
  getAssignedList: () => void;
}

export default function SimplePopover(props: IProps) {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const handleClose = () => {
    props?.onClose(null);
  };

  const open = Boolean(props?.el!);
  const id = open ? "simple-popover" : undefined;

  const [acceptMutate] = useMutation(MODAL_ACCEPT_BUSINESS_ADMIN_APPOINTMENTS, {
    onCompleted: () => {
      props?.getAssignedList();
    },
    onError: () => {
      setSnack({
        message: "Accept assigned failed",
        severity: "error",
        open: true,
      });
    },
  });

  const [rejectMutate] = useMutation(MODAL_REJECT_BUSINESS_ADMIN_APPOINTMENT, {
    onCompleted: () => {
      if (props?.returnUrl!) {
        window.location.reload();
        props?.returnUrl();
      } else {
        props?.getAssignedList();
      }
    },
    onError: () => {
      setSnack({
        message: "Reject assigned failed",
        severity: "error",
        open: true,
      });
    },
  });

  const handleAccept = () => {
    if (props?.id!) {
      acceptMutate({
        variables: {
          input: {
            id: props?.id!,
          },
        },
      });
    }
  };

  const handleReject = () => {
    if (props?.id!) {
      rejectMutate({
        variables: {
          input: {
            id: props?.id!,
          },
        },
      });
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={props?.el!}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <IconButton
            onClick={handleAccept}
            aria-label="Accept"
            color="primary"
            aria-haspopup="true"
          >
            <CheckCircleIcon />
            <Typography className={classes.typography}>Accept</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            onClick={handleReject}
            aria-label="Reject"
            color="secondary"
            aria-haspopup="true"
          >
            <CancelIcon />
            <Typography className={classes.typography}>Reject</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Popover>
  );
}
