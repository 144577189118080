import EditCallForHelpProfile from "modules/bot_profiles/pages/BotProfileEditPage";
import AddCallForHelpProfile from "modules/bot_profiles/pages/BotProfileAddPage";
import BusinessHours from "modules/bot_profiles/pages/ConfigureBusinessHoursPage";
import { ModuleRouteConfig } from "common/types/RouteTypes";
import BotFramework from "modules/bot_profiles/components/bot_framework";
import BotProfilePage from "modules/bot_profiles/pages/BotProfilePage";

export const BotProfileRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "configure_bot",
    path: "/:companyId/:botProfileId",
    element: <BotFramework />,
  },
  {
    permissionId: "configure_business_hours",
    path: "/business-hours/:id",
    element: <BusinessHours />,
  },
  {
    permissionId: "modify_bot_profiles",
    path: "/add",
    element: <AddCallForHelpProfile />,
  },
  {
    permissionId: "modify_bot_profiles",
    path: "/edit/:id",
    element: <EditCallForHelpProfile />,
  },
  {
    permissionId: "view_bot_profiles",
    path: "",
    element: <BotProfilePage />,
  },
];
