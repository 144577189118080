import { ModuleRouteConfig } from "common/types/RouteTypes";
import Callbacks from "./pages/CallbackPage";

export const CallbackRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "view_callbacks",
    path: "/:customerId",
    element: <Callbacks />,
  },
  {
    permissionId: "view_callbacks",
    path: "",
    element: <Callbacks />,
  },
];
