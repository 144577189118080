import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3, 0, 3, 0),
      marginTop: "4rem",
      height: "100%",
    },
    alertStyle: {
      margin: theme.spacing(0, 3, 3, 3),
      flex: 1,
    },
  })
);
