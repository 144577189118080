import { useContext, useEffect } from "react";
import FormButton from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { useStyles } from "./style";
import FormInput from "../../../../../components/form/form_input/FormInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFeedback from "../../../../../components/form_feedback/FormFeedback";
import { CHANGE_MODAL_AGENT_APPOINTMENT } from "../../../adapters/mutations/ChangeModalAgentAppointment";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Popover,
} from "@mui/material";

const schema = yup.object().shape({
  startDate: yup.string().required(),
});

export default function ChangeModal(props: any) {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const [mutate, { error }] = useMutation(CHANGE_MODAL_AGENT_APPOINTMENT, {
    onCompleted: () => {
      props?.getPendingList();
      handleClose();
    },
    onError: () => {
      console.log(error);
      setSnack({
        message: "Change appointment failed",
        severity: "error",
        open: true,
      });
    },
  });

  useEffect(() => {
    document.title = "Change Appointment";
  }, []);

  const handleClose = () => {
    props?.onClose(null);
  };

  const open = Boolean(props?.el!);
  const id = open ? "simple-popover" : undefined;
  const appointment = props?.appointment!;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ startDate }: any) => {
    mutate({
      variables: {
        input: {
          id: appointment?.id!,
          timeSlot: startDate!,
        },
      },
    });
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={props?.el!}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Container maxWidth={false}>
        <Card>
          <CardHeader title="Change Appointment" />
          <Divider />

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormInput
                control={control}
                name="startDate"
                label="Date & Time"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800,
                }}
              />
              {error || errors.startDate ? (
                <FormFeedback className={classes.feedback} error>
                  {errors.startDate ? errors.startDate.message : error?.message}
                </FormFeedback>
              ) : null}
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.buttonWrapper}>
                <FormButton
                  className={classes.button}
                  color="secondary"
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Change
                </FormButton>
              </div>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Popover>
  );
}
