import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  Avatar,
  Divider,
  Button,
  ListItem,
} from "@mui/material";

import DomainIcon from "@mui/icons-material/Domain";
import BarChartIcon from "@mui/icons-material/BarChart";
import UsersIcon from "@mui/icons-material/People";
import PaymentIcon from "@mui/icons-material/Payment";
import EventAvailable from "@mui/icons-material/EventAvailable";
import StorageIcon from "@mui/icons-material/Storage";
import Android from "@mui/icons-material/Android";
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  PhoneCallback,
  SupportAgent,
} from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import { RootState } from "redux/reducers/rootReducer";
import { getInitials } from "utils/get-initials";
import { useLazyQuery } from "@apollo/client";
import { GET_PROFILE_ASSET } from "modules/users/adapters/mutations/GetProfileAsset";
import { logout } from "redux/actions/AuthActions";
import LiveConversationsButton from './LiveConversationItem';

// Define the structure for menu items
interface MenuItems {
  category: string;
  items: MenuItem[];
}
interface MenuItem {
  id: string;
  path: string;
  icon: React.ElementType;
  title: string;
}

// List of menu items
const menuItems: MenuItems[] = [
  {
    category: "category1",
    items: [
      {
        id: "dashboard",
        path: "/dashboard",
        icon: BarChartIcon,
        title: "Dashboard",
      },
      {
        id: "subscriptions",
        path: "/subscriptions",
        icon: DomainIcon,
        title: "Subscriptions",
      },
      {
        id: "users",
        path: "/users",
        icon: UsersIcon,
        title: "Users",
      },
      {
        id: "tickets",
        path: "/tickets",
        icon: ConfirmationNumberIcon,
        title: "Tickets",
      },
    ],
  },
  {
    category: "category2",
    items: [
      {
        id: "customers",
        path: "/customers",
        icon: UsersIcon,
        title: "Customers",
      },
      {
        id: "appointments",
        path: "/appointments",
        icon: EventAvailable,
        title: "Appointments",
      },
      {
        id: "callbacks",
        path: "/callbacks",
        icon: PhoneCallback,
        title: "Callbacks",
      },
      {
        id: "liveConversations",
        path: "/live-conversations",
        icon: ChatIcon,
        title: "Live Conversations",
      },
      {
        id: "agentSessions",
        path: "/agent-sessions",
        icon: SupportAgent,
        title: "Agent Sessions",
      },
      {
        id: "botSessions",
        path: "/bot-sessions",
        icon: Android,
        title: "Bot Sessions",
      },
      {
        id: "botProfiles",
        path: "/bot-profile",
        icon: RecordVoiceOverIcon,
        title: "BOT Profiles",
      },
      {
        id: "payments",
        path: "/payments",
        icon: PaymentIcon,
        title: "Payments",
      },
    ],
  },
  {
    category: "category3",
    items: [
      {
        id: "eventLog",
        path: "/eventlogs",
        icon: StorageIcon,
        title: "Event Log",
      },
    ],
  },
];

const Sidebar: React.FC = () => {
  // Theme and responsive breakpoints
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State for sidebar open/close
  const [isOpen, setIsOpen] = useState(isLargeScreen);

  // State for saving profile url
  const [url, setUrl] = useState<string | null>(null);

  // Get current location and user from Redux store
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, permissions } = useSelector((state: RootState) => state.auth);

  // Query to fetch user details
  const [getProfileAsset] = useLazyQuery(GET_PROFILE_ASSET, {
    onCompleted: (data) => {
      if (data?.getProfileAsset) {
        setUrl(data.getProfileAsset.url);
      }
    },
    onError: (error) => {
      console.log("Profile Asset Error", error);
    },
  });

  // Update sidebar state when screen size changes
  useEffect(() => {
    setIsOpen(isLargeScreen);
    // Fetching profile picture if not present
    if (user?.profilePicture) {
      getProfileAsset({
        variables: {
          input: {
            key: user?.profilePicture,
          },
        },
      });
    }
  }, [isLargeScreen, user?.profilePicture, getProfileAsset]);

  // Toggle sidebar open/close
  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  // Calculate drawer width based on screen size and sidebar state
  const drawerWidth = (() => {
    if (isLargeScreen || (isMediumScreen && isOpen)) return 240;
    if (isMediumScreen) return 72;
    return 0;
  })();

  // Render user profile section
  const renderProfileSection = () => {
    if (isLargeScreen || isMediumScreen) {
      // If > md screen and isOpen. Show profile picture, name and role
      if (isOpen) {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexShrink: 0,
              width: "100%",
              borderBottom: "1px solid white",
            }}
          >
            {isMediumScreen && (
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  position: "fixed",
                  alignSelf: "flex-end",
                }}
              >
                <ChevronLeftRounded
                  sx={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                my: theme.spacing(2),
              }}
            >
              <Avatar
                component={Link}
                to={`/users/edit/${user?.id}`}
                sx={{
                  height: 96,
                  width: 96,
                  fontSize: 36,
                  textDecoration: "none",
                  margin: 2,
                }}
              >
                {user?.profilePicture && url ? (
                  <img
                    src={url}
                    alt="Avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <Typography variant="h4">
                    {getInitials(`${user?.firstName} ${user?.lastName}`)}
                  </Typography>
                )}
              </Avatar>
              <Typography variant="h5">{`${user?.firstName} ${user?.lastName}`}</Typography>
              <Typography variant="body2">{user?.role}</Typography>
            </Box>
          </Box>
        );
      } else {
        // If < md screen and minimized. Only show profile picture
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pb: 2,
              width: "100%",
              borderBottom: "1px solid white",
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              <ChevronRightRounded
                sx={{
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
            <Box>
              <Avatar
                component={Link}
                to={`/users/edit/${user?.id}`}
                sx={{
                  height: 50,
                  width: 50,
                  fontSize: 24,
                  textDecoration: "none",
                }}
              >
                {user?.profilePicture && url ? (
                  <img
                    src={url}
                    alt="Avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <Typography variant="h5">
                    {getInitials(`${user?.firstName} ${user?.lastName}`)}
                  </Typography>
                )}
              </Avatar>
            </Box>
          </Box>
        );
      }
    }
    return null;
  };

  // Render menu items with categories
  const renderMenuItems = () => (
    <List
      sx={{
        overflowY: "auto",
        flexGrow: 1,
        width: "100%",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {menuItems.map((category, index) => (
        <React.Fragment key={category.category}>
          {index > 0 && <Divider sx={{ borderColor: "white", my: 1 }} />}
          {category.items.map(
            (item) =>
              permissions[item.id]?.length > 0 && (
                // <ListItemButton
                item?.id == "liveConversations" ? (
                  <LiveConversationsButton

                  key={item.id}
                  item={item}
                  isLargeScreen={isLargeScreen}
                  isMediumScreen={isMediumScreen}
                  isOpen={isOpen}
                  location={location}
                />
                ):
                  <ListItemButton
                    key={item.id}
                    component={Link}
                    to={item.path}
                    selected={location.pathname === item.path}
                    sx={{
                      justifyContent:
                        (isLargeScreen || isMediumScreen) && isOpen
                          ? "flex-start"
                          : "center",
                      color: "grey.300",
                      borderRadius: 0.5,
                      py: (isLargeScreen || isMediumScreen) && !isOpen ? 1.5 : 1,
                      "&.Mui-selected": {
                        color: "white",
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      },
                    }}
                  >
                    <Tooltip title={!isOpen ? item.title : ""} placement="right">
                      <ListItemIcon
                        sx={{
                          color: "inherit",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: 0,
                        }}
                      >
                        <item.icon
                          color={
                            location.pathname === item.path ? "white" : "inherit"
                          }
                        />
                      </ListItemIcon>
                    </Tooltip>
                    {(isLargeScreen || isMediumScreen) && isOpen && (
                      <ListItemText sx={{ ml: 2 }} primary={item.title} />
                    )}
                  </ListItemButton>

              )
          )}
        </React.Fragment>
      ))}
    </List>
  );

  // Render logout button to logout
  const renderLogoutButton = () => {
    return (
      <ListItem
        key="logout"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexShrink: 0,
          borderTop: "1px solid white",
        }}
        disableGutters
      >
        <Button
          onClick={() => {
            dispatch(logout());
          }}
        >
          <Tooltip title="logout">
            <PowerSettingsNewIcon sx={{ color: "white", mx: 1 }} />
          </Tooltip>
          {isOpen && (
            <Box
              component="span"
              sx={{
                color: "white",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              Logout
            </Box>
          )}
        </Button>
      </ListItem>
    );
  };

  return (
    <>
      {/* {renderToggleButton()} */}
      <Drawer
        variant={isMobileScreen ? "temporary" : "permanent"}
        open={isMobileScreen ? isOpen : true}
        onClose={isMobileScreen ? handleDrawerToggle : undefined}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            mx: 0.5,
          }}
        >
          {renderProfileSection()}
          {renderMenuItems()}
          {renderLogoutButton()}
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
