import { Box, Chip, Divider, Paper, Typography } from "@mui/material";

const BotChat: React.FC<{ botMessages: any[] }> = ({ botMessages }) => {
    const buttonsMap = new Map();
  
    const generateUserMessage = (message: any) => {
      if ("text" in message && message.text.startsWith("/greet")) {
        return null;
      }
  
      const buttonText = buttonsMap.get(message.text);
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Paper sx={{ maxWidth: 450, m: 1, p: 1, bgcolor: '#3f51b5', borderRadius: '15px', color: 'white' }}>
            <Typography variant="body2">
              {buttonText || message.text}
            </Typography>
          </Paper>
        </Box>
      );
    };
  
    const generateBotMessage = (message: any) => {
      let buttonsHTML: any[] = [];
      if (message?.data?.custom?.data?.buttons) {
        message.data.custom.data.buttons.forEach((button: any) => {
          buttonsMap.set(button.payload, button.title);
          buttonsHTML.push(
            <Paper key={button.payload} elevation={3} sx={{ borderRadius: '15px', bgcolor: '#3f51b5', marginRight: 1, marginTop: 1, p: 1, color: 'white' }}>
              <Typography variant="body2">{button.title}</Typography>
            </Paper>
          );
        });
      }
  
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
          <Paper sx={{ maxWidth: 450, p: 1, borderRadius: '15px', color: 'black' }}>
            <Typography sx={{bgcolor: '#f4f7f9'}} variant="body1">{message.text}</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
              {buttonsHTML}
            </Box>
          </Paper>
        </Box>
      );
    };
  
    const messageGenerators: any = {
      user: generateUserMessage,
      bot: generateBotMessage,
    };
  
    let rows = botMessages?.map(message => {
          const generator = messageGenerators[message.event];
          return generator ? generator(message) : undefined;
      }).filter((val) => val != undefined);
  
    console.log(rows);
  
    return (
        <>
            { rows ? (
                <Box sx={{ maxWidth: 500, margin: 'auto' }}>
                <Divider sx={{marginY: 1}}>
                    <Chip label="Conversation Started" size="small" />
                </Divider>
                {rows}
                <Divider sx={{marginY: 1}}>
                    <Chip label="Conversation Ended" size="small" />
                </Divider>
                </Box>
            ) : (<>
                <Divider sx={{marginY: 1}}>
                    <Chip label="No bot navigate available" size="medium" />
                </Divider>
            </>)}
        </>
    );
}

export default BotChat;