import { Feature, SubFeature } from "./types";

export const isFeatureChecked = (feature: Feature, featurePermissions: string[]) => {
    if (!featurePermissions) return false;

    // Check if all permissions of the feature match with featurePermissions
    const isFeatureFullyChecked = feature.permissions?.every(
        (permission) => featurePermissions.includes(permission.id)
    );

    // If there are no sub-features, return the result of the permissions check
    if (!feature.subFeatures || feature.subFeatures.length === 0) {
        return isFeatureFullyChecked;
    }

    // Check if all sub-features are fully checked
    const areAllSubFeaturesChecked = feature.subFeatures.every((subFeature) =>
        subFeature.permissions?.every(
            (permission) => featurePermissions.includes(permission.id)
        )
    );

    return isFeatureFullyChecked && areAllSubFeaturesChecked;
};

export const isFeaturePartiallyChecked = (feature: Feature, featurePermissions: string[]) => {
    if (!featurePermissions) return false;

    // Check if some permissions of the feature match with featurePermissions
    const isFeaturePartiallyChecked = feature.permissions?.some(
        (permission) => featurePermissions.includes(permission.id)
    );

    // If there are no sub-features, return if it's partially checked but not fully checked
    if (!feature.subFeatures || feature.subFeatures.length === 0) {
        return isFeaturePartiallyChecked && !isFeatureChecked(feature, featurePermissions);
    }

    // Check if any sub-features are partially checked
    const isAnySubFeaturePartiallyChecked = feature.subFeatures.some(
        (subFeature) =>
            subFeature.permissions?.some(
                (permission) => featurePermissions.includes(permission.id)
            )
    );

    return (
        (isFeaturePartiallyChecked || isAnySubFeaturePartiallyChecked) &&
        !isFeatureChecked(feature, featurePermissions)
    );
};

export const isSubFeatureChecked = (subFeature: SubFeature, subFeaturePermissions: string[]) => {
    if (!subFeaturePermissions) return false;

    // Check if all permissions within the sub-feature are checked for the given role
    return subFeature.permissions?.every(
        (permission) => subFeaturePermissions.includes(permission.id)
    );
};

export const isSubFeaturePartiallyChecked = (subFeature: SubFeature, subFeaturePermissions: string[]) => {
    if (!subFeaturePermissions) return false;
    
    // Check if some permissions of the sub-feature are checked
    const isChecked = subFeature.permissions?.some(
        (permission) => subFeaturePermissions.includes(permission.id)
    );

    return isChecked && !isSubFeatureChecked(subFeature, subFeaturePermissions);
};

// It looks up the permissions of a role for a particular feature and checks if the specified permission ID is included.
export const isPermissionChecked = (
    rolePermissions: { [key: string]: string[] },
    featureId: string,
    permissionId: string
): boolean => {
    return rolePermissions[featureId]?.includes(permissionId) ?? false;
};

// It first checks the permissions directly under the feature, and then checks the permissions of any sub-features.
export const isRoleCheckedForFeature = (
    feature: Feature,
    rolePermissions: { [key: string]: string[] }
) => {
    // Check if any of the permissions directly within the feature are checked for the role.
    const featureChecked = feature.permissions?.some((permission) =>
        isPermissionChecked(rolePermissions, feature.id, permission.id)
    );

    // Check if any of the permissions within the sub-features are checked for the role.
    const subFeaturesChecked = feature.subFeatures?.some((subFeature) =>
        isRoleCheckedForSubFeature(subFeature, rolePermissions)
    );

    return featureChecked || subFeaturesChecked;
};

// It works similarly to the isRoleCheckedForFeature function but focuses on sub-features.
export const isRoleCheckedForSubFeature = (
    subFeature: SubFeature,
    rolePermissions: { [key: string]: string[] }
) => {
    return subFeature.permissions?.some((permission) =>
        isPermissionChecked(rolePermissions, subFeature.id, permission.id)
    );
};

export const isRoleCheckedForOption = (
    role: string,
    permissionId: string,
    permissions: { [key: string]: string[] }
  ): boolean => {
    // Check if the role's permissions object has the given permissionId for the specified feature
    return Object.keys(permissions).some((featureKey) =>
      permissions[featureKey].includes(permissionId)
    );
  };

  export const getAllPermissionsInFeature = (feature: Feature): string[] => {
    // Collect permission IDs from the main feature
    let permissions = feature.permissions?.map(permission => permission.id) || [];

    // Collect permission IDs from each subfeature
    if (feature.subFeatures && feature.subFeatures.length > 0) {
        feature.subFeatures.forEach(subFeature => {
            const subFeaturePermissions = getAllPermissionsInSubFeature(subFeature);
            permissions = permissions.concat(subFeaturePermissions);
        });
    }

    return permissions;
};

export const getAllPermissionsInSubFeature = (subFeature: SubFeature): string[] => {
    // Collect permission IDs from the subfeature
    return subFeature.permissions?.map(permission => permission.id) || [];
};

