import { format } from "ts-date/esm/locale/en";
import {
  Avatar,
  Grid,
  Hidden,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { getInitials } from "utils/get-initials";
import { useNavigate } from "react-router-dom";

export default function UsersListItem(props: any) {
  const navigate = useNavigate();

  const { firstName, lastName, name, email, role, status, createdAt, id } =
    props.user;

  return (
    <TableRow
      style={{ cursor: "pointer" }}
      hover
      key={id}
      onClick={() =>
        navigate(
          props?.role == "admin"
            ? `/admin/users/edit/${id}`
            : `/users/edit/${id}`
        )
      }
    >
      <Hidden smDown>
        <TableCell>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Avatar>{getInitials(firstName + " " + lastName)}</Avatar>
            <Typography variant="subtitle2">
              {firstName + " " + lastName}
            </Typography>
          </Stack>
        </TableCell>
        {props?.role === "admin" && (
          <TableCell>{props.user.company.name}</TableCell>
        )}
        <TableCell>{email}</TableCell>
        <TableCell>
          {/* {role! && props.user.role === "owner"
            ? "Owner"
            : role! && props.user.role === "agent"
            ? "Executive"
            : "Manager"} */}
          {role}
        </TableCell>
        <TableCell>{status == 1 ? "Inactive" : "Active"}</TableCell>
        <TableCell>
          {format(new Date(createdAt!), "DD MMM, YYYY hh:mm a")}
        </TableCell>
      </Hidden>
      <Hidden smUp>
        <TableCell>
          <Grid container item direction="row" spacing={2}>
            <Grid item md={2} sm={5} xs={11}>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Avatar>{getInitials(firstName + " " + lastName)}</Avatar>
                <Typography variant="subtitle2">
                  {firstName} {lastName}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} sm={5} xs={11}>
              <Typography variant="body2">{name}</Typography>
            </Grid>
            <Grid item md={3} sm={5} xs={11}>
              <Typography variant="body2">{email}</Typography>
            </Grid>

            <Grid item md={2} sm={5} xs={11}>
              <Typography variant="body2">
                {role! && props.user.role === "owner"
                  ? "Owner"
                  : role! && props.user.role === "agent"
                  ? "Executive"
                  : "Manager"}
              </Typography>
            </Grid>

            <Grid item md={1} sm={5} xs={11}>
              <Typography variant="body2">
                {status == 1 ? "Inactive" : "Active"}
              </Typography>
            </Grid>

            <Grid item md={2} sm={5} xs={11}>
              <Typography variant="body2">
                {format(new Date(createdAt!), "DD MMM, YYYY hh:mm a")}
              </Typography>
            </Grid>

            <Grid item md={2} sm={6} xs={11}>
              {/* 
            <IconButton
              aria-label="Actions"
              aria-controls="actions-menu"
              aria-haspopup="true"
              component={Link}
              to={props?.role == "admin" ? `/admin/users/edit/${props.user.id}` : `/users/edit/${props.user.id}`}
            >
              <Edit />
            </IconButton>

            <IconButton
              aria-label="Actions"
              aria-controls="actions-menu"
              aria-haspopup="true"
              component={Link}
              to={props?.role == "admin" ? `/admin/users/edit/${props.user.id}/view` : `/users/edit/${props.user.id}/view`}
            >
              <Visibility />
            </IconButton> */}
            </Grid>
          </Grid>
        </TableCell>
      </Hidden>
    </TableRow>
  );
}
