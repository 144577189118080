import { useMemo } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import Page from "../page/Page";
import { useQuery } from "@apollo/client";
import { GET_ASSETS_URL } from "adapters/queries/GetAssetsUrl";
import {
  Typography,
} from "@mui/material";


const AssetsDownloadPage = (props: any): JSX.Element => {

  const key = `${props?.match?.params?.key}`;

  const classes = useStyles();

  const { data, loading } = useQuery(GET_ASSETS_URL, {
    variables: {
      input: {
        key
      }
    },
  });


  useEffect(() => {
    document.title = "Download Assets";
  }, []);

  const { getTicketAsset } = data || {};

  const url = useMemo(() => {
    return getTicketAsset && getTicketAsset.url
      ? getTicketAsset.url
      : null;
  }, [getTicketAsset]);

  useEffect(() => {
    if (url) {
      window.location.href = url;

    }

  }, [url])


  return (
    <Page className={classes.root} title="">
      <Container maxWidth={false}>
        <Typography>{loading ? 'Downloading Asset...' : 'Asset Downloaded :)'}</Typography>
      </Container>
    </Page>
  );
};

export default AssetsDownloadPage;
