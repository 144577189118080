import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import { Message, Customer } from '../live_conversations/LiveConversation';
import useStyles from './style';

interface MessageItemProps {
    msg: Message;
    customer: Customer | null;
}

const MessageItem: React.FC<MessageItemProps> = ({ msg, customer }) => {
    const classes = useStyles({});

    const formatTimestamp = (timestamp: string) => {
        const date = new Date(parseFloat(timestamp) * 1000); // Convert from seconds to milliseconds
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    };

    const getInitials = (firstName: string, lastName: string, id: string) => {
        return firstName ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase() : `${id.substring(0, 1).toUpperCase()}`;
    };

    return (
        <Box className={msg.sender === 'agent' ? classes.messageItemRight : classes.messageItem}>
            <Box display="flex" justifyContent="flex-start">
                {msg.sender === 'customer' && (
                    <Avatar className={classes.messageAvatar} sx={{ bgcolor: '#FFB6C1', color: 'black' }}>
                        {getInitials(customer?.firstName || '', customer?.lastName || '', customer?.id || '')}
                    </Avatar>
                )}
                {msg.sender === 'bot' && (
                    <Avatar className={classes.messageAvatar} sx={{ bgcolor: 'primary.main', color: 'white' }}>
                        <AndroidIcon />
                    </Avatar>
                )}
                <Box className={classes.messageContent}>
                    <Box
                        className={msg.sender === 'agent' ? classes.messageBubbleRight : classes.messageBubble}
                        sx={{
                            bgcolor: msg.sender === 'bot' ? 'primary.dark' : msg.sender === 'agent' ? '#6366F1' : 'grey.300',
                            color: (msg.sender === 'bot' || msg.sender === 'agent') ? 'primary.contrastText' : 'black',
                        }}
                    >
                        {msg.text}
                    </Box>
                    {msg.buttons && (
                        <Box className={classes.buttonGroup}>
                            {msg.buttons.map((button, buttonIndex) => (
                                <Typography
                                    key={buttonIndex}
                                    variant="body2"
                                    sx={{
                                        bgcolor: 'primary.dark',
                                        color: 'white',
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        borderRadius: '10px',
                                        maxWidth: '100%',
                                        wordWrap: 'break-word',
                                        display: 'inline-block',
                                    }}
                                >
                                    {button.title}
                                </Typography>
                            ))}
                        </Box>
                    )}
                    <Typography variant="caption" className={classes.timestamp}>
                        {formatTimestamp(msg.timestamp)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default MessageItem;
