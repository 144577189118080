import { Route } from "react-router-dom";
import Dashboard from "superadmin/modules/dashboard/Dashboard";
import List from "superadmin/modules/businesses/list/List";
import OnBoard from "superadmin/modules/businesses/onboard/OnBoard";
import Edit from "superadmin/modules/businesses/edit/Edit";
import CompanyUsersList from "modules/users/pages/UsersPage";
import AdminUserEdit from "modules/users/pages/UsersEditPage";
import CompanyCallsList from "modules/agent_sessions/pages/AgentSessionsPage";
import BotSessionsList from "modules/bot_sessions/pages/BotSessionsListPage";
import AdminVistors from "superadmin/modules/call_for_help/list/List";
import AdminPayments from "superadmin/modules/payments/list/List";
import AdminAudit from "superadmin/modules/audit/list/List";
import ResetPassword from "modules/users/components/reset_password/ResetPassword";
import AdminAddUser from "modules/users/pages/UsersAddPage";
import AdminBotProfileView from "superadmin/modules/call_for_help/view/View";
import AdminAddOnList from "superadmin/modules/add-on/list/AddonsList";
import AdminAddOnEdit from "superadmin/modules/add-on/edit/Edit";
import AdminAddOnAdd from "superadmin/modules/add-on/add/Add";
import SubscriptionsPage from "superadmin/modules/subscriptions/pages/ListSubscriptionPage";
import SubscriptionsEditPage from "superadmin/modules/subscriptions/pages/EditSubscriptionPage";
import SubscriptionAddPage from "superadmin/modules/subscriptions/pages/AddSubscriptionPage";

export function AdminRoutes() {
    return [
        <Route path="/admin/dashboard" element={<Dashboard />} key="admin-dashboard" />,
        <Route path="/admin/businesses" element={<List />} key="admin-businesses" />,
        <Route path="/admin/businesses/onboard" element={<OnBoard />} key="admin-businesses-onboard" />,
        <Route path="/admin/businesses/edit/:id" element={<Edit />} key="admin-businesses-edit" />,
        <Route path="/admin/user/:id" element={<CompanyUsersList />} key="admin-user" />,
        <Route path="/admin/users/edit/:id" element={<AdminUserEdit />} key="admin-users-edit" />,
        <Route path="/admin/users/edit/:id/:mode" element={<AdminUserEdit />} key="admin-users-edit-mode" />,
        <Route path="/admin/users" element={<CompanyUsersList />} key="admin-users" />,
        <Route path="/admin/agent-sessions" element={<CompanyCallsList />} key="admin-agent-sessions" />,
        <Route path="/admin/agent-sessions/:id" element={<CompanyCallsList />} key="admin-agent-sessions-id" />,
        <Route path="/admin/bot-sessions" element={<BotSessionsList />} key="admin-bot-sessions" />,
        <Route path="/admin/bot-sessions/:id" element={<BotSessionsList />} key="admin-bot-sessions-id" />,
        <Route path="/admin/bot-profile" element={<AdminVistors />} key="admin-bot-profile" />,
        <Route path="/admin/payments" element={<AdminPayments />} key="admin-payments" />,
        <Route path="/admin/payments/:id" element={<AdminPayments />} key="admin-payments-id" />,
        <Route path="/admin/audit" element={<AdminAudit />} key="admin-audit" />,
        <Route path="/admin/users/password/change/:id" element={<ResetPassword />} key="admin-users-password-change" />,
        <Route path="/admin/users/add" element={<AdminAddUser />} key="admin-users-add" />,
        <Route path="/admin/bot-profile/view/:id" element={<AdminBotProfileView />} key="admin-bot-profile-view" />,
        <Route path="/admin/add-on/:id" element={<AdminAddOnList />} key="admin-add-on-id" />,
        <Route path="/admin/add-ons/edit/:id" element={<AdminAddOnEdit />} key="admin-add-ons-edit-id" />,
        <Route path="/admin/add-on" element={<AdminAddOnList />} key="admin-add-on" />,
        <Route path="/admin/add-ons/add" element={<AdminAddOnAdd />} key="admin-add-ons-add" />,
        <Route
            key="admin-subscriptions-add"
            path="/admin/subscriptions/add"
            element={<SubscriptionAddPage />}
        />,
        <Route
            key="admin-subscriptions-edit"
            path="/admin/subscriptions/edit/:id"
            element={<SubscriptionsEditPage />}
        />,
        <Route path="/admin/subscriptions"
            key="admin-subscriptions" element={<SubscriptionsPage />} />,
    ];
}
