import { gql } from "@apollo/client";

export const CREATE_NOTE = gql`
mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      NoteEdge {
        node {
          id
        }
      }
    }
  }
`;

// "input": {
//     "botProfileId": null,
//     "callId": null,
//     "customerId": null,
//     "id": null,
//     "note": null,
//     "userId": null
//   }
// }