import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { useMutation } from "@apollo/client";
import Page from "../../components/page/Page";
import logo from "../../assets/images/guidizy-logo.png";
import { useStyles } from "./style";
import { VERIFY_EMAIL } from "adapters/mutations/VerifyEmail";
import { useParams } from "react-router-dom";

export default function PasswordChange() {
    const classes = useStyles();
    const [newPasswordLink, setPasswordLink] =useState();
    const [showButton, setShowButton] = useState(true);
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    useEffect(() => {
        document.title = "Email Activation";
    }, []);
      const [mutate] = useMutation(VERIFY_EMAIL, {
        onCompleted: ({verifyEmail}) => {
          console.log('Email Updation completed:', verifyEmail.link);
          setPasswordLink(verifyEmail.link)
          setShowButton(verifyEmail.resetPassword);
          setEmailConfirmed(verifyEmail.emailConfirmed);
        },
        onError: (error) => {
          console.log('Email Updation failed:', error);
        },
      });
      const { token } = useParams();
      useEffect(() => {
        mutate({
          variables: {
            token: token,
          },
        });
      }, []);

      const redirectToAnotherPage = async () => {  
        if (newPasswordLink) {
          window.location.href = newPasswordLink;
        } else {
          window.location.href = "/logout"
        }
      };

      

  return (
    <Page title="Message">
      <Container maxWidth="xs">
        <Box p={1} m={1} justifyContent="center" textAlign="center">
          <img src={logo} alt="Guidizy logo" className={classes.logo} />
        </Box>
        <Card raised>
            <CardHeader title="Email Confirmation" />
            <Divider />
            <CardContent>
                        {emailConfirmed ? (
                            <p>Your email has been verified </p>
                        ) : (
                            <p>Your email is not verified , please verify that </p>
                        )}
                        <br />
                        {showButton ? (
                            <Box justifyContent="center" textAlign="center">
                                <Button variant="contained" color="primary" onClick={redirectToAnotherPage}>
                                    Set Password
                                </Button>
                            </Box>
                        ) : (
                            <Box justifyContent="center" textAlign="center">
                                <p></p>
                            </Box>
                        )}
                    </CardContent>
          </Card>
      </Container>
</Page>
);
}