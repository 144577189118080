import { Theme, createStyles, makeStyles } from '@material-ui/core';

// import { Theme, createStyles, makeStyles } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      [theme.breakpoints.up('md')]: {
        // width: "60%"
      },
    },
    form: {},
    card: {
      padding: theme.spacing(3),
    },
    cardHeader: {
      // padding: theme.spacing(0.7, 2),
    },
    cardTitle: {
      fontSize: '1.25rem',
    },
    cardSubheader: {
      fontSize: '0.875rem',
    },
    feedback: {
      marginTop: theme.spacing(2),
      textTransform: 'capitalize',
    },
    title: {
      fontSize: 14,
    },
    error: {
      textTransform: 'capitalize',
    },
    button: {
      color: 'white',
    },
    buttonWrapper: {
      position: 'relative',
      // marginTop: theme.spacing(2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -9,
      marginLeft: -12,
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    userDetailsCard: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      padding: 40,
    },
    avatarSize: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      overflow: 'hidden', // Ensure that the image does not overflow the avatar container
      position: 'relative', // Set position to relative to allow absolute positioning of the image
      '& > img': {
        width: '100%', // Ensure that the image fills the avatar container
        height: '100%', // Ensure that the image fills the avatar container
        objectFit: 'contain', // Preserve aspect ratio and fill the container
        position: 'absolute', // Position the image absolutely within the avatar container
        top: 0, // Align the image to the top
        left: 0, // Align the image to the left
      },
    },
    cameraIcon: {
      color: theme.palette.text.primary, // Set the color of the camera icon
      fontSize: 40, // Set the font size of the camera icon
      cursor: 'pointer', // Change cursor to pointer on hover
      transition: 'color 0.3s', // Add transition effect for color change
      '&:hover': {
        color: theme.palette.secondary.main, // Change color on hover
      },
    },

    imagePreviewContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: theme.zIndex.modal + 1,
    },
    imagePreview: {
      position: 'relative',
      maxWidth: '70vw',
      maxHeight: '70vh',
      overflow: 'hidden',
    },
    previewImage: {
      display: 'block',
      maxWidth: '70vw',
      maxHeight: '70vh',
      margin: 'auto',
      objectFit: 'contain'
    },
    closePreviewButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    snackbar: {
      position: 'fixed',
      right: '50%',
      transform: 'translateX(110%)',
      bottom: theme.spacing(10), // Adjust as needed
      zIndex: theme.zIndex.snackbar,
    },
  })
);
