// @ts-nocheck
import { useParams } from "react-router-dom";
import Jitsi from "../business_customer/JitsiConfigAppointment";

const AppointmentConference = () => {

  const params = useParams()

  const chatRoomId = `${params?.appId}/${params?.appointmentId}`;

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      {chatRoomId && (
        <Jitsi chatRoomId={chatRoomId} token={params?.token} onMeetingEnded={() => {
          window.location.replace("https://guidizy.com/")
        }} />
      )}
    </div>
  );
};
export default AppointmentConference;
