import { useContext, useState } from "react";
import { Box, Menu, MenuItem, Typography, Button, TableRow, TableCell, Hidden, Grid, Dialog, DialogContent, IconButton, styled } from "@mui/material";
import { format } from "ts-date/esm/locale/en";
import { CallbacksPlaceHolders } from "../searchbar/Searchbar";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useMutation } from "@apollo/client";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { MODAL_CALL_AGENT_CALLBACK } from "modules/callbacks/adapters/mutations/ModalCallAgentCallback";
import DialerSipIcon from '@mui/icons-material/DialerSip';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from "@mui/icons-material/Close";
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';

export default function CallbackItem(props: any) {
  const { user } = useSelector((state: RootState) => state.auth);
  console.log(user)
  const { setSnack } = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const CustomDivider = styled(Divider)({
    width: '100%',
    height: '1px',
    backgroundColor: 'black',
    margin: '0',
    border: 'none',
  });

  const CustomBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '16px',
    flex: 1,
  });

  const IconContainer = styled(Box)({
    backgroundColor: 'white',
    padding: '8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
    height: 'fit-content',
  });

  const ContentBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  });

  const [callMutate] = useMutation(MODAL_CALL_AGENT_CALLBACK, {
    onCompleted: (response) => {
      setSubmenuOpen(false)
      if (response?.call?.callbackEdge?.node?.sipCallbackLink) {
        const url = `callbacks${response?.call?.callbackEdge?.node?.sipCallbackLink}`;
        window.open(url,'_blank')
        // navigate(response?.call?.callbackEdge?.node?.sipCallbackLink)
      }
      props?.getCompletedList();
    },
    onError: () => {
      setSnack({
        message: "Unable to change the status",
        severity: "error",
        open: true,
      });
    },
  });

  // const showCompleteButton = () => {
  //   if (user?.role === "agent" || user?.role === "manager" || user?.role === "owner") {
  //     return (
  //       props.callback?.status === CallbackStatus.ACCEPTED &&
  //       user?.id === props.callback?.assignee?.id
  //     );
  //   } else {
  //     return false;
  //   }
  // };

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSubmenuOpen(true);
  };

  const handleOpenDetailsDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = (callType: string) => {
    if (props.callback?.id) {
      callMutate({
        variables: {
          input: {
            id: props.callback?.id,
            callType
          },
        },
      });
    }
  };

  const str1 = new Date(props?.callback?.createdAt!).toISOString();
  let dt: any = "";
  if (str1) {
    try {
      dt = format(new Date(str1.substr(0, 19)), "DD MMM, YYYY - hh:mm A");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <TableRow hover>
      <Hidden smDown>
        {/* Desktop view */}
        <TableCell>
          {props?.callback?.customer?.firstName!}
          {props?.callback?.customer?.lastName!
            ? props?.callback?.customer?.lastName! ===
              props?.callback?.customer?.firstName!
              ? " "
              : " " + props?.callback?.customer?.lastName!
            : ""}
          <br />
          {props?.callback?.customer?.email!}
          <br />
          {props?.callback?.customer?.phone!}
        </TableCell>
        <TableCell>{CallbacksPlaceHolders(props?.callback?.status)}</TableCell>
        <TableCell>{props?.callback?.reason ? props?.callback?.reason : ""}</TableCell>
        <TableCell>{dt}</TableCell>
        <TableCell>
          {props?.callback?.assignee?.firstName! +
            " " +
            props?.callback?.assignee?.lastName!}
          <br />
          {props?.callback?.assignee?.email!}
        </TableCell>
        <TableCell>
            <div>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSubmenuOpen}
                endIcon={<ArrowDropDownIcon />}
              >
                Make Call
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={submenuOpen}
                onClose={() => setSubmenuOpen(false)}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <MenuItem onClick={() => handleClick('SIP')}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <DialerSipIcon />
                      <Typography sx={{ ml: 1 }}>In App Calling</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={() => handleClick('GSM')}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CellTowerIcon />
                      <Typography sx={{ ml: 1 }}>Mobile Conference</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={handleOpenDetailsDialog}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InfoIcon />
                      <Typography sx={{ ml: 1 }}></Typography>
                    </Box>
                  </MenuItem>
                </Box>
              </Menu>
            </div>
          
        </TableCell>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <IconButton sx={{ position: 'absolute', top: 5, right: 5 }} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ width: '30vw', height: '50vh', margin: 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
            <CustomBox>
              <IconContainer>
                <DialerSipIcon />
              </IconContainer>
              <ContentBox>
                <Typography variant="h6">In App Calling</Typography>
                <Typography variant="body1">Make Outbound Call to Customers' Mobile from with in the App.  Once the call is connected, You will talk through App while customer talks through his / her Mobile.</Typography>
              </ContentBox>
            </CustomBox>
            <CustomDivider />
            <CustomBox>
              <IconContainer>
                <CellTowerIcon />
              </IconContainer>
              <ContentBox>
                <Typography variant="h6">Mobile Conference</Typography>
                <Typography variant="body1">Mobile Conference hosted from with in the App between your Mobile and Customers' Mobile.</Typography>
              </ContentBox>
            </CustomBox>
          </DialogContent>
        </Dialog>

      </Hidden>

      <Hidden mdUp>
        {/* Mobile view */}
        <TableCell>
          <Grid
            container
            spacing={0}
            style={{ position: "relative", padding: "16px" }}
          >
            <Grid item xs={12}>
              <Typography variant="body2">
                {props?.callback?.customer?.firstName!}
                {props?.callback?.customer?.lastName!
                  ? props?.callback?.customer?.lastName! ===
                    props?.callback?.customer?.firstName!
                    ? " "
                    : " " + props?.callback?.customer?.lastName!
                  : ""}
                <br />
                {props?.callback?.customer?.email!}
                <br />
                {props?.callback?.customer?.phone!}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                {CallbacksPlaceHolders(props?.callback?.status)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                {props?.callback?.reason ? props?.callback?.reason : ""}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">{dt}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                {props?.callback?.assignee?.firstName! +
                  " " +
                  props?.callback?.assignee?.lastName!}
                <br />
                {props?.callback?.assignee?.email!}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={false}
                  onClick={() => handleClick('GSM')}
                >
                  Call
                </Button>
            </Grid>
          </Grid>
        </TableCell>
      </Hidden>
    </TableRow>
  );
}
