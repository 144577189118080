import "./App.css";
import { useEffect } from "react";
import { SocketProvider } from "context/SocketProvider";
import AppRoutes from "./routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useLazyQuery } from "@apollo/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import CallAlert from "components/call_alert/CallAlert";
import { createEmotionCache } from "utils/create-emotion-cache";
import { createTheme } from "theme";
import { GET_USER_DETAILS } from "adapters/queries/GetUserDetails";
import { updateUserStatus } from "redux/actions/AuthActions";
import CustomerAlert from "components/customer_alert/CustomerAlert";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }: { emotionCache?: any }) {
  const theme = createTheme();

  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [runQuery] = useLazyQuery(GET_USER_DETAILS, {
    onCompleted: (data) => {
      if (data?.user) {
        dispatch(updateUserStatus(data.user));
      }
    },
  });

  useEffect(() => {
    if (user?.id) {
      runQuery({
        variables: { userId: user.id },
      });
    }
  }, [user?.id, runQuery]);

  return (
    <div>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SocketProvider>
            <CallAlert />
            <AppRoutes />
            <CustomerAlert />
          </SocketProvider>
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default App;
