import { IconButton, Box } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { useState } from 'react';

interface SortButtonsProps {
  col: any;
  handleSort: (key: string, direction: 'ASC' | 'DESC') => void;
}

const SortButtons = ({ col, handleSort }: SortButtonsProps) => {
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC'); // Initial sort direction is 'ASC'

  const handleToggleSort = () => {
    const newDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC'; // Toggle between 'ASC' and 'DESC'
    setSortDirection(newDirection);
    handleSort(col.key, newDirection); // Call the parent handler with the new direction
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" marginLeft={0.5}>
      <span>{col.label}</span>
      <IconButton
        size="small"
        onClick={handleToggleSort}
        aria-label={`sort ${col.label} ${sortDirection === 'ASC' ? 'ascending' : 'descending'}`}
        style={{ padding: '0', margin: '0', height: '24px', width: '24px', marginLeft: '0.5rem' }}
      >
        {/* Dynamically render the icon based on the current sort direction */}
        {sortDirection === 'ASC' ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
      </IconButton>
    </Box>
  );
};

export default SortButtons;
