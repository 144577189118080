import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSession } from 'redux/actions/AgentSessionActions';
import { userStatusChange } from 'redux/actions/AuthActions';
import { RootState } from 'redux/reducers/rootReducer';
import { io, Socket } from 'socket.io-client';

interface SocketContextProps {
    socket: Socket | null;
}

const SocketContext = createContext<SocketContextProps>({ socket: null });

export const useSocket = (): SocketContextProps => useContext(SocketContext);

interface SocketProviderProps {
    children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const { user, permissions } = useSelector((state: RootState) => state.auth);
    const { agentStatus } = useSelector((state: RootState) => state.agentSession);
    const socketURL = process.env.REACT_APP_SOCKET_NSP_URL ? process.env.REACT_APP_SOCKET_NSP_URL : ""
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch()

    useEffect(() => {

        if (socket != null) {
            socket?.once("agent-status-change", () => {
                enqueueSnackbar("Your licence have been rewoked!", { variant: "warning", autoHideDuration: 5000 });
                dispatch(userStatusChange())
                socket.disconnect()
            });

            socket?.once("connect_error", (err) => {
                console.log(`connect_error due to ${JSON.stringify(err)}`);
                dispatch(updateSession({ agentStatus: 'unavailable', isCallOn: true }))
            });
        }
    }, [socket])



    useEffect(() => {

        if (permissions?.liveConversations?.length > 0 && user?.status == 2) {
            if (agentStatus == 'unavailable' || agentStatus == 'engaged') {
                if (socket != null && socket?.connected) {
                    socket.disconnect()
                }
            }
        }

        if (socket == null || !socket?.connected) {
            console.log("connecting....");

            const newSocket = io(socketURL, {
                transports: ["websocket", "xhr-polling", "htmlfile", "jsonp-polling"],
                query: {
                    token: user?.token,
                },
            });


            newSocket.on("connect", () => {
                console.log("agentStatus", agentStatus);
                setSocket(newSocket);
                if (agentStatus == "available") {
                    console.log("connected");
                    if (permissions?.liveConversations?.length > 0 && user?.status == 2) {
                        newSocket.emit("call", "chatRoomService.registerAgent", {
                            data: { agent: { ...user } }
                        });
                    }

                    // else {
                    //     socket.emit("call", "chatRoomService.registerObserver", {
                    //         data: { companyId: user?.companyId }
                    //     });
                    // }
                }
            });
        }




        return () => {
            if (socket != null) {
                socket.disconnect();
            }
        };
    }, [user, agentStatus]);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};
