import { useEffect, useState } from "react";
import Page from "../../../../components/page/Page";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import { useQuery } from "@apollo/client";
import EditCRMsync from "./EditCRMsync";
import EditForm from "./EditCompanyForm";
import { COMPANY_BUSINESS_ADMIN_EDIT } from "adapters/queries/CompanyBusinessAdminEdit";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useLocation } from "react-router-dom";
import EditAddressForm from "./EditAddressForm";
import { Box, Card, CardHeader, Container, Divider, Grid, Typography } from "@mui/material";
import EditNotificationEmailsForm from "./EditNotificationEmailsForm";
import EditRoles from "./EditRoles";

export default function EditAccount() {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  const { state } = useLocation();

  const stateValue: any = state;
  let id: any = user?.companyId;
  const { data, loading, refetch } = useQuery(COMPANY_BUSINESS_ADMIN_EDIT, {
    variables: { id },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    document.title = "Edit Account";
  }, []);

  useEffect(() => {
    if (stateValue?.state) {
      refetch();
      stateValue.state = false;
    }
  }, [stateValue, refetch]);

  const [isBusinessDetailsExpanded, setIsBusinessDetailsExpanded] = useState(false);
  const [isCorporateAddressExpanded, setIsCorporateAddressExpanded] = useState(false);
  const [isNotificationEmailsExpanded, setIsNotificationEmailsExpanded] = useState(false);
  const [isCRMSyncExpanded, setIsCRMSyncExpanded] = useState(false);
  const [isRolesExpanded, setIsRolesExpanded] = useState(false);

  const handleCardClick = (setter: React.Dispatch<React.SetStateAction<boolean>>, current: boolean) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const target = event.target as Element;
      if (target === event.currentTarget || target.closest(".card-header")) {
        setter(!current);
      }
    };
  };

  if (loading) {
    return <CircularProgress size={15} />;
  }

  return (
    <Page title="Modify Company">
      <Container className={classes.container}>
        <Typography variant="h5" sx={{ paddingY: "1rem", paddingX: "1rem" }} className={classes.title}>Account Settings</Typography>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <Card className={classes.card} onClick={handleCardClick(setIsBusinessDetailsExpanded, isBusinessDetailsExpanded)}>
              <CardHeader
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Modify Business Details</Typography>
                    <Box className={classes.expandCollapseButton}>
                      <Typography className="hover-text" sx={{ color: "#862f98" }}>
                        {isBusinessDetailsExpanded ? "Collapse" : "Expand"}
                      </Typography>
                    </Box>
                  </Box>
                }
                subheader={`Update your company's basic information and key details`}
                className={`card-header ${classes.cardHeader}`}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                sx={{ padding: "16px 24px 16px 24px" }} // Adjusted padding here
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />
              {isBusinessDetailsExpanded && (
                <div onClick={(e) => e.stopPropagation()}>
                  <EditForm company={data?.company} />
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card} onClick={handleCardClick(setIsCorporateAddressExpanded, isCorporateAddressExpanded)}>
              <CardHeader
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Modify Corporate Address</Typography>
                    <Box className={classes.expandCollapseButton}>
                      <Typography className="hover-text" sx={{ color: "#862f98" }}>
                        {isCorporateAddressExpanded ? "Collapse" : "Expand"}
                      </Typography>
                    </Box>
                  </Box>
                }
                subheader={`Change the official address of your business headquarters`}
                className={`card-header ${classes.cardHeader}`}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                sx={{ padding: "16px 24px 16px 24px" }} // Adjusted padding here
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />
              {isCorporateAddressExpanded && (
                <div onClick={(e) => e.stopPropagation()}>
                  <EditAddressForm company={data?.company} />
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card} onClick={handleCardClick(setIsNotificationEmailsExpanded, isNotificationEmailsExpanded)}>
              <CardHeader
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Notification Email List</Typography>
                    <Box className={classes.expandCollapseButton}>
                      <Typography className="hover-text" sx={{ color: "#862f98" }}>
                        {isNotificationEmailsExpanded ? "Collapse" : "Expand"}
                      </Typography>
                    </Box>
                  </Box>
                }
                subheader={`Manage the list of email addresses receiving important notifications`}
                className={`card-header ${classes.cardHeader}`}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                sx={{ padding: "16px 24px 16px 24px" }} // Adjusted padding here
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />
              {isNotificationEmailsExpanded && (
                <div onClick={(e) => e.stopPropagation()}>
                  <EditNotificationEmailsForm company={data?.company} />
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card} onClick={handleCardClick(setIsCRMSyncExpanded, isCRMSyncExpanded)}>
              <CardHeader
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">CRM sync details</Typography>
                    <Box className={classes.expandCollapseButton}>
                      <Typography className="hover-text" sx={{ color: "#862f98" }}>
                        {isCRMSyncExpanded ? "Collapse" : "Expand"}
                      </Typography>
                    </Box>
                  </Box>
                }
                subheader={`Configure and view the synchronization settings with your CRM system`}
                className={`card-header ${classes.cardHeader}`}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                sx={{ padding: "16px 24px 16px 24px" }} // Adjusted padding here
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />
              {isCRMSyncExpanded && (
                <div onClick={(e) => e.stopPropagation()}>
                  <EditCRMsync />
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card} onClick={handleCardClick(setIsRolesExpanded, isRolesExpanded)}>
              <CardHeader
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Roles</Typography>
                  <Box className={classes.expandCollapseButton}>
                    <Typography className="hover-text" sx={{ color: "#862f98" }}>
                      {isRolesExpanded ? "Collapse" : "Expand"}
                    </Typography>
                  </Box>
                </Box>
                }
                subheader={`Customise Company roles `}
                className={`card-header ${classes.cardHeader}`}
                titleTypographyProps={{
                  className: classes.cardTitle,
                }}
                sx={{ padding: "16px 24px 16px 24px" }} // Adjusted padding here
                subheaderTypographyProps={{
                  className: classes.cardSubheader,
                }}
              />
              <Divider />
              {isRolesExpanded && (
                <div onClick={(e) => e.stopPropagation()}>
                  <EditRoles />
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
