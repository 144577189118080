import { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_CHAT_BOT_CUSTOMERID } from 'adapters/queries/GetBotChatFromCustomerId';
import { useSocket } from 'context/SocketProvider';
import { Customer, Message, Button } from '../live_conversations/LiveConversation';
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

export const useCustomerMessages = () => {
  const [messages, setMessages] = useState<{ [customerId: string]: Message[] }>({});
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const { socket } = useSocket();
  const { setSnack } = useContext(SnackbarContext);

  const updateChatHistory = (chatHistory: any) => {
    if (selectedCustomer) {
      const chatMessages: Message[] = [];
      let isGreetingMessageSkipped = false;

      chatHistory.bot_messages.forEach((msg: any) => {
        if (msg.event === 'user') {
          if (!isGreetingMessageSkipped && msg.text.includes('/greet')) {
            isGreetingMessageSkipped = true;
            return;
          }

          const lastBotMessage = chatMessages[chatMessages.length - 1];
          let matchedTitle = msg.text;
          if (lastBotMessage?.buttons) {
            const matchedButton = (lastBotMessage.buttons as Button[]).find((button: Button) => button.payload === msg.text);
            if (matchedButton) {
              matchedTitle = matchedButton.title;
            }
          }

          chatMessages.push({
            text: matchedTitle,
            timestamp: msg.timestamp,
            sender: 'customer',
          });
        } else if (msg.event === 'agent') {
          if (!isGreetingMessageSkipped && msg.text.includes('/greet')) {
            isGreetingMessageSkipped = true;
            return;
          }

          const lastBotMessage = chatMessages[chatMessages.length - 1];
          let matchedTitle = msg.text;
          if (lastBotMessage?.buttons) {
            const matchedButton = (lastBotMessage.buttons as Button[]).find((button: Button) => button.payload === msg.text);
            if (matchedButton) {
              matchedTitle = matchedButton.title;
            }
          }

          chatMessages.push({
            text: matchedTitle,
            timestamp: msg.timestamp,
            sender: 'agent',
          });
        } else if (msg.event === 'bot') {
          chatMessages.push({
            text: msg.text,
            timestamp: msg.timestamp,
            sender: 'bot',
            buttons: msg.data?.custom?.data?.buttons as Button[] || undefined,
          });
        }
      });
      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedCustomer.id]: chatMessages,
      }));
    }
  }

  const [getChat, { refetch }] = useLazyQuery(GET_CHAT_BOT_CUSTOMERID, {
    onCompleted: ({ getBotChatFromCustomerID }: any) => {
      updateChatHistory(getBotChatFromCustomerID)
    },
  });

  useEffect(() => {
    if (selectedCustomer && !messages[selectedCustomer.id]) {
      getChat({
        variables: {
          getBotChatFromCustomerIdId: selectedCustomer.id,
        },
      });
    }
  }, [selectedCustomer, getChat, messages]);

  useEffect(() => {
    if (socket?.connected) {
      socket.on('privateMessage', (message: any) => {
        const customerId = message?.customerId;
        const newMessageObject: Message = {
          text: message?.message,
          timestamp: (new Date().getTime() / 1000).toString(),
          sender: 'customer',
        };
        setMessages((prevMessages) => ({
          ...prevMessages,
          [customerId]: [...(prevMessages[customerId] || []), newMessageObject],
        }));
        setSnack({ message: "Message from customerId", severity: 'info', open: true, playSound: true, autoClose: true })
      });
      socket.on('agentGotConnected', (message: any) => {
        const agentId = message?.agentId;
        console.log("agentId custom",agentId)
        setSnack({ message: "Agent got connected", severity: 'info', open: true, playSound: true, autoClose: true })
      });
    }
    return () => {
      if (socket?.connected) {
        socket.off('privateMessage');
      }
    };
  }, [socket]);

  useEffect(() => {
    if (socket?.connected) {
      socket.on('newBotMessage', (customerId) => {
        if (selectedCustomer && selectedCustomer.id == customerId) {
          refetch({
            variables: {
              getBotChatFromCustomerIdId: selectedCustomer.id,
            },
          }).then(({ data: { getBotChatFromCustomerID } }: any) => {
            updateChatHistory(getBotChatFromCustomerID)
          });
        }
      });
    }
    return () => {
      if (socket?.connected) {
        socket.off('newBotMessage');
      }
    };
  }, [socket, selectedCustomer]);

  const sendMessage = (message: string, user: any) => {
    if (selectedCustomer) {
      const customerId = selectedCustomer.id;
      const newMessage = message.trim();
      if (newMessage !== '') {
        const newMessageObject: Message = {
          text: newMessage,
          timestamp: (new Date().getTime() / 1000).toString(),
          sender: 'agent',
        };
        setMessages((prevMessages) => ({
          ...prevMessages,
          [customerId]: [...(prevMessages[customerId] || []), newMessageObject],
        }));

        if (socket?.connected) {
          socket.emit('call', 'chatRoomService.privateMessage', {
            data: {
              to: selectedCustomer.id,
              from: user?.id,
              companyId: user?.companyId,
              message: {
                text: newMessage,
              },
            },
          });
        }
      }
    }
  };

  return {
    messages,
    sendMessage,
    selectedCustomer,
    setSelectedCustomer
  };
};
