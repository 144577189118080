import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useStyles } from './style';
import { useDispatch } from 'react-redux';
import { updateSession } from '../../redux/actions/AgentSessionActions';
import { useSocket } from 'context/SocketProvider';
import { useMutation } from '@apollo/client';
import { CREATE_SESSION } from 'adapters/mutations/CreateSession';
import { callStatus } from 'utils/constants';

const ringtone = new Audio("https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3");

function CallAlert() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { socket } = useSocket();

    const [socketEvent, setSocketEvent] = useState<any | null>(null);
    const roomId = localStorage.getItem("chatRoomId");
    const token = localStorage.getItem("jitsi_token");

    const [createSession] = useMutation(CREATE_SESSION, {
        onError: (error) => console.error(error.message),
    });

    useEffect(() => {
        if (socket?.connected) {
            const handleCustomerAssigned = (event: any) => {
                const data = JSON.parse(event);
                localStorage.setItem("chatRoomId", data.chatRoomId.replace("==", "").toLowerCase());
                localStorage.setItem("jitsi_token", data.token);
                setSocketEvent(data);

                createSession({
                    variables: {
                        input: {
                            companyId: data.companyId,
                            customerId: data.customerId,
                            userId: data.agentId,
                            chatRoomId: data.chatRoomId,
                            status: callStatus.MISSED,
                        },
                    },
                });
            };

            socket.on("customer-assigned", handleCustomerAssigned);

            return () => {
                socket.off("customer-assigned", handleCustomerAssigned);
            };
        }
    }, [socket, createSession]);

    useEffect(() => {
        if (socketEvent) {
            ringtone.play();
        } else {
            ringtone.pause();
        }

        return () => {
            ringtone.pause();
        };
    }, [socketEvent]);

    const handleAccept = () => {
        if (roomId && token) {
            if (socketEvent?.callType == 'GSM') {
                window.open(`/appointments/meeting/${roomId}/${token}/${socketEvent?.phone}`, "_blank");
            } else {
                window.open(`/appointments/meeting/${roomId}/${token}`, "_blank");
            }
        } else {
            window.open('/dashboard', "_blank");
        }
        dispatch(updateSession({ agentStatus: 'engaged', isCallOn: true }));
        setSocketEvent(null);
        window.location.reload();
    };

    const handleReject = () => {
        setSocketEvent(null);
        window.location.reload();
    };

    return (
        <Dialog open={Boolean(socketEvent)}>
            <DialogTitle>
                <Typography>Incoming Call</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box component='div' className={classes.contentContainer}>
                    <AccountCircle style={{ fontSize: 100 }} color='disabled' />
                    {socketEvent?.message && <Typography className={classes.message}>{socketEvent?.message}</Typography>}
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-around" }}>
                <Button variant='contained' color='primary' onClick={handleAccept}>
                    Accept
                </Button>
                <Button variant='contained' color='inherit' onClick={handleReject}>
                    Reject
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CallAlert;
