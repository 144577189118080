import clsx from "clsx";
import { useStyles } from "./style";
import FormInput from "components/form/form_input_search/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormButton from "@mui/material/Button";
import { Clear, Search } from "@mui/icons-material";
import {
  Grid,
  Card,
  Stack,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { callStatus } from "utils/constants";

export interface SearchQuery {
  searchText: string;
  status: string;
}

interface IProps {
  className?: string;
  onSearch: (query: SearchQuery) => void;
  maxUsersReached?: boolean;
  showAlert?: () => void;
}

const schema = yup.object().shape({});

const statuses = [
  {
    value: " ",
    label: "All",
  },
  {
    value: callStatus.ANSWERED,
    label: "Answered",
  },
  {
    value: callStatus.MISSED,
    label: "Missed",
  },

];

const Searchbar = ({ className, onSearch, ...rest }: IProps) => {
  const classes = useStyles();

  const defaultValues = {
    status: statuses[0].value,
    searchText: ''
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = ({ status, searchText }: SearchQuery) => {
    onSearch({ status, searchText });
  };

  const onClear = () => {
    reset({ ...getValues(), searchText: '' }, {
      keepDefaultValues: true,
      keepValues: false
    })
    handleSubmit(onSubmit)()
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Card sx={{ p: 2 }}>
          <Grid container item direction="row" alignItems="center" columnSpacing={2} rowSpacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <FormInput
                control={control}
                type="text"
                name="searchText"
                label="Search by name or email"
                error={errors && errors.searchText}
                InputProps={{
                  endAdornment: (getValues().searchText ?
                    <InputAdornment position="end">
                      <IconButton onClick={onClear} size="small">
                        <Clear color="action" />
                      </IconButton>
                    </InputAdornment>
                    : null)
                }}
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <FormInput
                control={control}
                type="text"
                name="status"
                label="Status"
                select="select"
                defaultValue={statuses[0].value}
              >
                {statuses.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormInput>
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<Search />}
                >
                  Search
                </FormButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </form>
    </div>
  );
};

export default Searchbar;

