import { createContext, useState } from 'react';
import { Alert, Color } from '@material-ui/lab';
import { useStyles } from './style';
import { Button, CircularProgress } from '@material-ui/core';
import { GET_PAYMENT_COMPLETION_URL } from 'adapters/queries/GetPaymentCompletionUrl';
import { useLazyQuery } from '@apollo/client';

export const CommonAppBarAlertContext = createContext({
  alert: {
    message: "",
    severity: "success",
    open: false,
    showAction: false
  }, setAlert: (data: {
    message: string,
    severity: Color,
    open: boolean,
    showAction: boolean,
  }) => {
    console.log(data);
  }
});

export default function CommonAppBarAlert(props: any): JSX.Element {
  const classes = useStyles();
  const [alert, setAlert] = useState<{
    message: string,
    severity: Color,
    open: boolean,
    showAction: boolean,
  }>({
    message: '',
    severity: 'info',
    open: false,
    showAction: false
  });

  const handleClose = () => {
    setAlert({ ...alert, open: false })
  }

  const [runQuery, { loading }] = useLazyQuery(GET_PAYMENT_COMPLETION_URL, {
    onCompleted: (data) => {
      if (data?.completePayment?.url) {
        window.location.href = data.completePayment.url
      }
    },
  });


  const onClick = () => {
    runQuery()
  }
  return (
    <div>
      <CommonAppBarAlertContext.Provider value={{ alert, setAlert }}>
        {alert.open && <Alert elevation={0}
          action={alert.showAction ? loading ? <CircularProgress className={classes.buttonProgress} size="2rem" /> : <Button disabled={loading} color="inherit" size="large" variant='outlined' onClick={onClick}>
            {alert.severity == "error" ? "Verify Payment Method" : "Complete Subscription"}
          </Button> : null}
          variant="filled" onClose={handleClose} severity={alert.severity} className={classes.alertStyle}>
          {alert.message}
        </Alert>}
        {props.children}
      </CommonAppBarAlertContext.Provider>
    </div>
  );
}
