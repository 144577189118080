import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
    createStyles({
        contentContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            margin: '25px 25px 10px 25px'
        },
        message: {
            textAlign: 'justify',
            margin: '5px 0px 15px 5px',
            maxWidth: 180
        },

    })
);
