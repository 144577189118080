import { gql } from "@apollo/client";

export const GET_RECORDINGS = gql`
query GetRecordings($chatRoomId: String!) {
  getRecordings(chatRoomId: $chatRoomId) {
    recordings {
      ETag
      Key
      LastModified
      Size
      StorageClass
    }
    urls
  }
}
  `