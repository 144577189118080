import { gql } from "@apollo/client";

export const UPDATE_FAQ_NODE = gql`mutation UpdateFAQNode($input: UpdateFAQNodeInput!) {
    updateFAQNode(input: $input) {
      faqNodeEdge {
        node {
          id
        }
      }
    }
  }`