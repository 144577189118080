import { gql } from "@apollo/client";

export const GET_AVAILABLE_CUSTOMERS = gql`
query GetAvailableCustomers($companyId: String!) {
  getAvailableCustomers(companyId: $companyId) {
    id
    firstName
    lastName
    avatar
    sessionId
    email
    phone
    device
    status
    engagedWith
    isChattingToAgent
    companyId
    botId
    qryHandler
    socketId
    profile
    actor
    wrtc
    msgDirection
    x_socket_token
    privKeySet
    connectedWith {
      id
      firstName
      lastName
      email
      sessionId
      companyId
      avatar
      socketId
      engagedWith
      status
      actor
      wrtc
      x_socket_token
      msgDirection
      # connectedWith
    }
  }
}
`