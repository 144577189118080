import { gql } from "@apollo/client";

export const CREATE_ADD_ON_CART_CHECKOUT_SESSION = gql`
  mutation CreateAddOnCartCheckoutSession(
    $addOnPurchaseHistory: AddOnPurchaseHistoryCartInput!
  ) {
    createAddOnCartCheckoutSession(
      addOnPurchaseHistory: $addOnPurchaseHistory
    ) {
      url
    }
  }
`;
