import { gql } from "@apollo/client";

export const UPDATE_SESSION_END_TIME = gql`
mutation UpdateSessionEndTime($input: UpdateSessionEndTimeInput!) {
  updateSessionEndTime(input: $input) {
    sessionEdge {
      node {
        id
      }
    }
  }
}`