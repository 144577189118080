import { useMemo } from "react";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Page from "../../../../components/page/Page";
import { useQuery } from "@apollo/client";
import PaymentItem from "../../components/list/list_item/List_Item";
import { useStyles } from "./style";
import { PAYMENT_BUSINESS_ADMIN_REFETCH } from "modules/payments/adapters/queries/PaymentBusinessAdminRefetch";
import { LIST_ADD_ON_PAYMENT_HISTORY } from "modules/payments/adapters/queries/ListAddOnPaymentHistory";
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Grid,
  TablePagination,
} from "@mui/material";
import PaymentToolbar from "../../components/list/toolbar/Toolbar";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const PaymentList = (): JSX.Element => {
  const classes = useStyles();
  const { data: paymentsData } = useQuery(PAYMENT_BUSINESS_ADMIN_REFETCH);
  const { data: addOnPurchaseData } = useQuery(LIST_ADD_ON_PAYMENT_HISTORY);

  useEffect(() => {
    document.title = "Payments";
  }, []);

  const payments = useMemo(
    () =>
      paymentsData?.payments?.edges
        ?.map((edge: any) => ({
          id: edge.node.id,
          createdAt: edge.node.createdAt,
          amount: edge.node.amount,
          status: edge.node.status,
          planName: edge.node.pgwSubscriptionPlanId,
        }))
        .filter(isNotNull) || [],
    [paymentsData]
  );

  const addOnPurchases = useMemo(
    () =>
      addOnPurchaseData?.listAddOnPurchaseHistory
        ?.map((item: any) => ({
          ...item,
          amount: item.totalAmount.toString(),
          status: item.purchaseStatus,
          planId: item.addOnId,
          planName: item.addOnName,
        }))
        .filter(isNotNull) || [],
    [addOnPurchaseData]
  );

  const combinedList = useMemo(() => {
    const combined = [...payments, ...addOnPurchases];
    return combined.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [payments, addOnPurchases]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Page title="Payments">
      <Container maxWidth={false}>
        <PaymentToolbar />

        <Card sx={{ mt: 3 }}>
          <Table>
            <Hidden smDown>
              <TableHead>
                <TableRow>
                  <TableCell>Plan</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>

            <TableBody>
              {combinedList.length ? (
                combinedList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <PaymentItem payment={item} key={item.id || index} />
                  ))
              ) : (
                <Grid
                  className={classes.gridEmptyRecords}
                  container
                  direction="column"
                  item
                  xs={12}
                >
                  No payments found
                </Grid>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={combinedList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default PaymentList;
