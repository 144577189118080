import { ModuleRouteConfig } from "common/types/RouteTypes";
import BotSessionsList from "../bot_sessions/pages/BotSessionsListPage";


export const BotSessionRoutes: ModuleRouteConfig[] = [
 
    {
        permissionId: "view_bot_sessions",
        path: "",
        element: <BotSessionsList />,
    }
]
