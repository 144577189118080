import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/login/Login";
import Logout from "../pages/logout/Logout";
import SignUp from "pages/signup/SignUp";
import LinkMessage from "components/link_message/LinkMessage";
import AccountActivation from "pages/signup/AccountActivation";
import PaymentMethodSuccess from "modules/subscriptions/components/payment_method/PaymentMethodSuccess";
import PaymentMethodCancel from "modules/subscriptions/components/payment_method/PaymentMethodCancel";
import ForgotPassword from "pages/forgot_password/ForgotPassword";
import NewPassword from "pages/forgot_password/NewPassword";
import PasswordChange from "pages/signup/PasswordChangeNew";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import AgentAppointmentConference from "components/conference/appointment/AngentConference";
import CustomerAppointmentConference from "components/conference/appointment/CustomerConference";

export default function PublicRoutes(): JSX.Element {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/signup/:plan" element={<SignUp />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/success" element={<PaymentMethodSuccess />} />
      <Route path="/cancel" element={<PaymentMethodCancel />} />
      <Route path="/verifySignup/:token" element={<AccountActivation />} />
      <Route path="/passwordChange/:token" element={<PasswordChange />} />
      <Route path="/user/verify/:token" element={<NewPassword />} />
      <Route path="/ForcePassword" element={<NewPassword />} />
      <Route path="/account/verified" element={<LinkMessage />} />
      <Route path="/appointments/meeting/:appId/:appointmentId/:token/:phoneNumber" element={<AgentAppointmentConference />} />
      <Route path="/appointments/meet/:appId/:appointmentId/:token" element={<CustomerAppointmentConference />} />
      <Route path="/callbacks/meeting/:appId/:callbackId/:token/:phoneNumber" element={<AgentAppointmentConference />} />

      {/* Fallback Route */}
      {!isAuthenticated && (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
}
