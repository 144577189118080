import { gql } from "@apollo/client";

export const TICKET_ADMIN_REFETCH = gql`
  query ListTickets (
    $count: Int 
    $reversecount: Int
    $startcursor: String
    $endcursor: String
    $search: String
  ){
  listTickets (
      first: $count
      last: $reversecount
      after: $endcursor
      before: $startcursor
      search: $search
    ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        assigneeId
        customerId
        companyId
        subject
        code
        company
        description
        location
        customer {
          firstName
          lastName
          email
        }
        priority
        createdAt
        attachments
        screenshot
      }
    }
    totalCount
  }
}
`;
