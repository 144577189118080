import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useStyles } from "./style";
import { ExpandMoreOutlined, Upload, Description } from "@mui/icons-material";
import { useEffect, useState } from "react";
import FileDialog from "components/file_upload";
import { useMutation, useQuery } from "@apollo/client";
import { GET_BOT_FRAMEWORK } from "modules/bot_profiles/adapters/queries/GetBotFramework";
import { RootState } from "redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { UPDATE_KNOWLEDGE_BASE } from "modules/bot_profiles/adapters/mutations/UpdateBotFrameworkKnowledgeBase";

export interface Chip {
  botProfileId: string;
  companyId: string;
  id: string;
  questions: string;
  response: string;
  status: "active" | "inactive";
  title: string;
}

interface KnowledgeBaseProps {
  companyId: string;
  botProfileId: string;
  frmeworkId?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const KnowledgeBase = (props: KnowledgeBaseProps) => {
  const [loading, setLoading] = useState(true);
  const [showDialog, setshowDialog] = useState(false);
  const [value, setValue] = useState(0);
  const [fileDetails, setFileDetails] = useState({ name: "", updatedAt: "" });
  const { user } = useSelector((state: RootState) => state.auth);
  const companyName = user?.company?.name;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    data: botFramework,
    error: errorBotFramework,
    loading: loadingBotFramework,
  } = useQuery(GET_BOT_FRAMEWORK, {
    variables: {
      botFrameworkByBotProfileIdId: props.botProfileId,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (botFramework?.botFrameworkByBotProfileId?.fileMetadata) {
      try {
        const metadata = JSON.parse(
          botFramework?.botFrameworkByBotProfileId?.fileMetadata
        );
        setFileDetails({
          name: metadata?.file_name,
          updatedAt: new Date(
            botFramework?.botFrameworkByBotProfileId?.fileUploadDate
          ).toLocaleString(),
        });
      } catch (error) {
        setFileDetails({ name: "", updatedAt: "" });
      }
    }
  }, [botFramework]);

  useEffect(() => {
    setLoading(false);
    console.log(props);
    console.log(errorBotFramework);
  }, []);

  const [
    updateKnowledgeBase,
    { loading: updatingKnowledgeBase, error: createFAQError },
  ] = useMutation(UPDATE_KNOWLEDGE_BASE, {
    onCompleted: (res) => {
      console.log(res);
    },
    onError: () => {
      console.log(createFAQError);
    },
  });

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Typography variant="h6">Knowledge Base (Files)</Typography>
      </AccordionSummary>
      {(loading || updatingKnowledgeBase || loadingBotFramework) && (
        <LinearProgress />
      )}
      <AccordionDetails>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Files" {...a11yProps(0)} />
            <Tab label="Links" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className={classes.listContainer}>
            <Stack
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-around"}
              padding={1}
              className={classes.emptyListContainer}
            >
              {fileDetails.name ? (
                <Stack
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Description
                    color="primary"
                    fontSize="large"
                    style={{ marginTop: "30px", marginBottom: "10px" }}
                  />
                  <Typography style={{}} variant="body1">
                    {fileDetails.name}
                  </Typography>
                  <Typography style={{ marginBottom: "10px" }} variant="body1">
                    ({fileDetails.updatedAt})
                  </Typography>
                </Stack>
              ) : (
                <Typography style={{ marginBottom: "10px" }} variant="body1">
                  No File uploaded
                </Typography>
              )}
              <Button
                variant="contained"
                startIcon={<Upload />}
                onClick={() => {
                  setshowDialog(true);
                }}
              >
                Upload
              </Button>
            </Stack>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className={classes.listContainer}>
            <Box className={classes.emptyListContainer}>Comming Soon...</Box>
          </Box>
        </CustomTabPanel>
      </AccordionDetails>
      <FileDialog
        open={showDialog}
        onClose={() => {
          setshowDialog(false);
        }}
        onFilesAdded={(files) => {
          setshowDialog(false);
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);
          const url = `${process.env.REACT_APP_LLM_SERVER_URL}/upload/file?company_id=${props.companyId}&company_name=${companyName}&bot_profile_id=${props.botProfileId}`;
          console.log("url--", url);

          fetch(url, {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              console.log(response);
              if (!response.ok) {
                setLoading(false);
                throw new Error("Network response was not ok");
              }
              return response.json(); // You can parse the response JSON if needed
            })
            .then((data) => {
              // Handle the response data
              console.log(data);
              updateKnowledgeBase({
                variables: {
                  input: {
                    fileMetadata: JSON.stringify(data),
                    companyId: props.companyId,
                    botProfileId: props.botProfileId,
                  },
                },
              });
              // console.log(updateKnowledgeBase);

              setLoading(false);
            })
            .catch((error) => {
              // Handle errors
              console.log("error");
              console.error(error);
              setLoading(false);
            });
        }}
      />
    </Accordion>
  );
};

export default KnowledgeBase;
