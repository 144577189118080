import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up("md")]: {
                width: "60%"
            }
        },

        feedback: {
            marginTop: theme.spacing(2),
            textTransform: "capitalize"
        },
        title: {
            fontSize: 14
        },
        error: {
            textTransform: "capitalize"
        },
        button: {
            color: "white"
        },
        buttonWrapper: {
            position: "relative"
        },
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -9,
            marginLeft: -12
        },
        option: {
            fontSize: 15,
            "& > span": {
                marginRight: 10,
                fontSize: 18
            }
        },
        chakBoxGroup: {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            borderRadius: 5
        },
        chakBoxGroupEmpty: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderRadius: 5
        },
        avatarSize: {
            width: theme.spacing(12),
            height: theme.spacing(12),
            overflow: 'hidden', // Ensure that the image does not overflow the avatar container
            position: 'relative',
            bottom: 0, left: "50%", transform: "translateX(-50%)", // Set position to relative to allow absolute positioning of the image
            '& > img': {
              width: '100%', // Ensure that the image fills the avatar container
              height: '100%', // Ensure that the image fills the avatar container
              objectFit: 'contain', // Preserve aspect ratio and fill the container
              position: 'absolute', // Position the image absolutely within the avatar container
              top: 0, // Align the image to the top
              left: 0, // Align the image to the left
            },
          },
          cameraIcon: {
            color: theme.palette.text.primary, // Set the color of the camera icon
            fontSize: 40, // Set the font size of the camera icon
            cursor: 'pointer',
            bottom: 0, left: "50%", transform: "translateX(-50%)", // Change cursor to pointer on hover
            transition: 'color 0.3s',
             // Add transition effect for color change
            '&:hover': {
              color: theme.palette.secondary.main, // Change color on hover
            },
          },
          uploadButton: {
           
            color: theme.palette.text.primary, // Set the color of the camera icon
            fontSize: 40, // Set the font size of the camera icon
            cursor: 'pointer',
            bottom: 0, left: "50%", transform: "translateX(-50%)", // Change cursor to pointer on hover
            transition: 'color 0.3s',
             // Add transition effect for color change
            '&:hover': {
              color: theme.palette.secondary.main, // Change color on hover
            },
          },
          
          imagePreviewContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          },
          imagePreview: {
            maxWidth: "100%",
            maxHeight: "100%",
          },
          previewImage: {
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 'auto',
          },
          closePreviewButton: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: theme.palette.common.white,
          },
          snackbar: {
            position: 'fixed',
            right: '50%',
            transform: 'translateX(110%)',
            bottom: theme.spacing(10), // Adjust as needed
            zIndex: theme.zIndex.snackbar,
          },
          botDetailsCard: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            padding: 40,
          },
        chakBoxGroupTitle: {
            fontWeight: 600,
            marginLeft: 15,
            marginTop: 15,
            marginBottom: 10
        },
        chip: {
            margin: 5,
            backgroundColor: 'red'
        },
    })
);
