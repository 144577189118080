import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alertStyle: {
            // margin: theme.spacing(0, 3, 3, 3),
            margin: theme.spacing(-3, 0, 3, 0),
            flex: 1,
            borderRadius: 0
        },
        buttonProgress: {
            marginLeft: 30,
            marginRight: 30,
            margin: 5
        },
    })
);
