import { LIST_SUBSCRIPTIONS } from "superadmin/modules/subscriptions/adapters/queries/ListSubscriptionPlans";
import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import SubscriptionsListItem from "superadmin/modules/subscriptions/components/list/SubscriptionsListItem";
import DataPage from "common/components/datapage/DataPage";
import CommonToolbar from "common/components/toolbar/Toolbar";
import CommonSearchbar from "common/components/searchbar/Searchbar";

const SubscriptionsPage = () => {
  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
    setSort,
  } = usePaginationQuery(LIST_SUBSCRIPTIONS, {}, "listSubscriptions");

  const columns = [
    { key: "name", label: "Name" },
    { key: "agents", label: "Agents" },
    { key: "botProfiles", label: "Bot Profiles" },
    { key: "minutes", label: "Minutes" },
    { key: "trialDays", label: "Trial Days" },
    { key: "price", label: "Price" },
    { key: "isPublic", label: "Is Public" },
  ];

  const subscriptions = data?.listSubscriptions || {};
  const totalCount = subscriptions.totalCount || 0;
  const list =
    subscriptions.edges
      ?.map((edge: any) => edge.node)
      .filter((node: any) => node != null) || [];

  return (
    <DataPage
      title="Subscriptions"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      setSort={setSort}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      renderItem={(node: any) => (
        <SubscriptionsListItem key={node.id} subscription={node} />
      )}
      ToolbarComponent={
        <CommonToolbar title="Subscriptions" addButtonLink="add" />
      }
      SearchbarComponent={
        <CommonSearchbar
          defaultValues={{ search: "" }}
          fields={[
            {
              name: "search",
              label: "Search by name or email",
              type: "text",
            },
          ]}
          onSearch={setSearchQuery}
        />
      }
      paginationProps={{ rowsPerPageOptions: [10, 25, 50] }} // Example customization
    />
  );
};

export default SubscriptionsPage;
