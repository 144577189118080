import { Theme, makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        importButton: {
            marginRight: theme.spacing(1)
        },
        exportButton: {
            marginRight: theme.spacing(1)
        },
        filterBox: {
            minHeight: "48px",
            backgroundColor: theme.palette.background.paper,
            border: "1px solid rgb(224, 224, 224)",
            borderRadius: "4px"
        },
        cardHeader: {
            padding: theme.spacing(0.7, 2)
        },
        cardTitle: {
            fontSize: "1.5rem"
        },
        cardSubheader: {
            fontSize: "0.875rem"
        }
    })
);
