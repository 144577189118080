import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION = gql `
query RetrieveSubscription($input: RetrieveSubscriptionInput!) {
  retrieveSubscription(input: $input) {
    id
    createdAt
    updatedAt
    isDeleted
    priceId
    name
    agents
    botProfiles
    minutes
    trialDays
    price
    isPublic
    isDefault
  }
}
`