import * as yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import FormInput from "../../components/form/form_input/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { logout } from "../../redux/actions/AuthActions";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import Page from "components/page/Page";
import { VERIFY_TOKEN } from "adapters/queries/VerifyToken";
import { RESET_PASSWORD_ADMIN_USER } from "adapters/mutations/ResetPasswordAdminUser";
import { useNavigate, useParams } from "react-router-dom";


export default function NewPassword() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [userId, setUserId] = useState("")
  const { setSnack } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [mutate] = useLazyQuery(VERIFY_TOKEN, {
    onCompleted: ({ verifyToken }) => {
      setIsLoading(false)
      if (verifyToken.error == '') {
        setUserId(verifyToken?.user?.id)
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
      // navigate("/account/verified");
    },
    onError: (error) => {
      console.log(error);
      setIsSuccess(false)
      setIsLoading(false);
      setSnack({ message: "Activation failed", severity: 'error', open: true });
    },
  });

  const [reset_password, { error }] = useMutation(RESET_PASSWORD_ADMIN_USER, {
    onCompleted: () => {
      setSnack({ message: "Password changed successfully", severity: 'success', open: true });
      dispatch(logout())
      navigate("/login")
    },
    onError: () => {
      console.log(error);
      setSnack({ message: "Failed to change the password", severity: 'error', open: true });
    },
  });

  const { token } = useParams();
  useEffect(() => {
    mutate({
      variables: {
        token: token,
      },
    });
  }, []);

  const formSchema = yup.object().shape({
    newPassword: yup.string()
      .required("Password is required")
      .min(8, "Password length should be at least 8 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
        "Must Contain character and numbers"
      ),
    confirmPassword: yup.string()
      .required("Confirm Password is required")
      .min(8, "Password length should be at least 8 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([yup.ref("newPassword")], "Passwords do not match")
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });


  const onSubmit = ({ newPassword, confirmPassword }: any) => {
    reset_password({
      variables: {
        input: {
          id: userId,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
      },
    });
  };

  if (isLoading) {
    return (
      <Page title="Reset Password">
        <Container>
          <Grid container className={classes.container}>
            <Typography>Loading...</Typography>
          </Grid>
        </Container>
      </Page>)
  }

  if (isSuccess) {
    return (
      <Page title="Reset Password">
        <Container>
          <Grid container className={classes.container}>
            <Grid item lg={6} sm={12} xs={12}>
              <Typography
                component="h1"
                variant="h5"
                className={classes.signIn}
                color="primary"
              >
                Set Password
              </Typography>
              <br />
              <br />
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  control={control}
                  type="password"
                  name="newPassword"
                  label="New Password"
                  fullWidth
                  error={errors && errors.newPassword}
                />
                <br />
                <br />
                <FormInput
                  control={control}
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  error={errors && errors.confirmPassword}
                />
                <br />
                <br />
                <div className={classes.btnWrapper}>
                  <Button
                    type="submit"
                    name="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }

  return (
    <Page title="Reset Password">
      <Container>
        <Grid container className={classes.container}>
          <Typography>Request failed try again after some time.</Typography>
        </Grid>
      </Container>
    </Page>)
}
