import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Addon {
  id: string;
  name: string;
  description: string;
  allowanceType: string;
  allowanceUnits: string;
  price: number;
  active: boolean;
}

interface AddonItemProps {
  addon: Addon;
}

const AddonItem: React.FC<AddonItemProps> = ({ addon }) => {
  const { id, name, description, allowanceType, allowanceUnits, price, active } = addon;
  const navigate = useNavigate();
  return (
    <TableRow style={{ cursor: 'pointer' }} hover key={id} onClick={() => navigate(`/admin/add-ons/edit/${id}`)}>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{allowanceType}</TableCell>
      <TableCell>{allowanceUnits}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{active ? "Active" : "Inactive"}</TableCell>
    </TableRow>
  );
};

export default AddonItem;


