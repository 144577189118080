import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    rootButton: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "100%",
    },
    chip: {
      margin: theme.spacing(1),
    },
    gridHeading: {
      color: "#263238",
      fontWeight: 500,
      borderBottom: "1px solid rgb(224, 224, 224)",
    },
    gridList: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid rgb(224, 224, 224)",
      borderRadius: "4px",
    },
    gridEmptyRecords: {
      padding: theme.spacing(4),
      textAlign: "center",
      color: "#263238",
    },
    card: {
      // height: "350px", // Adjust the height as needed
      // maxWidth: "300px", // Adjust the width as needed
      margin: 10,
    },
    scrollableContent: {
      maxHeight: "150px", // Adjust the max-height as needed
      overflowY: "auto",
    },
    modal: {
      width: "50%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      boxShadow: "24",
      p: 4,
    },
    imageContainer: {},
    detailRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 8,
      padding: "0 16px",
    },
    detailLabel: {
      fontWeight: "bold",
    },
    icon: {
      marginInline: 5,
    },
    accordianContainer: {
      flex: 1,
      overflowY: "scroll",
    },
    submitButtonContainer: {
      flexDirection: "row",
      padding: theme.spacing(3),
    },
  })
);

export default useStyles;
