import { gql } from "@apollo/client";

export const VIEW_BUSINESS_ADMIN_COMPANY_DATA = gql`
  fragment ViewAccountCompany_data on Company {
    id
    status
    pgwSubscriptionPlanId
    pgwSubscriptionId
    maxAllowedAgents
    maxAllowedMinutes
    maxAllowedProfiles
    consumedActiveAgents
    consumedProfiles
    consumedMinutes
    nextBillAmount
    subscriptionStatus
    nextBillAt
    nextBillAmount
    pgwSubscriptionPlanId
    plan {
      id
      name
    }
  }
`;
