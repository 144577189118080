import { gql } from "@apollo/client";

export const EDIT_BUSINESS_ADMIN_COMPANY = gql`
    mutation EditAccountCompanyMutation($input: UpdateCompanyInput!) {
        updateCompany(input: $input) {
            companyEdge {
                node {
                    id
                    name
                    subdomain
                }
            }
        }
    }
`;
