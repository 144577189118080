import React, { useState } from 'react';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import useStyles from './style';
import SendIcon from '@mui/icons-material/Send';

interface MessageItemProps {
    handleSendMessage: (message: string) => void
}

const InputBox: React.FC<MessageItemProps> = ({ handleSendMessage }) => {
    const classes = useStyles({});
    const [message, setMessage] = useState<string>('');

    return (
        <Box className={classes.inputBox}>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleSendMessage(message);
                        setMessage('');
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => {
                                handleSendMessage(message)
                                setMessage('');
                            }} edge="end">
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default InputBox;
