import React from "react";
import { TableCell, TableRow, Avatar } from "@mui/material";

interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface CustomerItemProps {
  customer: Customer;
  onClick: () => void;
}

const CustomerItem: React.FC<CustomerItemProps> = ({ customer, onClick }) => {
  const { firstName, lastName, email, id } = customer;

  const getInitials = (firstName: string, lastName: string, id: string) => {
    return firstName ? `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase() : id.substring(0.1).toUpperCase();
  };

  return (
    <TableRow style={{ cursor: 'pointer', height: '60px' }} hover onClick={onClick}>
      <TableCell style={{ display: 'flex', alignItems: 'center', fontSize: '14px', padding: '8px 16px' }}>
        <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }} style={{ marginRight: '16px' }}>
          {getInitials(firstName, lastName, id)}
        </Avatar>
        <div>
          {firstName ? `${firstName || ""} ${lastName || ""}` : id}
          <br />
          {email}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CustomerItem;
