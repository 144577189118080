import { Theme } from "@material-ui/core";

export const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    error: {
        backgroundColor: "#ffebee",
        color: theme.palette.error.dark
    },
    success: {
        backgroundColor: "#e8f5e9",
        color: theme.palette.success.dark
    }
});
