import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';
import { useStyles } from './style';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_NOTE } from 'modules/callbacks/adapters/queries/GetNote';
import { CREATE_NOTE } from 'modules/callbacks/adapters/mutations/CreateNode';
import { UPDATE_NOTE } from 'modules/callbacks/adapters/mutations/UpdateNote';
import { CALLBACK_BUSINESS_ADMIN_REFETCH } from 'modules/callbacks/adapters/queries/CallbackstBusinessAdminRefetch';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
export interface Node {
    title: string;
    response: string;
    questions: Array<string>;
    status: boolean,
    mainmenu: boolean
}

export interface EditNode extends Partial<Node> {
    id: string;
}

interface NoteDialogProps {
    open: boolean;
    onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
    callbackId: string;
    node: any;
}




const NoteDialog = (props: NoteDialogProps) => {
    const { open, onClose } = props
    const classes = useStyles()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)

    const [getNote, { data }] = useLazyQuery(GET_NOTE, {
        variables: {
            getNotebyCallIdId: props.callbackId,
        },
    });

    const saveNote = () => {
        var content = editorState.getCurrentContent();
        var raw = convertToRaw(content);
        if (editMode) {
            updateNote({
                variables: {
                    "input": {
                        "id": data?.getNotebyCallID?.id,
                        "callId": props.callbackId,
                        "note": JSON.stringify(raw),
                    }
                }
            })
        } else {
            addNote({
                variables: {
                    "input": {
                        "callId": props.callbackId,
                        "note": JSON.stringify(raw),
                        customerId: props?.node?.customer?.id
                    }
                }
            })
        }
    }

    const [addNote, { loading: createNoteLoading, error: createNoteError }] = useMutation(CREATE_NOTE, {
        onCompleted: (res) => {
            setLoading(false)
            console.log(res);
        },
        onError: () => {
            console.log(createNoteError);
        },
        refetchQueries: [
            { query: CALLBACK_BUSINESS_ADMIN_REFETCH, variables: { customerId: props?.node?.customer?.id } },
            { query: GET_NOTE, variables: {getNotebyCallIdId: props?.callbackId} }
          ],
    });

    const [updateNote, { loading: updateNoteLoading, error: updateNoteError }] = useMutation(UPDATE_NOTE, {
        onCompleted: (res) => {
            setLoading(false)
            console.log(res);
        },
        onError: () => {
            console.log(updateNoteError);
        },
        refetchQueries: [
            { query: CALLBACK_BUSINESS_ADMIN_REFETCH, variables: { customerId: props?.node?.customer?.id } },
            { query: GET_NOTE, variables: {getNotebyCallIdId: props?.callbackId} }
          ],
    });

    useEffect(() => {
        if (open) {
            getNote({
                variables: {
                    getNotebyCallIdId: props.callbackId,
                }
            })
        }
    }, [open])


    useEffect(() => {
        if (data) {
            setEditMode(true)
            console.log(data);
            try {
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(data.getNotebyCallID.note))))
            } catch (error) {
                setEditorState(EditorState.createEmpty())
            }
        } else {
            setEditMode(false)
            setEditorState(EditorState.createEmpty())
        }
    }, [data])

    return (

        <BootstrapDialog
            maxWidth='md'
            fullWidth
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            scroll='paper'
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                Callback Note
            </BootstrapDialogTitle>

            <DialogContent dividers>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName={`demo-editor ${classes.editorArea}`}
                    onEditorStateChange={setEditorState}
                />
            </DialogContent>
            {(loading || createNoteLoading || updateNoteLoading) && <LinearProgress />}
            <DialogActions>
                <Button autoFocus onClick={saveNote} disabled={loading}>
                    Save changes
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

export default NoteDialog