import { gql } from "@apollo/client";

export const GET_TICKET_BY_ID = gql`
query RetrieveTicket($ticket: TicketRetrieveInput!) {
  retrieveTicket(ticket: $ticket) {
    id
    subject
    description
    createdAt
    attachments
    screenshot
    customer {
      firstName
      lastName
      email
    }
    assignee {
      firstName
      lastName
    }
    reporter {
      firstName
      lastName
    }
    manager {
      firstName
      lastName
    }
    location
    code
    company
    reason
    priority
    reporterId
    assigneeId
    managerId
    companyId
    ticketStatus
    ticketPhase
    category
    subCategory
    resolutionSummary
    resolutionDate
    notesComments
    customerFeedback
    escalationStatus
  }
}`