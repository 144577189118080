import { gql } from "@apollo/client";
import { EDIT_BUSINESS_ADMIN_CALL_FOR_HELP_DATA } from "adapters/fragments/EditBusinessAdminCallForHelpData";

export const CALL_FOR_HELP_BUSINESS_ADMIN_EDIT = gql`
    ${EDIT_BUSINESS_ADMIN_CALL_FOR_HELP_DATA}
    query BotProfileAccountEditQuery($id: String!) {
        botprofile(id: $id) {
            ...EditAccountBotProfile
            id
        }
    }
`;
