import { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Hidden, LinearProgress, Grid
} from '@mui/material';
import SortButtons from './renderSortButtons';

const GenericTable = ({ columns, data, totalCount, loading, renderItem, setSort }: any) => {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  const handleSort = (key: string, direction: 'ASC' | 'DESC') => {
    // Update the sort config state based on the selected direction
    setSortConfig({ key, direction });

    // Call setSort to send the updated sortBy and sortOrder to the query
    setSort({ sortBy: key, sortOrder: direction });
  };

  useEffect(() => {
    if (sortConfig.key) {
      console.log('SortConfig changed:', sortConfig);
    }
  }, [sortConfig]);

  return (
    <Table>
      <Hidden smDown>
        <TableHead>
          <TableRow>
            {columns.map((col: any) => (
              <TableCell key={col.key}>
                <SortButtons col={col} handleSort={handleSort} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Hidden>
      <TableBody>
        {totalCount ? (
          data.map(renderItem)
        ) : (
          <Grid container justifyContent="center">
            <Grid item>No data found</Grid>
          </Grid>
        )}
      </TableBody>
      {loading && <LinearProgress />}
    </Table>
  );
};

export default GenericTable;
