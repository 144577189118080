/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Page from "../../../../components/page/Page";
import { useQuery } from "@apollo/client";
import { useStyles } from "./style";
import { Box } from "@mui/material";
import CallForHelpToolbar from "../../components/list/toolbar/Toolbar";
import CallForHelpItem from "../../components/list/list_item/List_Item";
import Chip from "@mui/material/Chip";
import { CALL_FOR_HELP_REFETCH } from "modules/bot_profiles/adapters/queries/CallForHelpRefetch";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const BotProfilePage = (): JSX.Element => {
  const classes = useStyles();
  const { data, error, fetchMore, loading } = useQuery(CALL_FOR_HELP_REFETCH);
  console.log("Data", data, error);

  useEffect(() => {
    document.title = "Bot Profiles";
    fetchMore({});
  }, []);

  const { botprofiles } = data || {};

  const botprofile = botprofiles;

  const list = useMemo(() => {
    return botprofile && botprofile.edges
      ? botprofile.edges
          .filter(isNotNull)
          .map((edge: any) => edge.node)
          .filter(isNotNull)
      : [];
  }, [botprofile]);

  const LoadMore = () => (
    <div className={classes.rootButton}>
      {botprofiles?.pageInfo?.hasNextPage && (
        <Chip
          onClick={() => {
            fetchMore({
              variables: {
                cursor: botprofiles?.pageInfo?.endCursor,
              },
            });
          }}
          label="Load More"
          className={classes.chip}
          variant="outlined"
        />
      )}
    </div>
  );

  return (
    <Page className={classes.root} title="BotProfiles">
      <CallForHelpToolbar />
      {loading ? null : (
        <Container className={classes.gridList} maxWidth={false}>
          <Box mt={3}>
            <Grid container spacing={3}>
              {list?.length ? (
                list?.map((node: any) => {
                  if (node == null) {
                    return null;
                  }
                  return (
                    <Grid item key={node.id} lg={4} md={6} xs={12}>
                      <CallForHelpItem
                        className={classes.botPrfoileCard}
                        botProfile={node}
                        key={node.id}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Grid className={classes.gridEmptyRecords} item xs={12}>
                  No Bot Profiles found
                </Grid>
              )}
            </Grid>
          </Box>
          <LoadMore />
        </Container>
      )}
    </Page>
  );
};

export default BotProfilePage;
