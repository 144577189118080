import React, { useContext, useEffect } from "react";
import {
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Grid,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material"; // Updated import to MUI
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, FieldError } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./style";
import Page from "components/page/Page";
import FormInput from "components/form/form_input/FormInput";
import FormFeedback from "components/form_feedback/FormFeedback";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { ADD_SUBSCRIPTION } from "superadmin/modules/subscriptions/adapters/mutations/AddSubscription";

interface SubscriptionFormData {
  name: string;
  agents: number;
  botProfiles: number;
  minutes: number;
  trialDays: number;
  price: number;
  isPublic: boolean;
}

// Validation schema for Subscription
const schema = yup.object({
  name: yup.string().required("Name is required"),
  agents: yup
    .number()
    .required("Number of agents is required")
    .min(1, "Must have at least 1 agent"),
  botProfiles: yup
    .number()
    .required("Number of bot profiles is required")
    .min(0, "Must be 0 or more"),
  minutes: yup
    .number()
    .required("Number of minutes is required")
    .min(0, "Must be 0 or more"),
  trialDays: yup
    .number()
    .required("Number of trial days is required")
    .min(0, "Must be 0 or more"),
  price: yup.number().required("Price is required"),
  isPublic: yup.boolean().required(),
});

const AddSubscription: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SubscriptionFormData>({
    resolver: yupResolver(schema),
  });

  const [addSubscription, { loading }] = useMutation(ADD_SUBSCRIPTION, {
    onCompleted: () => {
      setSnack({
        message: "Subscription added successfully",
        severity: "success",
        open: true,
      });
      navigate("/admin/subscriptions");
    },
    onError: (err) => {
      console.error("Error creating subscription:", err);
      setSnack({
        message: "Create subscription failed",
        severity: "error",
        open: true,
      });
    },
  });

  useEffect(() => {
    document.title = "Add Subscription";
  }, []);

  const onSubmit = (formData: SubscriptionFormData) => {
    addSubscription({
      variables: {
        input: formData,
      },
    });
  };

  return (
    <Page className={classes.root} title="Add Subscription">
      <Container maxWidth="sm">
        <Card>
          <CardHeader title="Create New Plan" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormInput
                    control={control}
                    name="name"
                    label="Name*"
                    error={errors.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    control={control}
                    name="agents"
                    label="Number of Agents*"
                    type="number"
                    error={errors.agents}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    control={control}
                    name="botProfiles"
                    label="Bot Profiles*"
                    type="number"
                    error={errors.botProfiles}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    control={control}
                    name="minutes"
                    label="Minutes*"
                    type="number"
                    error={errors.minutes}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    control={control}
                    name="trialDays"
                    label="Trial Days*"
                    type="number"
                    error={errors.trialDays}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    control={control}
                    name="price"
                    label="Price*"
                    type="number"
                    error={errors.price}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="isPublic"
                        render={({ field }) => (
                          <Switch {...field} checked={field.value} />
                        )}
                      />
                    }
                    label="Public Subscription"
                  />
                </Grid>
              </Grid>
              {Object.values(errors).map((error, index) => (
                <FormFeedback key={index} className={classes.feedback} error>
                  {(error as FieldError)?.message}
                </FormFeedback>
              ))}
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Save Subscription"
                  )}
                </Button>
              </div>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

export default AddSubscription;
