import CommonSnackBar from "components/common_snackbar/CommonSnackBar";
import PublicRoutes from "./PublicRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";


export default function AppRoutes(): JSX.Element {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <Router>
      <CommonSnackBar>
          {/* <OldPrivateRoutes  /> */}
          { isAuthenticated && <PrivateRoutes /> }
          <PublicRoutes />
      </CommonSnackBar>
    </Router>
  );
}
