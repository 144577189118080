import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      [theme.breakpoints.up('md')]: {
        // width: "60%"
      },
    },
    form: {},
    card: {
      padding: theme.spacing(3),
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      // boxShadow: 24,
      p: 4,
    },
    cardHeader: {
      // padding: theme.spacing(0.7, 2),
    },
    cardTitle: {
      fontSize: '1.25rem',
    },
    cardSubheader: {
      fontSize: '0.875rem',
    },
    feedback: {
      marginTop: theme.spacing(2),
      textTransform: 'capitalize',
    },
    title: {
      fontSize: 14,
    },
    error: {
      textTransform: 'capitalize',
    },
    button: {
      color: 'white',
    },
    buttonWrapper: {
      position: 'relative',
      // marginTop: theme.spacing(2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -9,
      marginLeft: -12,
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    avatarSize: {
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    userDetailsCard: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      padding: 40,
    }
  })
);