import clsx from "clsx";
import { useStyles } from "./style";
import FormInput from "components/form/form_input_search/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormButton from "@mui/material/Button";
import { Clear, Search } from "@mui/icons-material";
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { LIST_ROLES } from "adapters/queries/ListRoles";

export interface SearchQuery {
  search: string;
  roleId: string;
}

interface IProps {
  className?: string;
  onSearch: (query: SearchQuery) => void;
  maxUsersReached?: boolean;
  showAlert?: () => void;
}

const schema = yup.object().shape({});

const Searchbar = ({ className, onSearch, ...rest }: IProps) => {
  const classes = useStyles();
  const { data: roles } = useQuery(LIST_ROLES);

  const defaultValues = {
    roleId: "",
    search: "",
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = ({ roleId, search }: SearchQuery) => {
    onSearch({ roleId, search });
  };

  const onClear = () => {
    reset(
      { ...getValues(), search: "" },
      {
        keepDefaultValues: true,
        keepValues: false,
      }
    );
    handleSubmit(onSubmit)();
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Card sx={{ p: 2 }}>
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            columnSpacing={2}
            rowSpacing={2}
          >
            <Grid item md={6} sm={6} xs={12}>
              <FormInput
                control={control}
                type="text"
                name="search"
                label="Search by [dd/mm/yyyy,jhon,owner,pending] "
                error={errors && errors.search}
                InputProps={{
                  endAdornment: getValues().search ? (
                    <InputAdornment position="end">
                      <IconButton onClick={onClear} size="small">
                        <Clear color="action" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <FormInput
                control={control}
                type="text"
                name="roleId"
                label="Role"
                select="select"
              >
                <MenuItem>All</MenuItem>
                {roles?.listRoles.map((role: any) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </FormInput>
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<Search />}
                >
                  Search
                </FormButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </form>
    </div>
  );
};

export default Searchbar;
