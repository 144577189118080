import { gql } from "@apollo/client";
export const MODAL_ACCEPT_BUSINESS_ADMIN_CALLBACKS = gql`
    mutation ModalAcceptAccountCallbackMutation(
        $input: AcceptCallbackInput!
    ) {
        acceptCallback(input: $input) {
            callbackEdge {
                node {
                    id
                }
            }
        }
    }
`;
