import { gql } from "@apollo/client";

export const EDIT_BUSINESS_ADMIN_USER = gql`
    mutation EditAccountUserMutation($input: UpdateUserInput!) {
        updateUser(input: $input) {
            userEdge {
                node {
                    id
                    firstName
                    lastName
                    managerId
                    phone
                    roleId
                    status
                }
            }
        }
    }
`;
