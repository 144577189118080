export interface Button {
  type: string,
  color?: Color;
  text: string
}

export type Color = 'inherit' | 'primary' | 'secondary' | 'default';

export interface Node {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  createdAt: Date,
  updatedAt: Date,
  role: string,
  status: number,
  __typename: string
}
export interface Edge {
  node: Node;
  cursor: string;

}

export interface Company {
  id: string;
  name: string;
  type: string;
  subdomain: string;
  pgwSubscriptionPlanId: string;
  subscriptionStatus: string;
  address: Address;
  maxAllowedAgents: number;
  consumedActiveAgents: number;
  consumedMinutes: number;
  maxAllowedMinutes: number;
  maxAllowedProfiles: number;
  consumedProfiles: number;
  vat: number;
  plan: {
    id: string;
    name: string;
    priceId: string;
  }
}

export interface Address {
  id?: string;
  correspondanceCity: string;
  correspondanceState: string;
  correspondanceAddress: string;
  correspondanceCountry: string;
}

export interface CallStatus {
  ANSWERED: string;
  MISSED: string;
}

export interface AddOn {
  id: string;
  name: string;
  description: string;
  allowanceType: string;
  allowanceUnits: number;
  price: number;
  active: boolean;
}

export enum TicketStatus {
  Open = "Open",
  InProgress = "In Progress",
  OnHold = "On Hold",
  Closed = "Closed",
  Reopen = "Reopen",
}

export enum TicketPhase {
  CREATED = "created",
  REQUEST = "requested",
  ASSIGNED = "assigned",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CLOSED = "closed",
}

export enum Priority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Critical = "Critical",
}
export interface TicketFormData {
  id: string;
  ticketStatus: TicketStatus;
  ticketPase: TicketPhase;
  assigneeId: string;
  managerId: string;
  reporterId: string;
  location: string;
  subject: string;
  code: string;
  company: string;
  description: string;
  priority: Priority;
  customerId: string;
  companyId: string;
}

