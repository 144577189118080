import { legacy_createStore as createStore, applyMiddleware, Middleware } from 'redux';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import persistedReducer from './reducers/rootReducer';

const middlewares = [];


if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}


const store = createStore(persistedReducer, applyMiddleware(...middlewares as Middleware[]));
export const persistor = persistStore(store);
export default store;