import clsx from "clsx";
import {
  useForm,
  Controller,
  FieldValues,
  SubmitHandler,
  Path,
  UnpackNestedValue,
  DeepPartial,
  Resolver,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Clear, Search } from "@mui/icons-material";
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Button,
  MenuItem,
  TextField,
} from "@mui/material";
import { useStyles } from "./style";

interface CommonSearchbarProps<T extends FieldValues> {
  className?: string; // Custom className for styling
  schema?: yup.ObjectSchema<any>; // Yup validation schema
  defaultValues: UnpackNestedValue<DeepPartial<T>>; // Default values for the form fields
  fields: Array<{
    name: Path<T>;
    label: string;
    type: "text" | "select"; // Input type
    options?: Array<{ value: string | number; label: string }>; // Options for select fields
  }>;
  onSearch: (query: T) => void; // Function to call on form submit
  buttonLabel?: string; // Label for the search button
}

const CommonSearchbar = <T extends FieldValues>({
  className,
  schema,
  defaultValues,
  fields,
  onSearch,
  buttonLabel = "Search",
}: CommonSearchbarProps<T>) => {
  const classes = useStyles();

  // Initialize form with react-hook-form and validation schema
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm<T>({
    resolver: schema ? (yupResolver(schema) as Resolver<T, any>) : undefined,
    defaultValues: defaultValues as UnpackNestedValue<DeepPartial<T>>,
  });

  const onSubmit: SubmitHandler<T> = (data: any) => {
    onSearch(data);
  };

  const onClear = () => {
    const currentValues = getValues();
    reset(
      { ...currentValues, search: "" } as any, // Correct type
      {
        keepDefaultValues: true,
        keepValues: false,
      }
    );
    handleSubmit(onSubmit)();
  };

  return (
    <div className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Card sx={{ p: 2 }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            {fields.map((field, index) => (
              <Grid item xs={12} sm={6} lg={4} key={index}>
                <Controller
                  name={field.name as Path<T>}
                  control={control}
                  render={({ field: controllerField }) => (
                    <>
                      {field.type === "text" && (
                        <TextField
                          {...controllerField}
                          placeholder={field.label}
                          type="text"
                          fullWidth
                          error={!!errors[field.name as keyof T]}
                          helperText={
                            (errors[field.name as keyof T] as any)?.message ??
                            "" // Ensure proper type-safe access
                          }
                          InputProps={{
                            endAdornment: getValues()[field.name as any] ? (
                              <InputAdornment position="end">
                                <IconButton onClick={onClear} size="small">
                                  <Clear color="action" />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                          }}
                        />
                      )}
                      {field.type === "select" && (
                        <TextField
                          {...controllerField}
                          select
                          label={field.label}
                          fullWidth
                          error={!!errors[field.name as keyof T]}
                          helperText={
                            (errors[field.name as keyof T] as any)?.message ??
                            "" // Ensure proper type-safe access
                          }
                        >
                          {field.options?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </>
                  )}
                />
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              container
              justifyContent="flex-end"
            >
              <Stack direction="row" spacing={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<Search />}
                >
                  {buttonLabel}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </form>
    </div>
  );
};

export default CommonSearchbar;
