import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    CardActions,
    CardHeader,
    Divider,
    Stack,
} from "@mui/material";
import useStyles from "./style";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { AddOn } from "../List/List";


interface AddOnItemProps {
    addOn: AddOn;
    addItem: (addon: AddOn) => void
}

const AddOnItem = ({ addOn, addItem }: AddOnItemProps) => {

    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader title={addOn.name} style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 20, paddingBottom: 10 }} action={
                <Typography>£ {addOn.price}</Typography>
            } />
            <Divider />
            <CardContent style={{ padding: 15 }}>
                <Box
                    // className={classes.scrollableContent}
                    style={{ overflowX: "auto", marginTop: 8 }}
                >
                    <Typography textAlign={'justify'} style={{
                        maxLines: 2,
                        minHeight: 48
                    }}>{addOn.description}</Typography>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Typography variant="subtitle1" flexDirection={'row'}>{addOn.allowanceType + ": "}</Typography>
                        <Typography variant="body1">{addOn.allowanceUnits}</Typography>
                    </Stack>
                </Box>
            </CardContent>
            <Divider />
            <CardActions style={{ justifyContent: "center" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => addItem(addOn)}
                >
                    <AddShoppingCartIcon
                        fontSize="small"
                        className={classes.icon}
                    ></AddShoppingCartIcon>
                    Add to Cart
                </Button>
            </CardActions>
        </Card>
    );
};

export default AddOnItem