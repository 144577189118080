import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from "../../assets/images/guidizy-logo.png";
import { Link} from "@mui/material";

interface LeftColumnCardProps {
  plan: string; 
}

const LeftColumnCard: React.FC<LeftColumnCardProps> = ({ plan }) => {
  const cardContent = (
    <React.Fragment>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
          <img src={logo} alt="Guidizy Logo" style={{ width: '50%' }} /> 
        </Box>
        <Typography sx={{ fontSize: { xs: 18, md: 24 }, marginBottom: 1, textAlign: 'center' }} gutterBottom>
          Unlock the Benefits
        </Typography>

        <Typography sx={{ mb: 1, fontSize: { xs: 18, md: 24 }, textAlign: 'center' }}>
          of {plan} Plan 
        </Typography>
        <Typography variant="body2" sx={{ fontSize: { xs: 18, md: 24 }, textAlign: 'center' }}>
          Sign Up Now.
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: 10, 
        height: '100%', 
        '@media (max-width: 960px)': {
          paddingX: 8.5, 
        },
        '@media (max-width: 600px)': {
          paddingX: 1, 
        },
        '@media (max-width: 480px)': {
          paddingX: 2,
        },
      }}
    >
      <Card
        variant="outlined"
        sx={{
          opacity: 0.7,
          width: 'calc(76% - 32px)',
          marginTop: '10px',
          marginBottom: '10px',
          '@media (max-width: 960px)': {
            width: 'calc(50% - 20px)',
          },
          '@media (max-width: 600px)': {
            width: 'calc(70% - 28px)',
          },
          '@media (max-width: 480px)': {
            width: 'calc(80% - 40px)',
          },
        }}
      >
        {cardContent}
        <CardActions sx={{ justifyContent: 'center' }}>
          <Link href="/login/" sx={{ textDecoration: 'none' }}>
            <Button
              color="primary"
              sx={{
                backgroundColor: '#6366F1',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#C73962',
                },
              }}
            >
              <b>Already have an account? Sign In</b>
            </Button>
          </Link>
        </CardActions>

      </Card>
    </Box>
  );
}

export default LeftColumnCard;