import { Grid, Card, CardContent, Divider, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useSocket } from "context/SocketProvider";
import clsx from "clsx";
import useStyles from "./styles";
import CustomerList from "../customers_list/CustomersList";
import AgentList from "../agents_list/AgentsList";

export default function LiveDashboard({ className }: Record<string, any>) {
    const { socket } = useSocket();
    const [draggedItem, setDraggedItem] = useState<string | null>(null);
    const [sourceAgentId, setSourceAgentId] = useState<string | null>(null);
    const [activeAgentBox, setActiveAgentBox] = useState<string | null>(null);
    const prevActiveAgentBoxRef = useRef<string | null>(null);
    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const { user } = useSelector((state: RootState) => state.auth);
    const companyId = user?.companyId;
    const classes = useStyles({});
    const [activeAgentCard, setActiveAgentCard] = useState<string | null>(null)

    useEffect(() => {
        if (socket?.connected) {
            if (user?.role === "owner") {
                socket.emit("call", "chatRoomService.registerObserver", {
                    data: { companyId },
                });
            }
        }

    }, [socket]);

    useEffect(() => {
        if (draggedItem && activeAgentBox ) {
            if (socket?.connected) {
                socket.emit("call", "chatRoomService.assignCustomerToAgentAction", {
                    data: {
                        companyId,
                        agentId: activeAgentBox,
                        customerId: draggedItem,
                    },
                });
                prevActiveAgentBoxRef.current = activeAgentBox;
                setDraggedItem(null);
                setActiveAgentBox(null);
            }
        }
    }, [activeAgentBox]);

    const handleDropCustomer = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        if (socket?.connected) {
            socket.emit("call", "chatRoomService.removeCustomerFromAgent", {
                data: {
                    companyId,
                    agentId: sourceAgentId,
                    customerId: draggedItem,
                },
            });
        }
    };

    const handleDragStart = (event: React.DragEvent<HTMLElement>, item: string, agentId: string | null = null) => {
        setSourceAgentId(agentId);
        setDraggedItem(item);
        event.dataTransfer.effectAllowed = "move";
    };

    const handleDrop = (event: React.DragEvent<HTMLElement>, agentId: string) => {
        event.preventDefault();
        setActiveAgentCard(null)
        setActiveAgentBox(agentId);
    };

    const handleDragOver = (event: React.DragEvent<HTMLElement>, id?: string) => {
        event.preventDefault();
        if (activeAgentCard !== id) {
            setActiveAgentCard(id ? id : null)
        }
        event.dataTransfer.dropEffect = "move";
    };


    return (
        <Grid container >
            <Grid item md={12} xl={11} lg={11}>
                <Card className={clsx(className, classes.card)}>
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            {/* Customers Section */}
                            <Grid item xs={3} md={3}>
                                <CustomerList
                                    handleDragStart={handleDragStart}
                                    handleDropCustomer={handleDropCustomer}
                                    handleDragOver={handleDragOver}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            {/* Agents Section */}
                            <Grid item xs={9} md={9}>
                                <AgentList
                                    handleDragStart={handleDragStart}
                                    handleDrop={handleDrop}
                                    handleDragOver={handleDragOver}
                                    activeAgent={activeAgentCard}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
