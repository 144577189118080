import CommonSnackBar from "components/common_snackbar/CommonSnackBar";
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { routeConfig } from "./configuration";
import { AuthRoutes } from "./AuthRoutes";
import { GeneralRoutes } from "./GeneralRoutes";
import { AdminRoutes } from "./AdminRoutes";
import NotFoundPage from "modules/auth/NotFoundPage";
import Layout from "common/layouts/User/Layout";
import SuperAdminLayout from "superadmin/common/layout";
import { getRouteConfig } from "./RouteConfig";

export default function AppRoutes(): JSX.Element {
  const { isAuthenticated, user, permissions } = useSelector((state: RootState) => state.auth);

  const hasAnyPermission = (pageId: string): boolean => {
    return permissions[pageId] && permissions[pageId].length > 0;
  };

  const hasItemPermission = (pageId: string, permissionId: string): boolean => {
    return permissions[pageId] && permissions[pageId].includes(permissionId);
  };

  return (
    <Router>
      <CommonSnackBar>
        <Routes>
          <Route element={<Outlet />}>
            {GeneralRoutes(isAuthenticated)}
            {AuthRoutes(isAuthenticated)}
          </Route>

          {isAuthenticated &&
            (user?.isSuperAdmin ?
              <Route element={<SuperAdminLayout />}>
                {AdminRoutes()}
              </Route>
              :
              <Route element={<Layout />}>
                {getRouteConfig(routeConfig, isAuthenticated, hasAnyPermission, hasItemPermission)}
                <Route path="" element={<Navigate to="/dashboard" />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            )}
        </Routes>
      </CommonSnackBar>
    </Router>
  );
}
