import { gql } from "@apollo/client";

export const SUBMIT_BOT_FRAMEWORK = gql`
query SubmitBotFramework($input: SubmitBotFrameworkInput!) {
    submitBotFramework(input: $input) {
      message
    }
  }
`

