// styles.ts

import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface StylesProps {
    isSmallScreen?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) =>
    createStyles({
        customerCard: {
            overflow: "hidden",
            height: "85vh",
        },
        chatCard: {
            display: "flex",
            flexDirection: "column",
        },
        stickyHeader: {
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: theme.palette.common.white,
        },
        scrollBox: {
            height: "calc(100vh - 150px)",
            overflowY: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
        },
        messageBox: {
            padding: theme.spacing(2),
            flex: 1,
            overflow: "scroll",
            marginBottom: theme.spacing(2),
        },
        card: {
            marginTop: "20px",
            backgroundColor: "#fff",
        },
        box: {
            height: "400px",
            overflowY: "auto",
            padding: "10px",
            boxSizing: "border-box",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
        },
        customerItem: {
            width: "100%",
            height: "60px",
            backgroundColor: "#ADD8E6",
            padding: "10px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            cursor: "move",
            borderRadius: "7px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            marginTop: "10px",
        },
        agentBox: {
            minHeight: "250px",
            maxHeight: "250px",
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: "auto",
            position: "relative",
        },
        agentAvatar: {
            bgcolor: "primary.main",
            width: 32,
            height: 32,
            fontSize: "13px",
            marginRight: '10px',
            borderRadius: "50%",
        },
        statusDot: {
            width: "11px",
            height: "11px",
            borderRadius: "50%",
            position: "absolute",
            bottom: 0,
            right: 0,
            border: "2px solid #fff",
        },
        connectedCustomerAvatar: {
            bgcolor: "primary.main",
            width: 24,
            height: 24,
            fontSize: "10px",
            borderRadius: "50%",
        },
    })
);

export default useStyles;
