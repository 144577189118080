import { gql } from "@apollo/client";

export const MODAL_CALL_AGENT_CALLBACK = gql`
    mutation ModalCallAgentCallbackMutation(
        $input: CallInput!
    ) {
        call(input: $input) {
            callbackEdge {
                node {
                    id
                    sipCallbackLink
                }
            }
        }
    }
`;
