import { useState } from "react";
import * as React from "react";
import { format } from "ts-date/esm/locale/en";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Modal from "../modal/Modal";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { CallbacksPlaceHolders } from "../searchbar/Searchbar";
import {
  TableRow,
  TableCell,
  Hidden,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

export default function AssignedItem(props: any) {
  // const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);
  // const role = user?.role;
  const userId = user?.id;
  const [modalOpen, setModal] = useState<HTMLButtonElement | null>(null);

  const str1 = new Date(props?.assigned?.createdAt!).toISOString();
  console.log("props", props);
  let dt: any;
  dt = "";
  if (str1) {
    dt = format(new Date(str1.substr(0, 19)), "DD MMM, YYYY - hh:mm A");
  }

  const handleAssign = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props?.assigned?.id!) {
      setModal(event.currentTarget);
    }
  };

  return (
    <TableRow hover>
      <Hidden smDown>
        <TableCell>
          {props?.assigned?.customer?.firstName!}
          {props?.assigned?.customer?.lastName!
            ? props?.assigned?.customer?.lastName! ===
              props?.assigned?.customer?.firstName!
              ? " "
              : " " + props?.assigned?.customer?.lastName!
            : ""}
          <br />
          {props?.assigned?.customer?.email!}
          <br />
          {props?.assigned?.customer?.phone!}
        </TableCell>
        <TableCell>{CallbacksPlaceHolders(props?.assigned?.status)}</TableCell>
        <TableCell>{props?.assigned?.reason}</TableCell>
        <TableCell>{dt}</TableCell>
        <TableCell>
          {props?.assigned?.assignee?.firstName! +
            " " +
            props?.assigned?.assignee?.lastName!}
          <br />
          {props?.assigned?.assignee?.email!}
        </TableCell>
        <TableCell>
          <IconButton
            disabled={userId != props?.assigned?.assignee?.id}
            onClick={handleAssign}
            aria-label="Assign"
            aria-haspopup="true"
          >
            <AssignmentTurnedInIcon />
          </IconButton>
          <Modal
            id={props?.assigned?.id!}
            el={modalOpen}
            onClose={setModal}
            appointment={props?.assigned!}
            getAssignedList={props?.getAssignedList}
            returnUrl={
             props?.getAssignedList 
            }
          />
        </TableCell>
      </Hidden>

      <Hidden mdUp>
        <Grid container spacing={0} style={{ position: "relative", padding: "16px" }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.assigned?.customer?.firstName!}
              {props?.assigned?.customer?.lastName!
                ? props?.assigned?.customer?.lastName! ===
                  props?.assigned?.customer?.firstName!
                  ? " "
                  : " " + props?.assigned?.customer?.lastName!
                : ""}
              <br />
              {props?.assigned?.customer?.email!}
              <br />
              {props?.assigned?.customer?.phone!}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {CallbacksPlaceHolders(props?.assigned?.status)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props?.assigned?.reason}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{dt}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {props?.assigned?.assignee?.firstName! +
                " " +
                props?.assigned?.assignee?.lastName!}
              <br />
              {props?.assigned?.assignee?.email!}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <ListItemSecondaryAction>
              <IconButton
                disabled={userId != props?.assigned?.assignee?.id}
                onClick={handleAssign}
                aria-label="Assign"
                aria-haspopup="true"
              >
                <AssignmentTurnedInIcon />
              </IconButton>
              <Modal
                id={props?.assigned?.id!}
                el={modalOpen}
                onClose={setModal}
                appointment={props?.assigned!}
                getAssignedList={props?.getAssignedList}
                returnUrl={
                  props?.getAssignedList 
                }
              />
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
