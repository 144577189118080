import { useStyles } from "./styles";
import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Button,
  Box,
  LinearProgress,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { EditPermissions as permissions } from "./permissions";
import { DefaultRole, Feature, SubFeature } from "../../../models/roles";
import {
  getAllPermissionsInFeature,
  getAllPermissionsInSubFeature,
  isFeatureChecked,
  isFeaturePartiallyChecked,
  isRoleCheckedForOption,
  isSubFeatureChecked,
  isSubFeaturePartiallyChecked,
} from "./utils";
import CreateupdateDefaultRoleModal from "./CreateRole";
import { LIST_DEFAULT_ROLES } from "superadmin/modules/subscriptions/adapters/queries/ListDefaultRoles";
import { useParams } from "react-router-dom";
import { UPDATE_DEFAULT_ROLE } from "superadmin/modules/subscriptions/adapters/mutations/UpdateDefaultRole";

const EditRoles: React.FC = () => {
  const classes = useStyles();
  const [roles, setRoles] = useState<DefaultRole[]>([]);
  const { id } = useParams();
  const [expandedFeature, setExpandedFeature] = useState<string | null>(null);
  const [hover, setHover] = useState(false);
  const { setSnack } = useContext(SnackbarContext);

  const [updateDefaultRole] = useMutation(UPDATE_DEFAULT_ROLE, {
    onCompleted: ({ updateDefaultRole }: any) => {
      console.log(updateDefaultRole, "updateDefaultRole");
      setSnack({
        message: "Record Updated Successfully",
        severity: "success",
        open: true,
      });
    },
  });

  // Fetch data using the useQuery hook, with the expected type being an object containing an array of Role objects.
  const {
    data,
    loading,
    refetch: rolesRefetch,
  } = useQuery<{ listDefaultRoles: DefaultRole[] }>(LIST_DEFAULT_ROLES, {
    variables: {
      defaultRole: {
        subscriptionId: id,
      },
    },
  });

  useEffect(() => {
    if (data?.listDefaultRoles) {
      setRoles(JSON.parse(JSON.stringify(data.listDefaultRoles)));
    }
  }, [data]);

  // Check if the data is still loading or if the listDefaultRoles field is not present.
  // If so, display a loading message.
  if (loading) return <LinearProgress />;

  const handleFeatureCheckboxChange = (feature: Feature, roleIndex: number) => {
    const updatedRoles = [...roles];
    const role = updatedRoles[roleIndex];
    const featureId = feature.id;
    const featurePermissions = role.permissions[featureId];
    if (featurePermissions && featurePermissions.length > 0) {
      role.permissions[featureId] = [];
    } else {
      role.permissions[featureId] = getAllPermissionsInFeature(feature);
    }
    updatedRoles[roleIndex] = role;
    setRoles(updatedRoles);
  };

  const handleFeatureItemChange = (
    feature: Feature,
    permissionId: string,
    roleIndex: number
  ) => {
    const updatedRoles = [...roles];
    const role = updatedRoles[roleIndex];
    const featureId = feature.id;
    if (!role.permissions[featureId]) role.permissions[featureId] = [];
    const permissions = role.permissions[featureId];
    if (permissions.includes(permissionId)) {
      permissions.splice(permissions.indexOf(permissionId), 1);
    } else {
      permissions.push(permissionId);
    }
    updatedRoles[roleIndex] = role;
    setRoles(updatedRoles);
  };

  const handleSubFeatureCheckboxChange = (
    featureId: string,
    subFeature: SubFeature,
    roleIndex: number
  ) => {
    const updatedRoles = [...roles];
    const role = updatedRoles[roleIndex];
    let featurePermissions = role.permissions[featureId] || [];
    const subFeaturePermissions = getAllPermissionsInSubFeature(subFeature);
    if (
      featurePermissions.length > 0 &&
      (isSubFeaturePartiallyChecked(subFeature, featurePermissions) ||
        isSubFeatureChecked(subFeature, featurePermissions))
    ) {
      featurePermissions = featurePermissions.filter(
        (permission) => !subFeaturePermissions.includes(permission)
      );
    } else {
      featurePermissions = [...featurePermissions, ...subFeaturePermissions];
    }
    role.permissions[featureId] = featurePermissions;
    updatedRoles[roleIndex] = role;
    setRoles(updatedRoles);
  };

  const handleSubFeatureItemChange = (
    featureId: string,
    permissionId: string,
    roleIndex: number
  ) => {
    const updatedRoles = [...roles];
    const role = updatedRoles[roleIndex];
    if (!role.permissions[featureId]) role.permissions[featureId] = [];
    const permissions = role.permissions[featureId];
    if (permissions.includes(permissionId)) {
      permissions.splice(permissions.indexOf(permissionId), 1);
    } else {
      permissions.push(permissionId);
    }
    updatedRoles[roleIndex] = role;
    setRoles(updatedRoles);
  };

  const handleFeatureClick = (featureId: string) => {
    // If the clicked feature is already expanded, collapse it (set to null).
    setExpandedFeature(expandedFeature === featureId ? null : featureId);
  };

  const handleCancel = () => {};

  const handleSubmit = async () => {
    roles.forEach((role) => {
      updateDefaultRole({
        variables: {
          defaultRole: {
            id: role.id,
            permissions: role.permissions,
          },
        },
      });
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}></TableCell>
              {roles?.map((role) => (
                <TableCell
                  key={role.id}
                  className={classes.roleCellHeader}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <Box display="flex" alignItems="center">
                    <span>{role.name}</span>
                    {hover && (
                      <CreateupdateDefaultRoleModal
                        rolesRefetch={rolesRefetch}
                        roles={roles}
                        editMode={true}
                        roleToEdit={role}
                      />
                    )}
                  </Box>
                </TableCell>
              ))}
              <TableCell className={classes.newColumnCell}>
                {/* Component for creating new role */}
                <CreateupdateDefaultRoleModal
                  rolesRefetch={rolesRefetch}
                  roles={roles}
                  editMode={false}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map((feature) => (
              <React.Fragment key={feature.id}>
                <TableRow>
                  <TableCell className={classes.featureCell}>
                    <Box
                      display="flex"
                      alignItems="center"
                      component="span"
                      className={classes.featureBox}
                      onClick={() => handleFeatureClick(feature.id)}
                    >
                      <IconButton size="small">
                        {expandedFeature === feature.id ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                      <Box sx={{ flexGrow: 1 }}>{feature.name}</Box>
                    </Box>
                  </TableCell>

                  {roles?.map((role, index) => (
                    <TableCell
                      key={role.id}
                      className={classes.featureCheckBox}
                    >
                      <Checkbox
                        checked={isFeatureChecked(
                          feature,
                          role.permissions[feature.id]
                        )}
                        indeterminate={isFeaturePartiallyChecked(
                          feature,
                          role.permissions[feature.id]
                        )}
                        onChange={() =>
                          handleFeatureCheckboxChange(feature, index)
                        }
                        disabled={
                          index > 0 &&
                          (!roles[0].permissions[feature.id] ||
                            !(roles[0].permissions[feature.id]?.length > 0))
                        }
                      />
                    </TableCell>
                  ))}
                </TableRow>

                {expandedFeature === feature.id &&
                  feature.permissions.map((permission) => (
                    <TableRow key={permission.id} sx={{ paddingY: 0 }}>
                      <TableCell sx={{ paddingX: 0, paddingLeft: 8 }}>
                        {permission.label}
                      </TableCell>
                      {roles?.map((role, index) => (
                        <TableCell key={role.id} sx={{ paddingY: 0 }}>
                          <Checkbox
                            checked={isRoleCheckedForOption(
                              feature.id,
                              permission.id,
                              role.permissions
                            )}
                            onChange={() =>
                              handleFeatureItemChange(
                                feature,
                                permission.id,
                                index
                              )
                            }
                            disabled={
                              index > 0 &&
                              (!roles[0].permissions[feature.id] ||
                                !roles[0].permissions[feature.id]?.includes(
                                  permission.id
                                ))
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                {expandedFeature === feature.id &&
                  feature.subFeatures?.map((subFeature) => (
                    <React.Fragment key={subFeature.id}>
                      <TableRow sx={{ paddingY: 0 }}>
                        <TableCell sx={{ paddingX: 0, paddingLeft: 4 }}>
                          <Box
                            display="flex"
                            alignItems="center"
                            component="span"
                            className={classes.subFeatureBox}
                          >
                            <IconButton size="small">
                              <ExpandMore />
                            </IconButton>
                            <Box>{subFeature.name}</Box>
                          </Box>
                        </TableCell>
                        {roles?.map((role, index) => (
                          <TableCell key={role.id} sx={{ paddingY: 0 }}>
                            <Checkbox
                              checked={isSubFeatureChecked(
                                subFeature,
                                role.permissions[feature.id]
                              )}
                              indeterminate={isSubFeaturePartiallyChecked(
                                subFeature,
                                role.permissions[feature.id]
                              )}
                              onChange={() =>
                                handleSubFeatureCheckboxChange(
                                  feature.id,
                                  subFeature,
                                  index
                                )
                              }
                              disabled={index > 0}
                            />
                          </TableCell>
                        ))}
                      </TableRow>

                      {subFeature.permissions.map((permission) => (
                        <TableRow
                          key={permission.id}
                          style={{ paddingLeft: 80 }}
                          sx={{ paddingY: 0 }}
                        >
                          <TableCell sx={{ paddingX: 0, paddingLeft: 10 }}>
                            {permission.label}
                          </TableCell>
                          {roles?.map((role, index) => (
                            <TableCell key={role.id} sx={{ paddingY: 0 }}>
                              <Checkbox
                                checked={isRoleCheckedForOption(
                                  feature.id,
                                  permission.id,
                                  role.permissions
                                )}
                                onChange={() =>
                                  handleSubFeatureItemChange(
                                    feature.id,
                                    permission.id,
                                    index
                                  )
                                }
                                disabled={
                                  index > 0 &&
                                  (!roles[0].permissions[feature.id] ||
                                    !roles[0].permissions[feature.id]?.includes(
                                      permission.id
                                    ))
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.buttonBox}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          style={{ marginRight: 8 }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </>
  );
};

export default EditRoles;
