import { gql } from "@apollo/client";
import { LIST_BUSINESS_ADMIN_CALL_FOR_HELP } from "../../../../adapters/fragments/ListBusinessAdminCallForHelp";

export const CALL_FOR_HELP_REFETCH = gql`
    ${LIST_BUSINESS_ADMIN_CALL_FOR_HELP}
    query BotProfileAccoutFragmentRefetchQuery(
        $count: Int = 10
        $cursor: String
    ) {
        ...ListAccount_botprofiles
    }
`;
