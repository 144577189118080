import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(1)
        },
        importButton: {
            marginRight: theme.spacing(1)
        },
        exportButton: {
            marginRight: theme.spacing(1)
        },
        filterBox: {
            minHeight: "48px",
            backgroundColor: theme.palette.background.paper,
            border: "1px solid rgb(224, 224, 224)",
            borderRadius: "4px"
        },
        cardHeader: {
            padding: theme.spacing(0.7, 1)
        },
        cardTitle: {
            fontSize: "1.5rem"
        },
        addButton: {
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            },
            color: 'white',
        },
    })
);
