import { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';

const usePaginationQuery = (query: any, initialVariables: any, dataKey: any) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(initialVariables?.count || 10);
  const [searchQuery, setSearchQuery] = useState<any>({ search: "" });
  const [sort, setSort] = useState<any>({ sortBy: "",sortOrder:"" });

  // Merging initial variables with pagination and search parameters
  const variables = useMemo(() => ({
    count,
    ...searchQuery,
    ...sort,
    ...initialVariables,
  }), [count, searchQuery, sort, initialVariables]);
    
  const { data, loading, refetch } = useQuery(query, { variables, fetchPolicy: "network-only", });

  useEffect(() => {
    if (sort.sortBy) {  // Check to avoid logging the initial empty state
      console.log('Sort changed:', sort);
    }
  }, [sort]);

  const getRefetchVariables = useCallback((change: any) => {
    const pageInfo = data[dataKey]?.pageInfo;
    if (change === "forward") {
      return {
        ...variables,
        startcursor: null,
        endcursor: pageInfo?.endCursor || null,
      };
    } else {
      return {
        ...variables,
        count: undefined,
        reversecount: count,
        startcursor: pageInfo?.startCursor || null,
        endcursor: null,
      };
    }
  }, [data, dataKey, count, variables]);

  const onPageChange = useCallback((event: any, newPage: any) => {
    const direction = newPage > page ? 'forward' : 'backward';
    refetch(getRefetchVariables(direction));
    setPage(newPage);
  }, [page, getRefetchVariables, refetch]);

  const onRowsPerPageChange = useCallback((event: any) => {
    setCount(parseInt(event.target.value, 10));
    setPage(0);  // Reset to the first page with new rows per page
    refetch({
      ...variables,
      count: parseInt(event.target.value, 10),
      startcursor: null,  // Optional: reset pagination on rows per page change
    });
  }, [setCount, refetch, variables]);

  return { data, loading, onPageChange, onRowsPerPageChange, setSearchQuery, setSort, setPage, page, count, refetch };
};

export default usePaginationQuery;
