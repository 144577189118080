import AuthReducer from './AuthReducer';
import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import JitsiReducer from './JitsiReducer';
import AddOnCartReducer from './AddOnCartReducer';
import AgentSessionReducer from './AgentSessionReducer';

const migrations = {
    1.2: (state: any) => {
      // migration clear out device state
      return {
        ...state,
        auth: {
            ...state.auth,
            // Handle new permissions or state transformations here
            permissions: state.auth.permissions || {}, // Add default permissions or handle changes
        },};
    },
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'jitsi', 'addOnCart', 'agentSession'],
    version: 1.2,
    migrate: createMigrate(migrations, {debug: false})
};

const rootReducer = combineReducers({
    auth: AuthReducer,
    jitsi: JitsiReducer,
    addOnCart: AddOnCartReducer,
    agentSession: AgentSessionReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);

