import Card from "@mui/material/Card";
import Page from "components/page/Page";
import {
  Button,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import FormInput from "components/form/form_input_tickets/FormInput";
import FormButton from "components/form/form_button/FormButton";
import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./style";
// import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useContext, useMemo, useState } from "react";
import { USERS } from "adapters/queries/Users";
import { CREATE_CUSTOMER } from "adapters/mutations/createCustomer";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { v4 as uuidv4 } from "uuid";
import { CREATE_TICKET } from "modules/tickets/adapters/mutations/TicketAdminCreate";
import { UPDATE_TICKET_MUTATION } from "modules/tickets/adapters/mutations/updateTicketMutation";
import { Priority } from "utils/interfaces";
// import { TicketPhase } from "utils/interfaces";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import { useNavigate } from "react-router-dom";
import { GET_ASSETS_URL } from "adapters/queries/GetAssetsUrl";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, Box } from "@mui/material";

export default function CustomerTicketDetails({ ticket }: any) {
  const { user } = useSelector((state: RootState) => state.auth);
  const companyId = user?.companyId;
  const companyName = user?.company?.name;
  const { setSnack } = useContext(SnackbarContext);
  const userId = user?.id;
  const classes = useStyles();
  const [showAttachments, setShowAttachments] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState<string>("");
  const [selectedManagerId, setSelectedManagerId] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState<string | null>(null);
  // const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  // const [openSsModal, setOpenSsModal] = useState(false);
  interface Agent {
    id: string;
    name: string;
  }
  interface Manager {
    id: string;
    name: string;
  }
  interface FormData {
    status: string;
    phase: string;
    manager: string;
    assignee: string;
    reporter: string;
    category: string;
    subCategory: string;
    feedback: string;
    reason: string;
    location: string;
    subject: string;
    description: string;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    priority: string;
  }
  // const schema = yup.object().shape({
  //     name: yup.string().required(),
  //   });
  enum TicketStatus {
    Open = "Open",
    InProgress = "InProgress",
    OnHold = "OnHold",
    Closed = "Closed",
    Reopen = "Reopen",
  }

  enum TicketPhase {
    CREATED = "CREATED",
    REQUESTED = "REQUESTED",
    ASSIGNED = "ASSIGNED",
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    CLOSED = "CLOSED",
  }

  const TicketPhaseOptions = [
    "CREATED",
    "REQUESTED",
    "ASSIGNED",
    "PENDING",
    "ACCEPTED",
    "REJECTED",
    "CLOSED",
  ];
  console.log("This is ticket", ticket);

  const { data: managerData } = useQuery(USERS, {
    variables: {
      companyId: companyId,
      role: "manager",
    },
  });
  const managerOptions = useMemo(() => {
    if (managerData && managerData.users && managerData.users.edges) {
      return managerData.users.edges.map((edge: any) => {
        const user = edge.node;
        return {
          id: user?.id,
          name: `${user.firstName} ${user.lastName}`,
        };
      });
    }
    return [];
  }, [managerData]);
  // Code for agent role
  const { data: agentData } = useQuery(USERS, {
    variables: {
      companyId: companyId,
      role: "agent",
    },
  });
  console.log(agentData);

  const agentOptions = useMemo(() => {
    if (agentData && agentData.users && agentData.users.edges) {
      return agentData.users.edges.map((edge: any) => {
        const user = edge.node;
        return {
          id: user?.id,
          name: `${user.firstName} ${user.lastName}`,
        };
      });
    }
    return [];
  }, [agentData]);

  const [ticketcreate] = useMutation(CREATE_TICKET, {
    onCompleted: ({ CreateTicket }: any) => {
      console.log("Create Ticket", CreateTicket);
      setSnack({
        message: "Ticket creation done",
        severity: "success",
        open: true,
      });
      navigate("/tickets");
    },
    onError: () => {
      setSnack({
        message: "Ticket creation failed",
        severity: "error",
        open: true,
      });
    },
  });

  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onError: () => {
      setSnack({
        message: "Customer creation failed",
        severity: "error",
        open: true,
      });
    },
  });

  const [ticketupdate] = useMutation(UPDATE_TICKET_MUTATION, {
    onCompleted: ({ updateTicket }: any) => {
      console.log("update Ticket", updateTicket);
      setSnack({
        message: "Ticket Update done",
        severity: "success",
        open: true,
      });
      navigate("/tickets");
    },
    onError: () => {
      setSnack({
        message: "Ticket Update failed",
        severity: "error",
        open: true,
      });
    },
  });

  const { control, handleSubmit } = useForm({
    //   resolver: yupResolver(schema),
    //   defaultValues,
  });

  const handleAttachmentsButtonClick = () => {
    setShowAttachments(!showAttachments);
  };

  const handleButtonClick = (key: any) => {
    if (key) {
      const cleanedKey = key.replace(/[[\]"]/g, "");
      navigate(`/tickets/ticketsassets/${cleanedKey}`);
    }
    console.log("key", key);
  };

  const [fetchScreenshotUrl, { loading }] = useLazyQuery(GET_ASSETS_URL, {
    onCompleted: (data) => {
      console.log("url", data.getTicketAsset.url);
      if (data && data.getTicketAsset && data.getTicketAsset.url) {
        setScreenshotUrl(data.getTicketAsset.url);
        setOpenModal(true);
      } else {
        setScreenshotUrl(null);
        setOpenModal(true); // Open the modal to show the alert message
      }
    },
  });

  const handleScreenshotButtonClick = () => {
    const key = ticket?.screenshot;
    if (key) {
      fetchScreenshotUrl({
        variables: {
          input: {
            key,
          },
        },
      });
    } else {
      setScreenshotUrl(null);
      setOpenModal(true); // Open the modal to show the alert message
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setScreenshotUrl("");
  };

  const timestamp = new Date().toISOString();

  const onSubmit = ({
    firstname,
    lastname,
    phone,
    email,
    reason,
    location,
    subject,
    description,
    feedback,
    status,
    phase,
    manager,
    assignee,
    category,
    subCategory,
    priority,
  }: FormData) => {
    console.log("Selected Agent ID:", selectedAgentId);
    console.log("Selected manager ID:", selectedManagerId);
    console.log("firstName", firstname);
    const customerId = uuidv4();
    const timestamp = new Date().getTime();
    if (!ticket) {
      createCustomer({
        variables: {
          input: {
            id: customerId,
            companyId: companyId,
            email: email,
            appointmentDate: timestamp,
            firstName: firstname,
            lastName: lastname,
            phone: phone,
          },
        },
        onCompleted: ({ CreateCustomer }: any) => {
          console.log("Create create", CreateCustomer);
          setSnack({
            message: "Customer creation done",
            severity: "success",
            open: true,
          });
          const ticketVariables = {
            customerId: customerId,
            companyId: companyId,
            company: companyName,
            ticketStatus: status,
            ticketPhase: phase,
            category: category,
            subCategory: subCategory,
            customerFeedback: feedback,
            reason: reason,
            location: location,
            subject: subject,
            description: description,
            managerId: manager,
            assigneeId: assignee,
            reporterId: userId,
            priority: priority,
          };
          ticketcreate({
            variables: {
              ticket: ticketVariables,
            },
          });
        },
      });
    } else {
      ticketupdate({
        variables: {
          ticket: {
            id: ticket?.id,
            ticketStatus: status,
            ticketPhase: phase,
            category: category,
            subCategory: subCategory,
            assigneeId: selectedAgentId,
            managerId: selectedManagerId,
          },
        },
      });
    }
  };

  return (
    <Page title="Ticket Details">
      <Container className={classes.container}>
        {/* Main Heading */}
        <Typography variant="h4" gutterBottom style={{ fontWeight: "bold" }}>
          Ticket Details
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Card className={classes.card}>
                <CardHeader
                  title="Customer Details"
                  className={classes.cardHeader}
                  titleTypographyProps={{
                    className: classes.cardTitle,
                  }}
                />
                <Divider />
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1px",
                    }}
                  >
                    <FormInput
                      control={control}
                      type="text"
                      name="firstname"
                      label="Customer's First Name*"
                      defaultValue={ticket?.customer?.firstName}
                      disabled={ticket ? true : false}
                      fullWidth
                    />
                    <div style={{ width: "20px" }} />{" "}
                    {/* Adjust the width of the space as needed */}
                    <FormInput
                      control={control}
                      name="lastname"
                      label="Customer's Last Name'*"
                      defaultValue={ticket?.customer?.lastName}
                      disabled={ticket ? true : false}
                      fullWidth
                    />
                  </div>
                  <br />
                  <FormInput
                    control={control}
                    name="email"
                    label="Customer Email*"
                    defaultValue={ticket?.customer.email}
                    disabled={ticket ? true : false}
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    type="text"
                    name="reason"
                    label="Reason*"
                    defaultValue={ticket?.reason}
                    disabled={ticket ? true : false}
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    type="text"
                    name="location"
                    label="Location*"
                    defaultValue={ticket?.location}
                    disabled={ticket ? true : false}
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    name="subject"
                    label="Subject*"
                    defaultValue={ticket?.subject}
                    disabled={ticket ? true : false}
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    name="description"
                    label="Description*"
                    defaultValue={ticket?.description}
                    disabled={ticket ? true : false}
                    multiline
                    rows={4}
                    fullWidth
                  />
                  <br />
                  <br />
                  {/* <FormInput
                    control={control}
                    name="feedback"
                    type="text"
                    label="Customer Feedback*"
                    defaultValue={ticket?.customerFeedback}
                    disabled={ticket ? true : false}
                    fullWidth
                  />
                  <br /> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                <CardHeader
                  title="Ticket Details"
                  className={classes.cardHeader}
                  titleTypographyProps={{
                    className: classes.cardTitle,
                  }}
                />
                <Divider />
                <CardContent>
                  <FormInput
                    control={control}
                    name="status"
                    label="Ticket Status*"
                    defaultValue={ticket?.ticketStatus || TicketStatus.Open}
                    select
                    fullWidth
                  >
                    {Object.entries(TicketStatus).map(([key, value], index) => (
                      <MenuItem key={index} value={value}>
                        {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </MenuItem>
                    ))}
                  </FormInput>
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    name="phase"
                    label="Ticket Phase*"
                    defaultValue={ticket?.ticketPhase || TicketPhase.CREATED}
                    select
                    fullWidth
                  >
                    {TicketPhaseOptions.map((phase, index) => (
                      <MenuItem key={index} value={phase}>
                        {phase}
                      </MenuItem>
                    ))}
                  </FormInput>
                  <br />
                  <br />
                  {ticket ? (
                    <FormInput
                      control={control}
                      name="priority"
                      label="Priority*"
                      defaultValue={ticket?.priority}
                      fullWidth
                    />
                  ) : (
                    <FormInput
                      control={control}
                      name="priority"
                      label="Priority*"
                      select
                      fullWidth
                    >
                      {Object.values(Priority).map((priority, index) => (
                        <MenuItem key={index} value={priority}>
                          {priority}
                        </MenuItem>
                      ))}
                    </FormInput>
                  )}
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    name="createdAt"
                    label="createdAt*"
                    defaultValue={ticket ? ticket.createdAt : timestamp}
                    disabled
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    type="text"
                    name="manager"
                    label="Manager*"
                    defaultValue={ticket?.managerId}
                    disabled={ticket ? true : false}
                    select
                    fullWidth
                  >
                    {managerOptions.map((manager: Manager, index: number) => (
                      <MenuItem
                        key={index}
                        value={manager.id}
                        onClick={() => setSelectedManagerId(manager.id)}
                      >
                        {manager.name}
                      </MenuItem>
                    ))}
                  </FormInput>
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    name="assignee"
                    label="Assignee*"
                    defaultValue={ticket?.assigneeId}
                    select
                    fullWidth
                  >
                    {agentOptions.map((agent: Agent, index: number) => (
                      <MenuItem
                        key={index}
                        value={agent.id}
                        onClick={() => setSelectedAgentId(agent.id)}
                      >
                        {agent.name}
                      </MenuItem>
                    ))}
                  </FormInput>
                  <br />
                  <br />
                  {ticket && (
                    <>
                      <FormInput
                        control={control}
                        name="Reporter"
                        label="Reporter*"
                        type="text"
                        defaultValue={
                          ticket
                            ? `${ticket?.reporter?.firstName || ""} ${
                                ticket?.reporter?.lastName || ""
                              }`
                            : `${user?.firstName || ""} ${user?.lastName || ""}`
                        }
                        fullWidth
                        disabled
                      />
                      <br />
                      <br />
                    </>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        {ticket && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleScreenshotButtonClick}
                            style={{
                              flex: "1 1 20%",
                              margin: "5px",
                              minWidth: "50px",
                            }}
                          >
                            <ScreenshotMonitorIcon />
                          </Button>
                        )}
                        {ticket && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAttachmentsButtonClick}
                            style={{
                              flex: "1 1 20%",
                              margin: "5px",
                              minWidth: "50px",
                            }}
                          >
                            <AttachFileIcon />
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    {showAttachments && (
                      <Grid item xs={12}>
                        <Box mt={0}>
                          <Grid container direction="column" spacing={1}>
                            {ticket?.attachments &&
                            ticket.attachments.length > 0 ? (
                              ticket.attachments.map(
                                (attachment: any, index: any) => (
                                  <Grid item key={index}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleButtonClick(attachment)
                                      }
                                      fullWidth
                                      style={{
                                        whiteSpace: "nowrap",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ textAlign: "center" }}>
                                        <Typography variant="body1">
                                          Attachment {index + 1}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          style={{ wordWrap: "break-word" }}
                                        >
                                          {attachment.split("/").pop()}
                                        </Typography>
                                      </div>
                                    </Button>
                                  </Grid>
                                )
                              )
                            ) : (
                              <Alert severity="info">No attachments</Alert>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                    <Dialog
                      open={openModal}
                      onClose={handleCloseModal}
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogTitle>
                        Screenshot
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseModal}
                          style={{ position: "absolute", right: 8, top: 8 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent dividers>
                        {loading ? (
                          <Typography>Loading...</Typography>
                        ) : screenshotUrl ? (
                          <img
                            src={screenshotUrl}
                            alt="Screenshot"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <Alert severity="info">No screenshots</Alert>
                        )}
                      </DialogContent>
                    </Dialog>
                  </Grid>
                  {/* <FormInput
                    control={control}
                    type="text"
                    name="category"
                    label="Category*"
                    defaultValue={ticket?.category}
                    fullWidth
                  />
                  <br />
                  <br />
                  <FormInput
                    control={control}
                    type="text"
                    name="subCategory"
                    label="Sub Category*"
                    defaultValue={ticket?.subCategory}
                    fullWidth
                  /> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <div style={{ width: "50%", maxWidth: "200px" }}>
              <FormButton
                className={classes.button}
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
              >
                {ticket ? "Update Record" : "Create Record"}
              </FormButton>
            </div>
          </div>
        </form>
      </Container>
    </Page>
  );
}
