import { ModuleRouteConfig } from "common/types/RouteTypes";
import Appointments from "./pages/AppointmentsPage";
// import AgentAppointmentConference from "components/conference/appointment/AngentConference";

export const AppointmentRoutes: ModuleRouteConfig[] = [

  // {
  //   permissionId: "assign_appointments",
  //   path: "/meeting/:appId/:appointmentId/:token/:phoneNumber",
  //   element: <AgentAppointmentConference />,
  // },
  // {
  //   permissionId: "assign_appointments",
  //   path: "/meeting/:appId/:appointmentId/:token",
  //   element: <AgentAppointmentConference />,
  // },
  // {
  //   permissionId: "assign_appointments",
  //   path: "/meeting/:appId/:appointmentId/:token/:phoneNumber",
  //   element: <AgentAppointmentConference />,
  // },
  {
    permissionId: "view_appointments",
    path: "/:customerId",
    element: <Appointments />,
  },
  {
    permissionId: "view_appointments",
    path: "/:id",
    element: <Appointments />,
  },
  {
    permissionId: "view_appointments",
    path: "",
    element: <Appointments />,
  },
];
