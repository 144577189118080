import { gql } from "@apollo/client";

export const EDIT_LIST_ROLES = gql `query ListRoles {
  listRoles {
    id
    createdAt
    updatedAt
    isDeleted
    name
    companyId
    permissions
    isDefault
    parentRoleId
  }
}`