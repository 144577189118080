import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2)
        },
        container: {
            [theme.breakpoints.up("md")]: {
                width: "80%"
            }
        },
        feedback: {
            marginTop: theme.spacing(2),
            textTransform: "capitalize"
        },
        title: {
            fontSize: 14
        },
        error: {
            textTransform: "capitalize"
        },
        button: {
            color: "white"
        },
        buttonWrapper: {
            position: "relative"
        }
    })
);
