import { gql } from "@apollo/client";

export const EDIT_BUSINESS_EMAIL_LIST = gql`
mutation UpdateCustomerEmailList($input: UpdateCompanyEmailListInput!) {
    updateCustomerEmailList(input: $input) {
      companyEdge {
        node {
          contactEmailList
          id
        }
      }
    }
  }
`;
