import * as React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./stytle";
import { types } from "../../../../utils/CompanyTypes";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../modal/Modal";
import { useState } from "react";
import Switch from "@material-ui/core/Switch";
import { CHANGE_COMPANY_STATUS } from "adapters/mutations/ChangeCompanyStatus";
import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";

export default function ClientItem({ company }: any) {
  const classes = useStyles();
  const [checked, setChecked] = useState([0]);

  const [modalOpen, setModal] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    if (company.id) {
      setModal(event?.currentTarget!);
    }
  };
  const t = parseInt(company?.type);

  const [companyStatus, setCompanyStatus] = useState(company?.status);

  const [mutate] = useMutation(CHANGE_COMPANY_STATUS, {
    onCompleted: () => {
      console.log("mutation successfull");
    },
  });

  const handleStatusToggle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStatus = event.target.checked ? "active" : "inactive";
    setCompanyStatus(newStatus);
    try {
      await mutate({
        variables: {
          input: {
            companyId: company.id,
            status: newStatus,
          },
        },
      });
    } catch (error) {
      console.error("Error changing company status:", error);
    }
  };

  const handleUpdate = () => {
    console.log("successfull");
    navigate("/login");
  };

  return (
    <ListItem
      className={classes.listItem}
      key={company?.id}
      button
      onClick={handleToggle(company?.id)}
    >
      <Grid container item direction="row">
        <Grid item md={3} sm={5} xs={11}>
          <Typography className={classes.outerColumn}>
            {company?.name}
          </Typography>
        </Grid>

        <Grid item md={3} sm={5} xs={11}>
          <Typography className={classes.outerColumn}>{types[t]}</Typography>
        </Grid>

        <Grid item md={2} sm={5} xs={11}>
          <Switch
            checked={companyStatus === "active"}
            onChange={handleStatusToggle}
            color="primary"
            name="company-status-switch"
            inputProps={{ "aria-label": "company status switch" }}
          />
        </Grid>

        <Grid item md={3} sm={5} xs={11}>
          <Typography className={classes.outerColumn}>
            {company?.subscriptionStatus === "inactive" &&
            company?.pgwSubscriptionPlanId === "enterprise" ? (
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "80px" }}
                onClick={handleUpdate}
              >
                Complete Enterprise Subscription
              </Button>
            ) : (
              company?.pgwSubscriptionPlanId
            )}
          </Typography>
        </Grid>
        <Grid item md={1} sm={2} xs={1}>
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Actions"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={`/admin/businesses/edit/${company?.id}`}
              >
                View
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={`/admin/user/${company?.id}`}
              >
                Users
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={`/admin/payments/${company?.id}`}
              >
                Payments
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={`/admin/agent-sessions/${company?.id}`}
              >
                Calls
              </MenuItem>

              <MenuItem
                onClick={handleStatus}
                aria-label="Status"
                aria-haspopup="true"
              >
                {company?.status === "active" ? "Deactivate" : "Activate"}
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
      <Modal
        id={company?.id!}
        el={modalOpen}
        onClose={setModal}
        company={company!}
      />
    </ListItem>
  );
}
