import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
mutation CreateCustomer($input: CreateCustomerInput!) {
  createCustomer(input: $input) {
    email
    id
    firstName
    lastName
    createdAt
  }
}
`