import { gql } from "@apollo/client";

export const LIST_BUSINESS_ADMIN_CALL_FOR_HELP = gql`
    fragment ListAccount_botprofiles on Query {
        botprofiles(first: $count, after: $cursor) {
            edges {
                node {
                    id
                    name
                    description
                    priority
                    isAppointment
                    isBotInterectionsOn
                    isWhatsappRequired
                    isChannelSwitchingOn
                    isCallbackOn
                    isRaiseTicketRequired
                    isWebRTCVideoRequired
                    isAgentRequired
                    isScreenSharingRequired
                    isForwardToPhoneRequired
                    forwardNumber
                    callRecording
                    languages
                    primaryLanguage
                    createdAt
                    updatedAt
                    profilePicture
                    status
                    companyId
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;
