import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100%",
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3)
        },
        rootButton: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%"
        },
        botPrfoileCard: {
            height: "100%"
        },
        chip: {
            margin: theme.spacing(1)
        },
        gridHeading: {
            color: "#263238",
            fontWeight: 500,
            borderBottom: "1px solid rgb(224, 224, 224)"
        },
        gridList: {
            [theme.breakpoints.up("md")]: {
                width: "80%"
            }
        },
        gridEmptyRecords: {
            padding: theme.spacing(4),
            textAlign: "center",
            color: "#263238"
        }
    })
);
