import { Card, Box, Divider, useMediaQuery, Grid } from '@mui/material';
import Page from 'components/page/Page';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/rootReducer';
import CustomersList from '../customers_list/CustomersList';
import ChatHeader from '../chat_header/ChatHeader';
import MessageItem from '../message_item/message_item';
import InputBox from '../Input_box/InputBox';
import { useCustomerMessages } from '../custom_hooks/useCustomerMessages';
import useStyles from '../customers_list/style';
import { useEffect, useRef } from 'react';

export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  botId: string;
  companyId: string;
  isChattingToAgent?: boolean
}

export interface Message {
  text: string;
  timestamp: string;
  sender: 'bot' | 'customer' | 'agent';
  buttons?: Button[];
}

export type Button = {
  payload: string;
  title: string;
};

const LiveConversation = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const { messages, sendMessage, setSelectedCustomer, selectedCustomer } = useCustomerMessages();
  const classes = useStyles({ isSmallScreen });
  const scrollViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = (message: string) => {
    sendMessage(message, user);
  };

  const handleSelectCustomer = (customer: Customer) => {
    setSelectedCustomer(customer);
  };

  return (
    <Page title="Live Conversations">
      <Grid container spacing={1} padding={1} sx={{ height: '85vh', display: 'flex', marginBottom: 20 }}>
        {!isSmallScreen || !selectedCustomer ? (
          <CustomersList isSmallScreen={isSmallScreen} onSelectCustomer={handleSelectCustomer} />
        ) : null}

        {selectedCustomer ? (
          <Grid item md={8} sm={12} style={{ display: 'flex', height: '85vh' }}>
            <Card className={classes.chatCard} style={{ width: "100%" }}>
              <ChatHeader selectedCustomer={selectedCustomer} />
              <Divider sx={{ marginLeft: 2, marginRight: 2 }} />
              <Box className={classes.messageBox} ref={scrollViewRef}>
                {messages[selectedCustomer.id]?.map((msg, index) => (
                  <MessageItem msg={msg} customer={selectedCustomer} key={index + selectedCustomer.id} />
                ))}
              </Box>
              <InputBox handleSendMessage={handleSendMessage} />
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </Page>
  );
};

export default LiveConversation;
