import AddOnList from "modules/addons/List/List";
import { ModuleRouteConfig } from "common/types/RouteTypes";

export const AddOnRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "view_addons",
    path: "",
    element: <AddOnList />,
  },
];
