import { useQuery } from '@apollo/client';
import { COMPANY_BUSINESS_ADMIN_VIEW } from 'modules/users/adapters/queries/CompanyBusinessAdminView';
import { GET_PAYMENT_STATUS } from 'adapters/queries/GetPaymentStatus';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/rootReducer';
import { CommonAppBarAlertContext } from './CommonAppBarAlert';

export default function CheckSubscription(props: any): JSX.Element {
  const { user } = useSelector((state: RootState) => state.auth);
  const { setAlert } = useContext(CommonAppBarAlertContext);
  const { data } = useQuery(GET_PAYMENT_STATUS);
  const { data: company } = useQuery(COMPANY_BUSINESS_ADMIN_VIEW, {
    variables: {
      id: user?.companyId
    }
  })

  useEffect(() => {
    if (user?.company?.subscriptionStatus == 'inactive') {
      setAlert({
        message: "Please complete subscription setup to use your account",
        severity: 'warning',
        open: true,
        showAction: user?.role == "owner"
      })
    }
  }, [user]);

  useEffect(() => {
    if (data && data.lastPaymentStatus.label == "failed") {
      setAlert({
        message: "Last payment failed! Please verify your payment method.",
        severity: 'error',
        open: true,
        showAction: user?.role == "owner"
      })
    }
  }, [data])

  useEffect(() => {
    if (company && company.company.consumedMinutes >= 0.8 * company.company.maxAllowedMinutes) {
      setAlert({
        message:
          "Your Account is reaching its Monthly allocated Guidizy minutes. Please upgrade your plan to continue using without interruption",
        severity: "warning",
        open: true,
        showAction: user?.role == "owner",
      });
    }
    if (company && company.company.consumedMinutes >= company.company.maxAllowedMinutes) {
      setAlert({
        message:
          "All the allocated Guidizy Minutes are used. Please upgrade your plan or wait till next billing month to get your full monthly allocated Minutes. Your customers won’t be able to use Widgets for Audio, Video Calls",
        severity: "warning",
        open: true,
        showAction: user?.role == "owner",
      });
    }
  }, [company?.company.consumedMinutes]);

  return <>{props.children}</>;
}
