import { Components, Palette, PaletteOptions, Theme, createTheme as createMuiTheme } from '@mui/material';
import { createPalette } from './create-palette';
import { createComponents } from './create-components';
import { createShadows } from './create-shadows';
import { createTypography } from './create-typography';
import { Shadows } from '@material-ui/core/styles/shadows';
import { TypographyOptions } from '@mui/material/styles/createTypography';


export function createTheme() {
  const palette: PaletteOptions = createPalette();
  const components: Components<Omit<Theme, 'components'>> = createComponents({ palette });
  const shadows: Shadows = createShadows();
  const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = createTypography();

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440
      }
    },
    components,
    palette,
    shadows,
    shape: {
      borderRadius: 8
    },
    typography
  });
}
