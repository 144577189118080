
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/rootReducer';

export const useHasAnyPermission = (pageId: string): boolean => {
  const { permissions } = useSelector((state: RootState) => state.auth);

  return permissions[pageId] && permissions[pageId].length > 0;
};

export const useHasItemPermission = (pageId: string, permissionId: string): boolean => {
  const { permissions } = useSelector((state: RootState) => state.auth);

  return permissions[pageId] && permissions[pageId].includes(permissionId);
};
