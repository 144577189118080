import { gql } from "@apollo/client";

export const EDIT_BUSINESS_ADMIN_USER_DATA = gql`
    fragment EditAccountUser_data on User {
        id
        email
        firstName
        lastName
        role
        roleId
        phone
        status
        managerId
        companyId
        manager {
            id
            firstName
            email
        }
        emailConfirmed
        profilePicture
    }
`;
