import { useContext, useEffect, useState } from "react";
import Page from "../../../../components/page/Page";
import { useStyles } from "./style";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import EditRoles from "superadmin/modules/subscriptions/components/edit/EditRoles";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { Subscription } from "../models/subscription";
import { GET_SUBSCRIPTION } from "superadmin/modules/subscriptions/adapters/queries/GetSubscriptionDetails";
import { useParams } from "react-router-dom";
import { EDIT_SUBSCRIPTION } from "superadmin/modules/subscriptions/adapters/mutations/EditSubscription";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

export default function SubscriptionsEditPage() {
  const classes = useStyles();
  const { id } = useParams();
  const { setSnack } = useContext(SnackbarContext);
  const [isRolesExpanded, setIsRolesExpanded] = useState(false);
  const [isSubscriptionExpanded, setIsSubscriptionExpanded] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Subscription>({
    defaultValues: {
      id,
      name: "",
      agents: 0,
      botProfiles: 0,
      minutes: 0,
      trialDays: 0,
      price: 0,
      isPublic: false,
    },
  });

  // Fetch subscription details and reset form with fetched data (example with dummy data)
  const { data: subscriptionData, refetch: subscriptionRefetch } = useQuery<{
    retrieveSubscription: Subscription;
  }>(GET_SUBSCRIPTION, {
    variables: {
      input: {
        id,
      },
    },
  });

  const [updateSubscription] = useMutation(EDIT_SUBSCRIPTION, {
    onCompleted: () => {
      setSnack({
        message: "Subscription Updated Successfully",
        severity: "success",
        open: true,
      });
      subscriptionRefetch();
    },
  });

  useEffect(() => {
    document.title = "Subscriptions";
    reset(subscriptionData?.retrieveSubscription); // Use actual fetched data here
  }, [reset, subscriptionData]);

  const handleCardClick =
    (setter: React.Dispatch<React.SetStateAction<boolean>>, current: boolean) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const target = event.target as Element;
      if (target === event.currentTarget || target.closest(".card-header")) {
        setter(!current);
      }
    };

  const onSubmit = (data: Subscription) => {
    // Add mutation or API call to update subscription details here
    const {
      id,
      name,
      agents,
      botProfiles,
      minutes,
      trialDays,
      price,
      isPublic,
    } = data;
    updateSubscription({
      variables: {
        input: {
          id,
          name,
          agents,
          botProfiles,
          minutes,
          trialDays,
          price,
          isPublic,
        },
      },
    });
  };

  return (
    <Page title="Modify Subscription">
      <Container className={classes.container}>
        <Typography
          variant="h5"
          sx={{ paddingY: "1rem", paddingX: "1rem" }}
          className={classes.title}
        >
          Subscription Details
        </Typography>

        {/* Subscription Details Card */}
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <Card
            className={classes.card}
            onClick={handleCardClick(
              setIsSubscriptionExpanded,
              isSubscriptionExpanded
            )}
          >
            <CardHeader
              title={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">Edit Subscription</Typography>
                  <Box className={classes.expandCollapseButton}>
                    <Typography
                      className="hover-text"
                      sx={{ color: "#862f98" }}
                    >
                      {isSubscriptionExpanded ? "Collapse" : "Expand"}
                    </Typography>
                  </Box>
                </Box>
              }
              subheader="Modify subscription details"
              className={`card-header ${classes.cardHeader}`}
              titleTypographyProps={{
                className: classes.cardTitle,
              }}
              sx={{ padding: "16px 24px" }}
              subheaderTypographyProps={{
                className: classes.cardSubheader,
              }}
            />
            <Divider />
            {isSubscriptionExpanded && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{ padding: "16px 24px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Subscription name is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Subscription Name"
                            fullWidth
                            error={!!errors.name}
                            helperText={errors.name?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="agents"
                        control={control}
                        rules={{ required: "Number of agents is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Number of Agents"
                            type="number"
                            fullWidth
                            error={!!errors.agents}
                            helperText={errors.agents?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="botProfiles"
                        control={control}
                        rules={{
                          required: "Number of bot profiles is required",
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Bot Profiles"
                            type="number"
                            fullWidth
                            error={!!errors.botProfiles}
                            helperText={errors.botProfiles?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="minutes"
                        control={control}
                        rules={{ required: "Minutes is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Minutes"
                            type="number"
                            fullWidth
                            error={!!errors.minutes}
                            helperText={errors.minutes?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="price"
                        control={control}
                        rules={{ required: "Price is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Price"
                            fullWidth
                            error={!!errors.price}
                            helperText={errors.price?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="trialDays"
                        control={control}
                        rules={{ required: "Trial Days is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Trial Days"
                            fullWidth
                            error={!!errors.trialDays}
                            helperText={errors.trialDays?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="minutes"
                        control={control}
                        rules={{ required: "Minutes is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Price"
                            fullWidth
                            error={!!errors.minutes}
                            helperText={errors.minutes?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary">
                        Save Subscription
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Card>
        </Grid>

        {/* Roles Card */}
        <Grid item xs={12}>
          <Card
            className={classes.card}
            onClick={handleCardClick(setIsRolesExpanded, isRolesExpanded)}
          >
            <CardHeader
              title={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">Roles</Typography>
                  <Box className={classes.expandCollapseButton}>
                    <Typography
                      className="hover-text"
                      sx={{ color: "#862f98" }}
                    >
                      {isRolesExpanded ? "Collapse" : "Expand"}
                    </Typography>
                  </Box>
                </Box>
              }
              subheader="Customize Subscription Roles"
              className={`card-header ${classes.cardHeader}`}
              titleTypographyProps={{
                className: classes.cardTitle,
              }}
              sx={{ padding: "16px 24px" }}
              subheaderTypographyProps={{
                className: classes.cardSubheader,
              }}
            />
            <Divider />
            {isRolesExpanded && (
              <div onClick={(e) => e.stopPropagation()}>
                <EditRoles />
              </div>
            )}
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
