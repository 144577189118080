// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*:after,
*:before {
    box-sizing: border-box;
    font: inherit;
    color: "inherit";
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;IAGI,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,YAAY;IACZ,aAAa;AACjB","sourcesContent":["*,\n*:after,\n*:before {\n    box-sizing: border-box;\n    font: inherit;\n    color: \"inherit\";\n    margin: 0;\n    padding: 0;\n    border: none;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
