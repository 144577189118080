import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import FormButton from "@mui/material/Button";
import { useStyles } from "../../pages/UsersEditPage/style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import PhoneInput from "components/form/phone_input/PhoneInput";
import { USER_BUSINESS_ADMIN_REFETCH } from "modules/users/adapters/queries/UserBusinessRefetch";
import { EDIT_BUSINESS_ADMIN_USER } from "adapters/mutations/EditBusinessAdminUser";
import { COMPANY_BUSINESS_ADMIN_VIEW } from "modules/users/adapters/queries/CompanyBusinessAdminView";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { updateUser } from "redux/actions/AuthActions";
import {
  CardActions,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
} from "@mui/material";
import { LIST_ROLES } from "adapters/queries/ListRoles";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  roleId: string;
  status: number;
  managerId: string;
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("first name is Required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9]+$/,
      "first name should contain Alpha Numeric characters"
    ),
  lastName: yup
    .string()
    .required("last name is Required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z0-9]+$/,
      "last name should contain Alpha Numeric characters"
    ),
  email: yup
    .string()
    .required()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "email is not valid"
    ),
  phone: yup
    .string()
    .required()
    .matches(phoneRegExp, "phone number is not valid"),
  role: yup.string().required(),
});

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

export default function EditForm({
  user,
  isMyProfile,
  viewOnly,
  isOwner,
}: {
  user: any;
  isMyProfile: boolean;
  viewOnly: boolean;
  isOwner: boolean;
}) {
  console.log("user", user);
  const active: number = 2;
  const inactive: number = 1;
  const classes = useStyles();
  const navigate = useNavigate();
  const mode = user?.status === active ? true : false;
  const [status, setStatus] = useState(mode);
  const { setSnack } = useContext(SnackbarContext);
  const { user: cUser } = useSelector((state: RootState) => state.auth);
  const { data: roles } = useQuery(LIST_ROLES);
  const dispatch = useDispatch();

  const defaultValues = {
    ...user,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [mutate, { loading, error }] = useMutation(EDIT_BUSINESS_ADMIN_USER, {
    onCompleted: (data) => {
      setSnack({
        message: "Record updated successfully",
        severity: "success",
        open: true,
      });
      if (!isMyProfile) {
        navigate(cUser?.role == "admin" ? "/admin/users" : "/users");
      }
      if (isMyProfile) {
        dispatch(updateUser({ ...data.updateUser.userEdge.node }));
      }
    },
    onError: () => {
      console.log(error);
      setSnack({
        message: "Editing user failed",
        severity: "error",
        open: true,
      });
    },
  });

  const { data, refetch: managerRefetch } = useQuery(
    USER_BUSINESS_ADMIN_REFETCH,
    { variables: { roleId: user.managerId } }
  );

  const roleId = watch("roleId");

  // Used for querying parent role users
  const roleParentMap = useMemo(() => {
    if (!roles || !roles.listRoles) return {}; // Handle missing roles safely

    return roles.listRoles.reduce((acc: Record<string, string>, role: any) => {
      if (role.id && role.parentRoleId) {
        // Check for valid properties
        acc[role.id] = role.parentRoleId;
      }
      return acc;
    }, {});
  }, [roles]);

  const { users } = data || {};
  const managers = useMemo(() => {
    return users && users.edges
      ? users.edges
          .filter(isNotNull)
          .map((edge: any) => edge.node)
          .filter(isNotNull)
      : [];
  }, [users]);

  useEffect(() => {
    const parentRoleId = roleParentMap[roleId];
    if (parentRoleId) {
      managerRefetch({ roleId: parentRoleId });
    }
  }, [roleId, roleParentMap, managerRefetch]);

  const onSubmit = async ({
    firstName,
    lastName,
    roleId,
    phone,
    managerId,
  }: User) => {
    if (!status) {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/api/conferenceService/deactivate_agent`;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            agentId: user?.id,
            agentCompanyId: user?.companyId,
          },
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    mutate({
      refetchQueries: [
        { query: USER_BUSINESS_ADMIN_REFETCH },
        { query: COMPANY_BUSINESS_ADMIN_VIEW },
      ],
      variables: {
        input: isMyProfile
          ? {
              id: user?.id,
              firstName: firstName,
              lastName: lastName,
              phone: phone,
            }
          : {
              id: user?.id,
              firstName: firstName,
              lastName: lastName,
              roleId: roleId,
              role: roles?.listRoles.find((role: any) => role.id === roleId)
                ?.name,
              phone: phone,
              managerId,
              status: status ? active : inactive,
            },
      },
    });
  };

  const maxSize = 15;
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                disabled={viewOnly}
                control={control}
                type="text"
                name="firstName"
                label="First Name*"
                inputProps={{ maxLength: maxSize }}
                defaultValue={user?.firstName}
                error={errors && errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                disabled={viewOnly}
                control={control}
                type="text"
                name="lastName"
                label="Last Name*"
                defaultValue={user?.lastName}
                error={errors && errors.lastName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormInput
                disabled
                control={control}
                type="email"
                name="email"
                label="Email"
                defaultValue={user?.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <PhoneInput
                disabled={viewOnly}
                control={control}
                name="phone"
                error={errors && errors.phone}
              />
            </Grid>

            {!isMyProfile && (
              <Grid item xs={12} md={6}>
                <FormInput
                  disabled={viewOnly}
                  control={control}
                  type="text"
                  name="roleId"
                  label="Role"
                  select="select"
                  defaultValue={user?.roleId}
                  error={errors && errors?.role}
                >
                  {roles?.listRoles.map((role: any) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </FormInput>
              </Grid>
            )}

            {!isMyProfile && isOwner && (
              <Grid item xs={12} md={6}>
                <FormInput
                  disabled={viewOnly}
                  control={control}
                  type="text"
                  name="managerId"
                  label="Manager"
                  select="select"
                  defaultValue={user?.managerId}
                >
                  {managers
                    .map((item: User) => {
                      return {
                        value: item.id,
                        label: item.firstName + " " + item.lastName,
                      };
                    })
                    .map((option: { value: string; label: string }) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={option.value == user?.id}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </FormInput>
              </Grid>
            )}

            {!isMyProfile && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={viewOnly}
                    control={
                      <Switch
                        checked={status}
                        onChange={(event: any) => {
                          setStatus(event.target.checked);
                        }}
                        color="primary"
                        name="statusform"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            )}

            {error ? (
              <FormFeedback className={classes.feedback} error>
                {error.message}
              </FormFeedback>
            ) : null}
          </Grid>
        </CardContent>
        {!viewOnly && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ paddingX: "1rem" }}
          >
            <CardActions>
              <div className={classes.buttonWrapper}>
                <FormButton
                  className={classes.button}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Update Record
                </FormButton>

                {loading && (
                  <CircularProgress className={classes.buttonProgress} />
                )}
              </div>
            </CardActions>
          </Stack>
        )}
      </form>
    </div>
  );
}
