import { gql } from "@apollo/client";

export const RESET_PASSWORD_ADMIN_USER = gql`
    mutation ResetPasswordAdminUserMutation(
        $input: ResetPasswordInput!
    ) {
        resetPassword(input: $input) {
            id
        }
    }
`;
