import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: "none"
    },
    text: {
        fontFamily: "Poppins"
    },
    signIn: {
        fontFamily: "Poppins",
        fontWeight: "bold",
        textAlign: "center",
        paddingBottom: "2%"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        fontFamily: "Poppins",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.14)",
        borderRadius: "10px",
        marginTop: "10%",
        padding: "2%",
        maxWidth: "100%",
        margin: "auto",
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    footerText: {
        fontSize: "15px",
        fontFamily: "Poppins"
    },
    logo: {
        width: "100%"
    },
    btnWrapper: {
        paddingTop: "5%",
        paddingBottom: "3%"
    },
    footer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    loginContainer: {
        padding: "50px",
        height: '600px', // Adjust the height to your desired value
        display: 'flex',
        flexDirection: 'column',
    },
    imageContainer: {
        height: "100vh",
        width: "100%",
        alignItems: "center"
    },
    gridItem: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    gridContainer: {
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        display: "flex",
    },
    "@media (min-width: 600px) and (max-width: 960px)": {},
    "@media (min-width: 960px) and (max-width: 1280px)": {
        container: {
            display: "flex",
            flexDirection: "row",
            marginTop: "10%",
            padding: "5%",
            maxWidth: "75%",
            margin: "auto"
        },
        logo: {
            width: "80%"
        },
        footer: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "left"
        }
    },
    "@media (min-width: 1280px)": {
        container: {
            display: "flex",
            flexDirection: "row",
            marginTop: "10%",
            padding: "5%",
            maxWidth: "75%",
            margin: "auto"
        },
        footer: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "left"
        },
        logo: {
            width: "80%"
        }
    }
}));
