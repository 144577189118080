import { useContext, useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { useMutation } from "@apollo/client";

import Page from "../../components/page/Page";
import logo from "../../assets/images/guidizy-logo.png";
import { useStyles } from "./style";
import { VERIFY_SIGNUP } from "adapters/mutations/VerifySignup";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { CardActions, CircularProgress, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";

export default function AccountActivation() {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [stripeSessionUrl, setStripeSessionUrl] = useState("")

  useEffect(() => {
    document.title = "Account Activation";
  }, []);

  const { token } = useParams();
  useEffect(() => {
    mutate({
      variables: {
        token: token,
      },
    });
  }, []);

  const [mutate] = useMutation(VERIFY_SIGNUP, {
    onCompleted: ({ verifySignUp }) => {
      setStripeSessionUrl(verifySignUp.url)
      setIsSuccess(true)
      setIsLoading(false)
      // navigate("/account/verified");
    },
    onError: (error) => {
      console.log(error);
      setIsSuccess(false)
      setIsLoading(false);
      setSnack({ message: "Activation failed", severity: 'error', open: true });
    },
  });

  const handleClick = () => {
    if (stripeSessionUrl) {
      window.location.href = stripeSessionUrl;
    } else {
      window.location.href = "/logout"
    }
  };

  if (isLoading) {
    return (
      <Page title="Message">
        <Container maxWidth="xs">
          <Box p={1} m={1} justifyContent="center" textAlign="center">
            <img src={logo} alt="Guidizy logo" className={classes.logo} />
          </Box>
          <Card raised>
            <CardHeader title="Activating Account" />
            <Divider />
            <CardContent>
              <Box justifyContent="center" textAlign="center">
                <CircularProgress className={classes.buttonProgress} />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Page>
    )
  }

  return (
    <Page title="Message">
      <Container maxWidth="xs">
        <Box p={1} m={1} justifyContent="center" textAlign="center">
          <img src={logo} alt="Guidizy logo" className={classes.logo} />
        </Box>
        <Card raised>
          <CardHeader title={isSuccess ? "Acount Activated" : "Account Activation Failed"} />
          <Divider />
          <CardContent>
            <Typography component="div" variant="body2">
              {" "}
              {isSuccess ? 'Your account has been successfully activated. You may close this window' : 'Account activation failed. Please try again in some time.'}
            </Typography>
          </CardContent>
          {isSuccess && <Divider />}
          {isSuccess && <CardActions>
            <Button onClick={handleClick} color="primary" fullWidth>
              {`Continue ${stripeSessionUrl ? 'with Subscription' : ''}`}
            </Button>
          </CardActions>}
        </Card>
      </Container>
    </Page>
  );
}
