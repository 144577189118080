import clsx from "clsx";
import { useStyles } from "./style";
import FormInput from "components/form/form_input_search/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
// import { useEffect, useMemo } from "react";
import FormButton from "@mui/material/Button";
import { Clear, Search } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, MenuItem, Card, Stack } from "@mui/material";

export interface SearchQuery {
  searchText: string;
  status: string;
}

export enum AppointmentStatus {
  ALL = " ",
  PENDING = "request",
  CONFIRMED = "accepted",
  ASSIGNED = "assigned",
  REJECTED = "rejected",
  EXPIRED = "expired",
}

export const AppointmentsPlaceHolders = (status: string): string => {
  const placeholder = {
    request: "Pending",
    accepted: "Confirmed",
    assigned: "Assigned",
    rejected: "Rejected",
    expired: "Expired",
  }[status];

  return placeholder || "";
};

interface IProps {
  className?: string;
  onSearch: (query: SearchQuery) => void;
  role?: string;
  appointmentStatus?: string;
}

const schema = yup.object().shape({});

const Searchbar = ({
  className,
  onSearch,
  appointmentStatus,
  ...rest
}: IProps) => {
  const classes = useStyles();

  const statuses = [
    {
      value: AppointmentStatus.ALL,
      label: "All",
    },
    {
      value: AppointmentStatus.PENDING,
      label: "Pending",
    },
    {
      value: AppointmentStatus.CONFIRMED,
      label: "Confirmed",
    },
    {
      value: AppointmentStatus.ASSIGNED,
      label: "Assigned",
    },
    {
      value: AppointmentStatus.REJECTED,
      label: "Rejected",
    },
    {
      value: AppointmentStatus.EXPIRED,
      label: "Expired",
    },
  ];

  const defaultValues = {
    status: appointmentStatus ? appointmentStatus : statuses[0].value,
    searchText: "",
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onClear = () => {
    reset(
      { ...getValues(), searchText: "" },
      {
        keepDefaultValues: true,
        keepValues: false,
      }
    );
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (appointmentStatus) {
      reset(
        { searchText: "", status: appointmentStatus },
        {
          keepDefaultValues: true,
          keepValues: false,
        }
      );
      handleSubmit(onSubmit)();
    }
  }, [appointmentStatus]);

  const onSubmit = ({ status, searchText }: SearchQuery) => {
    onSearch({ status, searchText });
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Card sx={{ p: 2 }}>
        <Grid container item direction="row" alignItems="center" spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <FormInput
              control={control}
              type="text"
              name="searchText"
              label="Search by name, email or date-time"
              error={errors && errors.searchText}
              InputProps={{
                endAdornment: getValues().searchText ? (
                  <InputAdornment position="end">
                    <IconButton onClick={onClear} size="small">
                      <Clear color="action" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <FormInput
              control={control}
              type="text"
              name="status"
              label="Status"
              select="select"
              defaultValue={statuses[0].value}
            >
              {statuses.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormInput>
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <FormButton
              className={classes.button}
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              startIcon={<Search />}
            >
              Search
            </FormButton>
            </Stack>
          </Grid>
        </Grid>
        </Card>
      </form>
    </div>

    
  );
};

export default Searchbar;
