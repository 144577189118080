import { gql } from "@apollo/client";

export const MODAL_REJECT_BUSINESS_ADMIN_APPOINTMENT = gql`
    mutation ModalRejectAccountAppointmentMutation(
        $input: AcceptInput!
    ) {
        reject(input: $input) {
            appointmentEdge {
                node {
                    id
                }
            }
        }
    }
`;
