import { ReactNode } from "react";
import { Controller } from "react-hook-form";
import { Typography } from "@mui/material";
// import FormField from "@material-ui/core/TextField";
import { useStyles } from "./style";
import PhoneInput from 'react-phone-input-2'
import './material.css'
interface FormInputProps {
  id?: string;
  control: any;
  error?: any;
  name: string;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  select?: any;
  children?: ReactNode;
  inputProps?: any;
  defaultValue?: any;
  InputLabelProps?: any;
  helperText?: string;
  inputRef?: any;
  size?: any;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  SelectProps?: any;
  value?: any;
  className?: string;
  style?: any;
}
export default function FormInput({
  control,
  error,
  name,
  // type,
  // placeholder,
  // autoComplete,
  // label,
  // select,
  // children,
  // defaultValue,
  // InputLabelProps,
  inputProps,
  // helperText,
  // inputRef,
  // size,
  // required,
  // SelectProps,
  // value,
  disabled,
  // className,
  // style,
}: FormInputProps) {
  const classes = useStyles();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <PhoneInput
            disabled={disabled}
            enableSearch
            inputProps={inputProps}
            inputStyle={{
              width: '100%'
            }}
            containerStyle={{
              marginBottom: '0px'
            }}
            {...field}
          />
        )}
      />
      {error && (
        <Typography className={classes.errorMessage}>
          {error.message}
        </Typography>
      )}
    </>
  );
}
