import { useState, useEffect } from "react";
import { useSocket } from "context/SocketProvider";
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Avatar, DialogActions, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { redirect} from "react-router-dom";
// @ts-ignore
import notificationTone from '../../assets/audio/notification.wav';

function CustomerAlert() {
    const { socket } = useSocket();
    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [audioAllowed, setAudioAllowed] = useState(false);
    const audio = new Audio(notificationTone);
    // const navigate = useNavigate(); // For navigation

    useEffect(() => {
        const enableAudio = () => {
            setAudioAllowed(true);
            document.removeEventListener('click', enableAudio);
            document.removeEventListener('keydown', enableAudio);
        };

        // Listen for the first user interaction
        document.addEventListener('click', enableAudio);
        document.addEventListener('keydown', enableAudio);

        return () => {
            document.removeEventListener('click', enableAudio);
            document.removeEventListener('keydown', enableAudio);
        };
    }, []);

    useEffect(() => {
        if (socket?.connected) {
            socket.on('userUpdated', (messages: any) => {
                const message = messages?.message;
                const customerId = messages?.customerId;

                console.log("message", message);
                if (message === "Customer got connected") {
                    console.log("agent connected");
                    if (audioAllowed) {
                        audio.play().catch((error) => {
                            console.error("Audio play failed:", error);
                        });
                    }
                    setDialogMessage("Customer got connected");
                    setCustomerId(customerId);
                    setOpen(true);
                }
            });
        }

        return () => {
            if (socket?.connected) {
                socket.off('userUpdated');
            }
        };
    }, [socket, audioAllowed]);

    const handleReject = () => {
        setOpen(false);
    };

    const handleAccept = () => {
        // Redirect to another path, e.g., "/customer-details"
        redirect(`/live-conversations`);
        window.location.href = `/live-conversations`
        console.log("customerId",customerId)
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleReject} sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '350px', padding: '20px' } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                    Notification
                    <IconButton
                        aria-label="close"
                        onClick={handleReject}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                    <Tooltip title={`ID: ${customerId}`} placement="top" arrow>
                        <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>
                            {customerId?.slice(0, 2).toUpperCase()}
                        </Avatar>
                    </Tooltip>
                    <div>{dialogMessage}</div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
                    <Button onClick={handleReject} variant="outlined" color="secondary">
                        Ignore
                    </Button>
                    <Button onClick={handleAccept} variant="contained" color="primary" sx={{ marginLeft: '10px' }}>
                        View
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CustomerAlert;
