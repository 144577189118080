import { ModuleRouteConfig } from "common/types/RouteTypes";
import EventLog from "modules/event_log/pages/EventLogPage";

export const EventLogRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "view_event_log",
    path: "",
    element: <EventLog />,
  },
];
