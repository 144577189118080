//@ts-nocheck
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button"
import { DialogActions,DialogTitle, DialogContent } from "@material-ui/core";

function Modal({ title, content, isOpen, handleClose, handleConfirm }: Props) {

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">Close</Button>
            <Button onClick={handleConfirm} variant="contained" color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


export default Modal