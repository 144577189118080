import { useState, useEffect } from "react";

import Divider from "@mui/material/Divider";
import Page from "../../../../components/page/Page";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Modal,
  Button,
  Avatar,
  Card,
  Grid,
  CardHeader,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
import CircularProgress from "../../../../components/facebook_progress/FacebookProgress";
import { useStyles } from "./style";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import EditForm from "./EditForm";
import { BOT_PROFILE_PICTURE } from "modules/bot_profiles/adapters/mutations/BotProfilePicture";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import { CALL_FOR_HELP_BUSINESS_ADMIN_EDIT } from "modules/bot_profiles/adapters/queries/CallForHelpBusinessAdminEdit";
import { GET_BOT_PROFILE_ASSET } from "modules/bot_profiles/adapters/queries/GetBotProfileAsset";

export default function EditCallForHelpProfile() {
  const classes = useStyles();
  let { id } = useParams();
  const { data, loading } = useQuery(CALL_FOR_HELP_BUSINESS_ADMIN_EDIT, {
    variables: { id },
  });

  const botProfile = data?.botprofile;

  const [avatar, setAvatar] = useState<string | null>(null);
  const [botProfilePicture] = useMutation(BOT_PROFILE_PICTURE);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  //const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(() => {
    document.title = "Edit Bot Profile";
  }, []);

  const handleAvatarSelect = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        alert("File size exceeds 2 MB limit. Please upload a smaller file.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        //setPreviewImage(reader.result as string);
        setAvatar(reader.result as string);
        setAvatarFile(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleAvatarClick = (imageUrl: string) => {
    setPreviewImageUrl(imageUrl);
    setIsPreviewOpen(true);
  };

  const [profileUrl, setProfileUrl] = useState("");

  const [getBotProfileAsset] = useLazyQuery(GET_BOT_PROFILE_ASSET, {
    onCompleted: (data) => {
      if (data && data.getBotProfileAsset) {
        setProfileUrl(data.getBotProfileAsset.url);
      }
    },
    onError: (error) => {
      console.log("Profile Asset Error", error);
    },
  });

  useEffect(() => {
    if (botProfile?.profilePicture) {
      getBotProfileAsset({
        variables: {
          input: {
            key: botProfile?.profilePicture,
          },
        },
      });
    }
  }, [botProfile]);

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };
  const handleUploadButtonClick = () => {
    if (avatarFile) {
      uploadImage(avatarFile);
    } else {
      alert("Please select an image first.");
    }
  };

  const uploadImage = async (file: File) => {
    console.log(file);

    try {
      const { data } = await botProfilePicture({
        variables: {
          input: {
            botProfileId: id,
            profilePictureUpload: file,
          },
        },
      });
      console.log("Image uploaded successfully:", data);
      setUploadSuccess(true);
      setSnackMessage("Image uploaded successfully");
      setSnackOpen(true);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  if (loading) {
    return <CircularProgress size={15} />;
  }
  return (
    <Page title="Edit Bot Profile">
      <Container className={classes.container}>
        <Grid container spacing={0}>
          <Grid
            item
            md={4}
            xs={12}
            container
            direction="column"
            alignItems="center"
          >
            <Card className={classes.botDetailsCard}>
              <div onClick={() => handleAvatarClick(avatar || "")}>
                <Avatar
                  className={classes.avatarSize}
                  sx={{ width: 80, height: 80 }}
                >
                  {avatar ? (
                    <img src={avatar} alt="Avatar" />
                  ) : profileUrl ? (
                    <img src={profileUrl} alt="Avatar" />
                  ) : (
                    <Typography variant="h4">{botProfile?.name}</Typography>
                  )}
                </Avatar>
              </div>
              <label htmlFor="upload-avatar">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="upload-avatar"
                  type="file"
                  onChange={(e) => handleAvatarSelect(e.target.files)}
                />
                <IconButton component="span" className={classes.cameraIcon}>
                  <PhotoCameraIcon />
                </IconButton>
              </label>
              {uploadSuccess && (
                <Snackbar
                  open={snackOpen}
                  autoHideDuration={6000}
                  onClose={() => setSnackOpen(false)}
                  className={classes.snackbar}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => setSnackOpen(false)}
                    severity="success"
                  >
                    {snackMessage}
                  </MuiAlert>
                </Snackbar>
              )}
              {
                <div style={{ marginTop: "1rem" }}>
                  <Button variant="contained" onClick={handleUploadButtonClick}>
                    Upload
                  </Button>
                </div>
              }
            </Card>
          </Grid>
          <Grid item md={8} xs={12}>
            <Card>
              <CardHeader
                subheader={botProfile?.name}
                title="Modify Call Bot Profile Button"
              />
              <Divider />

              <Divider />
              {botProfile && <EditForm botProfile={botProfile} />}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={isPreviewOpen}
        onClose={handleClosePreview}
        aria-labelledby="image-preview-title"
        aria-describedby="image-preview-description"
      >
        <div className={classes.imagePreviewContainer}>
          <div className={classes.imagePreview}>
            <IconButton
              className={classes.closePreviewButton}
              onClick={handleClosePreview}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={previewImageUrl || ""}
              alt="Preview"
              className={classes.previewImage}
            />
          </div>
        </div>
      </Modal>
    </Page>
  );
}
