import { gql } from "@apollo/client";

export const MODAL_REJECT_BUSINESS_ADMIN_CALLBACK = gql`
    mutation ModalRejectAccountCallbackMutation(
        $input: RejectCallbackInput!
    ) {
        rejectCallback(input: $input) {
            callbackEdge {
                node {
                    id
                }
            }
        }
    }
`;
