import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import {
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Button,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material"; // Updated import to MUI
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, FieldError } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useStyles } from "./style";
import Page from "components/page/Page";
import FormInput from "components/form/form_input/FormInput";
import PhoneInput from "components/form/phone_input/PhoneInput";
import FormFeedback from "components/form_feedback/FormFeedback";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { LIST_ROLES } from "adapters/queries/ListRoles";
import { IOSSwitch } from "common/components/IOSSwitch";
import { USER_BUSINESS_ADMIN_REFETCH } from "modules/users/adapters/queries/UserBusinessRefetch";
import { USER_ADMIN_REFETCH } from "modules/users/adapters/queries/UserAdminRefetch";
import { ADD_BUSINESS_ADMIN_USER } from "modules/users/adapters/mutations/AddBusinessAdminUser";

interface UserFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: string;
  password: string;
  managerId?: string;
}

const phoneRegExp =
  /^(\+?[0-9]{1,4}[-.\s]?)?(\(?\d{2,3}\)?[-.\s]?)?\d{3,4}[-.\s]?\d{3,4}?$/;

const schema = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(
      /^[A-Za-z0-9]+$/,
      "First name should contain alphanumeric characters"
    ),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(
      /^[A-Za-z0-9]+$/,
      "Last name should contain alphanumeric characters"
    ),
  email: yup.string().required("Email is required").email("Email is not valid"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(15, "Password must be at most 15 characters")
    .matches(/[A-Za-z]/, "Password must contain at least one letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  roleId: yup.string().required("Role is required"),
  managerId: yup.string().required("Manager is required"),
});

const AddUser: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [status, setStatus] = useState(1);
  const [showManagerDropdown, setShowManagerDropdown] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const { user: cUser } = useSelector((state: RootState) => state.auth);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<UserFormData>({
    resolver: yupResolver(schema),
  });

  const { data: rolesData } = useQuery(LIST_ROLES);
  const [fetchDataByParentRole, { data: managers, refetch: managerRefetch }] =
    useLazyQuery(USER_BUSINESS_ADMIN_REFETCH);

  const roleId = watch("roleId");

  // Used for querying parent role users
  const roleParentMap = useMemo(() => {
    if (!rolesData || !rolesData.listRoles) return {}; // Handle missing rolesData safely

    return rolesData.listRoles.reduce(
      (acc: Record<string, string>, role: any) => {
        if (role.id && role.parentRoleId) {
          // Check for valid properties
          acc[role.id] = role.parentRoleId;
        }
        return acc;
      },
      {}
    );
  }, [rolesData]);

  const [addUser, { loading }] = useMutation(ADD_BUSINESS_ADMIN_USER, {
    onCompleted: () => {
      setSnack({
        message: "User added successfully",
        severity: "success",
        open: true,
      });
      navigate(cUser?.role === "admin" ? "/admin/users" : "/users");
    },
    onError: (err) => {
      console.error("Error creating user:", err);
      setSnack({
        message: "Create user failed",
        severity: "error",
        open: true,
      });
    },
    refetchQueries: [{ query: USER_ADMIN_REFETCH }],
  });

  useEffect(() => {
    document.title = "Add User";
  }, []);

  useEffect(() => {
    const parentRoleId = roleParentMap[roleId];
    if (parentRoleId) {
      managerRefetch({ roleId: parentRoleId });
    }
  }, [roleId, roleParentMap, managerRefetch]);

  const handleRoleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedRoleId = event.target.value as string;
      const parentRoleId = roleParentMap[selectedRoleId];

      if (parentRoleId) {
        fetchDataByParentRole({ variables: { roleId: parentRoleId } });
      }
      setShowManagerDropdown(true);
      setValue("managerId", undefined);
    },
    [roleParentMap, fetchDataByParentRole]
  );

  const onSubmit = (formData: UserFormData) => {
    addUser({
      variables: {
        input: {
          ...formData,
          status,
        },
      },
    });
  };

  return (
    <Page className={classes.root} title="Add User">
      <Container maxWidth="sm">
        <Card>
          <CardHeader title="Add User" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    control={control}
                    name="firstName"
                    label="First Name*"
                    error={errors.firstName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    control={control}
                    name="lastName"
                    label="Last Name*"
                    error={errors.lastName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    control={control}
                    name="email"
                    label="Email*"
                    type="email"
                    error={errors.email}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    control={control}
                    name="phone"
                    error={errors.phone}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                    control={control}
                    name="password"
                    label="Password*"
                    type="password"
                    autoComplete="new-password"
                    error={errors.password}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="role-label">Role*</InputLabel>
                    <Controller
                      control={control}
                      name="roleId"
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="role-label"
                          label="Role"
                          onChange={(e) => {
                            field.onChange(e);
                            handleRoleChange(e as SelectChangeEvent<string>);
                          }}
                        >
                          {rolesData?.listRoles.map((role: any) => (
                            <MenuItem
                              key={role.id}
                              value={role.id}
                              data-parentRoleId={role.parentRoleId}
                            >
                              {role.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                {showManagerDropdown && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="manager-label">Manager*</InputLabel>
                      <Controller
                        control={control}
                        name="managerId"
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="manager-label"
                            label="Manager"
                          >
                            {managers?.users.edges.map(({ node }: any) => (
                              <MenuItem key={node.id} value={node.id}>
                                {node.firstName} {node.lastName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={status === 2}
                        onChange={(e) => setStatus(e.target.checked ? 2 : 1)}
                        color="primary"
                        name="status"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        sx={{ mx: 1 }}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              {Object.values(errors).map((error, index) => (
                <FormFeedback key={index} className={classes.feedback} error>
                  {(error as FieldError)?.message}
                </FormFeedback>
              ))}
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Save User"}
                </Button>
              </div>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

export default AddUser;
