import { gql } from "@apollo/client";

export const LIST_BUSINESS_ADMIN_PAYMENTS = gql`
    fragment ListAccount_payments_1G22uz on Query {
        payments(first: $count, after: $cursor) {
            edges {
                node {
                    id
                    createdAt
                    planId
                    amount
                    pgwSubscriptionPlanId
                    status
                    company {
                        name
                        ccLast4
                        id
                    }
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
