import { gql } from "@apollo/client";

export const GET_RASA_BOT_CHAT = gql`
query GetBotChatFromChatroomID($chatRoomId: String!) {
  getBotChatFromChatroomID(id: $chatRoomId) {
    bot_messages {
      event
      text
      timestamp
      data
    }
    agent_messages {
      jid
      content
      timestamp
      name
      email
    }
  }
}
`