import { TICKET_ADMIN_REFETCH } from "modules/tickets/adapters/queries/TicketAdminRefetch";
import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import TicketsListItem from "modules/tickets/components/list/TicketsListItem";
import DataPage from "common/components/datapage/DataPage";
import CommonToolbar from "common/components/toolbar/Toolbar";
import CommonSearchbar from "common/components/searchbar/Searchbar";

const TicketsPage = () => {
  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
    setSort,
  } = usePaginationQuery(TICKET_ADMIN_REFETCH, {}, "listTickets");

  const columns = [
    { key: "customerDetails", label: "Customer Details" },
    { key: "location", label: "Location" },
    { key: "subject", label: "Subject" },
    { key: "company", label: "Company" },
    { key: "description", label: "Description" },
    { key: "priority", label: "Priority" },
    { key: "code", label: "Code" },
    { key: "createdAt", label: "Created At" },
  ];

  const tickets = data?.listTickets || {};
  const totalCount = tickets.totalCount || 0;
  const list =
    tickets.edges
      ?.map((edge: any) => edge.node)
      .filter((node: any) => node != null) || [];

  return (
    <DataPage
      title="Tickets"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      setSort={setSort}
      renderItem={(node: any) => (
        <TicketsListItem key={node.id} ticket={node} />
      )}
      ToolbarComponent={
        <CommonToolbar title="Tickets" addButtonLink="/tickets/add" />
      }
      SearchbarComponent={
        <CommonSearchbar
          defaultValues={{ search: "" }}
          fields={[
            {
              name: "search",
              label: "Search by name or email",
              type: "text",
            },
          ]}
          onSearch={setSearchQuery}
        />
      }
      paginationProps={{ rowsPerPageOptions: [10, 25, 50] }} // Example customization
    />
  );
};

export default TicketsPage;
