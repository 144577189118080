import Draggable from 'react-draggable';
import Fab from "@material-ui/core/Fab";
import { Android } from "@material-ui/icons";
import { useState, useRef, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from "./style";
import moment from 'moment';
import { motion } from 'framer-motion';// import useLongPress from './useLongPress';
import { useReactClicks } from 'react-clicks';

const DraggableChat = ({ messages }: { messages?: Array<any> }) => {
    const [showChatBox, setshowChatBox] = useState(false)
    const classes = useStyles();

    const [openScaleX, setOpenScaleX] = useState<string>("0");
    const [openScaleY, setOpenScaleY] = useState<string>("0");
    const [closeScaleX, setCloseScaleX] = useState<string>("-50%");
    const [closeScaleY, setCloseScaleY] = useState<string>("50%");
    const buttonRef = useRef<any>();

    let chatRef = useRef<any>();

    const clickProps = useReactClicks({
        longClick: () => {
            console.log("long click");
            setshowChatBox(showChatBox);
        },
        singleClick: () => {
            console.log("single click");
            setshowChatBox(!showChatBox);
        },

    })

    useEffect(() => {
        let handler = (event: any) => {
            if (chatRef.current && !chatRef.current.contains(event.target)) {
                setshowChatBox(false);
            }
        };

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    })




    const handleDrag = () => {
        const button = buttonRef.current;
        const buttonRect = button.getBoundingClientRect();
        if (buttonRect.y > (window.innerHeight - 500)) {
            setOpenScaleY("0");
            setCloseScaleY("50%");
            if (buttonRect.x > (window.innerWidth - 350)) {
                setOpenScaleX("-90%");
                setCloseScaleX("-50%")
            }
        }
        else {
            setOpenScaleY("110%");
            setCloseScaleY("60%");
            if (buttonRect.x > (window.innerWidth - 350)) {
                setOpenScaleX("-90%");
            }
            else {
                setOpenScaleX("0");
            }
        }
    }

    if (messages == undefined || messages.length == 0) {
        return null;
    }

    console.log("chat data", messages);

    const parseMessages = () => {
        return messages.map((item, index) => {
            return {
                id: index + item.event,
                type: item.event == 'bot' ? 0 : 1,
                name: item.event,
                timestamp: moment(parseInt((item.timestamp * 1000) + '')).calendar(),
                text: item.text,
            }
        })
    }

    const variants = {
        open: { scale: 1, x: openScaleX, y: openScaleY },
        closed: { scale: 0, x: closeScaleX, y: closeScaleY },
    }

    return (
        <Draggable handle="strong" bounds={{ left: 0, right: window.innerWidth - 100, bottom: 0 }} onDrag={handleDrag} defaultPosition={{ x: 150, y: -650 }} >
            <div className={'chatBoxclass'} ref={chatRef} style={{ position: 'absolute', display: 'flex', flexDirection: 'column-reverse', }}>
                <strong className="cursor" ref={buttonRef} >
                    <Fab color="primary" aria-label="bot"
                        {...clickProps} >
                        <Android />
                    </Fab>
                </strong>
                <motion.div
                    animate={showChatBox ? "open" : "closed"}
                    variants={variants}
                    transition={{ duration: 0.2 }}
                    className={classes.chatBox}
                    style={{ marginTop: 15, marginLeft: 10, borderRadius: 10, overflow: 'hidden' }}>
                    <div
                        style={{ height: 60, background: '#3f51b5', padding: '1rem' }}>
                        <Typography className={classes.chatBoxHeaderText}>Assistant Chat History</Typography>
                    </div>
                    <div
                        style={{ overflowY: 'scroll', maxHeight: 500, width: 350, background: 'white', whiteSpace: 'pre-wrap', padding: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                        {messages && parseMessages().map((message) => {
                            return (
                                <Box className={message.type == 0 ? classes.bubbleContainerMe : classes.bubbleContainerYou} key={message.id + ''}>
                                    <Typography className={message.type == 0 ? classes.bubbleNameMe : classes.bubbleNameYou}>{message.name}</Typography>
                                    <Typography className={message.type == 0 ? classes.bubbleTextMe : classes.bubbleTextYou}>{message.text}</Typography>
                                    <Typography className={message.type == 0 ? classes.bubbleTimeMe : classes.bubbleTimeYou}>{moment(message.timestamp).calendar()}</Typography>
                                </Box>
                            );
                        })}
                    </div>
                </motion.div>
            </div>
        </Draggable>
    )
}

export default DraggableChat