import clsx from "clsx";
import { Link } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import { Button } from "@mui/material";
import { useStyles } from "./style";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
interface IProps {
  className?: string;
}

const CallForHelpToolbar = ({ className, ...rest }: IProps) => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="BOT Profiles"
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
        action={
          <>
            <Button
              disabled={user?.company?.subscriptionStatus == "Not Paid"}
              color="primary"
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              component={Link}
              to="/bot-profile/add"
            >
              Add
            </Button>
          </>
        }
      />
    </div>
  );
};

export default CallForHelpToolbar;
