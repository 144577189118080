import { gql } from "@apollo/client";

export const RETRIEVE_CUSTOMER_PAYMENT_METHOD = gql`
query RetrieveCustomerPaymentMethod($planId: String!) {
    retrieveCustomerPaymentMethod(planId: $planId) {
      card {
        brand
        last4
      }
    }
  }`