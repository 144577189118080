import { gql } from "@apollo/client";
import { VIEW_BUSINESS_ADMIN_PAYMENT_ACCOUNT_DATA } from "adapters/fragments/ViewBusinessAdminPaymentData";

export const PAYMENT_BUSINESS_ADMIN_VIEW = gql`
    ${VIEW_BUSINESS_ADMIN_PAYMENT_ACCOUNT_DATA}
    query PaymentAccountViewQuery($id: ID!) {
        payment(id: $id) {
            ...ViewAccountPayment_data
        }
    }
`;
