import { gql } from "@apollo/client";

export const UPDATE_SESSION = gql`
mutation updateSession($input: UpdateSessionInput!){
    updateSession(input: $input){
        sessionEdge{
        node {
          id
          status
          createdAt
          updatedAt
        }
        cursor
      }
    }
  }`