import Recording from "../../../../components/modal/Recording";
import Feedback from "components/modal/Feedback";
import Chat from "components/modal/Chat";
import { TableRow, TableCell, Hidden, Grid, Typography } from "@mui/material";

export default function CallItem(props: any) {
  let status = props?.session?.status!;
  if (status === "cancel") {
    status = "Missed";
  } else if (status === "answer") {
    status = "Answered";
  }

  return (
    <TableRow hover>
      {/* Desktop view-set */}
      <Hidden smDown>
        <TableCell>
          {props.session.customer?.firstName +
            " " +
            props.session.customer?.lastName}
          <br />
          {props.session.customer?.email}
        </TableCell>
        <TableCell>
          {props.session.user?.firstName} {props.session.user?.lastName}
          <br />
          {props.session.user?.email}
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          {new Date(props?.session?.createdAt).toLocaleString()}
        </TableCell>
        <TableCell>
          <Recording chatRoomId={props?.session?.chatRoomId} />
        </TableCell>
        <TableCell>
          <Chat
            chatRoomId={props?.session?.chatRoomId}
            customerName={
              props.session.customer?.firstName +
              " " +
              props.session.customer?.lastName
            }
            agentName={
              props.session.user?.firstName + " " + props.session.user?.lastName
            }
            conversation={
              props.session.chatMessage
                ? JSON.parse(props.session.chatMessage)
                : {}
            }
          />
        </TableCell>
        <TableCell>
          <Feedback
            feedbackQuality={props?.session?.feedbackQuality}
            feedbackComment={props?.session?.feedbackComment}
          />
        </TableCell>
      </Hidden>

      <Hidden mdUp>
        <Grid container spacing={0} style={{ position: "relative" }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {props.session.customer?.firstName +
                " " +
                props.session.customer?.lastName}
              <br />
              {props.session.customer?.email}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {props.session.user?.firstName} {props.session.user?.lastName}
              <br />
              {props.session.user?.email}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props.session?.status}</Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Typography variant="body2">
              {new Date(props?.session?.createdAt).toLocaleString()}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Recording chatRoomId={props?.session?.chatRoomId} />
            <Chat
              chatRoomId={props?.session?.chatRoomId}
              customerName={
                props.session.customer?.firstName +
                " " +
                props.session.customer?.lastName
              }
              agentName={
                props.session.user?.firstName +
                " " +
                props.session.user?.lastName
              }
              conversation={
                props.session.chatMessage
                  ? JSON.parse(props.session.chatMessage)
                  : {}
              }
            />
            <Feedback
              feedbackQuality={props?.session?.feedbackQuality}
              feedbackComment={props?.session?.feedbackComment}
            />
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
