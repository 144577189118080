import { gql } from "@apollo/client";

export const LIST_DEFAULT_ROLES = gql `query ListDefaultRoles($defaultRole: DefaultRoleListInput!) {
  listDefaultRoles(defaultRole: $defaultRole) {
    id
    createdAt
    updatedAt
    isDeleted
    name
    permissions
    parentDefaultRoleId
    isOwner
  }
}`