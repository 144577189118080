import {
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Button as FormButton,
  Grid,
} from "@mui/material";

import FormFeedback from "../../../../components/form_feedback/FormFeedback";
import FormInput from "../../../../components/form/form_input/FormInput";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { EDIT_BUSINESS_ADMIN_COMPANY } from "adapters/mutations/EditBusinessAdminCompany";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";
import { updateUserCompany } from "redux/actions/AuthActions";
import { Address } from "utils/interfaces";
import { USER_BUSINESS_ADMIN_REFETCH } from "modules/users/adapters/queries/UserBusinessRefetch";

export default function EditAddressForm({ company }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);
  const defaultValues = {
    ...company,
  };

  const [mutate, { loading, error }] = useMutation(
    EDIT_BUSINESS_ADMIN_COMPANY,
    {
      onCompleted: (res) => {
        dispatch(updateUserCompany(res.updateCompany.companyEdge.node));
        setSnack({
          message: "Record Updated Successfully",
          severity: "success",
          open: true,
        });
      },
      onError: () => {
        setSnack({
          message: "Editing company failed",
          severity: "error",
          open: true,
        });
      },
    }
  );

  const { handleSubmit, control } = useForm({
    defaultValues,
  });

  const onSubmit = ({
    correspondanceAddress,
    correspondanceCity,
    correspondanceCountry,
    correspondanceState,
  }: Address) => {
    mutate({
      refetchQueries: [{ query: USER_BUSINESS_ADMIN_REFETCH }],
      variables: {
        input: {
          id: company?.id,
          address: {
            correspondanceAddress:
              correspondanceAddress || company.address.correspondanceAddress,
            correspondanceCity:
              correspondanceCity || company.address.correspondanceCity,
            correspondanceCountry:
              correspondanceCountry || company.address.correspondanceCountry,
            correspondanceState:
              correspondanceState || company.address.correspondanceState,
          },
        },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                required
                control={control}
                type="text"
                name="correspondanceAddress"
                label="Correspondance Address"
                fullWidth
                defaultValue={company?.address.correspondanceAddress}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                required
                control={control}
                type="text"
                name="correspondanceCity"
                label="Correspondance City"
                fullWidth
                defaultValue={company?.address?.correspondanceCity}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                required
                control={control}
                type="text"
                name="correspondanceCountry"
                label="Correspondance Country"
                fullWidth
                defaultValue={company?.address?.correspondanceCountry}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormInput
                required
                control={control}
                type="text"
                name="correspondanceState"
                label="Correspondance State"
                fullWidth
                defaultValue={company?.address?.correspondanceState}
              />
            </Grid>
          </Grid>
          {error ? (
            <FormFeedback className={classes.feedback} error>
              {error.message}
            </FormFeedback>
          ) : null}
        </CardContent>
        <Divider />
        <CardActions className={classes.buttonContainer}>
          <Grid item sx={{ padding: "16px 16px 16px 16px" }}>
            <FormButton
              className={classes.button}
              type="submit"
              color="secondary"
              variant="contained"
            >
              Update Record
            </FormButton>
            {loading && <CircularProgress className={classes.buttonProgress} />}
          </Grid>
        </CardActions>
      </form>
    </div>
  );
}
