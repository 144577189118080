import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import DataPage from "common/components/datapage/DataPage";
import CommonToolbar from "common/components/toolbar/Toolbar";
import CommonSearchbar from "common/components/searchbar/Searchbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { APPOINTMENT_BUSINESS_ADMIN_REFETCH } from "../adapters/queries/AppointmentBusinessAdminRefetch";
import PendingItem from "../components/list/pendingItem/PendingItem";
import { AppointmentStatus } from "../components/list/searchbar/Searchbar";
import AssignedItem from "../components/list/assigned_item/AssignedItem";
import { useHasItemPermission } from "common/layouts/utils/RouteUtils";
import RejectedItem from "../components/list/rejected_item/RejectedItem";
import AppointmentItem from "../components/list/list_item/ListItem";
import { useEffect } from "react";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const Appointments = (props: any): JSX.Element => {

  const { user } = useSelector((state: RootState) => state.auth);
  const assigneeId = user?.role === 'owner' ? null : user?.id;
  let customerId = props.match?.params?.customerId;
  const [appointmentStatus, setAppointmentStatus] = useState<string>("");
  useEffect(() => {
    console.log("appointmentStatus changed:", appointmentStatus);
  }, [appointmentStatus]);

  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
    setSort,
  } = usePaginationQuery(APPOINTMENT_BUSINESS_ADMIN_REFETCH, {customerId: customerId, assigneeId}, "appointments");

  const columns = [
    { key: "customer.firstName", label: "Customer" },
    { key: "status", label: "Status" },
    { key: "reason", label: "Reason" },
    { key: "startDate", label: "Appointment Slot" },
    { key: "user.firstName", label: "Assigned To" },
  ];

  const appointmentList = () => {
    const { appointments } = data || {};
    return appointments;
  };
  const appointments = appointmentList();
  const totalCount = appointments?.totalCount || 0;
  const list = appointments?.edges
  ?.map((edge: any) => edge.node)
  .filter(isNotNull) || [];

  const canEditAssignAppointments = useHasItemPermission("appointments","assign_appointments")

  return (
    <DataPage
      title="Appointments"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      setSort={setSort}
      renderItem={(node: any) => {
        if (node?.status === AppointmentStatus.PENDING) {
          return (
            <PendingItem
              request={node}
              key={node.id}
              getAssignedList={() => {
                setAppointmentStatus(AppointmentStatus.ASSIGNED);
              }}
              getPendingList={() => {
                setAppointmentStatus(AppointmentStatus.PENDING);
              }}
            />
          );
        }
        if (node?.status == AppointmentStatus.ASSIGNED && canEditAssignAppointments) {
          return (
            <AssignedItem
              assigned={node}
              key={node.id}
              getAssignedList={() => {
                setAppointmentStatus(AppointmentStatus.ASSIGNED);
              }}
              getRejectedList={() => {
                setAppointmentStatus(AppointmentStatus.REJECTED);
              }}
            />
          );
        }
        if (node?.status == AppointmentStatus.REJECTED) {
          return <RejectedItem request={node} key={node.id} />;
        }

        return <AppointmentItem appointment={node} key={node.id} />;
      }}
      ToolbarComponent={
        <CommonToolbar title="Appointments" />
      }
      SearchbarComponent={
        <CommonSearchbar
          defaultValues={{ search: "" }}
          fields={[
            {
              name: "search",
              // label: "Search by name or email",
              type: "text",
            },
          ]}
          onSearch={(query: any) => {
            setSearchQuery(query);
            setAppointmentStatus(""); // Reset appointmentStatus when search is triggered
          }}
          status={appointmentStatus}
        />
      }
      paginationProps={{ rowsPerPageOptions: [10, 25, 50] }} // Example customization
    />
  );
};

export default Appointments;
