import FormInput from "../../../../components/form/form_input/FormInput";
import { useStyles } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { UPDATE_ADDON_MUTATION } from "../../../../adapters/mutations/updateAddOnMutation";
import { useContext } from "react";
import { SnackbarContext } from "../../../../components/common_snackbar/CommonSnackBar";
import {
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Switch,
  FormControlLabel,
  Button,
} from "@mui/material";
import { AddOn } from "../../../../utils/interfaces";
import { omit } from "lodash";
import { useState } from "react";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  allowanceType: yup.string().required("Allowance type is required"),
  allowanceUnits: yup.number().required("Allowance units is required"),
  active: yup.boolean().required("Active status is required"),
  price: yup.number().required("Price is required"),
});

export default function EditForm({ addon }: { addon: AddOn }) {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const [status, setStatus] = useState<boolean>(addon.active);

  const [updateAddOnMutation, { loading }] = useMutation(
    UPDATE_ADDON_MUTATION,
    {
      onCompleted: () => {
        setSnack({
          message: "Add-on updated successfully",
          severity: "success",
          open: true,
        });
      },
      onError: (error) => {
        setSnack({
          message: "Updating add-on failed",
          severity: "error",
          open: true,
        });
        console.error(error);
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...addon },
  });

  const handleFormSubmit = async (updatedAddon: AddOn) => {
    try {
      const addonWithoutTypename = omit(updatedAddon, "__typename");
      addonWithoutTypename.active = status;
      await updateAddOnMutation({
        variables: {
          addOn: addonWithoutTypename,
        },
      });
    } catch (error) {
      console.error("Failed to update addon:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                control={control}
                type="text"
                name="name"
                label="Name*"
                defaultValue={addon.name}
                error={errors && errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                control={control}
                type="text"
                name="description"
                label="Description*"
                defaultValue={addon.description}
                error={errors && errors.description}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                control={control}
                type="text"
                name="allowanceType"
                label="Allowance Type*"
                defaultValue={addon.allowanceType}
                error={errors && errors.allowanceType}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                control={control}
                type="number"
                name="allowanceUnits"
                label="Allowance Units*"
                defaultValue={addon.allowanceUnits}
                error={errors && errors.allowanceUnits}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                    color="primary"
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                }
                label="Active"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                control={control}
                type="number"
                name="price"
                label="Price*"
                defaultValue={addon.price}
                error={errors && errors.price}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ paddingX: "1rem" }}
        >
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            fullWidth
            disabled={loading}
          >
            Update Addon
          </Button>
          {loading && <CircularProgress size={24} />}
        </Stack>
      </form>
    </div>
  );
}
