import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface StylesProps {
    isSmallScreen: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) =>
    createStyles({
        agentBox: {
            minHeight: "250px",
            maxHeight: "250px",
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: "auto",
            position: "relative",
        },
        agentAvatar: {
            bgcolor: theme.palette.primary.main,
            width: 32,
            height: 32,
            fontSize: "13px",
            borderRadius: "50%",
        },
        status: {
            color: "white",
            paddingLeft: 5,
            paddingRight: 5,
            alignSelf: "flex-start",
            borderRadius: 3,
        },
        dropZone: {
            position: "absolute",
            top: 20,
            right: 20,
            left: 20,
            bottom: 20,
            borderWidth: 1,
            borderRadius: 15,
            borderColor: "black",
            borderStyle: "dashed",
            backgroundColor: "rgba(255,255,255,0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
        },
        connectedCustomerAvatar: {
            bgcolor: theme.palette.primary.main,
            width: 24,
            height: 24,
            fontSize: "10px",
            borderRadius: "50%",
        },
    })
);
