import { gql } from "@apollo/client";
export const EDIT_BUSINESS_ADMIN_CALL_FOR_HELP = gql`
    mutation EditAccountBotProfileMutation($input: UpdateBotProfileInput!) {
        updateBotProfile(input: $input) {
            profileEdge {
                node {
                    id
                }
            }
        }
    }
`;
