import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useStyles } from "./style";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Divider,
  FormGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import GuidizyIcon from "assets/images/guidizy-48.svg";
import EmbedCode from "modules/bot_profiles/components/embed_code/EmbedCode";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import { Settings } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import { useHasItemPermission } from "common/layouts/utils/RouteUtils";
import { GET_BOT_PROFILE_ASSET } from "modules/bot_profiles/adapters/queries/GetBotProfileAsset";

export default function CallForHelpItem(props: any) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { className, botProfile } = props;
  const navigate = useNavigate();
  const [profileUrl, setProfileUrl] = useState("");

  const [getBotProfileAsset] = useLazyQuery(GET_BOT_PROFILE_ASSET, {
    onCompleted: (data) => {
      if (data && data.getBotProfileAsset) {
        setProfileUrl(data.getBotProfileAsset.url);
      }
    },
    onError: (error) => {
      console.log("Profile Asset Error", error);
    },
  });

  useEffect(() => {
    botProfile.profilePicture &&
      getBotProfileAsset({
        variables: {
          input: {
            key: botProfile.profilePicture,
          },
        },
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    if (botProfile.id!) {
      navigate(`/bot-profile/edit/${botProfile.id}`);
    }
  };
  const canViewModify = useHasItemPermission(
    "botProfiles",
    "modify_bot_profiles"
  );
  const canViewConfigureBusinessHours = useHasItemPermission(
    "botProfiles",
    "configure_business_hours"
  );
  const canViewConfigureBot = useHasItemPermission(
    "botProfiles",
    "configure_bot"
  );
  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div
                style={{
                  height: 15,
                  width: 15,
                  borderRadius: 8,
                  backgroundColor: botProfile.status ? "green" : "red",
                }}
              />
            }
          >
            <Avatar
              className={classes.avatar}
              src={profileUrl ? profileUrl : GuidizyIcon}
              alt={botProfile.name!}
            />
          </Badge>
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {botProfile.name!}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          {botProfile.body!}
        </Typography>

        <br />
        <FormGroup>
          <>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item></Grid>
                <Grid item>
                  {botProfile.isBotInterectionsOn ? (
                    <Done color={"primary"} />
                  ) : (
                    <Close color={"error"} />
                  )}
                </Grid>
                <Grid item>Bot Q&A</Grid>
              </Grid>
            </Typography>

            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item></Grid>
                <Grid item>
                  {botProfile.isAgentRequired ? (
                    <Done color={"primary"} />
                  ) : (
                    <Close color={"error"} />
                  )}
                </Grid>
                <Grid item>Agent Handover</Grid>
              </Grid>
            </Typography>

            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item></Grid>
                <Grid item>
                  {botProfile.isAppointment ? (
                    <Done color={"primary"} />
                  ) : (
                    <Close color={"error"} />
                  )}
                </Grid>
                <Grid item>Appointments</Grid>
              </Grid>
            </Typography>

            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item></Grid>
                <Grid item>
                  {botProfile.isCallbackOn ? (
                    <Done color={"primary"} />
                  ) : (
                    <Close color={"error"} />
                  )}
                </Grid>
                <Grid item>Callbacks</Grid>
              </Grid>
            </Typography>
          </>
        </FormGroup>
      </CardContent>

      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            {canViewConfigureBusinessHours && (
              <Tooltip title="Configure Business Hours">
                <IconButton
                  color="primary"
                  aria-label="business hours"
                  component={Link}
                  to={`/bot-profile/business-hours/${botProfile.id!}`}
                >
                  <AccessTimeIcon color="action" />
                </IconButton>
              </Tooltip>
            )}
            {botProfile.isBotInterectionsOn && canViewConfigureBot && (
              <Tooltip title="Configure Bot">
                <IconButton
                  color="primary"
                  aria-label="Bot Configurations"
                  component={Link}
                  to={`/bot-profile/${botProfile.companyId!}/${botProfile.id!}`}
                >
                  <Settings color="action" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid className={classes.statsItem} item>
            {canViewModify && (
              <Tooltip title="Modify">
                <IconButton
                  onClick={handleClick}
                  color="primary"
                  aria-label="modify"
                  component="span"
                >
                  <EditIcon color="action" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Embed Code">
              <IconButton
                onClick={handleOpen}
                color="primary"
                aria-label="Embeded Code"
                component="span"
              >
                <GetAppIcon color="action" />
              </IconButton>
            </Tooltip>

            <EmbedCode
              isOpen={open}
              setOpen={setOpen}
              onClose={handleClose}
              botProfile={botProfile}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
