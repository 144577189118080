import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { USER_BUSINESS_ADMIN_REFETCH } from "modules/users/adapters/queries/UserBusinessRefetch";
import { COMPANY_BUSINESS_ADMIN_VIEW } from "modules/users/adapters/queries/CompanyBusinessAdminView";
import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import UsersListItem from "modules/users/components/list/ListItemTable";
import Toolbar from "modules/users/components/list/toolbar/Toolbar";
import Searchbar from "modules/users/components/list/searchbar/Searchbar";
import DataPage from "common/components/datapage/DataPage";

const UsersPage = () => {
  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
  } = usePaginationQuery(USER_BUSINESS_ADMIN_REFETCH, { count: 5 }, "users");

  const columns = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "role", label: "Role" },
    { key: "status", label: "Status" },
    { key: "date", label: "Date" },
  ];

  const users = data?.users || {};
  const totalCount = users.totalCount || 0;
  const list =
    users.edges
      ?.map((edge: any) => edge.node)
      .filter((node: any) => node != null) || [];

  const [showAlert, setShowAlert] = useState(false);
  console.log(showAlert);

  const showUsersAlert = () => {
    setShowAlert(true);
  };

  const { user } = useSelector((state: RootState) => state.auth);
  const userId = user?.id;

  const { data: companyData } = useQuery(COMPANY_BUSINESS_ADMIN_VIEW, {
    variables: { id: userId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const company = useMemo(() => companyData?.company, [companyData]);

  const toolbarProps = {
    showAlert: showUsersAlert,
    maxUsersReached: company?.consumedActiveAgents >= company?.maxAllowedAgents,
    showWarning: company?.consumedActiveAgents >= company?.maxAllowedAgents,
  };

  return (
    <DataPage
      title="Users"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      renderItem={(node: any) => <UsersListItem key={node.id} user={node} />}
      ToolbarComponent={<Toolbar {...toolbarProps} />}
      SearchbarComponent={
        <Searchbar onSearch={setSearchQuery} {...toolbarProps} />
      }
      cardProps={{ sx: { backgroundColor: "#f5f5f5" } }} // Example card customization
      paginationProps={{ rowsPerPageOptions: [5, 10, 25] }} // Example pagination customization
    />
  );
};

export default UsersPage;
