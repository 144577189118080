import { ReactNode, forwardRef } from "react";

type IProps = {
  title: string | "";
  children?: ReactNode;
  className?: string;
};

const Page = forwardRef<any, IProps>(({ children, ...rest }, ref) => (
  <div ref={ref} {...rest}>
    {children}
  </div>
));

export default Page;
