import { ModuleRouteConfig } from "common/types/RouteTypes";
import CallList from "modules/agent_sessions/pages/AgentSessionsPage";

export const AgentSessionRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "view_agent_sessions",
    path: "/:customerId",
    element: <CallList />,
  },
  {
    permissionId: "view_agent_sessions",
    path: "",
    element: <CallList />,
  },
];
