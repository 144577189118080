import { gql } from "@apollo/client";

export const GET_TWILLIO_RECORDING = gql`
query GetCallRecording($input: CallRecordingInput!) {
  getCallRecording(input: $input) {
    url
    recording {
      Size
    }
  }
}
`

// {
//   "getNotebyCallIdId": null
// }