import { gql } from "@apollo/client";

export const LIST_ADMIN_AUDITS = gql`
    fragment ListAdmin_audits_1G22uz on Query {
        audits(
            first: $count
            last: $reversecount
            after: $endcursor
            before: $startcursor
            search: $search
        ) {
            edges {
                node {
                    id
                    entityId
                    entity
                    action
                    user {
                        firstName
                        lastName
                        id
                    }
                    requestIp
                    createdAt
                    __typename
                }
                cursor
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
        }
    }
`;
