import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from "ts-date/esm/locale/en";
import { Ticket } from "modules/tickets/models/Ticket";

interface TicketItemProps {
  ticket: Ticket;
}

const TicketsListItem = ({ ticket }: TicketItemProps) => {
  const { id, location, subject, company, description, priority, code, customer, createdAt } = ticket;
  const navigate = useNavigate();

  return (
    <TableRow style={{ cursor: 'pointer' }} hover key={id} onClick={() => navigate(`/tickets/edit/${id}`)}>
      <TableCell>
        {`${customer?.firstName || ""} ${customer?.lastName || ""}`}
        <br />
        {customer?.email}
      </TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>{subject}</TableCell>
      <TableCell>{company}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{priority}</TableCell>
      <TableCell>{code}</TableCell>
      <TableCell>{format(new Date(createdAt!), "DD MMM, YYYY hh:mm a")}</TableCell>
    </TableRow>
  );
};

export default TicketsListItem;
