import { gql } from "@apollo/client";

export const CREATE_TICKET = gql`
    mutation CreateTicket($ticket: TicketCreateInput!) {
    createTicket(ticket: $ticket) {
      company
      description
      priority
      location
      code
      ticketStatus
      id
      reporterId
      assigneeId
      customerId
      subject
      managerId
      companyId
    }
  }
  `;