import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface StylesProps {
    isSmallScreen?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) =>
    createStyles({
        customerCard: {
            overflow: 'hidden',
            height: '85vh'
        },
        chatCard: {
            display: 'flex',
            flexDirection: 'column',
        },
        stickyHeader: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
        },
        scrollBox: {
            height: 'calc(100vh - 150px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
        },
        messageBox: {
            padding: theme.spacing(2),
            flex: 1,
            overflow: 'scroll',
            marginBottom: theme.spacing(2),
        },
        messageItem: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
            width: '100%',
            overflow: 'scroll',
        },
        messageItemRight: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
            width: '100%',
            alignItems: "flex-end",
            overflow: 'scroll',
        },
        messageAvatar: {
            width: 32,
            height: 32,
            marginRight: theme.spacing(1),
        },
        messageContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: theme.spacing(1),
        },
        messageBubble: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            borderRadius: '10px',
            maxWidth: '100%',
            wordWrap: 'break-word',
        },
        messageBubbleRight: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            alignSelf: 'flex-end',
            borderRadius: '10px',
            maxWidth: '100%',
            wordWrap: 'break-word',
        },
        timestamp: {
            marginTop: theme.spacing(0.5),
            color: theme.palette.text.secondary,
        },
        buttonGroup: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        inputBox: {
            padding: theme.spacing(2),
            borderTop: '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
        },
    })
);

export default useStyles;
