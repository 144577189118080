import { gql } from "@apollo/client";

export const LIST_BUSINESS_ADMIN_USERS = gql`
  fragment ListAccount_users_1G22uz on Query {
    users(
      first: $count
      last: $reversecount
      after: $endcursor
      before: $startcursor
      search: $search
      role: $role
      roleId: $roleId
    ) {
      edges {
        node {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
          phone
          role
          roleId
          company {
            name
          }
          status
          managerId
          companyId
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
