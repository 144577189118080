import { TableRow, TableCell, Hidden, Grid, Typography } from "@mui/material";

export default function AuthLogItem(props: any) {
  return (
    <TableRow hover>
      {/* Desktop view-set */}
      <Hidden smDown>
        <TableCell>
          {props.authLog?.user?.firstName!} {props.authLog?.user?.lastName!}
        </TableCell>
        <TableCell>{props.authLog.action}</TableCell>
        <TableCell>{props.authLog.result}</TableCell>
        <TableCell>{props.authLog.requestIp}</TableCell>
        <TableCell>
          {new Date(props?.authLog?.createdAt).toLocaleString()}
        </TableCell>
      </Hidden>

      {/* Mobile view-set */}
      <Hidden mdUp>
        <Grid container spacing={1} padding={1} style={{ position: "relative" }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {props.authLog?.user?.firstName!} {props.authLog?.user?.lastName!}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props.authLog.action}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props.authLog.result}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{props.authLog.requestIp}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {new Date(props?.authLog?.createdAt).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
    </TableRow>
  );
}
