import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    planBtn: {
      color: "white",
      textTransform: "none",
      width: "265px",
      height: "50px",
      borderRadius: "10px",
      margin: "1rem",
      fontSize: "18px"
    },
    root: {
      padddingRight: "1rem",
      paddingLeft: "1rem",
    },
    paper: {
      padding: "2rem",
      textAlign: "center",
      color: theme.palette.text.secondary,
      background: "#E5E5E5",
      borderRadius: "20px",
      width: "23rem",
      height: "100%",
    },
    planName: {
      fontSize: "35px",
      color: "#21409A",
      fontWeight: "bold",
    },
    charge: {
      fontSize: "50px",
      color: "#000000",
      fontWeight: "bold",
    },
    features: {
      color: "#000000",
      fontSize: "17px",
    },
    featureWrapper: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "2rem",
    },
    doneIcon: {
      paddingRight: "1rem",
      fontSize: "2.5rem",
      color: "#21409A",
    },

  })
);
