import { gql } from "@apollo/client";

export const CHANGE_MODAL_AGENT_APPOINTMENT = gql`
mutation ChangeModalAgentAppointmentMutation($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      appointmentEdge {
        node {
          id
          __typename
        }
        __typename
      }
      __typename
    }
  }
  `;
