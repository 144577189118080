import { gql } from '@apollo/client';

export const UPDATE_ROLES = gql`
mutation UpdateRole($role: RoleUpdateInput!) {
  updateRole(role: $role) {
    id
    createdAt
    updatedAt
    isDeleted
    name
    companyId
    permissions
  }
}`