import { Button, Stack, Typography } from "@mui/material";
import useStyles from "./style";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import CartItem from "../cart_item/Item";
import { useMutation } from "@apollo/client";
import { CREATE_ADD_ON_CART_CHECKOUT_SESSION } from "adapters/mutations/CreateAddOnCartCheckoutSession";
import { useContext } from "react";
import { SnackbarContext } from "components/common_snackbar/CommonSnackBar";

const AddOnCart = () => {
  const classes = useStyles();
  const { items, total } = useSelector((state: RootState) => state.addOnCart);
  const { setSnack } = useContext(SnackbarContext);

  console.log("items", items);

  const [mutate] = useMutation(CREATE_ADD_ON_CART_CHECKOUT_SESSION, {
    onCompleted: ({ createAddOnCartCheckoutSession }) => {
      window.location.href = createAddOnCartCheckoutSession.url;
    },
    onError: (error) => {
      console.log(error);
      setSnack({ message: "Activation failed", severity: "error", open: true });
    },
  });

  const handleClick = () => {
    const addOns: any = [];
    items.forEach((item) => {
      addOns.push({ addOnId: item.id, quantity: item.quantity });
    });
    mutate({
      variables: {
        addOnPurchaseHistory: {
          items: addOns,
        },
      },
    });
  };

  return (
    <Stack
      display={"flex"}
      flexDirection={"column"}
      // height={"80vh"}
      style={{
        backgroundColor: "#f2f2f2",
        marginTop: 30,
        paddingTop: 20,
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,
      }}
    >
      <Typography variant="h5" textAlign={"center"}>
        Cart
      </Typography>

      <Stack className={classes.accordianContainer}>
        <Stack
          display={"flex"}
          flexDirection={"column"}
          height={"80vh"}
          style={{ backgroundColor: "#f2f2f2" }}
        >
          {items.map((item) => (
            <CartItem item={item} key={item.id} />
          ))}
        </Stack>
      </Stack>
      <Stack className={classes.submitButtonContainer} spacing={2}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleClick()}
        >
          Proceed to Checkout £{`${total}`}
        </Button>
      </Stack>
    </Stack>
  );
};

export default AddOnCart;
