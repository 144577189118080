import { gql } from "@apollo/client";
import { LIST_BUSINESS_ADMIN_USERS } from "modules/users/adapters/fragments/ListBusinessAdminUsers";

export const USER_BUSINESS_ADMIN_REFETCH = gql`
    ${LIST_BUSINESS_ADMIN_USERS}
    query UserAccountFragmentRefetchQuery(
        $count: Int  
        $reversecount: Int
        $startcursor: String
        $endcursor: String
        $search: String
        $role: String
        $roleId: String
    ) {
        ...ListAccount_users_1G22uz
    }
`;
