import { gql } from "@apollo/client";

export const ADD_SUBSCRIPTION = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    SubscriptionEdge {
      node {
        id
        createdAt
        updatedAt
        isDeleted
        name
        agents
        botProfiles
        minutes
        trialDays
        price
        isPublic
        isDefault
      }
      cursor
    }
  }
}
`;
