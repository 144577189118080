import { gql } from "@apollo/client";

export const RETRIEVE_UPCOMING_INVOICE = gql`
query retrieveUpcomingInvoice($planId: String!){
  retrieveUpcomingInvoice(planId:$planId) {
    amount_due
    nextBillAmount
    nextBillPeriod
  }
}
`