import { gql } from "@apollo/client";

export const ADD_BUSINESS_ADMIN_USER = gql`
    mutation AddAccountUserMutation($input: CreateUserInput!) {
        createUser(input: $input) {
            userEdge {
                node {
                    id
                }
            }
        }
    }
`;
