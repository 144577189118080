import { gql } from "@apollo/client";

export const LIST_ACCOUNT_USERS = gql`
  query Users($companyId: String, $status: String) {
    users(companyId: $companyId, status: $status) {
      edges {
        node {
          id
          firstName
          lastName
          role
        }
      }
    }
  }
`;
