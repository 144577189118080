import { gql } from "@apollo/client";

export const ADD_ONS_REFETCH = gql`query ListAddOn {
  listAddOn {
    active
    allowanceType
    allowanceUnits
    description
    expiryDate
    id
    isDeleted
    name
    price
    priceId
  }
}`;
