import { gql } from "@apollo/client";

export const GET_BOT_FRAMEWORK = gql`
query BotFrameworkByBotProfileId($botFrameworkByBotProfileIdId: String!) {
  botFrameworkByBotProfileId(id: $botFrameworkByBotProfileIdId) {
    id
    createdAt
    updatedAt
    isDeleted
    botProfileId
    companyId
    fallbackMessage
    welcomeMessage
    fallbackOptions
    fileMetadata
    fileUploadDate
    nodes
    edges
  }
}`