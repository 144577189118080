import ViewSubscriptions from "modules/subscriptions/pages/SubscriptionsPage/View";
import ChangeSubscription from "modules/subscriptions/pages/ChangeSubscriptionPage";
import { ModuleRouteConfig } from "common/types/RouteTypes";
import Pay from "modules/subscriptions/pages/SubscriptionCheckoutPage/CheckoutForm";

export const SubscriptionRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "edit_subscriptions",
    path: "/pay/:plan",
    element: <Pay />,
  },
  {
    permissionId: "edit_subscriptions",
    path: "/change-subscription",
    element: <ChangeSubscription />,
  },
  {
    permissionId: "edit_subscriptions",
    path: "/change-subscription",
    element: <ChangeSubscription />,
  },
  {
    permissionId: "view_subscriptions",
    path: "",
    element: <ViewSubscriptions />,
  },
];
