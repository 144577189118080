import { JaaSMeeting, JitsiMeeting } from '@jitsi/react-sdk';
import useLocalStorage from "./useLocalStorage";
import { useRef } from "react"

function Jitsi({ chatRoomId, id, token, onParticipantLeft, onMeetingEnded }) {
    const [isAudioMuted, setIsAudioMuted] = useLocalStorage("isAudioMuted", true);
    const [isVideoMuted, setIsVideoMuted] = useLocalStorage("isVideoMuted", true);
    const [screenSharingStatusChanged, setScreenSharingStatusChanged] =
        useLocalStorage("screenSharingStatusChanged", false);
    const apiRef = useRef();
    console.log(id);

    const handleReadyToClose = () => {
        localStorage.removeItem("startCall");
        localStorage.removeItem("chatRoomId");
        setTimeout(() => {
            window.location.reload();
            if (onMeetingEnded) {
                onMeetingEnded()
            }
        }, 1000);
    };

    const handleAudioStatusChange = (payload) => {
        setIsAudioMuted(payload.muted);
    };

    const handleVideoStatusChange = (payload) => {
        setIsVideoMuted(payload.muted);
    };

    const handleScreenSharingStatusChange = (payload) => {
        setScreenSharingStatusChanged(payload.on);
    };

    const handleParticipantLeft = (payload) => {
        localStorage.removeItem("startCall");
        localStorage.removeItem("chatRoomId");
        onParticipantLeft(payload)
        if (onMeetingEnded) {
            onMeetingEnded()
        }
    };

    const handleApiReady = apiObj => {
        apiRef.current = apiObj;
        apiRef.current.on('audioMuteStatusChanged', payload => handleAudioStatusChange(payload));
        apiRef.current.on('videoMuteStatusChanged', payload => handleVideoStatusChange(payload));
        apiRef.current.on('screenSharingStatusChanged', payload => handleScreenSharingStatusChange(payload))
        apiRef.current.on('participantLeft', payload => handleParticipantLeft(payload));
    };

    const handleJaaSIFrameRef = iframeRef => {
        iframeRef.style.border = '10px solid #3d3d3d';
        iframeRef.style.background = '#3d3d3d';
        iframeRef.style.height = '100%';
    };

    return (
        <>
            {process.env.REACT_APP_JITSI_DOMAIN === "8x8.vc" ?
                <JaaSMeeting
                    roomName={chatRoomId}
                    // appId={process.env.REACT_APP_JAAS_APP_ID}
                    //parentNode
                    getIFrameRef={handleJaaSIFrameRef}
                    jwt={token}
                    interfaceConfigOverwrite={{
                        SHOW_CHROME_EXTENSION_BANNER: false,
                        SHOW_BRAND_WATERMARK: false,
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        SHOW_POWERED_BY: false,
                        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                        MOBILE_APP_PROMO: false,
                    }}
                    configOverwrite={{
                        startWithAudioMuted: isAudioMuted,
                        prejoinPageEnabled: false,
                        startWithVideoMuted: isVideoMuted,
                        startScreenSharing: screenSharingStatusChanged,
                        toolbarButtons: [
                            "microphone",
                            "camera",
                            "desktop",
                            "hangup",
                            "chat",
                            "feedback",
                        ],
                    }}
                    onApiReady={externalApi => handleApiReady(externalApi)}
                    onReadyToClose={handleReadyToClose}
                /> : < JitsiMeeting
                    roomName={chatRoomId}
                    appId={process.env.REACT_APP_JITSI_APP_ID}
                    domain={process.env.REACT_APP_JITSI_DOMAIN}
                    //parentNode
                    getIFrameRef={handleJaaSIFrameRef}
                    jwt={token}
                    interfaceConfigOverwrite={{
                        SHOW_CHROME_EXTENSION_BANNER: false,
                        SHOW_BRAND_WATERMARK: false,
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        SHOW_POWERED_BY: false,
                        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                        MOBILE_APP_PROMO: false,
                    }}
                    configOverwrite={{
                        startWithAudioMuted: isAudioMuted,
                        prejoinPageEnabled: false,
                        startWithVideoMuted: isVideoMuted,
                        startScreenSharing: screenSharingStatusChanged,
                        toolbarButtons: [
                            "microphone",
                            "camera",
                            "desktop",
                            "hangup",
                            "chat",
                            "feedback",
                        ],
                    }}
                    useStaging={true}
                    onApiReady={externalApi => handleApiReady(externalApi)}
                    onReadyToClose={handleReadyToClose}
                />}
        </>
    )
}

export default Jitsi;

