//@ts-nocheck
import React, { useEffect } from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import MuiDialogContent from "@material-ui/core/DialogContent";
import moment from "moment";
import ChatIcon from "@material-ui/icons/Chat";
import { useLazyQuery } from "@apollo/client";
import { GET_RASA_BOT_CHAT } from "adapters/queries/GetAiBotChat";
import BotChat from "./BotChat";

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children?: React.ReactNode | null;
  onClose: () => void;
}

interface Props {
  chatRoomId?: string;
  customerName?: string;
  agentName?: string;
  conversation?: JSON,
  botConversation?: Array[JSON]
}

const DialogTitle = withStyles(useStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ChatContent({ classes, conversation, botConversation, chatRoomId }: Props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [botMessages, setBotMessasges] = React.useState(botConversation);
  const [agentMessages, setAgentMessages] = React.useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const [runQuery, { loading }] = useLazyQuery(GET_RASA_BOT_CHAT, {
    onCompleted: (data) => {
      console.log(data)
      setBotMessasges(data?.getBotChatFromChatroomID.bot_messages)
      setAgentMessages(data?.getBotChatFromChatroomID.agent_messages)
    },
  });

  useEffect(() => {
    if (value == 0 && !botMessages) {
      console.log("Making api call")
      runQuery({
        variables: {
          chatRoomId
        }
      })
    }
  }, [value])

  let chat = conversation;
  return (
    <div>
      <IconButton
        aria-label="Actions"
        aria-controls="actions-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClickOpen}
        classes={classes}
      >
        <ChatIcon />
        <Typography>Chat</Typography>
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Chats
        </DialogTitle>
        <DialogContent style={{ overflowX: 'auto' }}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Bot Conversation" value={0} />
              {chat && <Tab label="Agent Conversation" value={1} />}
            </Tabs>
          </Box>
          {value === 0 && !loading && <BotChat botMessages={botMessages} />}
          {value == 1 && agentMessages && agentMessages.length > 0 &&
            agentMessages.map((message: any) => (
              <div key={message.jid}>
                <Typography style={{ fontWeight: 600 }}>
                  {message.name}
                </Typography>
                <Typography>{message.content}</Typography>
                <Typography gutterBottom variant="caption">
                  <>{moment(parseInt(message.timestamp)).calendar()}</>
                </Typography>
                <br />
                <br />
              </div>
            ))}
          {(!chat && !botMessages) && (!chat?.messages && botMessages.length == 0) && <Typography>No chats</Typography>}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const Chat = withStyles(() => ({
  // label: {
  //   flexDirection: 'column'
  // },
}))(ChatContent);

export default Chat