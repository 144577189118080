import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      paddingY: 0,
      paddingX: 0,
      height: "auto",
    },
    tableCell: {
      padding: "4px 8px",
      fontSize: "0.875rem",
    },
    roleCellHeader: {
      paddingY: "0.5rem",
      paddingX: "1rem",
    },
    newColumnCell: {
      padding: "4px 8px",
    },
    textField: {
      marginRight: theme.spacing(1),
      "& .MuiInputBase-input": {
        padding: "6px 10px",
      },
    },
    iconButton: {
      padding: "4px",
    },
    featureCell: {
      width: "300px",
      paddingY: "0.5rem",
      paddingX: "1rem",
    },
    featureBox: {
      paddingY: "0.1rem",
      paddingX: "0.5rem",
    },
    featureCheckBox: {
      paddingY: "0.5rem",
      paddingX: "1rem",
    },
    dynamicColumnCell: {
      width: "100px",
      paddingY: "0.5rem",
      paddingX: "1rem",
    },
    subFeatureCell: {
      paddingY: 0,
      paddingLeft: theme.spacing(5),
    },
    subFeatureBox: {
      paddingY: "0.1rem",
      paddingX: "0.5rem",
    },
    subPermissionCell: {
      paddingY: 0,
      paddingLeft: theme.spacing(10),
    },
    buttonBox: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(2),
      margin: "0 3rem 0 0"
    },
    cancelButton: {
      marginRight: 8,
    },
    dialogFooter: {
      margin: "5px 15px",
    }
  })
);