import { gql } from "@apollo/client";

export const GET_FAQS = gql`
query FaqNodes($botProfileId: String, $companyId: String $search: String) {
    faqNodes(botProfileId: $botProfileId, companyId: $companyId, search: $search) {
      edges {
        node {
          botProfileId
          companyId
          createdAt
          id
          isDeleted
          questions
          responses
          status
          feedbackRequired
          mainmenu
          title
          displayName
          updatedAt
          aiGenerated
        }
      }
    }
  }`