import { Feature } from "../../../models/roles";

export const EditPermissions: Feature[] = [
  {
    id: "dashboard",
    name: "Dashboard",
    path: "/dashboard",
    permissions: [{ id: "view_dashboard", name: "view", label: "View" }],
    subFeatures: [
      {
        id: "callsSummary",
        name: "Calls Summary",
        permissions: [
          { id: "view_calls_summary", name: "view", label: "View" },
        ],
      },
      {
        id: "agentActivityOverview",
        name: "Agent Activity Overview",
        permissions: [
          { id: "view_agent_activity", name: "view", label: "View" },
        ],
      },
      {
        id: "liveDashboard",
        name: "Live Dashboard",
        permissions: [
          { id: "view_live_dashboard", name: "view", label: "View" },
        ],
      },
    ],
  },
  {
    id: "subscriptions",
    name: "Subscriptions",
    path: "/subscription-payments",
    permissions: [
      { id: "view_subscriptions", name: "view", label: "View" },
      { id: "edit_subscriptions", name: "edit", label: "Edit" },
    ],
    subFeatures: [
      {
        id: "addOns",
        name: "Add-Ons",
        permissions: [
          { id: "view_addons", name: "view", label: "View" },
          { id: "edit_addons", name: "edit", label: "Edit" },
        ],
      },
    ],
  },
  {
    id: "users",
    name: "Users",
    path: "/users",
    permissions: [
      { id: "view_users", name: "view", label: "View" },
      { id: "create_users", name: "create", label: "Create" },
      { id: "edit_users", name: "edit", label: "Edit" },
    ],
  },
  {
    id: "tickets",
    name: "Tickets",
    path: "/tickets",
    permissions: [
      { id: "view_tickets", name: "view", label: "View" },
      { id: "create_tickets", name: "create", label: "Create" },
      { id: "edit_tickets", name: "edit", label: "Edit" },
    ],
  },
  {
    id: "customers",
    name: "Customers",
    path: "/customers",
    permissions: [{ id: "view_customers", name: "view", label: "View" }],
  },
  {
    id: "appointments",
    name: "Appointments",
    path: "/appointments",
    permissions: [
      { id: "view_appointments", name: "view", label: "View" },
      { id: "assign_appointments", name: "assign", label: "Assign to Agent" },
    ],
  },
  {
    id: "callbacks",
    name: "Call-Backs",
    path: "/callbacks",
    permissions: [
      { id: "view_callbacks", name: "view", label: "View" },
      { id: "assign_callbacks", name: "assign", label: "Assign to Agent" },
      { id: "make_callback_call", name: "makeCall", label: "Make Call" },
    ],
  },
  {
    id: "agentSessions",
    name: "Agent Sessions",
    path: "/agent-sessions",
    permissions: [{ id: "view_agent_sessions", name: "view", label: "View" }],
  },
  {
    id: "botSessions",
    name: "Bot Sessions",
    path: "/bot-sessions",
    permissions: [{ id: "view_bot_sessions", name: "view", label: "View" }],
  },
  {
    id: "liveConversations",
    name: "Live Conversations",
    path: "/live-conversations",
    permissions: [{ id: "view_live_conversations", name: "view", label: "View" }],
  },
  {
    id: "botProfiles",
    name: "BOT Profiles",
    path: "/bot-profiles",
    permissions: [
      { id: "view_bot_profiles", name: "view", label: "View" },
      { id: "modify_bot_profiles", name: "modify", label: "Modify" },
      {
        id: "configure_business_hours",
        name: "configureBusinessHours",
        label: "Configure Business Hours",
      },
      { id: "configure_bot", name: "configure", label: "Configure BOT" },
    ],
  },
  {
    id: "payments",
    name: "Payments",
    path: "/payments",
    permissions: [{ id: "view_payments", name: "view", label: "View" }],
  },
  {
    id: "eventLog",
    name: "Event Log",
    path: "/event-log",
    permissions: [{ id: "view_event_log", name: "view", label: "View" }],
  },
  {
    id: "account",
    name: "Account",
    path: "/account",
    permissions: [
      { id: "view_account", name: "view", label: "View" },
      { id: "edit_account", name: "edit", label: "Edit" },
    ],
  },
];