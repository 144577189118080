import { gql } from "@apollo/client";

export const BUSINESS_HOURS_BUSINESS_ADMIN_CALL_FOR_HELP = gql`
    fragment BusinessHoursAccountBotProfile on BotProfile {
        id
        name
        description
        businessHours
        openingHours

    }
`;
