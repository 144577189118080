import { gql } from "@apollo/client";
import { LIST_ADMIN_AUDITS } from "../../../../adapters/fragments/ListAdminAudits";

export const AUDIT_ADMIN_REFETCH = gql`
    ${LIST_ADMIN_AUDITS}
    query AuditAdminFragmentRefetchQuery(
        $count: Int 
        $reversecount: Int
        $startcursor: String
        $endcursor: String
        $search: String
    ) {
        ...ListAdmin_audits_1G22uz
    }
`;
