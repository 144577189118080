import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    box: {
      display: "flex",
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      justifyContent: "center",
      alignItems: "center",
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.primary.main,
      borderWidth: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
  })
);
