import { gql } from "@apollo/client";

export const EDIT_BUSINESS_ADMIN_CALL_FOR_HELP_DATA = gql`
    fragment EditAccountBotProfile on BotProfile {
        id
        name
        description
        isAgentRequired
        isBotInterectionsOn
        isWhatsappRequired
        isChannelSwitchingOn
        isCallbackOn
        isWebRTCVideoRequired
        isScreenSharingRequired
        isAppointment
        isForwardToPhoneRequired
        isRaiseTicketRequired
        forwardNumber
        forwardMessage
        domain
        languages
        primaryLanguage
        status
        profilePicture
        priority
        callRecording
    }
`;
