import { ModuleRouteConfig } from "common/types/RouteTypes";
import CustomerList from "modules/customers/pages/CustomersPage";

export const CustomerRoutes: ModuleRouteConfig[] = [
  {
    permissionId: "view_customers",
    path: "",
    element: <CustomerList />,
  },
];
