import { gql } from "@apollo/client";

export const LIST_ROLES = gql`
  query ListRoles {
    listRoles {
        id
        name
        companyId
        parentRoleId
    }
  }
`;
