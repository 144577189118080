import { gql } from "@apollo/client";
import { LIST_BUSINESS_ADMIN_PAYMENTS } from "adapters/fragments/ListBusnessAdminPayments";

export const PAYMENT_BUSINESS_ADMIN_REFETCH = gql`
    ${LIST_BUSINESS_ADMIN_PAYMENTS}
    query PaymentAccountFragmentRefetchQuery(
        $count: Int = 10
        $cursor: String
    ) {
        ...ListAccount_payments_1G22uz
    }
`;
