import usePaginationQuery from "common/components/datapage/usePaginationQuery";
import DataPage from "common/components/datapage/DataPage";
import CommonToolbar from "common/components/toolbar/Toolbar";
import CommonSearchbar from "common/components/searchbar/Searchbar";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import PendingItem from "../components/list/pendingItem/PendingItem";
import AssignedItem from "../components/list/assigned_item/AssignedItem";
import { useHasItemPermission } from "common/layouts/utils/RouteUtils";
import RejectedItem from "../components/list/rejected_item/RejectedItem";
import { useEffect } from "react";
import { CallbackStatus } from "components/callbacks/searchbar/Searchbar";
import { CALLBACK_BUSINESS_ADMIN_REFETCH } from "../adapters/queries/CallbackstBusinessAdminRefetch";
import CompletedItem from "../components/list/completed_item/CompletedItem";
import CallbackItem from "../components/list/list_item/ListItem";
import NoteDialog from "../components/list/notes";
import AudioDialog from "../components/list/audio_modal/Modal";

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null;
}

const Appointments = (props: any): JSX.Element => {

  const { user } = useSelector((state: RootState) => state.auth);
  const assigneeId = user?.role === 'owner' ? null : user?.id;
  let customerId = props.match?.params?.customerId;
  const [showNote, setShowNote] = useState(false)
  const [noteNode, setNoteNode] = useState(false)
  const [showAudio, setShowAudio] = useState(false)
  const [audioNode, setAudioNode] = useState(false)
  const [noteCallbackId, setNoteCallbackId] = useState("")
  const [callbackStatus, setCallbackStatus] = useState<string>("");

  const {
    data,
    loading,
    onPageChange,
    onRowsPerPageChange,
    setSearchQuery,
    page,
    count,
    setSort,
  } = usePaginationQuery(CALLBACK_BUSINESS_ADMIN_REFETCH, {customerId: customerId, assigneeId}, "callbacks");

  const columns = [
    { key: "customer.firstName", label: "Customer" },
    { key: "status", label: "Status" },
    { key: "reason", label: "Reason" },
    { key: "startDate", label: "request time" },
    { key: "user.firstName", label: "Assigned To" },
  ];

  useEffect(() => {
    document.title = "Callbacks";
  }, []);

  const callbackList = () => {
    const { callbacks } = data || {};
    return callbacks;
  };
  const callbacks = callbackList();
  const totalCount = callbacks?.totalCount || 0;
  const list = useMemo(() => {
    return callbacks && callbacks.edges
      ? callbacks.edges
        .filter(isNotNull)
        .map((edge: any) => edge.node)
        .filter(isNotNull)
      : [];
  }, [callbacks]);

  const canEditAssignCallbacks = useHasItemPermission("callbacks","assign_callbacks")

  return (
    <div>
    <DataPage
      title="Appointments"
      columns={columns}
      data={list}
      totalCount={totalCount}
      loading={loading}
      page={page}
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      setSort={setSort}
      renderItem={(node: any) => {
        if (node == null) {
          return null;
        }
        if (node?.status == CallbackStatus.REQUEST) {
          return (
            <PendingItem
              request={node}
              key={node.id}
              getAssignedList={() => {
                setCallbackStatus(CallbackStatus.ASSIGNED);
              }}
              getPendingList={() => {
                setCallbackStatus(CallbackStatus.REQUEST);
              }}
            />
          );
        }

        if (node?.status == CallbackStatus.ASSIGNED && canEditAssignCallbacks) {
          return (
            <AssignedItem
              assigned={node}
              key={node.id}
              getAssignedList={() => {
                setCallbackStatus(CallbackStatus.ASSIGNED);
              }}
              getRejectedList={() => {
                setCallbackStatus(CallbackStatus.REJECTED);
              }}
            />
          );
        }

        if (node?.status == CallbackStatus.REJECTED) {
          return <RejectedItem request={node} key={node.id} />;
        }

        if (node?.status == CallbackStatus.COMPLETE) {
          return (
            <CompletedItem
              // showIcon={
              //   searchQuery?.status !== CallbackStatus.COMPLETE
              // }
              callback={node}
              key={node.id}
              openNote={(id: string) => {
                setNoteCallbackId(id)
                setNoteNode(node)
                setShowNote(true)
              }}
              openRecording={(node: any) => {
                setAudioNode(node)
                setShowAudio(true)
              }}
            />
          );
        }

        return (
          <CallbackItem
            getCompletedList={() => {
              setCallbackStatus(CallbackStatus.COMPLETE);
            }}
            callback={node}
            key={node.id}
          />
        );

      }}
      ToolbarComponent={
        <CommonToolbar title="Appointments" />
      }
      SearchbarComponent={
        <CommonSearchbar
          defaultValues={{ search: "" }}
          fields={[
            {
              name: "search",
              // label: "Search by name or email",
              type: "text",
            },
          ]}
          onSearch={(query: any) => {
            setSearchQuery(query);
            // callbackStatus={callbackStatus}
          }}
          status={callbackStatus}
        />
      }
      paginationProps={{ rowsPerPageOptions: [10, 25, 50] }} // Example customization
    />
    {showNote && <NoteDialog open={showNote} onClose={() => { setShowNote(false) }} callbackId={noteCallbackId} node={noteNode} />}
    {showAudio && <AudioDialog open={showAudio} onClose={() => { setShowAudio(false) }} node={audioNode} />}
    </div>
  );
};

export default Appointments;
