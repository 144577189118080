import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            [theme.breakpoints.up("md")]: {
                width: "60%"
            }
        },
        feedback: {
            marginTop: theme.spacing(2),
            textTransform: "capitalize"
        },
        title: {
            fontSize: 14
        },
        error: {
            textTransform: "capitalize"
        },
        button: {
            color: "white"
        },
        buttonWrapper: {
            position: "relative"
        },
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -9,
            marginLeft: -12
        },
        option: {
            fontSize: 15,
            "& > span": {
                marginRight: 10,
                fontSize: 18
            }
        },
        formInput: {
            marginBottom: theme.spacing(2) 
        },
        signIn: {
            marginTop: theme.spacing(2), 
            marginBottom: theme.spacing(2), 
            color: theme.palette.primary.main,
            textAlign: "center" 
        },
        logoContainer: {
            textAlign: "center", 
            marginBottom: theme.spacing(2) 
        },
        logo: {
            width: "50%"
        },
        form: {
            marginTop: theme.spacing(2) 
        },
        submit: {
            marginTop: theme.spacing(2) 
        },
        btnWrapper: {
            position: "relative", 
            marginTop: theme.spacing(2) 
        },
        formContainer: {
            padding: theme.spacing(6), 
            marginTop: theme.spacing(3)
          },
        centerTitle: {
            textAlign: 'center', 
        },
        gridItem: {
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        centerButton: {
            justifyContent: 'center',
          },
          input: {
            marginBottom: theme.spacing(2), 
          },
    })
);

