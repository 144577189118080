import { gql } from "@apollo/client";

export const LIST_ADD_ON_PAYMENT_HISTORY = gql`
  query ListAddOnUsageDetails {
    listAddOnPurchaseHistory {
      id
      createdAt
      addOnId
      addOnName
      purchaseStatus
      totalAmount
    }
  }
`
;