import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      [theme.breakpoints.up("md")]: {
        width: "60%"
      }
    },
    form: {},
    card: {},
    cardHeader: {},
    cardTitle: {
      fontSize: "1.25rem"
    },
    cardSubheader: {
      fontSize: "0.875rem"
    },
    feedback: {
      marginTop: theme.spacing(2),
      textTransform: "capitalize"
    },
    title: {
      fontSize: 14
    },
    error: {
      textTransform: "capitalize"
    },
    button: {
      color: "white",
      padding: theme.spacing(1, 3), // Added padding for button size
    },
    buttonWrapper: {
      position: "relative",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end', // Ensures buttons align to the right
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1), // Adjust padding if needed
    },
    buttonContainerEmailList: {
      display: 'flex',
      justifyContent: 'flex-end', // Aligns buttons to the right
      gap: theme.spacing(2), // Adds space between buttons
      width: '100%',
      boxSizing: 'border-box',
      padding: theme.spacing(2), // Optional: Add padding for spacing
    },
    emailField: {
      marginBottom: 20,
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -9,
      marginLeft: -12
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18
      }
    },
    expandCollapseButton: {
      border: "1px solid #862f98",
      padding: "4px 8px",
      borderRadius: "8px",
      backgroundColor: "transparent",
      cursor: "pointer",
      display: "inline-block",
      '&:hover': {
        backgroundColor: "#862f98",
        '& .hover-text': {
          color: "white",
        },
      },
    },
  })
);
