import clsx from "clsx";
import { CardHeader } from "@mui/material";
import { useStyles } from "./style";

interface IProps {
  className?: string;
  title?: string;
  subtitle?: string;
  action?: number;
  role?: string;
  isAgent?: boolean;
}

const Toolbar = ({
  className,
  title,
  ...rest
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={clsx(className)} {...rest}>
      <CardHeader
        title={title}
        className={classes.cardHeader}
        titleTypographyProps={{
          className: classes.cardTitle,
        }}
      />
    </div>
  );
};

export default Toolbar;
